import { useParams } from 'react-router';
import { EmailTemplateTypes } from 'shared';

import { useFetchEmailTemplatesQuery } from '../services/api/emailTemplates/emailTemplates';

export function useEmailTemplates() {
  const { organisationId } = useParams();

  const result = useFetchEmailTemplatesQuery(organisationId);

  return result;
}

export function useEmailTemplatesByType(type: EmailTemplateTypes) {
  const result = useEmailTemplates();

  return result.data?.filter(t => t.type === type) ?? [];
}
