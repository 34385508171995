import { IInvalidField, IUpdateSettingsRequest } from 'shared';

import { FormLabel } from '../../../../../common/Atoms/FormLabel';
import { FormDescription } from '../../../../../common/Atoms/FormDescription';
import { TwoColFormRow } from '../../../Molecules/Form/TwoColFormRow';

import { FileUpload } from './FileUpload';
import { OnChangeFn } from './GeneralSettings';

interface BrandingProps {
  formData: IUpdateSettingsRequest;
  onChange: OnChangeFn;
  invalids: IInvalidField[];
}

export function Branding({
  formData,
  onChange,
}: BrandingProps) {

  return (
    <TwoColFormRow
      heading={ `Branding` }
    >
      { /* Logo Upload */ }
      <FormLabel name={ `logoUrl` }>{ `Logo` }</FormLabel>
      <FormDescription>
        { `Will be used on outbound emails and statements` }
      </FormDescription>
      <FileUpload
        url={ formData.logoUrl }
        setUrl={ v => onChange(`logoUrl`, v) }
      />

    </TwoColFormRow>

  );
}
