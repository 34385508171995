import { useMemo } from "react";
import tzJson from "timezones.json";

export function useTimezoneList(filterStr?: string) {
  const options = useMemo(() => {
    const tzs: {
      [name: string]: string;
    } = {};

    const allTzs = tzJson.map(tzObj => tzObj.utc).flat().map(iana => ({
      label: iana,
      value: iana,
    }))
      .concat(...[{
        label: `Not Synced`,
        value: `NOT_SYNCED`,
      }, {
        label: `Unknown`,
        value: `UNKNOWN`,
      }]);

    tzJson.forEach(tzObj => {
      tzObj.utc.forEach(iana => {
        if (filterStr) {
          if (!iana.toLowerCase().includes(filterStr.toLowerCase())) {
            return;
          }
        }

        tzs[iana] = iana;
      });
    });

    const tzsArr = Object.keys(tzs).map(iana => ({
      label: iana,
      value: iana,
    })).concat(...[{
      label: `Not Synced`,
      value: `NOT_SYNCED`,
    }, {
      label: `Unknown`,
      value: `UNKNOWN`,
    }]);

    return {
      allOptions: allTzs,
      filteredOptions: tzsArr,
    };
  }, [tzJson, filterStr]);

  return options;
}
