import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { When } from 'react-if';
import { useLocation } from 'react-router';

import { Paragraph } from '../../../../common/Atoms/Typography/Paragraph';
import { HelpIcon } from '../../Molecules/HelpIcon';
import { useGroups } from '../../../hooks/useGroups';
import { useAddContactsToGroupMutation } from '../../../services/api/groupApi/group';
import { LoadingOverlay } from '../../Molecules/LoadingOverlay';
import { useGetSelectedOrganisation } from '../../../hooks/useGetSelectedOrganisation';
import { useAccountingLanguge } from '../../../hooks/useAccountingLanguage';
import { Dropdown } from '../../../../common/Atoms/Dropdown';
import { useContactParams } from '../../../hooks/useContacts';
import { Button } from '../../../../common/Atoms/Button';

import { ContactTableRow } from './ContactTableRow';
import { ApplyTags } from './ApplyTags';
import { SendAnnouncement } from './SendAnnouncement';

export interface IContactPersonRow {
  name: string;
  email?: string;
  mobileNumber?: string;
  includeInNotifications?: boolean;
  isPrimary?: boolean;
}

export interface IContactTableRow {
  id: number;
  name: string;
  group?: string;
  tags: string[];
  externalAccountNumber?: string;
  unpaidInvoiceCount: number;
  externalAggregateNames?: string[];
  balances: {
    overdue?: string;
    outstanding: string;
  };
  contactPersons: IContactPersonRow[]
}

interface IContactTableProps {
  rows: IContactTableRow[];
}

export function ContactTable({
  rows,
}: IContactTableProps) {
  const currentOrg = useGetSelectedOrganisation();
  const { data: groups } = useGroups();
  const [addContactsToGroup, { isLoading }] = useAddContactsToGroupMutation();
  const [selected, setSelected] = useState<number[]>([]);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const accountingLanguage = useAccountingLanguge();
  const { params, resetParams } = useContactParams();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const checkbox = useRef();

  // On first mount, reset the filters
  useEffect(() => {
    if (urlParams.has(`persist`)) {
      // Do nothing
    }
    else {
      console.log(`Resetting params`);
      resetParams();
    }
  }, []);

  useLayoutEffect(() => {
    const isIndeterminate = selected.length > 0 && selected.length < rows.length;
    setChecked(selected.length === rows.length);
    setIndeterminate(isIndeterminate);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    checkbox.current.indeterminate = isIndeterminate;
  }, [selected, rows]);

  const groupOptions = useMemo(() => {
    if (!groups) return [];

    return groups.map(g => ({
      label: g.title,
      value: g.id,
    }));
  }, [groups]);

  const filtersAsText = useMemo(() => {
    if (!params) return ``;

    const searches = [];

    if (params.searchStr) {
      searches.push(`"${params.searchStr}"`);
    }

    if (params.groupId) {
      const group = groups?.find(g => g.id === params.groupId);
      if (group) {
        searches.push(`Paidnice Group: ${group.title}`);
      }
    }

    if (params.externalAggregateName) {
      searches.push(`"${params.externalAggregateName}"`);
    }

    if (searches.length === 0) return ``;

    return `No contacts found matching search: ${searches.join(` + `)}`;
  }, [params]);

  const filterCount = useMemo(() => {
    let count = 0;

    if (params.groupId) count++;

    if (params.externalAggregateName) count++;

    if (params.searchStr) count++;

    return count;
  }, [params]);

  function clearFilters() {
    resetParams();
  }

  function toggleAll() {
    setSelected(checked || indeterminate ? [] : rows.map(i => i.id));
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  async function onMoveToGroup(e) {
    try {
      await addContactsToGroup({
        contactIds: selected,
        id: e.value,
        organisationId: currentOrg.id,
      });

      setSelected([]);
    }
    catch (e) {
      console.error(e);
    }
  }

  const {
    contacts: {
      externalAggregateNameLabel,
      externalAggregateNameDescription,
      externalAccountNumberLabel,
    },
  } = accountingLanguage;

  return (
    <LoadingOverlay
      loading={ isLoading }
    >

      <div className={ `mt-8 flow-root` }>
        { /* Selected options */ }
        <div className={ `mb-4 border-y py-4 border-gray-200 flex space-x-4` }>
          { /* Groups */ }
          <div
            className={ `flex flex-col` }
            data-tour={ `contactMoveDD` }
          >
            <Dropdown
              size={ `md` }
              position={ `right` }
              label={
                selected.length === 0 ?
                  `Move contacts to group` :
                  `Move ${selected.length} contacts to group`
              }
              options={ [groupOptions] }
              disabled={ !selected.length  }
              onSelect={ onMoveToGroup }
            />
            <When condition={ selected.length === 0 }>
              <Paragraph
                className={ `pt-1.5` }
                variant={ `help` }
              >
                { `No contacts selected.` }
              </Paragraph>
            </When>
            { /* Tags */ }
          </div>
          <ApplyTags
            selectedContacts={ selected }
          />
          <SendAnnouncement
            selectedContacts={ selected }
          />

        </div>
        <div className={ `-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8` }>
          <div className={ `min-w-full py-2 align-middle sm:px-6 lg:px-8` }>
            <table className={ `min-w-full` }>
              <thead className={ `bg-white` }>
                <tr>
                  <th scope={ `col` }
                    className={ `relative w-12 px-6 sm:w-16 sm:px-8` }>
                    <input
                      type={ `checkbox` }
                      className={ `absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-lateGreen-600 focus:ring-lateGreen-500 sm:left-6` }
                      ref={ checkbox }
                      checked={ checked }
                      onChange={ toggleAll }
                    />
                  </th>
                  <th scope={ `col` }
                    className={ `py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3` }>
                    <span className={ `flex flex-col` }>
                      { `Name` }
                      <Paragraph
                        as={ `span` }
                        variant={ `subHeading` }
                      >
                        { `People` }
                      </Paragraph>
                    </span>
                  </th>

                  <th scope={ `col` }
                    className={ `px-3 py-3.5 text-left text-sm font-semibold text-gray-900` }>
                    <span className={ `flex flex-col` }>
                      <div className={ `flex` }>
                        { `Paidnice Group` }
                        <HelpIcon
                          className={ `ml-1.5` }
                          tooltip={ `The Paidnice Group this contact belongs to. This is not the group from your accounting system` }
                        />
                      </div>
                      <Paragraph
                        as={ `span` }
                        variant={ `subHeading` }
                      >
                        { `Email` }
                      </Paragraph>
                    </span>
                  </th>

                  <th scope={ `col` }
                    className={ `px-3 py-3.5 text-left text-sm font-semibold text-gray-900` }>
                    <span className={ `flex flex-col` }>
                      <div className={ `flex` }>
                        <div className={ `flex` }>
                          { externalAggregateNameLabel }
                          <HelpIcon
                            className={ `ml-1.5` }
                            tooltip={ externalAggregateNameDescription }
                          />
                        </div>
                      </div>
                      <div className={ `flex` }>
                        <Paragraph
                          as={ `span` }
                          variant={ `subHeading` }
                        >
                          { `Mobile Number` }
                        </Paragraph>
                        <HelpIcon
                          className={ `ml-1.5` }
                          tooltip={ `If the mobile number is not found. Ensure it is saved in the correct form field in your accounting system` }
                        />
                      </div>
                    </span>
                  </th>
                  <th scope={ `col` }
                    className={ `px-3 py-3.5 text-left text-sm font-semibold text-gray-900` }>
                    <span className={ `flex flex-col` }>
                      { externalAccountNumberLabel }
                      <div className={ `flex` }>
                        <Paragraph
                          as={ `span` }
                          variant={ `subHeading` }
                        >
                          { `Included in Notifications` }
                        </Paragraph>
                        <HelpIcon
                          className={ `ml-1.5` }
                          tooltip={ `Should this contact receive email and SMS notifications when applicable` }
                        />
                      </div>
                    </span>
                  </th>

                  <th scope={ `col` }
                    className={ `relative py-3.5 pl-3 pr-4 sm:pr-3` }>
                    { /* <span className={ `sr-only` }>{ `Edit` }</span> */ }
                  </th>
                </tr>
              </thead>
              <tbody className={ `bg-white` }>
                <When condition={ rows.length === 0 && filtersAsText }>
                  <div className={ `mt-12` }></div>
                  <tr>
                    <td colSpan={ 12 }
                      className={ `text-center` }
                    >
                      <Paragraph>
                        { filtersAsText }
                      </Paragraph>
                      <When condition={ filterCount > 0 }>
                        <Button
                          onClick={ clearFilters }
                          className={ `mb-1` }
                          disabled={ filterCount === 0 }
                        >
                          { `Try Clearing ${filterCount} filter${filterCount === 1 ? `` : `s`}` }
                        </Button>
                      </When>
                    </td>
                  </tr>
                </When>
                { rows.map(row => (
                  <ContactTableRow
                    row={ row }
                    setSelected={ setSelected }
                    selectedIds={ selected }
                    key={ row.name }
                  />
                )) }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
}
