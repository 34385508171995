import { useCallback, useEffect, useMemo, useState } from "react";
import { toDecimal } from "shared";
import { Else, If, Then } from "react-if";

import { Card } from "../../../../common/Atoms/Card";
import { useOrgMetrics } from "../../../hooks/useOrgMetrics";
import { Select } from "../../../../common/Atoms/Select";
import { Heading } from "../../../../common/Atoms/Typography/Heading";
import { MultiButtonCardHeader } from "../../../../common/Atoms/MultiButtonCardHeader";
import { CardContent } from "../../../../common/Atoms/CardContent";
import { LoadingElementPlaceholder } from "../../../../common/Atoms/LoadingElementPlaceholder";

export function InvoiceValue() {
  const { data: metrics, isLoading } = useOrgMetrics();
  const [currentCurrency, setCurrentCurrency] = useState(``);
  const [currentBalances, setCurrentBalances] = useState({
    amountOverdueCents: 0,
    amountOutstandingCents: 0,
    amountCreditCents: 0,
    averageDaysOverdue: 0,
  });

  const possibleCurrencies = useMemo(() => {
    if (!metrics) return [];

    return Object.keys(metrics.balances);
  }, [metrics]);

  useEffect(() => {
    if (!currentCurrency && possibleCurrencies.length > 0) {
      setCurrentCurrency(possibleCurrencies[0]);
    }

    if (!possibleCurrencies.includes(currentCurrency)) {
      setCurrentCurrency(possibleCurrencies[0]);
    }
  }, [possibleCurrencies, currentCurrency]);

  useEffect(() => {
    if (!metrics) return;

    if (metrics.balances[currentCurrency]) {
      setCurrentBalances(metrics.balances[currentCurrency]);
    }
  }, [currentCurrency, metrics]);

  const formatter = useCallback(() => {
    return new Intl.NumberFormat(`en-US`, {
      style: `currency`,
      currency: currentCurrency || `USD`,
    });
  }, [ currentCurrency ]);

  return (
    <Card
      className={ `min-h-full` }
    >
      <MultiButtonCardHeader
        helpText={ `These values may differ from your accounting system due to Credit Notes not being included and FX rates` }
        title={ `Balances` }
        description={ `Current balances as of today` }
        cta={
          <div className={ `w-32` }>
            <Select
              options={ possibleCurrencies.map(currency => ({ label: currency, value: currency })) }
              onChange={ value => setCurrentCurrency(value.value) }
              selected={ currentCurrency }
            />
          </div>
        }
      >
      </MultiButtonCardHeader>
      <CardContent>
        <If condition={ isLoading }>
          <Then>
            <LoadingElementPlaceholder
              rows={ 3 }
              border={ false }
            />
          </Then>
          <Else>
            <div className={ `grid grid-cols-2 md:grid-cols-3 py-6` }>
              { /* Outstanding Balance */ }
              <div className={ `pr-4 mr-4 border-r border-gray-300 h-full flex flex-col justify-between` }>
                <Heading>
                  { `Outstanding` }
                </Heading>
                <Heading
                  className={ `text-sm xl:text-lg` }
                >
                  {
                    formatter().format(toDecimal(currentBalances.amountOutstandingCents))
                  }
                </Heading>
              </div>
              { /* Overdue Balance */ }
              <div className={ `pr-4 mr-4 border-r border-gray-300 h-full flex flex-col justify-between` }>
                <Heading>
                  { `Overdue` }
                </Heading>
                <Heading
                  className={ `text-red-500 text-sm xl:text-lg` }
                >
                  {
                    formatter().format(toDecimal(currentBalances.amountOverdueCents))
                  }
                </Heading>
              </div>
              { /* Credit balance */ }
              <div className={ `pr-4 mr-4 h-full flex flex-col justify-between` }>
                <Heading>
                  { `Credit Balance` }
                </Heading>
                <Heading
                  className={ `text-sm xl:text-lg` }
                >
                  { `-` }
                  {
                    formatter().format(toDecimal(currentBalances.amountCreditCents))
                  }
                </Heading>
              </div>

              { /* Average days overdue */ }
            </div>
            <div className={ `flex justify-center` }>
              <div className={ `h-full flex flex-col justify-around text-center` }>
                <Heading>
                  { `Average Overdue Days` }
                </Heading>
                <Heading
                  className={ `text-sm xl:text-lg` }
                >
                  {
                    currentBalances.averageDaysOverdue
                  }
                </Heading>
              </div>
            </div>
          </Else>
        </If>
      </CardContent>
    </Card>
  );
}
