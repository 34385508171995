import { createSlice } from '@reduxjs/toolkit';
import { EscalationSchedule, EscalationStatus, IEscalationParams } from 'shared';

export const initialState: IEscalationParams = {
  invoiceId: null,
  organisationId: null,
  type: null,
  status: EscalationStatus.PENDING,
  scheduled: EscalationSchedule.READY,
  page: 1,
  limit: 300,
  searchText: null,
  includePaid: false,
  contactId: null,
  assignedUserId: null,
};

const escalationParamsSlice = createSlice({
  name: `escalationParams`,
  initialState,
  reducers: {
    setParams(state, escalation) {
      Object.assign(state, escalation.payload);
    },
  },
});

export const { setParams } = escalationParamsSlice.actions;

export const escalationParamsReducer = escalationParamsSlice.reducer;
