import { useParams } from "react-router";

import { useFetchAuditLogsQuery } from "../services/api/auditLogApi/auditLog";

export function useAuditLogs() {
  const { organisationId } = useParams();

  const logsResult = useFetchAuditLogsQuery({
    organisationId,
  }, {
    skip: !organisationId,
    refetchOnMountOrArgChange: true,
  });

  return logsResult;
}
