import { When } from 'react-if';

import { classNames }  from '../lib/classNames';
import { HelpIcon } from '../../main/components/Molecules/HelpIcon';

import { FormLabel } from './FormLabel';
import { FormDescription } from './FormDescription';

interface ICheckboxProps {
  checked: boolean;
  onChange: (obj: {value: boolean, name: string}) => void;
  name?: string;
  label?: string;
  className?: string;
  disabled?: boolean;
  description?: string;
  placement?: `left` | `right`;
  helpIcon?: string;
}

export function CheckBox({
  checked,
  onChange,
  name,
  label,
  className,
  disabled,
  description,
  placement = `right`,
  helpIcon,
}: ICheckboxProps) {

  function handleChange(e) {
    onChange({
      value: e.target.checked,
      name,
    });
  }

  return (
    <div className={
      classNames(
        `relative flex items-center`,
        className,
      )
    }>
      {
        placement === `left` ? (
          <>
            <div className={ `mr-3 flex h-6 items-center` }>
              <input
                id={ name }
                name={ name }
                type={ `checkbox` }
                checked={ checked }
                onChange={ handleChange }
                disabled={ disabled }
                className={ `h-4 w-4 rounded border-gray-300 text-lateGreen-600 focus:ring-lateGreen-600` }
              />
            </div>
            <div className={ `min-w-0 text-sm leading-6` }>
              <FormLabel
                name={ name }
                className={ `flex` }
              >
                { label }
                <When condition={ !!helpIcon }>
                  <HelpIcon
                    className={ `ml-1` }
                    tooltip={ helpIcon }
                  />
                </When>
              </FormLabel>
              <FormDescription>
                { description }
              </FormDescription>
            </div>
          </>
        )
          : (
            <>
              <div className={ `min-w-0 text-sm leading-6` }>
                <FormLabel
                  name={ name }
                  className={ `flex` }
                >
                  { label }
                  <When condition={ !!helpIcon }>
                    <HelpIcon
                      className={ `mr-1` }
                      tooltip={ helpIcon }
                    />
                  </When>
                </FormLabel>
                <FormDescription>
                  { description }
                </FormDescription>
              </div>
              <div className={ `ml-3 flex h-6 items-center` }>
                <input
                  id={ name }
                  name={ name }
                  type={ `checkbox` }
                  checked={ checked }
                  onChange={ handleChange }
                  disabled={ disabled }
                  className={ `h-4 w-4 rounded border-gray-300 text-lateGreen-600 focus:ring-lateGreen-600` }
                />
              </div>
            </>
          )
      }
    </div>
  );
}
