// import './MentionList.scss';

import {
  forwardRef, useEffect, useImperativeHandle,
  useState,
} from 'react';

import { Card } from '../../../../common/Atoms/Card';
import { classNames } from '../../../../common/lib/classNames';

interface IMentionListProps {
  items: string[];
  command: (props: { id: string }) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MentionList = forwardRef<any, IMentionListProps>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = index => {
    const item = props.items[index];

    if (item) {
      props.command({ id: item });
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === `ArrowUp`) {
        upHandler();

        return true;
      }

      if (event.key === `ArrowDown`) {
        downHandler();

        return true;
      }

      if (event.key === `Enter`) {
        enterHandler();

        return true;
      }

      return false;
    },
  }));

  return (
    <Card
      className={ `flex flex-col items-start p-0 sm:p-0 py-1` }
    >
      { props.items.length
        ? props.items.map((item, index) => (
          <button
            className={ classNames(
              `w-full text-left px-1`,
              index === selectedIndex ? `bg-lateGreen-200 rounded-md` : ``,
            ) }
            key={ index }
            aria-label={ item }
            onClick={ () => selectItem(index) }
          >
            { item }
          </button>
        ))
        : <div className={ `item` }>{ `No result` }</div>
      }
    </Card>
  );
});

MentionList.displayName = `MentionList`;

