import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { useFetchContactsQuery } from '../services/api/contactsApi/contacts';
import { RootState } from '../store';
import { initialState, setParams } from '../slices/contactParams';

export function useContacts() {
  const { organisationId, groupId: groupIdParam } = useParams<{ organisationId: string, groupId?: string }>();
  const { params } = useContactParams();

  const groupId = groupIdParam ? Number(groupIdParam) : undefined;

  const result = useFetchContactsQuery({
    ...params,
    // If groupId is present in URL, then override the params, otherwise the group ID params takes precedence, it may be defined or null
    groupId: groupId ? groupId : params.groupId,
    organisationId,
  }, {
    skip: !organisationId,
    refetchOnMountOrArgChange: true,
    pollingInterval:  1000 * 15,
    skipPollingIfUnfocused: true,
  });

  return result;
}

export function useContactParams() {
  const params = useSelector((s: RootState) => s.contactParams);
  const dispatch = useDispatch();

  const updateParams = (update: Partial<typeof params>) => {
    return dispatch(setParams(update));
  };

  const resetParams = (keepKeys?: string[]) => {
    const newState = { ...initialState };

    if (keepKeys) {
      keepKeys.forEach(key => {
        newState[key] = params[key];
      });
    }

    dispatch(setParams(newState));
  };

  return {
    resetParams,
    updateParams,
    params,
  };
}
