import { allowedSubscriptionStatus, IPlanV3, plansV3, StripePricesLookupKeys } from "shared";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useMemo } from "react";
import { useParams } from "react-router";
import { When } from "react-if";

import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { Heading } from "../../../common/Atoms/Typography/Heading";
import { SmallSpinner } from "../../../common/Atoms/SmallSpinner";
import { usePlans } from "../../hooks/usePlans";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { Notification } from "../../../common/Atoms/Notification";

import { NewPlan } from "./NewPlan";

export function NewPlans() {
  const { isLoading } = usePlans();
  const { data: subscriptions } = useSubscriptions();
  const id = Number(useParams().subscriptionId);

  const subscription = useMemo(() => {
    return subscriptions.find(s => s.id === id);
  }, [ subscriptions]);

  const filteredPlans = useMemo(() => {
    const currentPlan = subscription?.mainLookupKey as StripePricesLookupKeys;

    if (currentPlan === StripePricesLookupKeys.Essentials50) {

      // if essentials 50 then use all plans
      return plansV3;
    }

    return Object.entries(plansV3).reduce((acc, [key, product]) => {
      if (product.name !== `Essentials`) {
        acc[key] = product;
      }
      else {
        // take the essentials 50 out of the prices list
        const filteredPrices = {};
        for (const [priceKey, price] of Object.entries(product.prices)) {
          if (priceKey !== StripePricesLookupKeys.Essentials50) {
            filteredPrices[priceKey] = price;
          }
        }

        acc[key] = { ...product, prices: filteredPrices };
      }

      return acc;
    }, {} as typeof plansV3);
  }, [subscriptions]);

  const showTrialMessage = !allowedSubscriptionStatus.includes(subscription?.status);

  if (isLoading) return (
    <div className={ `flex justify-center pt-6` }>
      <SmallSpinner
        colored
        size={ `lg` }
      />
    </div>
  );

  return (
    <div>
      <div className={ `flex justify-center pt-6` }>
        <Grid2
          container
          columns={ 2 }
          spacing={ 3 }
        >
          {
            Object.entries(filteredPlans).map(([key, product]) => (
              <NewPlan
                key={ key }
                plan={ product as IPlanV3 }
              />
            ))
          }
        </Grid2>
      </div>
      <div className={ `flex justify-center pt-1` }>
        <Paragraph
          className={ `text-center` }
          variant={ `help` }>
          { `All prices in USD. Price excludes any applicable taxes. Billed Monthly. Cancel Anytime.` }
        </Paragraph>
      </div>
      <div className={ `flex justify-center pt-1` }>
        <Paragraph
          className={ `text-center` }
          variant={ `help` }>
          { `*Invoices means number of invoices we needed to track. E.g Only unpaid invoices in most cases.` }
        </Paragraph>
      </div>
      <When condition={ showTrialMessage }>
        <Notification
          type={ `info` }
          className={ `mt-6` }
        >
          { `The way trials work has recently changed. You can now use Paidnice fully for free for the first 20 actions.` }
        </Notification>
      </When>
      <div className={ `flex justify-center pt-12` }>
        <div className={ `flex flex-col items-center` }>
          <Heading
            size={ `3xl` }
            variant={ `secondary` }
          >
            { `F.A.Qs` }
          </Heading>
          <div className={ `space-y-3` }>
            { /* <Heading>
              { `What is counted as an action?` }
            </Heading>
            <Paragraph>
              { `An action is when we issue a late fee, remove a discount or send an SMS message` }
            </Paragraph> */ }

            <Heading>
              { `What is counted an email?` }
            </Heading>
            <Paragraph>
              { `An email is when we send an auto-statement, reminder, late fee & discount notification emails` }
            </Paragraph>

            <Heading>
              { `What if I have more than one organisation?` }
            </Heading>
            <Paragraph>
              { `Multiple organisations can be added to any Pro plan to share the allowance for just $10 per month each. Please contact us if you wish to remove any add ons` }
            </Paragraph>

            <Heading>
              { `Is there also tax to pay on the subscription price?` }
            </Heading>
            <Paragraph>
              { `Paidnice will add any applicable taxes to your monthly subscription. Paidnice is required to collect New Zealand GST for any customer with their billing address in New Zealand. ` }
            </Paragraph>
          </div>

        </div>
      </div>
    </div>
  );
}
