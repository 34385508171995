import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

import { baseRequest as axiosBaseRequest } from '../services/api/axiosBaseQuery';

// A handy hook for making API requests, outside of Redux. (Local State)
// Includes the org id
export function useApiRequest() {
  const { organisationId } = useParams();

  useEffect(() => {
    if (organisationId) {
      axiosBaseRequest.defaults.headers.common[`X-Organisation-Id`] = organisationId;
    }
  }, [organisationId]);

  return axiosBaseRequest;
}
