import { GridFilterModel, GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { createSlice } from '@reduxjs/toolkit';
import { ActionStatus } from 'shared';

// Set defaults here
export const initialState: {
  filterModel: GridFilterModel
  sortModel: GridSortModel;
  paginationModel: GridPaginationModel;
  currentTab: ActionStatus;
} = {
  filterModel: {
    items: [],
  },
  sortModel: [],
  paginationModel: {
    page: 0,
    pageSize: 300,
  },
  currentTab: ActionStatus.PENDING,
};

const actionTableModelsSlice = createSlice({
  name: `actionTableModels`,
  initialState,
  reducers: {
    setFilterModel: (state, action) => {
      const parsed = action.payload.items.map(item => {
        if (item.value instanceof Date) {
          return {
            ...item,
            value: item.value.toISOString(),
          };
        }

        return item;
      });
      action.payload.items = parsed;
      state.filterModel = action.payload;
    },
    setSortModel: (state, action) => {
      state.sortModel = action.payload;
    },
    setPaginationModel: (state, action) => {
      state.paginationModel = action.payload;
    },
    reset: () => initialState,
    resetSort: state => {
      state.sortModel = initialState.sortModel;
    },
    resetFilter: state => {
      state.filterModel = initialState.filterModel;
    },
    resetPagination: state => {
      state.paginationModel = initialState.paginationModel;
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
  },
});

export const {
  setFilterModel,
  setSortModel,
  setPaginationModel,
  reset,
  resetSort,
  resetFilter,
  resetPagination,
  setCurrentTab,
} = actionTableModelsSlice.actions;

export const actionTableModelReducer = actionTableModelsSlice.reducer;
