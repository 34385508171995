import { IContactTagsResponse } from 'shared';

import { CacheTags } from '../../types/cacheTags';
import { emptySplitApi } from '../baseApi';

export const contactTagsApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchContactTags: builder.query<IContactTagsResponse, { organisationId: string }>({
      query: ({ organisationId }) => ({
        url: `contact-tags`,
        method: `GET`,
        headers: {
          [`X-Organisation-Id`]: organisationId,
        },
      }),
      providesTags: result => result.tags.map(t => ({
        id: t,
        type: CacheTags.ContactTags,
      })),
    }),
    createContactTag: builder.mutation<void, { organisationId: string; tagName: string; }>({
      query: ({ organisationId, tagName }) => ({
        url: `contact-tags`,
        method: `PUT`,
        data: {
          tagName,
        },
        headers: {
          [`X-Organisation-Id`]: organisationId,
        },
      }),
      invalidatesTags: () => [{ type: CacheTags.ContactTags }],
    }),
    deleteContactTag: builder.mutation<void, {organisationId: string; tagName: string;}>({
      query: ({ organisationId, tagName }) => ({
        url: `contact-tags/${tagName}`,
        method: `DELETE`,
        headers: {
          [`X-Organisation-Id`]: organisationId,
        },
      }),
      invalidatesTags: () => [{ type: CacheTags.ContactTags }],
    }),
  }),
});

export const {
  useFetchContactTagsQuery,
  useDeleteContactTagMutation,
  useCreateContactTagMutation,
} = contactTagsApi;
