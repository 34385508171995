import { Else, If, Then } from 'react-if';
import { UserTypes } from 'shared';
import { useMemo } from 'react';

import { Card } from '../../../common/Atoms/Card';
import { MultiButtonCardHeader } from '../../../common/Atoms/MultiButtonCardHeader';
import { Spinner } from '../Common/Spinner';
import { useGetSelectedOrganisation } from '../../hooks/useGetSelectedOrganisation';
import { env } from '../../../common/lib/env';
import { Paragraph } from '../../../common/Atoms/Typography/Paragraph';
import { useAccountingSystemName } from '../../hooks/useAccountingSystemName';
import { Button } from '../../../common/Atoms/Button';
import { useAccountingResources } from '../../hooks/useAccountingResources';
import connectQBOButton from '../../assets/QuickBooks-connect-button/connect-to-quickbooks-tall-default_2x.png';
import { CardContent } from '../../../common/Atoms/CardContent';
import { useUser } from '../../hooks/useUser';

export function ConnectionSettings() {
  const accountingSystemName = useAccountingSystemName();
  const { data: accountingResources, isLoading  } = useAccountingResources();
  const currentOrg = useGetSelectedOrganisation();
  const { data: user } = useUser();

  const connectedUser = useMemo(() => {
    return (user?.orgs?.find(org => org.id === currentOrg.id)?.type === UserTypes.Inherit) || false;
  }, [user]);

  function handleDisconnect() {
    window.location.href = `${env.apiUrl}/connect/` + accountingSystemName.toLocaleLowerCase() + `/disconnect?org=${currentOrg.id}`;
  }

  if (!currentOrg || isLoading) {
    return <Spinner />;
  }

  return (
    <Card>
      <MultiButtonCardHeader
        title={ `Accounting Connection` }
      />
      <CardContent className={ `mt-4` }>
        <If condition={ accountingSystemName === `QuickBooks` && !accountingResources }>
          <Then>
            <Paragraph> { `We have detected a problem connecting to ` }{ currentOrg.legalName } { ` please reconnect.` }</Paragraph>
          </Then>
          <Else>
            <Paragraph> <span className={ `text-lg font-bold` }>{ `Connected to organisation: ` }</span> <span className={ `text-lg` }>{ `${ currentOrg.legalName }` }</span> </Paragraph>
            <Paragraph> <span className={ `text-lg font-bold` }>{ `Accounting system: ` }</span> <span className={ `text-lg` }>{ `${ accountingSystemName }` }</span> </Paragraph>
          </Else>
        </If>
        <If condition={ accountingSystemName === `Xero` && connectedUser }>
          <div className={ `mt-4` }>
            <button onClick={ handleDisconnect }>
              <img src={ `/img/disconnect-white.svg` }
                alt={ `Disconnect ` + accountingSystemName }
                className={ `disconnect-button` }/>
            </button>
          </div>
        </If>
        <If condition={ accountingSystemName === `QuickBooks` && !!accountingResources && connectedUser }>
          <div className={ `mt-4` }>
            <Button onClick={ handleDisconnect }>{ `Disconnect QuickBooks` }</Button>
          </div>
        </If>
        <If condition={ accountingSystemName === `QuickBooks` && !accountingResources }>
          <div className={ `mt-4` }>
            <a href={ `${env.apiUrl}/connect/quickbooks` }>
              <img width={ `245px` }
                style={ { width: `245px`, maxWidth: `none` } }
                src={ connectQBOButton } />
            </a>
          </div>
        </If>
      </CardContent>
    </Card>
  );
}
