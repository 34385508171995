import {  When } from 'react-if';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { langauge, policyConfigV2 } from 'shared';
import { ArrowPathIcon } from '@heroicons/react/20/solid';

import { Grid } from '../../../common/Atoms/Grid';
import { GridItem } from '../../../common/Atoms/GridItem';
import { Card } from '../../../common/Atoms/Card';
import { CardContent } from '../../../common/Atoms/CardContent';
import { Notification } from '../../../common/Atoms/Notification';
import { MultiButtonCardHeader } from '../../../common/Atoms/MultiButtonCardHeader';
import { usePolicies } from '../../hooks/usePolicies';
import { Table } from '../../../common/Atoms/Table';
import { Badge } from '../../../common/Atoms/Badge';
import { useInvoice } from '../../hooks/useInvoice';
import { useIsSafeMode } from '../../hooks/useIsSafeMode';
import { DatePopover } from '../../../common/Components/DatePopover';
import { Button } from '../../../common/Atoms/Button';
import { usePreviewPolicy } from '../../hooks/usePreviewPolicy';
import { classNames } from '../../../common/lib/classNames';

const headings = [{
  name: `title`,
  label: ``,
  className: `whitespace-nowrap min-w-[320px]`,
},
{
  name: `reason`,
  label: ``,
  textAlign: `right` as const,
},
{
  name: `preview`,
  label: ``,
},
];

export function PreviewPolicies() {
  const { data: policies } = usePolicies();
  const { policyCheck, invoice, refetch, isFetching } = useInvoice();
  const safeModeOn = useIsSafeMode();
  const { openPreviewPolicy } = usePreviewPolicy();

  if (!policyCheck?.policyResults) return null;

  if (!policies) return null;

  const rows = [];

  for (const policyId in policyCheck.policyResults) {
    const policy = policies?.find(x => x.policy.id === Number(policyId))?.policy;
    const result = policyCheck.policyResults[policyId];

    if (!policy) {
      throw new Error(`Policy ${policyId} not found`);
    }

    const policyConfig = policyConfigV2[policy.policy_type];

    let canPreview = policyConfig.previewConfig.canPreview;

    if (policyConfig.previewConfig.mustApplyToPreview && canPreview) {
      canPreview = result.applies;
    }

    rows.push({
      title: (
        <span>
          {
            result.applies ? <CheckIcon className={ `inline-block w-5 h-5 mr-2 text-green-500` } /> : <XMarkIcon className={ `inline-block w-5 h-5 mr-2 text-red-500` } />
          }

          { policy.title }
          <Badge
            color={ `purple` }
            className={ `ml-2` }
            message={ langauge.policyType[policy.policy_type] }
          />
        </span>
      ),
      reason: (result.applies && result.appliesOn) ? (
        <span className={ `flex` }>
          <span className={ `mr-1` }>
            { `This policy will be applied on` }
          </span>
          <DatePopover
            date={ result.appliesOn }
            labelFormat={ `dd LLL yyyy` }
            organisationTimezone={ invoice.organisationTimezone }
          />
        </span>
      )
        : (
          <span className={ `flex` }>
            { result.reason }
          </span>
        ),
      preview: canPreview ? (
        <Button
          onClick={ () => openPreviewPolicy({
            policyId: Number(policyId),
            invoiceId: invoice.id,
          }) }
        >
          { `Preview` }
        </Button>
      ) : null,
    });
  }

  function reload() {
    refetch();
  }

  return (
    <>
      { /* Content */ }
      <Grid cols={ 12 }
        className={ `min-w-full` }>
        <GridItem span={ 8 }
          className={ `` }>
          <Card>
            <MultiButtonCardHeader
              title={ `Applicable Policy results for ${invoice.xero_number}` }
              description={ `Only "Invoice" type policies are checked here. "Statement" type policies cannot be checked at the invoice level` }
            />
            <CardContent>

              <When condition={ safeModeOn }>
                <Notification type={ `info` }
                  className={ `rounded-t-none` }>
                  { `Safe mode is currently on. Applicable policies below will not be actioned until safe mode is off` }
                </Notification>
              </When>
              <div className={ `flex justify-between mt-4 -mb-6` }>
                <p className={ `text-md text-gray-600` }>{ `Invoice contact found in group: ` }<strong>{ policyCheck?.groupName }</strong></p>
                <button
                  onClick={ reload }
                  className={ `w-5 h-5 z-10` }
                  disabled={ isFetching }
                >
                  <ArrowPathIcon
                    className={
                      classNames(
                        `w-5 h-5 text-lateGreen-600`,
                        isFetching ? `animate-spin cursor-not-allowed` : `cursor-pointer`,
                      )
                    }
                  />
                </button>
              </div>

              <Table
                headings={ headings }
                rows={ rows }
              />
            </CardContent>
          </Card>
        </GridItem>
        <GridItem span={ 4 }
          className={ `` }>
          <Card className={ `` }>
            <Notification type={ `info` }>
              { `Use this tool to find which policies will apply to this invoice, if it was processed now.` }
            </Notification>
          </Card>
        </GridItem>
      </Grid>
    </>
  );
}
