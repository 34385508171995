import { useMemo } from "react";
import { PolicyIntervalType, invoiceTypes, quoteTypes, statementTypes } from "shared";

import { useCurrentGroup } from "../../../../hooks/useGroups";
import { Card } from "../../../../../common/Atoms/Card";
import { Heading } from "../../../../../common/Atoms/Typography/Heading";
import { getDayIndex } from "../../../../lib/helper";
import { HelpIcon } from "../../../Molecules/HelpIcon";

import { ExistingPolicyItem } from "./ExistingPolicyItem";

interface PolicyColumnProps {
  type: `invoice` | `quote` | `contact`;
}

const headingMap: Record<PolicyColumnProps[`type`], {
  title: string;
  description: string;
}> = {
  invoice: {
    title: `Invoice Policies`,
    description: `These policies will apply for each invoice.`,
  },
  quote: {
    title: `Quote Policies`,
    description: `These policies will apply for each quote.`,
  },
  contact: {
    title: `Contact Policies`,
    description: `These policies will apply for each contact.`,
  },
};

export function PolicyColumn({
  type,
}: PolicyColumnProps) {
  const { policies } = useCurrentGroup();

  const policiesOfType = useMemo(() => {
    if (!policies || !policies.length) {
      return [];
    }

    return policies
      .filter(x => {
        if (type === `invoice`) {
          return invoiceTypes.includes(x.policy_type);
        }

        if (type === `quote`) {
          return quoteTypes.includes(x.policy_type);
        }

        if (type === `contact`) {
          return statementTypes.includes(x.policy_type);
        }

        return [];
      })
      .sort((a, b) => {
        const aDayIndex = getDayIndex(a);
        const bDayIndex = getDayIndex(b);

        return aDayIndex - bDayIndex;
      })
      // Then make sure the weeks are first
      .sort((a, b) => {
        if (type === `contact`) {
          // Sort by if its week or month first. Then by day index. Put week first.
          if (a.interval_type === PolicyIntervalType.MONTHLY && b.interval_type === PolicyIntervalType.WEEKLY) {
            return 1;
          }

          if (a.interval_type === PolicyIntervalType.WEEKLY && b.interval_type === PolicyIntervalType.MONTHLY) {
            return -1;
          }

          return 0;
        }
      });
  }, [policies, type]);

  // If none of this type, dont render
  if (!policiesOfType.length) {
    return null;
  }

  return (
    <Card className={ `max-w-[750px]` }>
      { /* Heading */ }
      <Heading
        className={ `mb-4` }
      >
        <span className={ `flex` }>
          { headingMap[type].title }
          <HelpIcon
            tooltip={ headingMap[type].description }
            className={ `ml-1` }
          />
        </span>
      </Heading>
      { /* <Paragraph
        variant={ `help` }
        className={ `mb-3` }
      >
        { headingMap[type].description }
      </Paragraph> */ }

      { /*  List of existing policies */ }
      <div className={ `space-y-2` }>
        {
          policiesOfType.map(p => {
            return (
              <ExistingPolicyItem
                key={ p.id }
                policy={ p }
              />
            );
          })
        }
      </div>
    </Card>
  );
}
