
import LogRocket from 'logrocket';

import { env } from '../lib/env.js';
import { emptySplitApi as extendedApi } from '../../main/services/api/baseApi.js';

import { rtkQueryActionLogger } from './rtkQueryActionLogger.js';

export function createMiddleware(getDefaultMiddleware) {
  const middleware = getDefaultMiddleware();

  // Add the API Slice
  middleware.push(extendedApi.middleware);

  // Add error listener for toasts
  middleware.push(rtkQueryActionLogger);

  if (env.environment === `production`) {
    middleware.push(LogRocket.reduxMiddleware());
  }

  return middleware;
}
