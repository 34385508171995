import { Tooltip } from '@mui/material';
import { When } from 'react-if';

import { classNames } from '../lib/classNames';
import { IHeroIcon } from '../../main/types/types';
import { HelpLinkIcon } from '../../main/components/Molecules/HelpLinkIcon';

export interface ToggleOption {
  icon?: IHeroIcon;
  name?: string;
  value: string;
  color: `red` | `green` | `orange`;
  tooltip?: string;
  disabled?: boolean;
}

interface ThreeWayToggleProps {
  options: ToggleOption[];
  value: string;
  onChange: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: string) => void;
  className?: string;
  loading?: boolean;
  helpLink?: string;
  fullDetail?: boolean;
}

export function ThreeWayToggle({ options, value, onChange, className, loading, helpLink, fullDetail }: ThreeWayToggleProps) {
  const getColorClasses = (color: `red` | `green` | `orange`, isSelected: boolean) => {
    const baseClass = isSelected ? `text-white` : `text-gray-700`;
    switch (color) {
    case `red`:
      return `${baseClass} ${isSelected ? `bg-red-500` : ``}`;
    case `green`:
      return `${baseClass} ${isSelected ? `bg-green-500` : ``}`;
    case `orange`:
      return `${baseClass} ${isSelected ? `bg-orange-500` : ``}`;
    default:
      return baseClass;
    }
  };

  return (
    <div className={ classNames(
      `flex flex-col items-end`,
      loading ? `opacity-50 cursor-wait` : ``,
      className,
      fullDetail ? `max-w-md` : `max-w-xs`,
    ) }>
      <When condition={ helpLink }>
        <HelpLinkIcon
          className={ `` }
          href={ helpLink }
        />
      </When>
      <div className={ `flex items-center justify-between bg-gray-200 rounded-md p-1` }>
        { options.map(option => {
          const component = (
            <button
              key={ option.value }
              onClick={ e => onChange(e, option.value) }
              disabled={ loading || option.disabled }
              className={ classNames(
                `flex-1 flex items-center justify-center px-2 py-1 rounded-md font-medium leading-4 text-center`,
                option.disabled ? `cursor-not-allowed` : ``,
                getColorClasses(option.color, value === option.value),
                fullDetail ? `text-sm` : `text-xs`,
                option.name && option.icon ? `` : ``,
              ) }
              style={ { minWidth: `0` } }
            >
              <span
                className={ classNames(
                  option.name && option.icon ? `mr-1` : ``,
                ) }
              >
                { option.name }
              </span>
              <When condition={ !!option.icon }>
                { () => <option.icon className={ `w-5 h-5` } /> }
              </When>
            </button>
          );
          if (option.tooltip) {
            return (
              <Tooltip
                key={ option.value }
                title={ option.tooltip }
                placement={ `top` }
              >
                <span>
                  { component }
                </span>
              </Tooltip>
            );
          }

          return component;
        }) }
      </div>
    </div>
  );
}
