import { useParams } from "react-router";

import { useFetchAccountingResourcesQuery } from "../services/api/accountingResourcesApi/accountingResources";

export function useAccountingResources() {
  const { organisationId } = useParams<{ organisationId: string }>();
  const result = useFetchAccountingResourcesQuery(organisationId, {
    skip: !organisationId,
  });

  return result;
}
