import { useMemo } from "react";
import { IInvalidField } from "shared";

import { IOption, Select } from "../../../common/Atoms/Select";
import { useGetSelectedOrganisation } from "../../hooks/useGetSelectedOrganisation";
import { useCurrentOrgUsers } from "../../hooks/useCurrentOrgUsers";

interface IAssigneeProps {
  current: string | null;
  onChange: (id: string) => void;
  loading?: boolean;
  invalid?: IInvalidField;
}

export function Assignee({
  current,
  onChange,
  loading,
  invalid,
}: IAssigneeProps) {
  const currentOrg = useGetSelectedOrganisation();

  const currentOrgUsers = useCurrentOrgUsers();

  const dropdownOpts: IOption[] = useMemo(() => {
    if (!currentOrg) return [];

    const options = currentOrgUsers.map(user => ({
      ...user,
      disabled: user.value === current,
    }));

    if (!options.find(u => u.value === current)) {
      options.unshift({
        label: `Removed User`,
        value: current,
        disabled: true,
      });
    }

    return options;
  }, [currentOrg]);

  return (
    <Select
      options={ dropdownOpts }
      loading={ loading }
      selected={ current }
      onChange={ ({ value }) => onChange(value) }
      emptyText={ `Unassigned` }
      nullable
      valueClassname={ `max-w-[180px] truncate` }
      invalid={ invalid }
    />
  );
}
