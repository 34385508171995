import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';

import { Notification } from '../../common/Atoms/Notification';

import { Invoices } from './Invoices/Invoices';
import { Dashboard } from './Dashboard/Dashboard';
import { Subscriptions } from './Subscriptions/Subscriptions';
import { Subscription } from './Subscriptions/Subscription';
import { Root } from './Root';
import { OrganisationRoot } from './OrganisationRoot';
import { GeneralSettings } from './Settings/General/Main/GeneralSettings';
import { ConnectionSettings } from './Settings/ConnectionSettings';
import { EmailTemplates } from './Settings/EmailTemplates/EmailTemplates';
import { EmailTemplate } from './Settings/EmailTemplates/EmailTemplate';
import { InvoicePage } from './Invoice/InvoicePage';
import { PreviewPolicies } from './Invoice/PreviewPolicies';
import { NotFound } from './NotFound';
import { ErrorPage } from './ErrorPage';
import { Contacts } from './CRM/Contacts';
import { CRMRoot } from './CRM/CRMRoot';
import { MyAccount } from './Account/MyAccount';
import { SettingsRoot } from './Settings/General/SettingsRoot';
import { Settings } from './Settings/Settings';
import { EmailRoot } from './Settings/General/Email/EmailRoot';
import { AdvancedRoot } from './Settings/General/Advanced/AdvancedRoot';
import { InvoiceDocument } from './Invoice/InvoiceDocument/InvoiceDocument';
import { AuditLogRoot } from './AuditLogs/AuditLogRoot';
import { PortalRoot } from './Settings/General/Portal/PortalRoot';
import { PaymentRoot } from './Settings/General/Payments/PaymentsRoot';
import { StatementRoot } from './Settings/General/Statement/StatementRoot';
import { ContactTable } from './CRM/Group/GroupTableSection/ContactTable';
import { GroupV2Root } from './CRM/Group/GroupRoot';
import { ContactActivity } from './CRM/ContactPage/ContactTableSection/ContactActivity';
import { ContactStatements } from './CRM/ContactPage/ContactTableSection/ContactStatements';
import { ContactPageRoot } from './CRM/ContactPage/ContactPageRoot';
import { OnboardingRoot } from './Onboarding/FullScreen/OnboardingRoot';
import { ActionsTableRoot } from './Actions/ActionTableRoot';
import { GroupActionsTable } from './CRM/Group/GroupTableSection/GroupActionsTable';
import { CustomerPaymentsRoot } from './CustomerPayments/CustomerPaymentsRoot';
import { FilesRoot } from './Settings/Files/FilesRoot';
import { ApplicableContactPolicies } from './CRM/ContactPage/ContactTableSection/ApplicableContactPolicies';
import { AICallRoot } from './AICalls/AICallRoot';
import { UserSettings } from './Settings/UserSettings';
import { QuotesRoot } from './Quotes/QuotesRoot';
import { InvoiceAnalytics } from './Invoice/InvoiceAnalytics';
import { GroupPolicies } from './CRM/Group/GroupPoliciesSection/GroupPoliciesRoot';
import { ContactNotes } from './CRM/ContactPage/ContactTableSection/ContactNotes';
import { InvoiceNotes } from './Invoice/InvoiceNotes';
import { ContactEscalations } from './CRM/ContactPage/ContactTableSection/ContactEscalations';
import { EscalationsRoot } from './Escalations/EscalationsRoot';
import { UserNotificationsRoot } from './UserNotifications/UserNotificationsRoot';
import { EscalationPageRoot } from './Escalations/Escalation/EscalationPageRoot';

const createRoutes = routes => createBrowserRouter(routes);

const LegacyPolicyPage = () => (
  <div className={ `h-64 justify-center items-center` }>
    <Notification
      type={ `info` }
    >
      { `Policies have moved to the group pages. Please choose a group on the left hand side.` }
    </Notification>
  </div>
);

export function Routes() {
  const allRoutes: RouteObject[] = [
    {
      path: `/`,
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: `account`,
          element: <MyAccount />,
        },
        {
          path: `notifications`,
          element: <UserNotificationsRoot />,
        },
        {
          path: `billing`,
          element: <Subscriptions />,
        },
        {
          path: `billing/subscriptions`,
          element: <Subscriptions />,
        },
        {
          path: `billing/subscriptions/:subscriptionId`,
          element: <Subscription />,
        },
        {
          path: ``,
          element: <OrganisationRoot />,
          children: [
            {
              path: ``,
              index: true,
              element: <Dashboard />,
            },
          ],
        },
        {
          path: `:organisationId`,
          element: <OrganisationRoot />,
          children: [
            {
              path: ``,
              index: true,
              element: <Dashboard />,
            },
            {
              path: `onboarding`,
              element: <OnboardingRoot />,
            },
            {
              path: `invoices`,
              element: <Invoices />,
            },
            // Legacy policy users
            {
              path: `policies`,
              element: <LegacyPolicyPage />,
            },
            {
              path: `groups/:groupId`,
              element: <GroupV2Root />,
              children: [
                {
                  path: ``,
                  element: <GroupPolicies />,
                },
                {
                  path: `contacts`,
                  element: <ContactTable />,
                },
                {
                  path: `actions`,
                  element: <GroupActionsTable />,
                },
              ],
            },
            {
              path: `contacts`,
              element: <CRMRoot />,
              children: [
                {
                  path: `manage`,
                  element: <Contacts />,
                },
                {
                  path: `:contactId`,
                  element: <ContactPageRoot />,
                  children: [
                    {
                      path: ``,
                      element: <ContactStatements />,
                    },
                    {
                      path: `activity`,
                      element: <ContactActivity />,
                    },
                    {
                      path: `tasks`, // Different name for navigation highlight
                      element: <ContactEscalations />,
                    },
                    {
                      path: `notes`,
                      element: <ContactNotes />,
                    },
                    {
                      path: `policies`,
                      element: <ApplicableContactPolicies />,
                    },
                  ],
                },
              ],
            },
            {
              path: `invoices/:invoiceId`,
              element: <InvoicePage />,
              children: [
                {
                  path: ``,
                  element: <InvoiceDocument />,
                },
                {
                  path: `notes`,
                  element: <InvoiceNotes />,
                },
                {
                  path: `policy-preview`,
                  element: <PreviewPolicies />,
                },
                {
                  path: `analytics`,
                  element: <InvoiceAnalytics />,
                },
              ],
            },
            {
              path: `actions`,
              element: <ActionsTableRoot />,
            },
            {
              path: `escalations`,
              element: <EscalationsRoot />,
            },
            {
              path: `escalations/:escalationId`,
              element: <EscalationPageRoot />,
            },
            {
              path: `quotes`,
              element: <QuotesRoot />,
            },
            {
              path: `ai-calls`,
              element: <AICallRoot />,
            },
            {
              path: `payments`,
              element: <CustomerPaymentsRoot />,
            },
            {
              path: `settings`,
              element: <Settings />,
              children: [

                {
                  path: `general`,
                  element: <SettingsRoot />,
                  children: [
                    {
                      path: ``,
                      index: true,
                      element: <GeneralSettings />,
                    },
                    {
                      path: `email`,
                      element: <EmailRoot />,
                    },
                    {
                      path: `statement`,
                      element: <StatementRoot />,
                    },
                    {
                      path: `portal`,
                      element: <PortalRoot />,
                    },
                    {
                      path: `payments`,
                      element: <PaymentRoot />,
                    },
                    {
                      path: `advanced`,
                      element: <AdvancedRoot />,
                    },
                  ],
                },
                {
                  path: `email-templates`,
                  element: <EmailTemplates />,
                },
                {
                  path: `files`,
                  element: <FilesRoot />,
                },
                {
                  path: `email-templates/:templateId`,
                  element: <EmailTemplate />,
                },
                {
                  path: `connection`,
                  element: <ConnectionSettings />,
                },
                {
                  path: `users`,
                  element: <UserSettings />,
                },
              ],
            },
            {
              path: `logs`,
              element: <AuditLogRoot />,
            },
          ],
        },
      ],
    },
    {
      path: `*`,
      element: <NotFound />,
    },
  ];

  return (
    <RouterProvider router={ createRoutes(allRoutes) } />
  );
}
