import { INotification, NotificationType } from "shared";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { TrashIcon } from "@heroicons/react/20/solid";
import { Tooltip } from "@mui/material";

import { Card } from "../../../common/Atoms/Card";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { useDismissNotificationMutation } from "../../services/api/userApi/user";
import { LoadingOverlay } from "../Molecules/LoadingOverlay";

const invoiceNotificationTypes = [
  NotificationType.INVOICE_ASSIGNMENT,
  NotificationType.INVOICE_NOTE_MENTION,
  // For the time being, send escalation notifications to the invoice page
  NotificationType.ESCALATION_ASSIGNMENT,
  NotificationType.ESCALATION_NOTE_MENTION,
];

export function UserNotificationItem({ notification }: { notification: INotification }) {
  const [dismiss, { isLoading }] = useDismissNotificationMutation();
  function buildLink(): {
    to: string
    label: string
  } | null {
    if (!notification.organisationId) {
      return null;
    }

    if (invoiceNotificationTypes.includes(notification.type)) {
      return {
        to: `/${notification.organisationId}/invoices/${notification.invoiceId}/notes#note-${notification.noteId}`,
        label: `View Invoice Notes`,
      };
    }

    if (notification.type === NotificationType.CONTACT_NOTE_MENTION || notification.type === NotificationType.CONTACT_ASSIGNMENT) {
      return {
        to: `/${notification.organisationId}/contacts/${notification.contactId}/notes#note-${notification.noteId}`,
        label: `View Contact Notes`,
      };
    }

    return null;
  }

  const link = buildLink();

  function onAck() {
    // Acknowledge the notification
    dismiss(notification.id);
  }

  return (
    <LoadingOverlay loading={ isLoading }>
      <Card>
        <div className={ `grid grid-cols-12` }>
          <Paragraph
            className={ `col-span-10` }
          >
            { notification.content }
          </Paragraph>

          <button
            className={ `col-span-2 text-right` }
            onClick={ onAck }
          >
            <Tooltip title={ `Dismiss` }>
              <TrashIcon className={ `h-6 w-6 text-red-900 inline-block text-right ml-1` } />
            </Tooltip>
          </button>

        </div>
        { /* metadata */ }
        <div className={ `mt-2 grid grid-cols-12` }>
          { /* Date */ }
          <Paragraph
            className={ `text-xs col-span-10` }
            variant={ `help` }
          >
            { DateTime.fromJSDate(new Date(notification.createdAt)).toRelative() }
          </Paragraph>

          { /* Link */ }
          {
            link && (
              <Link
                to={ link.to }
                className={ `col-span-2 text-right` }
              >
                <Paragraph
                  variant={ `link` }>
                  { link.label }
                  <ArrowRightIcon className={ `h-4 w-4 inline-block ml-1` } />
                </Paragraph>
              </Link>
            )
          }
        </div>
      </Card>
    </LoadingOverlay>
  );
}
