import { useNavigate, useParams } from 'react-router';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

import { env } from '../../common/lib/env';

import { useOrganisations } from './useOrganisations';

export function useGetSelectedOrganisation() {
  const { organisationId } = useParams();
  const nav = useNavigate();
  const { data: organisations } = useOrganisations();

  useEffect(() => {
    if (env.sentryDsn) {
      Sentry.setTag(`organisationId`, organisationId);
    }
  }, [organisationId]);

  const organisation = organisations.find(x => x.id === organisationId);

  if (!organisation && organisationId && organisations.length > 0) {
    // We have connected orgs, but the url doesnt match any, must be a 404
    // redirect them to any org
    nav(`/${organisations[0].id}`);

    return organisations[0];
  }

  return organisation;
}
