import { useMemo } from 'react';
import { DateFormats, IContactPerson } from 'shared';
import { format } from 'date-fns';
import { When } from 'react-if';

import { Card } from '../../../common/Atoms/Card';
import { MultiButtonCardHeader } from '../../../common/Atoms/MultiButtonCardHeader';
import { useContactParams, useContacts } from '../../hooks/useContacts';
import { useGetSelectedOrganisation } from '../../hooks/useGetSelectedOrganisation';
import { useSyncContactsMutation } from '../../services/api/contactsApi/contacts';
import { Pagination } from '../../../common/Atoms/Pagination';
import { CardContent } from '../../../common/Atoms/CardContent';
import { useAccountingSystemTerm } from '../../hooks/useAccountingSystemTerm';
import { Notification } from '../../../common/Atoms/Notification';

import { ContactTable, IContactPersonRow, IContactTableRow } from './ContactTable/ContactTable';
import { ContactFilters } from './ContactTable/ContactFilters';

function createContactPersonRow(contactPerson: IContactPerson): IContactPersonRow {
  const hasNoName = !contactPerson.firstName && !contactPerson.lastName;

  const name = hasNoName ?
    `No Contact Name` :
    `${contactPerson.firstName || ``} ${contactPerson.lastName || `-`}`;

  const hasNoNumber = !contactPerson.mobileNumber && !contactPerson.mobileCountryCode;

  const mobile = hasNoNumber ?
    `` :
    `${contactPerson.mobileCountryCode || ``} ${contactPerson.mobileNumber || ``}`;

  return {
    name: name,
    email: contactPerson.email || `-`,
    mobileNumber: mobile || `-`,
    includeInNotifications: contactPerson.includeInNotifications,
    isPrimary: contactPerson.isPrimary,
  };
}

export function Contacts() {
  const organisation = useGetSelectedOrganisation();
  const { data } = useContacts();
  const { params, updateParams } = useContactParams();
  const [ syncContacts, { isLoading: syncContactsLoading } ] = useSyncContactsMutation();
  const nameOfContact = useAccountingSystemTerm(`Contacts`);

  const contacts = data?.contacts;
  const total = data?.totalCount;

  const contactRows: IContactTableRow[] = useMemo(() => {
    if (!contacts) return [];

    return contacts.map(contact => {
      const primary = createContactPersonRow(contact.primaryContactPerson);

      const row = {
        id: contact.id,
        name: contact.name,
        group: contact.groupTitle || `Default`,
        externalAggregateNames: contact.externalAggregateNames,
        externalAccountNumber: contact.externalAccountNumber || `-`,
        unpaidInvoiceCount: contact.unpaidInvoiceCount,
        tags: contact.tags,
        balances: {
          overdue: contact.overdueBalanceDisplay,
          outstanding: contact.outstandingBalanceDisplay,
        },
        contactPersons: [
          // Ensure primary is first
          primary,
          ...contact.contactPersons.map(createContactPersonRow),
        ],
      };

      return row;
    });
  }, [contacts]);

  function onContactSync() {
    syncContacts(organisation.id);
  }

  const buttonText = organisation.contactSyncInProgress ?
    `Sync in progress` :
    `Sync with ${organisation.accountingSystemName}`;

  return (
    <>
      <Card>
        <MultiButtonCardHeader
          title={ `${organisation.accountingSystemName} ` + nameOfContact }
          description={ `Manage your contacts and associated people.` }
          buttonsAsDropdownText={ `Options` }
          buttons={ [
            {
              buttonText,
              onClick: onContactSync,
              loading: syncContactsLoading,
              tooltip: `Start a sync with ${organisation.accountingSystemName}. This may take a while.`,
              caption: organisation.contactsLastSynced && `Last synced: ${format(new Date(organisation.contactsLastSynced), DateFormats.UI_SHORT_TIME)}`,
              disabled: organisation.contactSyncInProgress,
            },
          ] }
        >
          <When condition={ data?.totalVersionOneCount > 0 }>
            <Notification
              type={ `warning` }
              className={ `mt-4 mb-0` }
            >
              {
                organisation.contactSyncInProgress ?
                  `${data?.totalVersionOneCount} contacts are still waiting to be synced. They will be available shortly.` :
                  `${data?.totalVersionOneCount} contacts have not yet been synced. Please click sync or wait for the next daily sync.`
              }
            </Notification>
          </When>
        </MultiButtonCardHeader>
        <CardContent>
          <Pagination
            total={ data?.totalCount || 0 }
            activePage={ params.page }
            onChangePage={ page => updateParams({ page }) }
            limit={ params.limit }
            count={ contacts?.length || 0 }
            className={ `pt-2` }
            perPageOptions={ [ 50, 100, 250, 500 ] }
            onPerPageChange={ limit => updateParams({ limit }) }
          />
          <ContactFilters />
          <ContactTable rows={ contactRows }/>
          <Pagination
            total={ total || 0 }
            activePage={ params.page }
            onChangePage={ page => updateParams({ page }) }
            limit={ params.limit }
            count={ contacts?.length || 0 }
            className={ `pt-3` }
            perPageOptions={ [ 50, 100, 250, 500 ] }
            onPerPageChange={ limit => updateParams({ limit }) }
          />
        </CardContent>
      </Card>
    </>
  );
}
