import { ActionStatus, ActionType, IActionResponseItem, langauge } from "shared";
import { Tooltip } from "@mui/material";

import { Badge } from "../../../common/Atoms/Badge";

interface ActionStatusBadgeProps {
  action: IActionResponseItem;
  size?: `sm` | `md` | `lg`;
}

export function ActionStatusBadge({ action, size = `sm` }: ActionStatusBadgeProps) {
  const langaugeObject = langauge.action[action.type];

  let status = langaugeObject.status[action.status];
  let tooltip = ``;

  if (action.type === ActionType.LATE_FEE && action.ignored) {
    status = `Issued & Ignored`;
  }

  if (action.subStatus && langaugeObject.subStatus[action.subStatus]) {
    status = langaugeObject.subStatus[action.subStatus];
  }

  if (action.onlyWebhook) {
    status = `Webhook Only`;
  }

  if (action.status === ActionStatus.APPROVAL_PENDING) {
    tooltip = `Will not run automatically due to policy settings. Use "Action Now" to run manually.`;
  }

  const badge = (
    <Badge
      color={ action.status }
      message={ status }
      size={ size }
    />
  );

  if (tooltip) {
    return (
      <Tooltip title={ tooltip }>
        { badge }
      </Tooltip>
    );
  }

  return badge;
}
