import { StripePricesLookupKeys, findPriceByLookupKey } from "shared";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { useMemo } from "react";
import { When } from "react-if";

import { Button } from "../../../common/Atoms/Button";
import { Divider } from "../../../common/Atoms/Divider";
import { Modal } from "../../../common/Atoms/Modal";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { usePlans } from "../../hooks/usePlans";
import { Notification } from "../../../common/Atoms/Notification";

interface IPlanChangeModalProps {
  currentPlan: StripePricesLookupKeys | null,
  newPlan: StripePricesLookupKeys | null,
  show: boolean,
  onConfirm: () => void,
  onCancel: () => void,
  trial: boolean,
  loading?: boolean,
}

export function PlanChangeModal({
  currentPlan,
  newPlan,
  onConfirm,
  onCancel,
  show,
  trial,
  loading,
}: IPlanChangeModalProps) {
  const { data: plans } = usePlans();

  const details = useMemo(() => {
    if (!currentPlan || !newPlan || !plans) {
      return null;
    }

    const currentPlanPrice = plans.find(p => p.lookup_key === currentPlan);
    const newPlanPrice = plans.find(p => p.lookup_key === newPlan);

    if (!currentPlan || !newPlan) {
      return null;
    }

    const basePriceCurrent = currentPlanPrice?.unit_amount;
    const basePriceNew = newPlanPrice?.unit_amount;

    if (!basePriceCurrent || !newPlanPrice) return null;

    const currentPrice = (basePriceCurrent / 100).toFixed(2);
    const newPrice = (basePriceNew / 100).toFixed(2);

    const priceConfigCurrent = findPriceByLookupKey(currentPlan);
    const priceConfigNew = findPriceByLookupKey(newPlan);

    return {
      currentPlan: {
        name: priceConfigCurrent.name,
        price: currentPrice,
      },
      newPlan: {
        name: priceConfigNew.name,
        price: newPrice,
      },
    };
  }, [currentPlan, newPlan, plans]);

  if (!details) return null;

  return (
    <Modal
      title={ `Are you sure you want to change your plan?` }
      open={ show }
      setOpen={ onCancel }
    >

      <div className={ `flex flex-col items-center mt-6` }>
        <div className={ `flex flex-row w-full justify-around` }>
          <div>
            <Paragraph variant={ `primary` }>
              { details.currentPlan.name }
            </Paragraph>
            <Paragraph variant={ `secondary` }>
              { `$` }{ details.currentPlan.price } { `/ month` }
            </Paragraph>
          </div>
          <ArrowRightIcon className={ `w-6 h-6` } />
          <div>
            <Paragraph variant={ `primary` }>
              { details.newPlan.name }
            </Paragraph>
            <Paragraph variant={ `secondary` }>
              { `$` }{ details.newPlan.price } { `/ month` }
            </Paragraph>
          </div>
        </div>

        <Divider weight={ `light` } />

        <When condition={ trial }>
          <Notification
            type={ `warning` }
            className={ `mb-6` }
          >
            { `Changing plans during a trial will automatically charge you. To avoid this please contact us` }
          </Notification>
        </When>

        <div>
          <Button
            color={ `gray` }
            className={ `mr-4` }
            onClick={ onCancel }
          >
            { `No, Keep current plan` }
          </Button>
          <Button
            className={ `mr-4` }
            color={ `lateGreen` }
            onClick={ onConfirm }
            loading={ loading }
          >
            { `Yes, Change my plan` }
          </Button>
        </div>
      </div>
    </Modal>
  );
}
