import { useFetchSystemPoliciesQuery } from "../services/api/systemPoliciesApi/systemPolicies";

import { useGetSelectedOrganisation } from "./useGetSelectedOrganisation";

export function useSystemPolicies() {
  const currentOrg = useGetSelectedOrganisation();

  const systemPoliciesApi = useFetchSystemPoliciesQuery({
    orgId: currentOrg?.id,
  }, {
    skip: !currentOrg,
    refetchOnMountOrArgChange: true,
  });

  systemPoliciesApi.data = systemPoliciesApi.data || [];

  return systemPoliciesApi;
}
