import { useParams } from "react-router";
import ms from "ms";

import { useFetchQuotesQuery } from "../services/api/quotesApi/quotes";

export function useQuotes() {
  const { organisationId } = useParams<{organisationId?: string}>();

  const api = useFetchQuotesQuery({ organisationId: organisationId }, {
    skip: !organisationId,
    pollingInterval: ms(`1m`),
    skipPollingIfUnfocused: true,
  });

  // Ensure array is always present
  return {
    ...api,
    quotes: api.data?.quotes ?? {},
  };
}
