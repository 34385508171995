import { useMemo } from "react";
import { PolicyDayPeriodType } from "shared";

import { Select } from "../../../../../../common/Atoms/Select";
import { InputTextAddon } from "../../../../../../common/Atoms/InputTextAddon";
import { fieldIsValid } from "../../../../../lib/helper";

import { IFieldProps } from "./fieldProps";

export function DayIndexWithType({
  handleChange,
  policyFormData,
  invalids,
  dayType,
  dayIndexValue,
  onDayIndexChange,
}: IFieldProps & {
  dayType: `before` | `after` | `on`;
  dayIndexValue: string;
  onDayIndexChange: (e: { value: string; name: string }, dayType?: `before` | `after` | `on`) => void;
}) {
  const options = useMemo(() => {
    return [
      {
        label: `Day${policyFormData.grace_period === 1 ? `` : `s`}`,
        value: PolicyDayPeriodType.DAYS,
        tooltip: `All calendar days`,
      },
      {
        label: `Weekday${policyFormData.grace_period === 1 ? `` : `s`}`,
        value: PolicyDayPeriodType.WEEKDAYS,
        tooltip: `Does not count weekends. Includes any local holidays.`,
      },
    ];
  }, [policyFormData.grace_period]);

  function handleDaysTypeChange({ value }) {
    return handleChange({
      value,
      name: `day_period_type`,
    });
  }

  const dropdown = (
    <Select
      options={ options }
      selected={ policyFormData.day_period_type }
      onChange={ handleDaysTypeChange }
      name={ `day_period_type` }
      className={ `rounded-l-none` }
      nestedInTextField
    />
  );

  return (
    <>
      <InputTextAddon
        value={ dayIndexValue }
        onChange={ onDayIndexChange }
        name={ `day_index` }
        invalid={ fieldIsValid(`day_index`, invalids) }
        disabled={ dayType === `on` }
        dataType={ `number` }
        addOnText={ dayType === `on` ? `days` : undefined }
        addOnElement={ dayType !== `on` ? dropdown : undefined }
      />
    </>
  );
}
