import { useMemo } from "react";

import { Card } from "../../../common/Atoms/Card";
import { CardContent } from "../../../common/Atoms/CardContent";
import { MultiButtonCardHeader } from "../../../common/Atoms/MultiButtonCardHeader";
import { useUser } from "../../hooks/useUser";
import { LoadingOverlay } from "../Molecules/LoadingOverlay";
import { useDismissAllNotificationsMutation } from "../../services/api/userApi/user";

import { UserNotificationItem } from "./UserNotificationItem";

export function UserNotificationsRoot() {
  const { data: user, isLoading } = useUser();
  const [dismissAll, { isLoading: dismissAllLoading }] = useDismissAllNotificationsMutation();

  const notifications = useMemo(() => {
    return user?.notifications || [];
  }, [user?.notifications]);

  function handleDismissAll() {
    dismissAll({});
  }

  return (
    <LoadingOverlay loading={ isLoading }>
      <Card>
        <MultiButtonCardHeader
          title={ `Notifications` }
          buttons={ [
            {
              buttonText: `Dismiss All`,
              disabled: notifications.length === 0 || dismissAllLoading,
              onClick: handleDismissAll,
              loading: dismissAllLoading,
            },
          ] }
        />
        <CardContent>
          <div className={ `space-y-4 mt-6` }>
            {
              notifications.map(notification => {
                return (
                  <UserNotificationItem
                    key={ notification.id }
                    notification={ notification }
                  />
                );
              })
            }
          </div>
        </CardContent>

      </Card>
    </LoadingOverlay>
  );
}
