import { IAuditLogResponse, IAuditLogResponseItem } from 'shared';

import { orgHeader } from '../axiosBaseQuery';
import { CacheTags } from '../../types/cacheTags';
import { emptySplitApi } from '../baseApi';

export const auditLogApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchAuditLogs: builder.query<IAuditLogResponseItem[], { organisationId: string }>({
      query: ({ organisationId }) => ({
        url: `/audit-logs`,
        method: `GET`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      transformResponse: (rawResult: IAuditLogResponse) => {
        return rawResult?.auditLogs;
      },
      providesTags: result => result ?
        [
          ...result.map(({ id }) => ({ type: CacheTags.AuditLogs, id })),
          { type: CacheTags.AuditLogs, id: `LIST` },
        ]
        : [{ type: CacheTags.AuditLogs, id: `LIST` }],
    }),

  }),
});

export const {
  useFetchAuditLogsQuery,
} = auditLogApi;
