import { allowedSubscriptionStatus } from "shared";

import { useGetSelectedOrganisation } from "./useGetSelectedOrganisation";
import { useGetSubscriptionMetadata } from "./useGetSubscription";

export function useTrialActionLimitReached() {
  const currentOrg = useGetSelectedOrganisation();

  const { subscriptionMetadata } = useGetSubscriptionMetadata();

  if (subscriptionMetadata?.status && allowedSubscriptionStatus.includes(subscriptionMetadata.status)) {
    return false;
  }

  return currentOrg?.usingFreeActionTrial && currentOrg?.freeActionsUsed >= currentOrg?.freeActionLimit;
}
