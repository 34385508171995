import React from 'react';
import { When } from 'react-if';

import { classNames }  from '../../../../common/lib/classNames';
import { Button } from '../../../../common/Atoms/Button';
import { Notification } from '../../../../common/Atoms/Notification';

export function FormWithButton({ children, className, submitText, loading, invalids, ...props }) {
  return (
    <form { ...props }
      className={
        classNames(
          `h-full`,
          className,
        )
      }>
      <div className={ `flex flex-col justify-between h-full` }>
        { children }
      </div>

      <When condition={ invalids?.length }>
        <div className={ `transition ease-in-out duration-250 pb-4` }>
          <Notification
            type={ `error` }
            title={ `Errors found in template:` }
          >
            <span className={ `flex flex-col` }>
              {
                invalids?.map(err => (
                  <span key={ err.message }>
                    { err.message }
                  </span>
                ))
              }
            </span>
          </Notification>
        </div>
      </When>

      <div className={ `flex flex-row justify-end` }>
        <Button
          type={ `submit` }
          disabled={ props.disabled || loading }
          loading={ loading }
        >
          { submitText || `Save` }
        </Button>
      </div>
    </form>
  );
}
