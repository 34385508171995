import { useParams } from 'react-router';
import { IUpdateSettingsRequest } from 'shared';

import { useFetchSettingsQuery } from '../services/api/settingsApi/settings';
import { useUpdateSettingsMutation } from '../services/api/settingsApi/settings';

export function useSettings() {
  const { organisationId } = useParams();

  const result = useFetchSettingsQuery(organisationId, {
    skip: !organisationId,
  });

  return result;
}

export function useSettingsUpdate() {
  const { organisationId } = useParams();

  const [ updateFn, ...args ] = useUpdateSettingsMutation();

  const update = (settings: IUpdateSettingsRequest) => updateFn({ organisationId, settings });

  return [ update, ...args ];
}
