import { useMemo } from "react";
import { MixpanelEvents, langauge } from "shared";
import { When } from "react-if";

import { Paragraph } from "../../../../common/Atoms/Typography/Paragraph";
import { classNames } from "../../../../common/lib/classNames";
import { useGroups } from "../../../hooks/useGroups";
import { useSaveOnboardingCompleteMutation } from "../../../services/api/organisationApi/organisation";
import { useGetSelectedOrganisation } from "../../../hooks/useGetSelectedOrganisation";
import { mixpanelService } from "../../../../common/lib/mixpanel";
import { Button } from "../../../../common/Atoms/Button";
import { useModals } from "../../../hooks/useModals";
import { Heading } from "../../../../common/Atoms/Typography/Heading";

export function FinalNotes() {
  const currentOrg = useGetSelectedOrganisation();
  const [saveOnboardingComplete] = useSaveOnboardingCompleteMutation();
  const { show } = useModals();

  function onComplete() {
    saveOnboardingComplete(currentOrg.id);

    mixpanelService.trackMixpanelEvent(MixpanelEvents.OnboardingCompleted);
  }

  const { data: groups } = useGroups();

  const nextSteps: {
    title: string;
    content: string;
    child?: React.ReactNode;
  }[] = useMemo(() => {

    const arr: {
      title: string;
      content: string;
      child?: React.ReactNode;
    }[] = [
      {
        title: `Chat With Us`,
        content: `Need help with setting up, or simply want us to check your settings before going live. Book a free onboarding call with us. Please let us know if you cannot find a time that suits.`,
        child: <Button
          onClick={ e => {
            e.preventDefault();
            show(`call`);
            mixpanelService.demoCallClicked();
          } }
        >
          { `Find a time` }
        </Button>,
      },
      {
        title: `Visit Help Centre`,
        content: `Browse our range of articles and videos to help you get started. If you can't find what you're looking for, reach out to us.`,
        child: <Button
          onClick={ e => {
            e.preventDefault();
            window.open(`https://support.paidnice.com`, `_blank`);
          } }
        >
          { `View Support` }
        </Button>,
      },
    ];

    return arr;
  }, []);

  // As the only groups/policies should be the ones we just created, we can summarise what we did for the user
  const groupSummaryContent = useMemo(() => {
    if (!groups) return null;

    const defaultGroup = groups.find(g => g.default);
    const otherGroup = groups.find(g => !g.default);

    if (groups.length === 1) {
      // They selected "everyone"
      const policies = defaultGroup.policies;
      const policyNames = policies.map(p => langauge.policyType[p.policy_type]).join(`, `);

      return [
        `We created a group called "${defaultGroup.title}".`,
        `As this is the default group all contacts will be automatically added to it.`,
        `We then added the policies you selected to this group. (${policyNames})`,
      ];
    }

    if (defaultGroup.policies.length > 0 && otherGroup) {
      // They selected to have a whitelist
      const policies = defaultGroup.policies;
      const policyNames = policies.map(p => langauge.policyType[p.policy_type]).join(`, `);

      return [
        `We created two groups. One called "${defaultGroup.title}" and the other called "${otherGroup.title}".`,
        `All contacts will be added to the "${defaultGroup.title}" group automatically.`,
        `We then added the policies you selected to the "${defaultGroup.title}" group. (${policyNames})`,
        `To stop contacts from having those policies applied, move them to the "${otherGroup.title}" group.`,
      ];
    }

    if (otherGroup?.policies?.length > 0) {
      const policies = otherGroup.policies;
      const policyNames = policies.map(p => langauge.policyType[p.policy_type]).join(`, `);

      return [
        `We created two groups. One called "${defaultGroup.title}" and another called "${otherGroup.title}".`,
        `All contacts will be added to the "${defaultGroup.title}" group automatically.`,
        `We then added the policies you selected to the "${otherGroup.title}" group. (${policyNames})`,
        `Only contacts you move to the "${otherGroup.title}" group will have those policies applied. Everyone else will not have any action taken on them`,
      ];
    }

    console.error(`Unknown group setup`, groups);

    return null;

  }, [groups]);

  return (
    <div>
      <When condition={ !!groupSummaryContent }>
        <div className={ `bg-green-100 p-4 rounded-md shadow-md mb-6` }>
          <ul className={ `list-disc pl-6` }>
            { groupSummaryContent.map((line, index) => (
              <li key={ index }
                className={ `mt-2` }
              >
                <Paragraph>
                  { line }
                </Paragraph>
              </li>
            )) }
            <li className={ `mt-2` }>
              <a
                href={ `#` }
                onClick={ e => {
                  e.preventDefault();
                  onComplete();
                } }>

                <Paragraph
                  variant={ `link` }
                >
                  { `Head to the group page to configure these policies.` }
                </Paragraph>
              </a>
            </li>
          </ul>
        </div>
      </When>
      <Heading>
        { `More help options` }
      </Heading>
      <div className={ classNames(
        `grid grid-cols-2 md:grid-cols-4 gap-4`,
        nextSteps.length === 4 && `md:grid-cols-3`,
      ) }>
        {
          nextSteps.map((step, index) => (
            <div key={ index }
              className={ classNames(
                `mb-4 shadow-md rounded-md p-4 min-h-[120px] flex flex-col justify-between`,
              ) }
            >
              { /* Main */ }
              <div className={ `space-y-3` }>

                <Heading>
                  { step.title }
                </Heading>

                <Paragraph
                  variant={ `help` }
                >
                  { step.content }
                </Paragraph>
              </div>

              { /* CTA */ }
              {
                step.child ? (
                  <div className={ `pt-2` }>
                    { step.child }
                  </div>
                ) : null
              }
            </div>
          ))
        }
      </div>
    </div>
  );
}
