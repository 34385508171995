import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Case, Switch } from 'react-if';
import { format } from 'date-fns';
import { EmailTemplateTypes } from 'shared';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';

import { StackedList } from '../../../../common/Atoms/StackedList';
import { MultiButtonCardHeader } from '../../../../common/Atoms/MultiButtonCardHeader';
import { useCreateEmailTemplateMutation, useDeleteEmailTemplateMutation, useDuplicateEmailTemplateMutation } from '../../../services/api/emailTemplates/emailTemplates';
import { Card } from '../../../../common/Atoms/Card';
import { CardContent } from '../../../../common/Atoms/CardContent';
import { Dropdown } from '../../../../common/Atoms/Dropdown';
import { useEmailTemplates } from '../../../hooks/useEmailTemplates';

import { typeColors, types } from './constants';

function createContent(templateRow) {
  const name = templateRow.user ? `${templateRow.user.first_name} ${templateRow.user.last_name}` : `Generated`;

  const {
    updated_at,
  } = templateRow;

  const formatted = format(new Date(updated_at), `dd LLL yy h:mm a`);

  const metadataA = `Last updated by ${name}`;
  let metadataB = `on ${formatted}`;

  if (templateRow.policies.length > 0) {
    metadataB = metadataB + ` (Used in ${templateRow.policies.length} policies)`;
  }

  return {
    metadataA,
    metadataB,
  };
}

export function EmailTemplates() {
  const { organisationId } = useParams();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data: emailTemplates, error } = useEmailTemplates() as any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [ createTemplate, createResult ] = useCreateEmailTemplateMutation() as any;
  const [ deleteTemplate ] = useDeleteEmailTemplateMutation();
  const [ duplicateTemplate ] = useDuplicateEmailTemplateMutation();

  useEffect(() => {
    if (createResult.isError) {
      toast.error(createResult.error.data);
    }
  }, [createResult.isError]);

  const ctaOptions = useMemo(() => {
    const customs = [
      {
        label: `Invoice Reminder Email`,
        onClick: () => createTemplate({
          org: organisationId,
          type: EmailTemplateTypes.REMINDER,
        }),
      },
      {
        label: `Invoice Marked Paid Email`,
        onClick: () => createTemplate({
          org: organisationId,
          type: EmailTemplateTypes.AFTER_MARKED_PAID,
        }),
      },
      {
        label: `Late Fee Email`,
        onClick: () => createTemplate({
          org: organisationId,
          type: EmailTemplateTypes.LATE_FEE,
        }),
      },
      {
        label: `Discount Email`,
        onClick: () => createTemplate({
          org: organisationId,
          type: EmailTemplateTypes.DISCOUNT,
        }),
      },
      {
        label: `Statement Email`,
        onClick: () => createTemplate({
          org: organisationId,
          type: EmailTemplateTypes.STATEMENT_EMAIL,
        }),
      },
      {
        label: `Statement Interest Charge Email`,
        onClick: () => createTemplate({
          org: organisationId,
          type: EmailTemplateTypes.STATEMENT_INTEREST_EMAIL,
        }),
      },
      {
        label: `Announcement Email`,
        onClick: () => createTemplate({
          org: organisationId,
          type: EmailTemplateTypes.ANNOUNCEMENT,
        }),
      },
      {
        label: `Quote Email`,
        new: true,
        onClick: () => createTemplate({
          org: organisationId,
          type: EmailTemplateTypes.QUOTE,
        }),
      },
    ];

    return [
      customs,
    ];
  }, [createTemplate, organisationId]);

  function onEdit(e, id) {
    if (e) {
      e.preventDefault();
    }

    navigate(id);
  }

  function onDelete(e, id) {
    if (e) {
      e.preventDefault();
    }

    deleteTemplate({
      org: organisationId,
      id,
    });
  }

  function onDuplicate(e, id) {
    if (e) {
      e.preventDefault();
    }

    duplicateTemplate({
      org: organisationId,
      id,
    });
  }

  const templates = useMemo(() => {
    if (!emailTemplates) return null;

    return emailTemplates
      .filter(t => t.type !== EmailTemplateTypes.LEGACY_LATE_FEE)
      .map(t => {
        const { metadataA, metadataB } = createContent(t);

        // Was this just created?
        const dt = DateTime.fromISO(t.created_at);
        let justAdded = false;

        // Created less than 4 minutes ago
        if (dt.diffNow().as(`minutes`) > -4) {
          justAdded = true;
        }

        return {
          title: t.name,
          metadataA,
          metadataB,
          id: t.id,
          onEdit: e => onEdit(e, t.id),
          onDelete: e => onDelete(e, t.id),
          onDuplicate: e => onDuplicate(e, t.id),
          link: `${t.id}`,
          type: types[t.type],
          typeColor: typeColors[t.type],
          created: t.created_at,
          justAdded,
        };
      });
  }, [emailTemplates]);

  return (
    <Card>
      <MultiButtonCardHeader
        title={ `Email Templates` }
        description={ `Manage and create all your email designs here.` }
        cta={ <Dropdown
          size={ `md` }
          options={ ctaOptions }
          label={ `Create New` }
          disabled={ createResult.isLoading }
        /> }
      />
      <CardContent>
        <Switch>
          <Case condition={ error?.message }>
            <div>{ `Error: ` }{ error?.message }</div>
          </Case>

          <Case condition={ emailTemplates }>
            <StackedList
              items={ templates }
              className={ `mt-6` }
            />
          </Case>
        </Switch>
      </CardContent>

    </Card>
  );
}
