import { accountingSystemSpecificSetting } from 'shared';
import { useMemo } from 'react';

import { useGetSelectedOrganisation } from './useGetSelectedOrganisation';

export function useAccountingSystemSetting(setting) {

  const organisation = useGetSelectedOrganisation();

  const accountingTerm = useMemo(() => {
    return accountingSystemSpecificSetting(organisation, setting);
  }, [organisation?.type, setting]);

  return accountingTerm;
}
