import { useFetchSubscriptionsQuery } from '../services/api/subscriptionApi/subscription';

export function useSubscriptions() {
  const result = useFetchSubscriptionsQuery();

  if (!result.data) {
    result.data = [];
  }

  return result;
}
