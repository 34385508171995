import { createApi } from '@reduxjs/toolkit/query/react';

import { CacheTags } from '../types/cacheTags';

import { axiosBaseQuery } from './axiosBaseQuery';

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
  reducerPath: `api`,
  tagTypes: Object.values(CacheTags),
});
