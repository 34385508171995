import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { When } from "react-if";

import { Modal } from "../../../common/Atoms/Modal";
import { RootState } from "../../store";
import { setState } from "../../slices/revertLateFeeModal";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { Button } from "../../../common/Atoms/Button";
import { SmallSpinner } from "../../../common/Atoms/SmallSpinner";
import { Heading } from "../../../common/Atoms/Typography/Heading";
import { Divider } from "../../../common/Atoms/Divider";

export function RevertLateFeeModal() {
  const dispatch = useDispatch();
  const modalState = useSelector((s: RootState) => s.lateFeeRevertModal);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  function onClose() {
    setConfirmed(false);
    setSuccess(false);
    setError(null);
    setLoading(false);

    dispatch(setState({
      show: false,
      actionId: null,
    }));
  }

  async function revertLateFee() {
    setConfirmed(true);
    setLoading(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 3000));

      setError(new Error(`Something went wrong`));
    }
    catch (e) {
      setError(e);
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      title={ `Revert Late Fee` }
      open={ modalState.show }
      setOpen={ onClose }
    >

      <When condition={ !confirmed }>
        <Paragraph>
          { `Are you sure you want to revert this late fee?` }
        </Paragraph>
        <div className={ `flex flex-row justify-end mt-8` }>
          <Button
            color={ `red` }
            onClick={ revertLateFee }>
            { `Confirm Revert Late Fee` }
          </Button>
        </div>
      </When>

      <When condition={ loading }>
        <div className={ `flex flex-col justify-center items-center  py-12` }>
          <SmallSpinner
            colored
            size={ `lg` }
          />
          <Paragraph variant={ `secondary` }>
            { `Reverting late fee...` }
          </Paragraph>
        </div>
      </When>

      <When condition={ success }>
        <div className={ `flex flex-col justify-center items-center  py-12` }>
          <Heading>
            { `Success` }
          </Heading>

          <Paragraph variant={ `secondary` }>
            { `The late fee has been reverted successfully. You may now close this popup.` }
          </Paragraph>
        </div>
      </When>

      <When condition={ error }>
        <div className={ `flex flex-col justify-center items-center  py-12` }>
          <Heading>
            { `Error` }
          </Heading>

          <Paragraph variant={ `secondary` }>
            { `The following error(s) occurred while trying to revert the late fee.` }
          </Paragraph>

          <Divider weight={ `light` } />
          <Paragraph variant={ `help` }>
            { error?.message }
          </Paragraph>
        </div>
      </When>

    </Modal>
  );
}

