import { policyConfig } from "shared";

import { fieldIsValid } from "../../../../../lib/helper";
import { Toggle } from "../../../../../../common/Atoms/Toggle";

import { IFieldProps } from "./fieldProps";

const config = policyConfig[`sms_fallback_to_phone`];

export function SMSFallbackToPhone({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {

  return (
    <Toggle
      label={ config.label }
      description={ `If the contact has no mobile phone number, send SMS to the business phone number` }
      checked={ policyFormData.sms_fallback_to_phone || false }
      onChange={ handleChange }
      invalid={ fieldIsValid(`sms_fallback_to_phone`, invalids) }
      name={ `sms_fallback_to_phone` }
    />
  );
}
