import { useParams } from 'react-router';
import { IGroup, IGroupDefaultRule, IGroupUpdate, IInvalidField, validatePolicySync } from 'shared';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFetchGroupsQuery, useUpdateGroupMutation } from '../services/api/groupApi/group';
import { validateForm } from '../services/forms/validate';

import { useGetSelectedOrganisation } from './useGetSelectedOrganisation';

export function useGroups() {
  const { organisationId } = useParams();

  const result = useFetchGroupsQuery(organisationId, {
    skip: !organisationId,
  });

  return result;
}

export function useCurrentGroup() {
  const { data: groups } = useGroups();
  const currentOrg = useGetSelectedOrganisation();
  const [updateGroup, updateApi] = useUpdateGroupMutation();
  const [isDirty, setIsDirty] = useState(false);
  const [invalidPolicyCount, setInvalidPolicyCount] = useState(0);

  const { groupId } = useParams();
  const group = useMemo(() => {
    if (!groups) {
      return null;
    }

    return groups.find(group => group.id === Number(groupId)) || null;
  }, [groups, groupId]);

  const [groupFormData, setGroupFormData] = useState<IGroup | null>(group ? { ...group } : null );
  const [invalidFields, setInvalidFields] = useState<IInvalidField[]>([]);

  useEffect(() => {
    setGroupFormData(group ? { ...group } : null);

    const invalidPolicyCount = group?.policies?.filter(p => {
      try {
        validatePolicySync(p);

        return false;
      }
      catch (e) {
        return true;
      }
    }).length;

    setInvalidPolicyCount(invalidPolicyCount);
  }, [group]);

  const onSave = useCallback(async() => {
    console.log(`onSave - `, isDirty);
    if (!isDirty) {
      return;
    }

    setInvalidFields([]);
    // Save group

    const validated = await validateForm<IGroupUpdate>(groupFormData, `GROUP`);

    if (!validated.valid) {
      setInvalidFields(validated.invalids);

      return;
    }

    updateGroup({
      organisationId: currentOrg.id,
      id: group.id,
      update: validated.form,
    });

    setIsDirty(false);
  }, [groupFormData, isDirty, currentOrg]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = useCallback((field: string, value: any) => {
    setGroupFormData({
      ...groupFormData,
      [field]: value,
    });

    setIsDirty(true);
  }, [groupFormData]);

  return {
    groupFormData,
    onChange,
    onSave,
    invalidFields,
    isDirty,
    updateLoading: updateApi.isLoading,
    policies: group?.policies || [],
    invalidPolicyCount,
  };
}

export function useAllGroupRules() {
  const { data: groups } = useGroups();

  if (!groups) {
    return [];
  }

  const rules: IGroupDefaultRule[] = [];

  groups.forEach(group => {
    if (group.rules) {
      group.rules.forEach(rule => {
        rules.push(rule);
      });
    }
  });

  rules.sort((a, b) => (a.priority || 0) - (b.priority || 0));

  return rules;
}
