import { Outlet } from "react-router";
import { ArrowTrendingUpIcon, BoltIcon, DocumentCheckIcon, InboxIcon, PencilSquareIcon } from "@heroicons/react/24/outline";

import { Card } from "../../../../../common/Atoms/Card";
import { Tabs } from "../../../../../common/Atoms/Tabs";

export function ContactTableSectionRoot() {
  const tabs = [
    {
      name: `Statement`,
      href: ``,
      icon: InboxIcon,
    },
    {
      name: `Actions`,
      href: `activity`,
      icon: BoltIcon,
    },
    {
      name: `Escalations`,
      href: `tasks`, // Different name for navigation highlight
      icon: ArrowTrendingUpIcon,
    },
    {
      name: `Notes`,
      href: `notes`,
      icon: PencilSquareIcon,
    },
    {
      name: `Applicable Policies`,
      href: `policies`,
      icon: DocumentCheckIcon,
    },
  ];

  return (
    <Card>
      <Tabs
        tabs={ tabs }
      />

      <Outlet />
    </Card>
  );
}
