import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { Else, If, Then, When } from 'react-if';
import { Link } from 'react-router-dom';

import { classNames }  from '../lib/classNames';

import { Badge } from './Badge';

export function StackedList({ items, className }) {
  function itemClick(fn, close) {
    if (fn) {
      fn();
    }

    close();
  }

  return (
    <ul role={ `list` }
      className={
        classNames(
          `divide-y divide-gray-100`,
          className,
        )
      }>
      { items.map(item => (
        <li key={ item.id }
          className={ `flex items-center justify-between gap-x-6 py-5` }>
          <div className={ `min-w-0` }>
            <div className={ `flex items-start gap-x-3` }>
              <If condition={ item.link }>
                <Then>
                  <Link to={ item.link }>
                    <p className={ `text-sm font-semibold leading-6 text-gray-900` }>{ item.title }</p>
                  </Link>
                </Then>
                <Else>
                  <p className={ `text-sm font-semibold leading-6 text-gray-900` }>{ item.title }</p>
                </Else>
              </If>
              <When condition={ () => item.status }>
                <Badge color={ item.statusColor }
                  message={ item.status } />
              </When>
              <Badge color={ item.typeColor }
                message={ item.type } />
              <When condition={ item.justAdded }>
                <Badge
                  color={ `green` }
                  message={ `Recently Created` }
                />
              </When>
            </div>
            <div className={ `mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500` }>
              <p className={ `whitespace-nowrap` }>
                { item.metadataA }
              </p>
              <svg viewBox={ `0 0 2 2` }
                className={ `h-0.5 w-0.5 fill-current` }>
                <circle cx={ 1 }
                  cy={ 1 }
                  r={ 1 } />
              </svg>
              <p className={ `truncate` }>{ item.metadataB }</p>
            </div>
          </div>
          <div className={ `flex flex-none items-center gap-x-4` }>
            <Menu as={ `div` }
              className={ `relative flex-none` }>
              <Menu.Button className={ `-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900` }>
                <span className={ `sr-only` }>{ `Open options` }</span>
                <EllipsisVerticalIcon className={ `h-5 w-5` }
                  aria-hidden={ `true` } />
              </Menu.Button>
              <Transition
                as={ Fragment }
                enter={ `transition ease-out duration-100` }
                enterFrom={ `transform opacity-0 scale-95` }
                enterTo={ `transform opacity-100 scale-100` }
                leave={ `transition ease-in duration-75` }
                leaveFrom={ `transform opacity-100 scale-100` }
                leaveTo={ `transform opacity-0 scale-95` }
              >
                <Menu.Items className={ `absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none` }>
                  <When condition={ () => item.onEdit }>
                    <Menu.Item>
                      { ({ active, close }) => (
                        <a
                          href={ `#` }
                          onClick={ () => itemClick(item.onEdit, close) }
                          className={ classNames(
                            active ? `bg-gray-50` : ``,
                            `block px-3 py-1 text-sm leading-6 text-gray-900`,
                          ) }
                        >
                          { `Edit` }<span className={ `sr-only` }>{ `, ` }{ item.title }</span>
                        </a>
                      ) }
                    </Menu.Item>
                  </When>
                  <When condition={ () => item.onDelete }>
                    <Menu.Item>
                      { ({ active, close }) => (
                        <a
                          href={ `#` }
                          onClick={ () => itemClick(item.onDelete, close) }
                          className={ classNames(
                            active ? `bg-gray-50` : ``,
                            `block px-3 py-1 text-sm leading-6 text-gray-900`,
                          ) }
                        >
                          { `Delete` }<span className={ `sr-only` }>{ `, ` }{ item.title }</span>
                        </a>
                      ) }
                    </Menu.Item>
                  </When>
                  <When condition={ () => item.onDuplicate }>
                    <Menu.Item>
                      { ({ active, close }) => (
                        <a
                          href={ `#` }
                          onClick={ () => itemClick(item.onDuplicate, close) }
                          className={ classNames(
                            active ? `bg-gray-50` : ``,
                            `block px-3 py-1 text-sm leading-6 text-gray-900`,
                          ) }
                        >
                          { `Duplicate` }<span className={ `sr-only` }>{ `, ` }{ item.title }</span>
                        </a>
                      ) }
                    </Menu.Item>
                  </When>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </li>
      )) }
    </ul>
  );
}
