import { IHeroIcon } from "../../main/types/types";
import { classNames } from "../lib/classNames";
import { DatePopover } from "../Components/DatePopover";

export interface ITimeLineFeedItem {
  timestamp: string;
  description: string;
  icon: IHeroIcon;
  id: string | number;
}

interface ITimeLineFeedProps {
  feed: ITimeLineFeedItem[];
  timezone: string;
}

export function TimelineFeed({ feed, timezone }: ITimeLineFeedProps) {
  return (
    <div className={ `flow-root mt-6` }>
      <ul role={ `list` }
        className={ `-mb-8` }>
        { feed.map((item, eventIdx) => (
          <li key={ item.id }>
            <div className={ `relative pb-8` }>
              { eventIdx !== feed.length - 1 ? (
                <span className={ `absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200` }
                  aria-hidden={ `true` } />
              ) : null }
              <div className={ `relative flex space-x-3` }>
                <div className={ `` }>
                  <span
                    className={ classNames(

                      `h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white bg-slate-500`,
                    ) }
                  >
                    <item.icon className={ `h-5 w-5 text-white` }
                      aria-hidden={ `true` } />
                  </span>
                </div>
                <div className={ `flex min-w-0 flex-1 justify-between space-x-4 pt-1.5` }>
                  <div>
                    <p className={ `text-sm text-gray-500` }>
                      { item.description }
                    </p>
                  </div>
                  <div className={ `whitespace-nowrap text-right text-sm text-gray-500` }>
                    <time dateTime={ item.timestamp }>
                      <DatePopover
                        date={ item.timestamp }
                        labelFormat={ `dd LLL yyyy HH:mm` }
                        organisationTimezone={ timezone }
                      />
                    </time>
                  </div>
                </div>
              </div>
            </div>
          </li>
        )) }
      </ul>
    </div>
  );
}
