import { EmailTemplateTypes, PolicyType, StatementRelativePeriods } from "shared";

import { useAdhocActionModal } from "../../../../hooks/useAdhocActionModal";
import { Heading } from "../../../../../common/Atoms/Typography/Heading";
import { EmailTemplateSelector } from "../PolicyForm/Fields/EmailTemplateSelector";
import { Select } from "../../../../../common/Atoms/Select";
import { Toggle } from "../../../../../common/Atoms/Toggle";

const periodOptions = [
  {
    label: `All Outstanding Items`,
    value: StatementRelativePeriods.ALL_TIME,
  },
  {
    label: `View all outstanding & future`,
    value: StatementRelativePeriods.ALL_TIME_INC_FUTURE,
  },
  {
    label: `Issued before current month`,
    value: StatementRelativePeriods.UPTO_PREVIOUS_MONTH,
  },
  {
    label: `Issued only in the previous month`,
    value: StatementRelativePeriods.PREVIOUS_MONTH,
  },
  {
    label: `Issued only in the current month`,
    value: StatementRelativePeriods.THIS_MONTH,
  },
];

export function AdhocStatement() {
  const { type, currentSystemPolicy, currentSystemPolicyForm, update, contactName, contactId } = useAdhocActionModal();

  if (!currentSystemPolicy || !currentSystemPolicyForm) return null;

  if (!type) return null;

  if (type !== PolicyType.STATEMENT || currentSystemPolicy.policy_type !== PolicyType.STATEMENT) return null;

  if (!contactName || !contactId) return null;

  return (
    <div>
      <Heading>
        { `Send a one off outstanding statement to ${contactName}` }
      </Heading>

      <div className={ `p-4 space-y-2` }>
        <EmailTemplateSelector
          value={ currentSystemPolicyForm.template_id }
          name={ `template_id` }
          handleChange={ update }
          label={ `Email template` }
          emptyText={ `Select Template` }
          nullable
          tourTarget={ `statementTemplate` }
          currentPolicyId={ currentSystemPolicy.id }
          type={ EmailTemplateTypes.STATEMENT_EMAIL }
        />

        <Select
          label={ `Statement Period` }
          options={ periodOptions }
          onChange={ update }
          selected={ currentSystemPolicyForm.statement_period }
          name={ `statement_period` }
          helpIcon={
            `Which Invoices & Credits to include in the statement. \n Current and previous months are relative to the day in which the statement is sent. Based on Issue Date.`
          }
        />

        <Toggle
          label={ `Attach statement as PDF` }
          checked={ currentSystemPolicyForm.include_statement_pdf || false }
          onChange={ update }
          name={ `include_statement_pdf` }
          description={ `Attaches a generated PDF statement to the email` }
        />
      </div>
    </div>
  );
}
