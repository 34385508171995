import { IPolicy, PolicyType } from 'shared';

export function sanityCheckPolicy(policy: IPolicy) {
  if (policy.policy_type === PolicyType.LATE_FEE) {
    return sanityCheckLateFee(policy);
  }

  return [];
}

function sanityCheckLateFee(policy: IPolicy) {
  const warnings = [];

  if (policy.policy_type === PolicyType.LATE_FEE) {
    // Late Fee Checks
    if (policy.first_time_only && policy.repeating) {
      warnings.push(`This late fee is repeating and first time only is enabled, late fees will repeat until another policy creates a late fee.`);
    }
  }

  return warnings;
}
