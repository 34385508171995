import { fieldIsValid } from "../../../../../lib/helper";
import { useGetSelectedOrganisation } from "../../../../../hooks/useGetSelectedOrganisation";
import { InputTextAddon } from "../../../../../../common/Atoms/InputTextAddon";

import { IFieldProps } from "./fieldProps";

export function InvoiceReferenceIncludes({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {
  const org = useGetSelectedOrganisation();
  const term = org.accountingSystemType === `XERO` ? `reference` : `memo`;
  const label = `Only include invoices where ${term} contains:`;
  const description = `Only include invoices that have this value in the ${term}. Leave blank to include all invoices.`;
  const helpText = `This is not case sensitive. Caution: May also filter out previous interest charges!`;

  return (
    <InputTextAddon
      label={ label }
      description={ description }
      helpIcon={ helpText }
      value={ policyFormData.reference_contains }
      onChange={ handleChange }
      name={ `reference_contains` }
      invalid={ fieldIsValid(`reference_contains`, invalids) }
    />
  );
}
