import { When } from "react-if";

import { Notification } from "../../../../common/Atoms/Notification";
import { Button } from "../../../../common/Atoms/Button";
import { classNames } from "../../../../common/lib/classNames";
import { useGeneralSettingsForm } from "../../../hooks/useGeneralSettingsForm";

interface SaveAndErrorProps {
  className?: string;
}

export function SaveAndError({
  className,
}: SaveAndErrorProps) {
  const { hasUnsavedChanges, onSave, globalInvalids, updateLoading } = useGeneralSettingsForm();

  return (
    <div className={
      classNames(
        `mt-6`,
        className,
      )
    }>
      <When condition={ globalInvalids.length }>
        {
          globalInvalids.map((invalid, i) => (
            <Notification
              key={ i }
              title={ `Could not save settings!` }
              type={ `error` }
            >
              { invalid.message }
            </Notification>
          ))
        }
        <div className={ `mb-6` } />
      </When>
      <div className={ `flex justify-end` }>
        <Button
          loading={ updateLoading }
          loadingText={ `Saving...` }
          onClick={ onSave }
          disabled={ !hasUnsavedChanges }
        >
          { `Save` }
        </Button>
      </div>
    </div>
  );
}
