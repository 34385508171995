import { BeakerIcon } from "@heroicons/react/24/outline";

import { Notification } from "../../../common/Atoms/Notification";

import { AICallSettings } from "./AICallSettings";
import { AICallTable } from "./AICallTable";

export function AICallRoot() {

  return (
    <div className={ `space-y-4` }>
      <Notification
        type={ `pro` }
        icon={ BeakerIcon }
        border
      >
        { `You have early access to this feature. It may not be perfect so please let us know if you have any feedback!` }
      </Notification>
      <AICallSettings />
      <AICallTable />
    </div>
  );
}
