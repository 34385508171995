import { createSlice } from '@reduxjs/toolkit';

export interface ILateFeeRevertModal {
  actionId: number | null;
  show: boolean;
}

const initialState = {
  actionId: null,
  show: false,
};

const lateFeeRevertModalSlice = createSlice({
  name: `lateFeeRevertModal`,
  initialState,
  reducers: {
    setState(state, action) {
      Object.assign(state, action.payload);
    },
  },
});

export const { setState } = lateFeeRevertModalSlice.actions;

export const lateFeeRevertModalReducer = lateFeeRevertModalSlice.reducer;
