import React from 'react';
import { When } from 'react-if';
import { IInvalidField } from 'shared';
import { XCircleIcon } from '@heroicons/react/24/outline';

import { classNames }  from '../lib/classNames';
import { HelpIcon } from '../../main/components/Molecules/HelpIcon';

import { FormLabel } from './FormLabel';
import { FormDescription } from './FormDescription';
import { Paragraph } from './Typography/Paragraph';

const classes = {
  invalid: `border-red-600`,
};

export interface InputTextAddonProps {
  label?: string;
  value: string | number;
  name?: string;
  onChange: (e: { value: string | number; name: string }) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  addOnText?: string | JSX.Element;
  description?: string | JSX.Element;
  invalid?: IInvalidField;
  disabled?: boolean;
  subHeading?: string;
  className?: string;
  required?: boolean;
  prefix?: string;
  /** When prefix is also a form */
  prefixOnChange?: (value: string) => void;
  dataType?: `number` | `string`;
  /** Text to go in a help icon tool tip */
  helpIcon?: string;
  addOnElement?: React.ReactNode;
  demoTarget?: string;
  clearable?: boolean;
}

export function InputTextAddon({
  label,
  value,
  name,
  onChange,
  onKeyDown,
  placeholder,
  addOnText,
  description,
  invalid,
  disabled,
  subHeading,
  className,
  prefix,
  prefixOnChange,
  helpIcon,
  dataType = `string`,
  addOnElement,
  demoTarget,
  clearable,
  ...props
}: InputTextAddonProps) {

  function handleChange(e) {
    if (clearable && e.target.value === null) {
      return onChange({
        value: null,
        name,
      });
    }

    let value = (dataType !== `number` && e.target.value !== null) ?
      e.target.value :
      Number(e.target.value);

    if (isNaN(value)) {
      // Fallback to OG value for now
      value = e.target.value;
    }

    return onChange({
      value,
      name,
    });
  }

  const prefixComponent = (prefixOnChange && prefix) ? (
    <input
      className={ `inline-flex items-center rounded-l-md whitespace-nowrap border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm` }
      onChange={ e => prefixOnChange(e.target.value) }
      value={ prefix }
    />
  ) : (
    <span className={ `inline-flex items-center rounded-l-md whitespace-nowrap border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm` }>
      { prefix }
    </span>
  );

  return (
    <div
      data-tour={ demoTarget }
      className={
        classNames(
          className,
        )
      }>
      <FormLabel name={ name }>
        <span className={ `flex` }>
          { label }
          <When condition={ !!helpIcon }>
            <HelpIcon
              tooltip={ helpIcon }
              className={ `ml-1` }
            />
          </When>
        </span>
      </FormLabel>
      <When condition={ subHeading }>
        <FormDescription>
          { subHeading }
        </FormDescription>
      </When>
      <div className={ `mt-2 flex rounded-md shadow-sm` }>
        <When condition={ !!prefix }>
          { prefixComponent }
        </When>
        <input
          { ...props }
          value={ value }
          onChange={ handleChange }
          type={ `text` }
          name={ name }
          id={ name }
          onKeyDown={ onKeyDown }
          className={
            classNames(
              `block w-full rounded-md focus:border-lateGreen-500 focus:ring-lateGreen-500 sm:text-sm`,
              // `block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`,
              invalid ? classes[`invalid`] : `border-gray-300`,
              disabled ? `bg-gray-100 cursor-not-allowed` : `bg-white`,
              prefix && ` rounded-none rounded-r-md`,
              addOnText && ` rounded-none rounded-l-md text-left`,
              !prefix && !addOnText && `rounded-md`,
              addOnElement && `rounded-none rounded-l-md`,
              // If has addon and prefix, no rounding
              prefix && (addOnText || addOnElement) && `rounded-none`,
              clearable && value && `border-r-0`,
            )
          }
          placeholder={ placeholder }
          disabled={ disabled }
        />
        <When condition={ clearable && value }>
          <button
            onClick={ () => handleChange({ target: { value: null, name } }) }
            className={ classNames(
              `border-t border-b border-r border-gray-300`,
              invalid ? classes[`invalid`] : `border-gray-300`,
            ) }
          >
            <XCircleIcon className={ `h-5 w-5 mr-1 text-gray-400` } />
          </button>
        </When>
        <When condition={ !!addOnText }>
          <span className={ `inline-flex items-center rounded-r-md whitespace-nowrap border border-l-0 border-gray-300 px-3 text-gray-500 sm:text-sm` }>
            { addOnText }
          </span>
        </When>
        <When condition={ !!addOnElement }>
          <span className={ `inline-flex` }>
            { addOnElement }
          </span>
        </When>
      </div>
      <When condition={ !!invalid }>
        <Paragraph variant={ `error` }
          size={ `xs` }>
          { invalid?.message }
        </Paragraph>
      </When>
      <FormDescription>
        { description }
      </FormDescription>
    </div>
  );
}
