import { Switch, Case } from 'react-if';
import { EmailTemplateTypes, PolicyCalculationType, PolicyTargetStatus } from 'shared';
import { useMemo, useState } from 'react';

import { RadioStack } from '../../../../../common/Atoms/RadioStack';
import { InputTextAddon } from '../../../../../common/Atoms/InputTextAddon';
import { fieldIsValid } from '../../../../lib/helper';
import { useAccountingSystemTerm } from '../../../../hooks/useAccountingSystemTerm';
import { Grid } from '../../../../../common/Atoms/Grid';
import { GridItem } from '../../../../../common/Atoms/GridItem';
import { CurrencyInput } from '../../../../../common/Atoms/CurrencyInput';
import { AccountSelector } from '../PolicyForm/AccountSelector';
import { IPolicyProps } from '../PolicyItem';
import { TaxSelector } from '../PolicyForm/TaxSelector';
import { EmailTemplateSelector } from '../PolicyForm/Fields/EmailTemplateSelector';
import { DayIndexWithType } from '../PolicyForm/Fields/DayIndexWithType';
import { Select } from '../../../../../common/Atoms/Select';
import { FormLabel } from '../../../../../common/Atoms/FormLabel';

const calcucateOptions = [
  {
    title: `Fixed Discount`,
    description: `Applies a fixed discount in the invoice's base currency`,
    value: PolicyCalculationType.FIXED,
  },
  {
    title: `Percentage Discount`,
    description: `Applies a discount based on the percentage of the invoice total`,
    value: PolicyCalculationType.PERCENT,
  },
];

const discountAppliesOptions = [
  {
    title: `Apply to new invoices`,
    description: `Apply discounts to any new invoice`,
    value: `new`,
  },
  {
    title: `Apply only to unsent invoices`,
    description: `Apply discounts to unsent new invoices`,
    value: `unsent`,
  },
];

function calculateDayTime(value: number): `before` | `after` | `on` {
  if (value === 0) {
    return `on`;
  }

  if (value < 0) {
    return `before`;
  }

  return `after`;
}

export function PolicyPromptPaymentDiscount({ policy, invalids, handleChange }: IPolicyProps) {

  const [dayType, setDayType] = useState(calculateDayTime(policy.day_index));

  const dayTypes = useMemo(() => {
    const result =[
      {
        label: `Before`,
        value: `before`,
      },
    ];

    if (policy.target_status === PolicyTargetStatus.ISSUED) {
      result.unshift({
        label: `After`,
        value: `after`,
      });

      result.unshift({
        label: `On the`,
        value: `on`,
      });
    }

    return result;
  }, [ policy.target_status ]);

  const invoiceStates = [
    {
      label: `Due Date`,
      value: PolicyTargetStatus.DUE,
    },
    {
      label: `Issue Date`,
      value: PolicyTargetStatus.ISSUED,
      tooltip: `Tip: Use this to be able to schedule a discount ahead of time or after an approval period`,
    },
  ];

  const dayIndexValue = useMemo(() => {
    let str = policy?.day_index?.toString() || ``;

    if (str.startsWith(`-`)) {
      str = str.replace(`-`, ``);
    }

    return str;
  }, [ policy.day_index ]);

  function onDayIndexChange(e, day = dayType) {
    const { value } = e;

    let num = Number(value);

    if (Number.isNaN(num)) {
      handleChange({
        name: `day_index`,
        value,
      });

      return;
    }

    if (day === `before`) {
      num = num < 0 ? num : -num;
    }

    if (day === `after`) {
      num = num > 0 ? num : -num;
    }

    if (day === `on`) {
      num = 0;
    }

    handleChange({
      name: `day_index`,
      value: num,
    });
  }

  function handleDayTypeChange(e) {
    setDayType(e.value);

    onDayIndexChange({
      value: policy.day_index,
    }, e.value);
  }

  function handleTargetStatusChange(e) {
    handleChange(e);
  }

  return (
    <>
      <RadioStack
        options={ calcucateOptions }
        onChange={ handleChange }
        selected={ policy.calculation_type }
        name={ `calculation_type` }
      />

      <RadioStack
        options={ discountAppliesOptions }
        onChange={ handleChange }
        selected={ policy.discount_applies }
        name={ `discount_applies` }
      />

      <div>
        <FormLabel>{ `Apply this discount up to:` }</FormLabel>
        <Grid cols={ 3 }
          className={ `items-end` }>
          <GridItem>
            <DayIndexWithType
              handleChange={ handleChange }
              policyFormData={ policy }
              invalids={ invalids }
              dayType={ dayType }
              dayIndexValue={ dayIndexValue }
              onDayIndexChange={ onDayIndexChange }
            />
          </GridItem>
          <GridItem>
            <Select
              options={ dayTypes }
              selected={ dayType }
              onChange={ handleDayTypeChange }
            />
          </GridItem>
          <GridItem>
            <Select
              options={ invoiceStates }
              selected={ policy.target_status }
              onChange={ handleTargetStatusChange }
              name={ `target_status` }
              invalid={ fieldIsValid(`target_status`, invalids) }
            />
          </GridItem>
        </Grid>
      </div>
      <Grid
        cols={ 4 }
        gapX={ 2 }
      >
        <GridItem>
          <AccountSelector
            label={ useAccountingSystemTerm(`Discount Account`) }
            value={ policy.xero_account_code }
            valueKey={ `code` }
            onChange={ handleChange }
            name={ `xero_account_code` }
            invalid={ fieldIsValid(`xero_account_code`, invalids) }
            description={ `The Xero account to record the negative amount against` }
          />
        </GridItem>
        <GridItem
        >
          <TaxSelector
            label={ useAccountingSystemTerm(`Tax Rate`) }
            code={ policy.tax_rate }
            onChange={ handleChange }
            name={ `tax_rate` }
            invalid={ fieldIsValid(`tax_rate`, invalids) }
          />
        </GridItem>
        <GridItem>
          <Switch>
            <Case condition={ policy.calculation_type === PolicyCalculationType.FIXED }>
              <CurrencyInput
                label={ `Discount Amount` }
                invalid={ fieldIsValid(`fixed_fee_amount`, invalids) }
                value={ policy.fixed_fee_amount }
                onChange={ handleChange }
                name={ `fixed_fee_amount` }
                description={ `The amount to discount the invoice by. In the Invoice's base currency` }
              />
            </Case>
            <Case condition={ policy.calculation_type === PolicyCalculationType.PERCENT || policy.calculation_type === PolicyCalculationType.PRORATA }>
              <InputTextAddon
                invalid={ fieldIsValid(`percent`, invalids) }
                label={ `Discount %` }
                value={ policy.percent }
                onChange={ handleChange }
                addOnText={ `%` }
                name={ `percent` }
                description={ `The percentage to discount the invoice by` }
              />
            </Case>
          </Switch>
        </GridItem>
        <GridItem>
          <InputTextAddon
            label={ `Discount Period` }
            value={ policy.grace_period }
            onChange={ handleChange }
            addOnText={ `days` }
            name={ `grace_period` }
            invalid={ fieldIsValid(`grace_period`, invalids) }
            description={ `Number of days before due date that the discount is removed. Use 0 days to remove the discount on the due date.` } />
        </GridItem>
      </Grid>

      <InputTextAddon
        label={ `Description` }
        value={ policy.line_item_text }
        onChange={ handleChange }
        name={ `line_item_text` }
        invalid={ fieldIsValid(`line_item_text`, invalids) }
        description={ `Appears on the invoice. Use #DiscountDate for the date the discount will be removed` } />

      <EmailTemplateSelector
        value={ policy.template_id }
        type={ EmailTemplateTypes.DISCOUNT }
        handleChange={ handleChange }
        invalid={ fieldIsValid(`template_id`, invalids) }
        name={ `template_id` }
        label={ `Send email notification to customer when discount is applied` }
        nullable
        emptyText={ `Do not send email notification` }
        helpIcon={ `Create or edit the template under Email Templates.` }
        currentPolicyId={ policy.id }
      />
    </>
  );
}
