import { INote, NoteSyncStatus } from "shared";
import { DateTime } from "luxon";
import { Tooltip } from "@mui/material";
import { When } from "react-if";
import { CloudArrowUpIcon } from "@heroicons/react/24/outline";

import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { classNames } from "../../../common/lib/classNames";
import { WrappedLink } from "../WrappedLink";
import { useAccountingSystemName } from "../../hooks/useAccountingSystemName";

interface NoteProps {
  note: INote;
}

export function Note({ note }: NoteProps) {
  const name = `${note.userFirstName || ``} ${note.userLastName || ``}`.trim();
  const accountingName = useAccountingSystemName();

  const hasFooter = !!note.escalationId; // TODO more footer items

  const header = name ?
    (
      <Paragraph
        variant={ `secondary` }
      >
        <Paragraph
          as={ `span` }
          className={ `font-semibold` }
        >
          { name }
        </Paragraph>
        <span>
          { ` created a note` }
        </span>

        <When condition={ note.invoiceNumber && note.invoiceId }>
          <WrappedLink
            to={ `/invoices/${note.invoiceId}` }
          >
            { ` on invoice ` }
            <Paragraph
              as={ `span` }
              variant={ `link` }
            >
              <span className={ `font-semibold` }>
                { note.invoiceNumber }
              </span>
            </Paragraph>
          </WrappedLink>
        </When>
      </Paragraph>
    ) : <div />;

  return (
    <div
      id={ `note-${note.id}` }
      className={ classNames(
        note.content ? `border border-gray-100 rounded-md p-3`: ``,
      ) }>
      <div className={ `flex justify-between` }>
        { /* The name of the user if applicable */ }
        <div className={ `flex` }>
          { header }

          <When condition={ note.syncStatus === NoteSyncStatus.NOT_SYNCED }>
            <Tooltip title={ `Note not yet synced with ${accountingName}` }>
              <CloudArrowUpIcon className={
                classNames(
                  `h-5 w-5 text-orange-400`,
                  header && `ml-1`,
                )
              } />
            </Tooltip>
          </When>

          <When condition={ note.syncStatus === NoteSyncStatus.SYNCED }>
            <Tooltip title={ `Synced with ${accountingName}` }>
              <CloudArrowUpIcon className={
                classNames(
                  `h-5 w-5 text-green-400`,
                  header && `ml-1`,
                )
              } />
            </Tooltip>
          </When>

          <When condition={ note.syncStatus === NoteSyncStatus.ERROR }>
            <Tooltip title={ note.syncError || `Unknown Sync Error` }>
              <CloudArrowUpIcon className={
                classNames(
                  `h-5 w-5 text-red-600`,
                  header && `ml-1`,
                )
              } />
            </Tooltip>
          </When>
        </div>

        { /* When it was left */ }
        <Tooltip title={ DateTime.fromJSDate(new Date(note.createdAt)).toFormat(`yyyy-MM-dd HH:mm`) }>
          <span>
            <Paragraph>
              { DateTime.fromJSDate(new Date(note.createdAt)).toRelative() }
            </Paragraph>
          </span>
        </Tooltip>
      </div>
      { /* The main content */ }
      <Paragraph
        variant={ `note` }
        className={ `mt-2 whitespace-pre-wrap` }
      >
        { note.content }
      </Paragraph>

      { /* The footer, shows the type of note etc */ }
      <When condition={ hasFooter }>
        <div className={ `border-t border-gray-50 mt-3` }/>
        <div className={ `pt-3` }>
          { /* Was this created via an escalation? */ }
          <When condition={ note.escalationId }>
            <Paragraph
              variant={ `help` }
              size={ `xs` }
            >
              { `Note was created from an escalation` }
            </Paragraph>
          </When>
        </div>
      </When>
    </div>
  );
}
