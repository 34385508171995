import { useParams } from 'react-router';

import { useFetchInvoiceQuery } from '../services/api/invoiceApi/invoice';

// ID override when getting this invoice outside of the invoice page
export function useInvoice(idOveride?: number | string) {
  const { organisationId, invoiceId } = useParams();

  const invoiceIdRes = idOveride || invoiceId;
  const result = useFetchInvoiceQuery({
    organisationId,
    invoiceId: invoiceIdRes?.toString(),
  },{
    skip: !organisationId || !invoiceIdRes,
    pollingInterval: 1000 * 10,
    skipPollingIfUnfocused: true,
  });

  return {
    ...result,
    invoice: result.data?.invoice,
    policyCheck: result.data?.policyCheck,
  };
}
