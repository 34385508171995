import { BrandingSelector } from "../BrandingSelector";

import { IFieldProps } from "./fieldProps";

export function ExcludeBrandingThemes({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {
  return (
    <BrandingSelector
      policy={ policyFormData }
      handleChange={ handleChange }
      invalids={ invalids }
    />
  );
}
