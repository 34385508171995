import { PolicyStatementType, PolicyType, policyConfig } from "shared";

import { fieldIsValid } from "../../../../../lib/helper";
import { CurrencyInput } from "../../../../../../common/Atoms/CurrencyInput";

import { IFieldProps } from "./fieldProps";

const config = policyConfig.minimum_threshold;

export function MinimumDue({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {
  const description = policyFormData.policy_type === PolicyType.STATEMENT ?
    `Do not send a statement if the balance is less than this amount. (In Statement Currency)` :
    `Apply this policy when at least this amount is overdue on the invoice`;

  function getLabel() {
    if (policyFormData.policy_type === PolicyType.STATEMENT) {
      if (policyFormData.statement_type === PolicyStatementType.OUTSTANDING) {
        return `Minimum Outstanding Amount`;
      }

      if (policyFormData.statement_type === PolicyStatementType.OVERDUE) {
        return `Minimum Overdue Amount`;
      }
    }

    return config.label;
  }

  return (
    <CurrencyInput
      label={ getLabel() }
      helpIcon={ description }
      value={ policyFormData.minimum_threshold }
      onChange={ handleChange }
      name={ `minimum_threshold` }
      invalid={ fieldIsValid(`minimum_threshold`, invalids) }
    />
  );
}
