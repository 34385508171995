import { IStatementCurrencySection, IStatementLine, StatementLineType, currencyFormatter, toDecimal } from "shared";
import { When } from "react-if";
import { Tooltip } from "@mui/material";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { DocumentArrowDownIcon, ExclamationCircleIcon } from "@heroicons/react/20/solid";

import { Card } from "../../Atoms/Card";
import { MultiButtonCardHeader } from "../../Atoms/MultiButtonCardHeader";
import { CardContent } from "../../Atoms/CardContent";
import { classNames } from "../../lib/classNames";
import { WrappedLink } from "../../../main/components/WrappedLink";
import { LoadingOverlay } from "../../../main/components/Molecules/LoadingOverlay";
import { HelpIcon } from "../../../main/components/Molecules/HelpIcon";
import { DatePopover } from "../DatePopover";
import { Badge } from "../../Atoms/Badge";
import { SmallSpinner } from "../../Atoms/SmallSpinner";

interface ICurrentStatementProps {
  currencySection: IStatementCurrencySection;
  sectionCount: number;
  statementPeriod: {
    start?: string;
    end?: string;
  };
  mode?: `app` | `portal`;
  onDownload?: (currency: string) => void;
  downloadText?: string;
  downloadTip?: string;
  loading?: boolean;
  children?: React.ReactNode;
  organisationTimezone: string;
  omitColumns?: Array<`payments`>;
  childContacts?: { id?: number, name?: string }[];
  onDownloadInvoice?: (line: IStatementLine) => void;
  downloadingInvoices?: Record<string, boolean>; // InvoiceId
  onChargeInterest?: () => void;
  chargeInterestLoading?: boolean;
}

export function CurrentStatement({
  currencySection: statement,
  sectionCount,
  statementPeriod,
  mode = `app`,
  onDownload,
  downloadText = `Download`,
  downloadTip = `Download Statement as PDF`,
  loading,
  children,
  organisationTimezone,
  omitColumns,
  childContacts,
  onDownloadInvoice,
  downloadingInvoices,
  onChargeInterest,
  chargeInterestLoading,
}: ICurrentStatementProps) {
  omitColumns = omitColumns || [];

  const [downloading, setDownloading] = useState(false);

  const hasPaymentLinks = statement.lines.some(line => !!line.paymentLink);

  const showChargeInterestButton = !!(mode === `app` && onChargeInterest);

  function formatAmount(amount: number, currency: string) {
    const formatter = currencyFormatter(currency);

    const isNegative = amount < 0;

    const result = formatter(toDecimal(amount));

    if (isNegative) {
      return `(${result.replace(`-`, ``)})`;
    }

    return formatter(toDecimal(amount));
  }

  async function downloadAsPDF() {
    if (onDownload) {
      setDownloading(true);

      await onDownload(statement.currency);

      setDownloading(false);
    }
  }

  function handleDownloadInvoice(line: IStatementLine) {
    if (onDownloadInvoice) {
      onDownloadInvoice(line);
    }
  }

  if (!statement) {
    return null;
  }

  let statementPeriodText = null;

  if (statementPeriod.start) {

    statementPeriodText = (
      <div className={ `flex` }>
        { `All items outstanding issued in ` }
        <DatePopover
          className={ `ml-1` }
          date={ statementPeriod.start }
          labelFormat={ `LLLL yyyy` }
          time={ `start` }
          monthOnly
          organisationTimezone={ organisationTimezone }
        />
      </div>
    );
  }
  else if (statementPeriod.end) {
    statementPeriodText = (
      <div className={ `flex` }>
        { `All items outstanding issued on or before ` }
        <DatePopover
          className={ `ml-1` }
          date={ statementPeriod.end }
          labelFormat={ `dd LLLL yyyy` }
          time={ `end` }
          organisationTimezone={ organisationTimezone }
        />
      </div>

    );
  }

  const colCount = 7 - omitColumns.length;

  const childIds = childContacts && childContacts.length > 0 ? childContacts.map(c => c.id) : [];
  const parentStatementLines = statement.lines.filter(line => !childIds.includes(line.contactId));

  const renderStatementLines = (lines: IStatementLine[], colCount: number, omitColumns: string[], hasPaymentLinks: boolean, childName?: string) => {

    const subtotalOutstanding = lines.reduce((acc, line) => acc + line.outstandingInCents, 0);

    return (
      <tbody className={ `divide-y divide-gray-200 bg-white` }>
        <When condition={ childName }>
          <tr className={ `whitespace-nowrap` }><td>&nbsp;</td></tr>
          <tr>
            <th
              colSpan={ colCount - 1 } // Subtract 1 for the subtotal column
              className={ `whitespace-nowrap py-2 text-sm text-gray-800 font-semibold bg-gray-100` }
            >
              { childName }
            </th>
            <th
              className={ `whitespace-nowrap py-2 text-sm text-gray-800 font-semibold bg-gray-100` }
            >
              { `Subtotal` }
            </th>
            <th
              className={ `whitespace-nowrap py-2 text-sm text-gray-800 font-semibold bg-gray-100` }
            >
              { formatAmount(subtotalOutstanding, statement.currency) }
            </th>
          </tr>
        </When>
        { lines.map(line => {
          const link = mode === `app` ? (
            <WrappedLink
              to={ `/invoices/${line.invoiceId}` }>
              <Tooltip title={ line.invoiceNumber }>
                <span>
                  { line.invoiceNumber }
                </span>
              </Tooltip>
              <When condition={ line.frozen }>
                <Tooltip title={ `This invoice has been frozen. It will appear in statements but no other policies will apply.` }>
                  <ExclamationCircleIcon className={ `h-5 w-5 ml-1 text-blue-500` } />
                </Tooltip>
              </When>
            </WrappedLink>
          ) : (
            <Link
              to={ `/i/${line.hash}` }>
              <Tooltip title={ line.invoiceNumber }>
                <span>
                  { line.invoiceNumber }
                </span>
              </Tooltip>
            </Link>
          );

          return (
            <tr key={ line.invoiceId }>
              <td className={
                classNames(
                  `whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 sm:pl-0 truncate max-w-[150px]`,
                  line.type === StatementLineType.Invoice && `underline`,
                )
              }>
                <When condition={ line.type === StatementLineType.Invoice }>
                  { link }
                </When>
                <When condition={ line.type === StatementLineType.CreditNote }>
                  <Tooltip title={ `Credit Note` }>
                    <span className={ `text-orange-700 flex items-center` }>
                      { line.invoiceNumber }

                      <Badge
                        size={ `xs` }
                        color={ `orange` }
                        className={ `ml-1` }
                      >
                        { `C` }
                      </Badge>
                    </span>
                  </Tooltip>
                </When>
              </td>
              <Tooltip title={ line.invoiceReference }>
                <td className={ `whitespace-nowrap px-2 py-2 text-sm text-gray-900 truncate max-w-[150px]` }>{ line.invoiceReference }</td>
              </Tooltip>
              <td className={ `whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900` }>
                <DatePopover
                  date={ line.issueDateString }
                  organisationTimezone={ organisationTimezone }
                  labelFormat={ `dd LLL yyyy` }
                />
              </td>
              <td className={ `whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900` }>
                <When condition={ !!line.invoiceDueDateString }>
                  {
                    () => (
                      <>
                        <DatePopover
                          date={ line.invoiceDueDateString }
                          organisationTimezone={ organisationTimezone }
                          labelFormat={ `dd LLL yyyy` }
                          time={ `end` }
                        />
                      </>
                    )
                  }
                </When>
              </td>
              <td className={ `whitespace-nowrap px-2 py-2 text-sm text-gray-500` }>
                <When condition={ line.debtAgeInDays }>
                  <span className={ classNames(
                    `font-semibold`,
                    line.debtAgeInDays > 0 && `text-orange-500`,
                    line.debtAgeInDays > 30 && `text-red-500`,
                  ) }
                  >
                    { line.debtAgeInDays }
                  </span>
                  { ` days` }
                </When>
              </td>
              <td className={
                classNames(
                  `whitespace-nowrap px-2 py-2 text-sm text-gray-500`,
                  line.type === StatementLineType.CreditNote ? `text-orange-700`: ``,
                )
              }>
                { formatAmount(line.totalInCents, line.currency) }
              </td>
              <When condition={ !omitColumns.includes(`payments`) }>
                <td className={
                  classNames(
                    `whitespace-nowrap px-2 py-2 text-sm text-gray-500`,
                    line.type === StatementLineType.CreditNote ? `text-orange-700`: ``,
                  )
                }>
                  { formatAmount(line.paymentsInCents, line.currency) }
                </td>
              </When>
              <td className={
                classNames(
                  `whitespace-nowrap px-2 py-2 text-sm text-gray-500`,
                  line.type === StatementLineType.CreditNote ? `text-orange-700`: ``,
                )
              }>
                { formatAmount(line.outstandingInCents, line.currency) }
              </td>
              {
                hasPaymentLinks && (
                  <td className={ `whitespace-nowrap px-2 py-2 text-sm text-blue-600` }>
                    <When condition={ line.paymentLink }>
                      <a
                        href={ line.paymentLink }
                        target={ `_blank` }
                        rel={ `noopener noreferrer` }
                        className={ `underline` }
                      >
                        { `Pay Now` }
                      </a>
                    </When>
                  </td>
                )
              }
              {
                mode === `portal` && (
                  // Download links
                  <td className={ `whitespace-nowrap px-2 py-2 text-sm text-gray-500` }>
                    <When condition={ line.type === StatementLineType.Invoice }>
                      <button
                        className={ classNames(
                          downloadingInvoices?.[line.invoiceId] ? `cursor-wait opacity-70` : `cursor-pointer`,
                        ) }
                        onClick={ () => handleDownloadInvoice(line) }

                        disabled={ downloadingInvoices?.[line.invoiceId] }
                      >
                        <When condition={ downloadingInvoices?.[line.invoiceId] }>
                          <SmallSpinner colored />
                        </When>
                        <When condition={ !downloadingInvoices?.[line.invoiceId] }>
                          <Tooltip title={ `Download Invoice PDF` }>
                            <DocumentArrowDownIcon className={ `h-5 w-5 text-blue-400` } />
                          </Tooltip>
                        </When>
                      </button>
                    </When>
                  </td>
                )
              }
            </tr>
          );
        }) }
      </tbody>
    );
  };

  const buttons = useMemo(() => {
    const buttonArray = [];

    if (showChargeInterestButton) {
      buttonArray.push({
        buttonText: `Charge Interest Now`,
        onClick: onChargeInterest,
        loading: chargeInterestLoading,
      });
    }

    if (onDownload) {
      buttonArray.push({
        buttonText: downloadText,
        tooltip: downloadTip,
        onClick: downloadAsPDF,
        loading: downloading,
      });
    }

    return buttonArray;
  }, [onDownload, showChargeInterestButton, chargeInterestLoading, downloading, downloadAsPDF, downloadText, downloadTip, onChargeInterest]);

  const mainContent = (
    <>
      <MultiButtonCardHeader
        title={
          sectionCount > 1 ?
            `Outstanding Statement (${statement.currency})` :
            `Outstanding Statement` }
        description={ statementPeriodText }
        buttons={ buttons }
      />
      <LoadingOverlay
        loading={ loading }
      >
        <CardContent>
          <div className={ `mt-8 flow-root` }>
            <div className={ `-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8` }>
              <div className={ `inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8` }>
                <table className={ `min-w-full divide-y divide-gray-300` }>
                  <thead>
                    <tr>
                      <th
                        scope={ `col` }
                        className={ `whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0` }
                      >
                        { `Invoice No.` }
                      </th>
                      <th
                        scope={ `col` }
                        className={ `whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900` }
                      >
                        { `Reference` }
                      </th>
                      <th
                        scope={ `col` }
                        className={ `whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900` }
                      >
                        { `Issue Date` }
                      </th>
                      <th
                        scope={ `col` }
                        className={ `whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900` }
                      >
                        { `Due Date` }
                      </th>
                      <th
                        scope={ `col` }
                        className={ `whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900` }
                      >
                        { `Overdue Age` }
                      </th>
                      <th
                        scope={ `col` }
                        className={ `whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900` }
                      >
                        { `Total` }
                      </th>
                      <When condition={ !omitColumns.includes(`payments`) }>
                        <th
                          scope={ `col` }
                          className={ `whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900` }
                        >
                          { `Payments` }
                        </th>
                      </When>
                      <th
                        scope={ `col` }
                        className={ `whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900` }
                      >
                        { `Outstanding` }
                      </th>
                      {
                        hasPaymentLinks && (
                          <th
                            scope={ `col` }
                            className={ `whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900` }
                          >
                            { `Pay` }
                          </th>
                        )
                      }
                      {
                        mode === `portal` && (
                          // Download links
                          <th
                            scope={ `col` }
                            className={ `whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900` }
                          />
                        )
                      }
                    </tr>
                  </thead>

                  { /* Always first render the parent contact id's lines */ }
                  { renderStatementLines(parentStatementLines, colCount, omitColumns, hasPaymentLinks) }

                  { /* If there's child contacts render each one's invoices, if any */ }
                  <When condition={ childContacts && childContacts.length > 0 }>
                    { childContacts.map(child => {
                      const childLines = statement.lines.filter(line => line.contactId === child.id);
                      { /* If there are no lines for this child, skip rendering */ }
                      if (childLines.length === 0) {
                        return null;
                      }

                      return renderStatementLines(childLines, colCount, omitColumns, hasPaymentLinks, child.name);
                    }) }
                  </When>
                  <tfoot>
                    <tr>
                      <td
                        colSpan={ colCount }
                        className={ `whitespace-nowrap py-2 text-sm text-gray-800 font-semibold` }
                      >
                        <div className={ `flex` }>
                          { `Balance:` }
                          <HelpIcon
                            className={ `ml-0.5` }
                            tooltip={ `This may differ from the total outstanding due to credit notes.` }
                          />
                        </div>
                      </td>
                      <td
                        className={ `whitespace-nowrap px-2 py-2 text-sm text-gray-800 font-semibold` }
                      >
                        { formatAmount(statement.totalOutstandingInCents, statement.currency) }
                      </td>
                    </tr>

                    <tr>
                      <td
                        colSpan={ colCount }
                        className={ `whitespace-nowrap py-2 text-sm text-gray-800 font-semibold` }
                      >
                        { `Total Outstanding:` }
                      </td>
                      <td
                        className={ `whitespace-nowrap px-2 py-2 text-sm text-gray-800 font-semibold` }
                      >
                        { formatAmount(statement.totalOutstandingGrossInCents, statement.currency) }
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          { children }
        </CardContent>
      </LoadingOverlay>
    </>
  );

  if (mode === `app`) {
    return mainContent;
  }

  return (
    <Card>
      { mainContent }
    </Card>
  );
}
