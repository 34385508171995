import React, { useMemo, useState } from 'react';
import { EmailTemplateTypes, PolicyTargetStatus } from 'shared';

import { InputTextAddon } from '../../../../../common/Atoms/InputTextAddon';
import { Grid } from '../../../../../common/Atoms/Grid';
import { FormRow } from '../../../Molecules/Form/FormRow';
import { GridItem } from '../../../../../common/Atoms/GridItem';
import { Select } from '../../../../../common/Atoms/Select';
import { fieldIsValid } from '../../../../lib/helper';
import { TextEditor } from '../../../Molecules/TextEditor';
import { useMergeTags } from '../../../../hooks/useMergeTagsAndLinks';
import { FormLabel } from '../../../../../common/Atoms/FormLabel';
import { FormDescription } from '../../../../../common/Atoms/FormDescription';
import { IPolicyProps } from '../PolicyItem';

function calculateDayTime(value) {
  if (value === 0) {
    return `on`;
  }

  if (value < 0) {
    return `before`;
  }

  return `after`;
}

export function PolicySms(props: IPolicyProps) {
  const { policy, invalids, handleChange } = props;
  const [dayType, setDayType] = useState(calculateDayTime(policy.day_index));
  const mergeTags = useMergeTags(EmailTemplateTypes.SMS); // TODO: Other types

  const formattedMergeTags = useMemo(() => {
    return mergeTags.asArray
      .filter(tag => tag.value !== `ForecastedPenaltyAmount` && !tag.archived)
      .map(tag => {
        return {
          text: tag.name,
          value: tag.value,
        };
      });
  }, [ mergeTags ]);

  const dayTypes = useMemo(() => {
    const result =[
      {
        label: `After`,
        value: `after`,
      },
      {
        label: `On The`,
        value: `on`,
      },
    ];

    if (policy.target_status === PolicyTargetStatus.DUE) {
      result.unshift({
        label: `Before`,
        value: `before`,
      });
    }

    return result;
  }, [ policy.target_status ]);

  const invoiceStates = [
    {
      label: `Due Date`,
      value: PolicyTargetStatus.DUE,
    },
    {
      label: `Issue Date`,
      value: PolicyTargetStatus.ISSUED,
    },
    {
      label: `Date Marked Paid (Coming Soon)`,
      value: PolicyTargetStatus.MARKED_PAID,
      disabled: true,
    },
  ];

  const dayIndexValue = useMemo(() => {
    let str = policy.day_index.toString();

    if (str.startsWith(`-`)) {
      str = str.replace(`-`, ``);
    }

    return str;
  }, [ policy.day_index ]);

  function onDayIndexChange(e, day = dayType) {
    const { value } = e;

    let num = Number(value);

    if (Number.isNaN(num)) {
      handleChange({
        name: `day_index`,
        value,
      });

      return;
    }

    if (day === `before`) {
      num = num < 0 ? num : -num;
    }

    if (day === `after`) {
      num = num > 0 ? num : -num;
    }

    if (day === `on`) {
      num = 0;
    }

    handleChange({
      name: `day_index`,
      value: num,
    });
  }

  function handleDayTypeChange(e) {
    setDayType(e.value);

    onDayIndexChange({
      value: policy.day_index,
    }, e.value);
  }

  function handleSmsContentChange({ text }) {
    handleChange({
      name: `sms_content`,
      value: text,
    });
  }

  return (
    <>
      { /* RULE */ }
      <Grid cols={ 3 }
        className={ `items-end` }>
        <GridItem>
          <InputTextAddon
            value={ dayIndexValue }
            onChange={ onDayIndexChange }
            name={ `day_index` }
            invalid={ fieldIsValid(`day_index`, invalids) }
            addOnText={ `days ` }
            label={ `Send this notification:` }
            disabled={ dayType === `on` }
          />
        </GridItem>
        <GridItem>
          <Select
            options={ dayTypes }
            selected={ dayType }
            onChange={ handleDayTypeChange }
          />
        </GridItem>
        <GridItem>
          <Select
            options={ invoiceStates }
            selected={ policy.target_status }
            onChange={ handleChange }
            name={ `target_status` }
            invalid={ fieldIsValid(`target_status`, invalids) }
          />
        </GridItem>
      </Grid>
      <FormRow className={ `space-y-1` }>
        <FormLabel>{ `Text Message Content` }</FormLabel>
        <FormDescription>{ `Use merge tags to dynamic insert values. Maximum 320 characters` }</FormDescription>
        <FormDescription>{ `Will be sent to the primary mobile phone number on file for the invoice contact if available.` }</FormDescription>
        <TextEditor
          value={ policy.sms_content }
          onChange={ handleSmsContentChange }
          mergeTags={ formattedMergeTags }
          mode={ `sms` }
        />
      </FormRow>
    </>
  );
}
