import { useContact } from "../../../../hooks/useContact";
import { Notes } from "../../../Molecules/Notes";
import { useCreateContactNoteMutation } from "../../../../services/api/contactsApi/contact";

export function ContactNotes() {
  const { data: contact } = useContact();

  const [postNewNote, { isLoading: isPostingNote }] = useCreateContactNoteMutation();

  async function onNewNote(content: string, mentionedUsers: string[]) {
    // Create the note
    await postNewNote({
      organisationId: contact.organisationId,
      contactId: contact.id,
      content,
      mentionedUsers,
    });
  }

  if (!contact) return null;

  return (
    <div>
      <Notes
        notes={ contact.notes }
        loading={ isPostingNote }
        enableNewNote
        onSave={ onNewNote }
      />
    </div>
  );
}
