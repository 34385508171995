import { policyConfig } from "shared";

import { fieldIsValid } from "../../../../../lib/helper";
import { InputTextAddon } from "../../../../../../common/Atoms/InputTextAddon";

import { IFieldProps } from "./fieldProps";

export function DestinationEmailOverride({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {
  const label = policyConfig.destination_email_override.label;

  return (
    <InputTextAddon
      label={ label }
      helpIcon={ `Instead of sending to the contact, only send to this address. Must be different than your From or Reply To address.` }
      value={ policyFormData.destination_email_override }
      onChange={ handleChange }
      name={ `destination_email_override` }
      invalid={ fieldIsValid(`destination_email_override`, invalids) }
    />
  );
}
