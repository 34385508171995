import { EscalationStatus, IEscalationResponseItem } from "shared";
import { ArrowUturnLeftIcon, BellSnoozeIcon, CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useMemo } from "react";
import { DateTime } from "luxon";
import { When } from "react-if";

import { Card } from "../../../../common/Atoms/Card";
import { Paragraph } from "../../../../common/Atoms/Typography/Paragraph";
import { useMarkCancelledEscalationMutation, useMarkCompleteEscalationMutation, useMarkSnoozeEscalationMutation, useReopenEscalationMutation } from "../../../services/api/escalationApi/escalation";
import { classNames } from "../../../../common/lib/classNames";
import { useGetSelectedOrganisation } from "../../../hooks/useGetSelectedOrganisation";
import { Dropdown } from "../../../../common/Atoms/Dropdown";

interface EscalationActionsProps {
  escalation: IEscalationResponseItem;
}

export function EscalationActions({ escalation }: EscalationActionsProps) {
  const organisation = useGetSelectedOrganisation();
  const [completeApiCall, { isLoading: completeLoading }] = useMarkCompleteEscalationMutation();
  const [cancelApiCall, { isLoading: cancelLoading }] = useMarkCancelledEscalationMutation();
  const [snoozeApiCall, { isLoading: snoozeLoading }] = useMarkSnoozeEscalationMutation();
  const [reopenApiCall, { isLoading: reopenLoading }] = useReopenEscalationMutation();

  const snoozeOption = useMemo(() => {
    const options = [
      {
        label: `Tomorrow`,
        value: `1`,
      },
      {
        label: `3 Days Later`,
        value: `3`,
      },
      {
        label: `Next Week`,
        value: `7`,
      },
      {
        label: `Next Month`,
        value: `30`,
      },
    ];
    if (!escalation) return options;

    // we have an expected payment date and it's in the future
    if (escalation.invoice?.expected_payment_date_str && organisation.validatedTimezone) {

      const expPayDateTime = DateTime.fromISO(escalation.invoice.expected_payment_date_str, { zone: organisation.validatedTimezone });
      const orgTz = organisation.validatedTimezone;
      const now = DateTime.local().setZone(orgTz);

      if (expPayDateTime > now) {

        // round up to nearest full day for snoozing
        const days = Math.ceil(expPayDateTime.diff(now).as(`days`));

        options.push({
          label: `Until expected payment date`,
          // number of days from now to expected payment date
          value: `${days}`,
        });
      }
    }

    return options;
  }, [escalation]);

  function completeEscalation() {
    if (!escalation) return;

    completeApiCall({
      organisationId: escalation.organisationId,
      escalationId: escalation.id,
    });
  }

  function snoozeEscalation(e) {
    if (!escalation) return;

    snoozeApiCall({
      organisationId: escalation.organisationId,
      escalationId: escalation.id,
      snoozeDays: e.value,
    });
  }

  function cancelEscalation() {
    if (!escalation) return;

    cancelApiCall({
      organisationId: escalation.organisationId,
      escalationId: escalation.id,
    });
  }

  function reopenEscalation() {
    if (!escalation) return;

    reopenApiCall({
      organisationId: escalation.organisationId,
      escalationId: escalation.id,
    });
  }

  return (
    <Card>
      <div className={ `grid grid-cols-3 gap-4` }>
        { /* Close Escalation */ }
        <button
          className={ classNames(
            `flex flex-col items-center justify-center p-4 rounded-md hover:bg-gray-100`,
            (completeLoading || escalation?.escalationStatus !== EscalationStatus.PENDING) ? `opacity-50 cursor-not-allowed` : ``,
          ) }
          onClick={ () => completeEscalation() }
          disabled={ (completeLoading || escalation?.escalationStatus !== EscalationStatus.PENDING) }
        >
          <CheckIcon className={ `w-8 h-8 text-gray-500` } />
          <Paragraph
            as={ `span` }
          >
            { `Close Escalation` }
          </Paragraph>
        </button>

        { /* Snooze */ }
        <div
          className={ classNames(
            `flex flex-col items-center justify-center p-4`,
            (snoozeLoading || escalation?.escalationStatus !== EscalationStatus.PENDING) ? `opacity-50 cursor-not-allowed` : ``,
          ) }
          // onClick={ () => completeEscalation() }
        >
          <BellSnoozeIcon className={ `w-8 h-8 text-gray-500` } />
          <Dropdown
            size={ `md` }
            position={ `right` }
            label={ `Snooze until...` }
            options={ [snoozeOption] }
            onSelect={ snoozeEscalation }
            disabled={ (snoozeLoading || escalation?.escalationStatus !== EscalationStatus.PENDING) }
            theme={ `clear` }
          />
        </div>

        <When condition={ escalation?.escalationStatus === EscalationStatus.PENDING }>
          { /* Cancel */ }
          <button
            className={ classNames(
              `flex flex-col items-center justify-center p-4 rounded-md hover:bg-gray-100`,
              (cancelLoading || escalation?.escalationStatus !== EscalationStatus.PENDING) ? `opacity-50 cursor-not-allowed` : ``,
            ) }
            onClick={ () => cancelEscalation() }
            disabled={ (cancelLoading || escalation?.escalationStatus !== EscalationStatus.PENDING) }
          >
            <XMarkIcon className={ `w-8 h-8 text-gray-500` } />
            <Paragraph
              as={ `span` }
            >
              { `Cancel Escalation` }
            </Paragraph>
          </button>
        </When>

        { /* Otherwise, show the reopen button */ }
        <When condition={ escalation?.escalationStatus !== EscalationStatus.PENDING }>
          <button
            className={ classNames(
              `flex flex-col items-center justify-center p-4 rounded-md hover:bg-gray-100`,
              (reopenLoading || escalation?.escalationStatus === EscalationStatus.PENDING) ? `opacity-50 cursor-not-allowed` : ``,
            ) }
            onClick={ () => reopenEscalation() }
            disabled={ (reopenLoading || escalation?.escalationStatus === EscalationStatus.PENDING) }
          >
            <ArrowUturnLeftIcon className={ `w-8 h-8 text-gray-500` } />
            <Paragraph
              as={ `span` }
            >
              { `Reopen Escalation` }
            </Paragraph>
          </button>
        </When>
      </div>
    </Card>
  );
}
