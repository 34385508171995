import { createSlice } from '@reduxjs/toolkit';
import { ContactStatus, IContactParams } from 'shared';

export const initialState: IContactParams = {
  page: 1,
  limit: 50,
  searchStr: ``,
  groupId: null,
  externalAggregateName: null,
  status: ContactStatus.ACTIVE, // Only show active contacts for now
  hasInvoices: false,
  onlyWithNoEmail: null,
  onlyNoPhone: null,
  onlyWithBalance: null,
};

const contactParamsSlice = createSlice({
  name: `contactParams`,
  initialState,
  reducers: {
    setParams(state, action) {
      Object.assign(state, action.payload);
    },
  },
});

export const { setParams } = contactParamsSlice.actions;

export const contactParamsReducer = contactParamsSlice.reducer;
