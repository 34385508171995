import { useParams } from "react-router";
import ms from "ms";

import { useFetchCustomerPaymentsQuery } from "../services/api/customerPaymentsApi/customerPayments";

export function useCustomerPayments() {
  const { organisationId } = useParams<{ organisationId: string }>();
  const result = useFetchCustomerPaymentsQuery({
    organisationId,
  }, {
    skip: !organisationId,
    refetchOnMountOrArgChange: true,
    pollingInterval: ms(`5 min`),
    skipPollingIfUnfocused: true,
  });

  return result;
}
