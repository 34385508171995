export function capitalizeEachWord(string = ``) {
  // Split the string into an array of words
  const words = string.split(` `);

  // Capitalize the first letter of each word
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  // Join the array of words back into a single string
  return words.join(` `);
}
