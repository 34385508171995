import { objectsAreEqual } from "shared";

export function useFormDataDirty(originalData: unknown, currentData: unknown, ignoreFields?: string[]): boolean {
  if (!originalData || !currentData) return false;

  if (typeof originalData !== `object` || typeof currentData !== `object`) {
    return originalData !== currentData;
  }

  const originalDataCopy = { ...originalData };
  const currentDataCopy = { ...currentData };

  // We can assume null, and false to be equal
  for (const key in originalDataCopy) {
    if (originalDataCopy[key] === null || originalDataCopy[key] === undefined) {
      originalDataCopy[key] = false;
    }
  }

  for (const key in currentDataCopy) {
    if (currentDataCopy[key] === null || currentDataCopy[key] === undefined) {
      currentDataCopy[key] = false;
    }
  }

  if (ignoreFields) {
    ignoreFields.forEach(field => {
      delete originalDataCopy[field];
      delete currentDataCopy[field];
    });

    return !objectsAreEqual(originalDataCopy, currentDataCopy);
  }

  return !objectsAreEqual(originalDataCopy, currentDataCopy);
}
