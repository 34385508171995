// @ASH we could probably consolidate here?

import { useGetSelectedOrganisation } from "./useGetSelectedOrganisation";

interface IAccountingLanguage {
  accountingName: `Xero` | `QuickBooks`;
  contacts: {
    /** Groups in Xero, Types in QBO etc */
    externalAggregateNameLabel: string;
    externalAggregateNameDescription: string;
    /** Account number in Xero, maybe the same in QBO tbc, etc */
    externalAccountNumberLabel: string;
  }
  groups: {
    externalAggregateGroupRule: string;
  }
}

export function useAccountingLanguge(): IAccountingLanguage {
  const currentOrg = useGetSelectedOrganisation();

  // Accounting specific variables
  const externalAggregateColName = currentOrg.type === `XERO` ? `Xero Contact Group(s)` : `Customer Types`;
  const externalAggregateColNameDesc = currentOrg.type === `XERO` ?
    `The contact groups in Xero that this contact is in. Note: A contact can only appear in one Paidnice group.` :
    `The customer type in QuickBooks that this contact has assigned.`;
  const externalAccountNumberColName = currentOrg.type === `XERO` ? `Xero Account Number` : `QuickBooks Customer ID`;

  const externalAggregateGroupRule = currentOrg.type === `XERO` ?
    `any of this contact's Xero Contact Groups` :
    `this contact's QuickBooks Customer Type`
  ;

  return {
    accountingName: currentOrg.type === `XERO` ? `Xero` : `QuickBooks`,
    contacts: {
      externalAggregateNameLabel: externalAggregateColName,
      externalAggregateNameDescription: externalAggregateColNameDesc,
      externalAccountNumberLabel: externalAccountNumberColName,
    },
    groups: {
      externalAggregateGroupRule,
    },
  };
}
