import { useDispatch, useSelector } from "react-redux";
import { IAdhocActionResponse, IInvalidField, IPolicy, PolicyType, validatePolicySync, yupErrorMessageParse } from "shared";
import { useEffect, useState } from "react";

import { RootState } from "../store";
import { initialState, reset, setState } from "../slices/adhocActionModal";
import { useLazyPreviewPolicyQuery, useUpdatePolicyMutation } from "../services/api/policyApi/policy";
import { useAdhocInterestChargeMutation, useAdhocStatementMutation } from "../services/api/contactsApi/contact";
import { useAdhocInvoiceEmailMutation, useAdhocLateFeeMutation } from "../services/api/invoiceApi/invoice";

import { useSystemPolicies } from "./useSystemPolicies";
import { useGetSelectedOrganisation } from "./useGetSelectedOrganisation";

export function useAdhocActionModal() {
  const {
    type,
    show,
    currentPolicy,
    currentPolicyForm,
    contactId,
    result,
    contactName,
    invoiceNumber,
    invoiceId,
    view,
    preview,
    previewLoading,
    applyLoading,
    updatePolicyLoading,
  } = useSelector((state: RootState) => state.adhocActionModal);
  const currentOrg = useGetSelectedOrganisation();
  const dispatch = useDispatch();
  const [updatePolicy, {
    isLoading: saveLoading,
  }] = useUpdatePolicyMutation();
  const [adhocStatement, { isLoading: adhocStatementLoading }] = useAdhocStatementMutation();
  const [adhocInterestCharge, { isLoading: isChargingInterest }] = useAdhocInterestChargeMutation();
  const [adhocInvoiceEmail, { isLoading: isSendingInvoiceEmail }] = useAdhocInvoiceEmailMutation();
  const [adhocLateFee, { isLoading: isIssuingLateFee }] = useAdhocLateFeeMutation();

  const [previewApi, { isLoading: previewLoadingSrc, data: previewData, isFetching: previewFetching }] = useLazyPreviewPolicyQuery();

  const [ invalids, setInvalids ] = useState<IInvalidField[]>([]);

  const { data: systemPolicies, isLoading: systemPolicesLoading } = useSystemPolicies();

  const close = () => {
    dispatch(reset());
  };

  const openSendStatement = (contactId: number, contactName: string) => {
    dispatch(setState({
      ...initialState,
      type: PolicyType.STATEMENT,
      show: true,
      contactId,
      contactName,
    }));
  };

  const openChargeInterest = (contactId: number, contactName: string) => {
    dispatch(setState({
      ...initialState,
      type: PolicyType.STATEMENT_LATE_FEE,
      show: true,
      contactId,
      contactName,
    }));
  };

  const openSendInvoiceEmail = (invoiceId: string, invoiceNumber: string) => {
    dispatch(setState({
      ...initialState,
      type: PolicyType.REMINDER,
      show: true,
      invoiceId,
      invoiceNumber,
    }));
  };

  const openIssueLateFee = (invoiceId: string, invoiceNumber: string) => {
    dispatch(setState({
      ...initialState,
      type: PolicyType.LATE_FEE,
      show: true,
      invoiceId,
      invoiceNumber,
    }));
  };

  useEffect(() => {
    if (systemPolicies && type && !currentPolicy) {
      const found = systemPolicies.find(policy => policy.policy_type === type);
      if (found) {
        dispatch(setState({
          currentPolicy: found,
          currentPolicyForm: { ...found },
        }));
      }
    }
  }, [systemPolicies, type, currentPolicy]);

  function update({ value, name }: { value: string | number | boolean, name: string }) {
    dispatch(setState({
      currentPolicyForm: {
        ...currentPolicyForm,
        [name]: value,
      },
    }));
  }

  async function saveAndExecute() {
    if (!currentPolicyForm) return;
    if (!currentPolicy) return;
    if (!type) return;
    if (!show) return;

    dispatch(setState({
      view: `result`,
    }));

    // Save the policy
    const validated = validate({ ...currentPolicyForm });

    if (!validated) {
      console.error(`Validation failed`);

      return;
    }

    await updatePolicy({
      organisationId: currentPolicy.organisation_id,
      id: currentPolicy.id,
      policy: currentPolicyForm,
      groupIds: [],
    });

    let result: null | IAdhocActionResponse | IAdhocActionResponse[] = null;

    if (type === PolicyType.STATEMENT) {
      const response = await adhocStatement({
        organisationId: currentPolicy.organisation_id,
        contactId,
      });

      if (response.data?.action) {
        result = response.data;
      }
    }

    if (type === PolicyType.STATEMENT_LATE_FEE) {
      const response = await adhocInterestCharge({
        organisationId: currentOrg?.id,
        contactId,
      });

      if (response.data?.action) {
        result = response.data;
      }
    }

    if (type === PolicyType.REMINDER) {
      const response = await adhocInvoiceEmail({
        organisationId: currentOrg?.id,
        invoiceId,
      });

      if (response.data?.action) {
        result = response.data;
      }
    }

    if (type === PolicyType.LATE_FEE) {
      const response = await adhocLateFee({
        organisationId: currentOrg?.id,
        invoiceId,
      });

      if (response.data?.action) {
        result = response.data;
      }
    }

    dispatch(setState({
      result,
    }));
  }

  async function onPreview() {
    if (!currentPolicyForm) return;
    if (!currentPolicy) return;
    if (!type) return;
    if (!show) return;

    dispatch(setState({
      view: `preview`,
    }));

    // Save the policy
    const validated = validate({ ...currentPolicyForm });

    if (!validated) {
      return;
    }

    await updatePolicy({
      organisationId: currentPolicy.organisation_id,
      id: currentPolicy.id,
      policy: currentPolicyForm,
      groupIds: [],
    });

    // Then call preview
    previewApi({
      organisationId: currentPolicy.organisation_id,
      policyId: currentPolicy.id,
      invoiceId,
      contactId,
    });
  }

  function validate(pol: IPolicy) {
    try {
      const valid = validatePolicySync(pol, false);

      setInvalids([]);

      return valid;
    }
    catch (err) {
      console.error(err);

      const errorMessages = yupErrorMessageParse(err);

      if (Array.isArray(errorMessages)) {
        setInvalids(errorMessages);
      }
      else {
        setInvalids([{
          message: `An Unknown Error has occured.`,
        }]);
      }

      return false;
    }
  }

  useEffect(() => {
    dispatch(setState({
      applyLoading: adhocStatementLoading || isChargingInterest || isSendingInvoiceEmail || isIssuingLateFee,
    }));
  }, [adhocStatementLoading, isChargingInterest, isSendingInvoiceEmail, isIssuingLateFee]);

  useEffect(() => {
    dispatch(setState({
      previewLoading: previewLoadingSrc || previewFetching,
    }));
  }, [previewLoadingSrc, previewFetching]);

  useEffect(() => {
    dispatch(setState({
      preview: previewData,
    }));
  }, [previewData]);

  useEffect(() => {
    dispatch(setState({
      updatePolicyLoading: saveLoading,
    }));
  }, [saveLoading]);

  return {
    type,
    show,
    close,
    openSendStatement,
    openChargeInterest,
    openSendInvoiceEmail,
    openIssueLateFee,
    currentSystemPolicy: currentPolicy,
    currentSystemPolicyForm: currentPolicyForm,
    loading: systemPolicesLoading || updatePolicyLoading,
    applyLoading,
    previewLoading,
    update,
    saveAndExecute,
    invalids,
    result,
    contactName,
    invoiceNumber,
    contactId,
    invoiceId,
    preview,
    onPreview,
    view,
  };
}
