// A way to determine which last action the loading state is for

import { useCallback, useMemo, useState } from 'react';

export function useLoading(mutation, key) {
  const [fn, { isLoading }] = mutation;
  const [lastAction, setLastAction] = useState(null);

  const wrappedFn = useCallback(args => {
    setLastAction(args[key]);

    return fn(args);
  }, [setLastAction, key]);

  const loadings = useMemo(() => {
    if (isLoading) {
      return {
        [lastAction]: true,
      };
    }

    return {};
  }, [ isLoading ]);

  return [ wrappedFn, loadings ];
}
