import { useGetSubscriptionMetadata } from '../hooks/useGetSubscription';
import { Notification } from '../../common/Atoms/Notification';
import { useGetSelectedOrganisation } from '../hooks/useGetSelectedOrganisation';
import { useUser } from '../hooks/useUser';

export function NotificationArea() {
  const currentOrg = useGetSelectedOrganisation();
  const { data: user } = useUser();
  const { subscriptionMetadata } = useGetSubscriptionMetadata();

  if (!user?.adminOverride) return null;

  if (currentOrg?.connected) return null;

  if (!subscriptionMetadata) return null;

  return (
    <>
      <div className={ `py-6` }>
        <Notification
          type={ `error` }
          title={ `Attention` }
        >
          { `Organisation is not connected` }
        </Notification>
      </div>
    </>
  );
}
