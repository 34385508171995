import { useEffect, useMemo, useRef, useState } from 'react';
import { EmailTemplateTypes, SignaturePositions, generateHtml } from 'shared';
import { useParams } from 'react-router';
import { format } from 'date-fns';
import { Parser } from 'html-to-react';
import { When } from 'react-if';
import mustache from 'mustache';

import { Card, ForwardRefCard } from '../../../../common/Atoms/Card';
import { CardContent } from '../../../../common/Atoms/CardContent';
import { useLazySendTestEmailQuery, useUpdateEmailTemplateMutation } from '../../../services/api/emailTemplates/emailTemplates';
import { FormWithButton } from '../../Molecules/Form/FormWithButton';
import { FormRow } from '../../Molecules/Form/FormRow';
import { InputTextAddon } from '../../../../common/Atoms/InputTextAddon';
import { useMergeTags } from '../../../hooks/useMergeTagsAndLinks';
import { MultiButtonCardHeader } from '../../../../common/Atoms/MultiButtonCardHeader';
import { Grid } from '../../../../common/Atoms/Grid';
import { ForwardRefGridItem, GridItem } from '../../../../common/Atoms/GridItem';
import { CustomIframe } from '../../IFrame';
import { FormLabel } from '../../../../common/Atoms/FormLabel';
import { FormDescription } from '../../../../common/Atoms/FormDescription';
import { CheckBox } from '../../../../common/Atoms/CheckBox';
import { useEmailTemplates } from '../../../hooks/useEmailTemplates';
import { TextEditor } from '../../Molecules/TextEditor';
import { validateForm } from '../../../services/forms/validate';
import { capitalizeFirstLetter, fieldIsValid } from '../../../lib/helper';
import { Transition } from '../../../../common/Atoms/Transition';
import { useSettings } from '../../../hooks/useSettings';
import { useUser } from '../../../hooks/useUser';
import { useFormDataDirty } from '../../../hooks/useFormDataDirty';
import { Notification } from '../../../../common/Atoms/Notification';
import { useGetSelectedOrganisation } from '../../../hooks/useGetSelectedOrganisation';
import { useFiles } from '../../../hooks/useFiles';
import { MultiSelect } from '../../../../common/Atoms/MultiSelect';
import { Select } from '../../../../common/Atoms/Select';

import { typeColors, types } from './constants';

const htmlParser = Parser();

export function EmailTemplate() {
  const { organisationId, templateId } = useParams();
  const { data: emailTemplates } = useEmailTemplates();
  const { data: user } = useUser();
  const { data: filesResponse, isLoading: filesLoading } = useFiles();
  const parentRef = useRef(null);
  const scrollingDivRef = useRef(null);
  const [ updateTemplate, { isLoading: isLoadingUpdate }] = useUpdateEmailTemplateMutation();
  const [ sendTestEmail, { isLoading: sendTestEmailLoading }] = useLazySendTestEmailQuery();
  const [invalids, setInvalids] = useState([]);
  const [formData, setFormData] = useState({
    name: ``,
    subject: ``,
    primary_color: ``,
    secondary_color: ``,
    suits_before_due: false,
    suits_after_due: false,
    suits_on_due: false,
    include_upcoming_latefee: true,
    main_json_content: null,
    main_html_content: null,
    secondary_json_content: ``,
    secondary_html_content: ``,
    type: EmailTemplateTypes.REMINDER,
    attached_files: [],
    signature_position: null,
  });
  const { data: settings } = useSettings();
  const currentOrg = useGetSelectedOrganisation();

  const logoUrl = useMemo(() => {
    const logo = settings?.resizedLogoUrl || `https://placehold.co/560x100?text=Your+Logo&font=roboto`;

    return logo;
  }, [ settings ]);

  const template = useMemo(() => {
    if (!emailTemplates) return null;

    return emailTemplates.find(t => t.id === templateId);
  }, [emailTemplates, templateId]);

  const fileOptions = useMemo(() => {
    if (!filesResponse) return [];

    return filesResponse.files.map(f => ({
      value: f.id,
      label: f.originalFileName,
    }));
  }, [ filesResponse ]);

  const mergeTags = useMergeTags(template?.type);

  const mergeTagsForPrimaryEditor = useMemo(() => {
    const mainTags = mergeTags.asArray
      .filter(t => {
        if (t.value === `PenaltyForecastAmount`) return false;
        if (t.archived) return false;

        return true;
      })
      .map(t => ({
        text: t.name,
        value: t.value,
        new: t.new,
        link: t.link,
      }));

    if (formData.include_upcoming_latefee) {
      // Let them insert the secondary section
      mainTags.push({
        text: `Upcoming Penalties Section`,
        value: `PenaltiesSection`,
        new: false,
        link: false,
      });
    }

    return mainTags;

  }, [ mergeTags ]);

  const mergeTagsForSecondaryEditor = useMemo(() => {
    const mainTags = mergeTags.asArray
      .filter(t => t.value === `PenaltyForecastAmount` && !t.archived)
      .map(t => ({
        text: t.name,
        value: t.value,
        new: t.new,
        link: t.link,
      }));

    return mainTags;

  }, [ mergeTags ]);

  useEffect(() => {
    if (!template) return;

    const form = {
      name: template.name,
      subject: template.subject,
      primary_color: template.primary_color,
      secondary_color: template.secondary_color,
      suits_before_due: !!template.suits_before_due,
      suits_after_due: !!template.suits_after_due,
      suits_on_due: !!template.suits_on_due,
      include_upcoming_latefee: !!template.include_upcoming_latefee,
      main_json_content: template.main_json_content,
      secondary_json_content: template.secondary_json_content,
      main_html_content: template.main_html_content,
      secondary_html_content: template.secondary_html_content,
      type: template.type,
      attached_files: template.attached_files,
      signature_position: template.signature_position,
    };

    setFormData(form);
  }, [ template ]);

  const isDirty = useFormDataDirty(template, formData, [
    `main_json_content`,
    `main_html_content`,
    `secondary_html_content`,
    `secondary_json_content`,
    `updated_at`,
    `created_at`,
    `id`,
    `organisation_id`,
    `user_id`,
    `version`,
    `user`,
    `primary_color`,
    `secondary_color`,
    `legacy_logo_url`,
    `attached_files`,
    `policies`,
  ]);

  // Generates the preview
  const html = useMemo(() => {
    if (!mergeTags || !mergeTags.asTestData) return ``;
    if (Object.keys(mergeTags.asTestData).length === 0) return ``;
    if (!formData.main_html_content) return ``;

    const htmlTemplate = generateHtml(formData, logoUrl, currentOrg.removePaidniceBranding, settings?.signatureHtml);

    // Now merge it with test data
    const fullyFormed = mustache.render(htmlTemplate, mergeTags.asTestData);

    return fullyFormed;

  }, [ formData, mergeTags ]);

  const positionOptions = Object.values(SignaturePositions).map(p => ({
    label: capitalizeFirstLetter(p),
    value: p,
  }));

  console.log(formData);

  useEffect(() => {
    const handleScroll = () => {
      const parent = parentRef.current;
      const scrollingDiv = scrollingDivRef.current;

      if (!parent || !scrollingDiv) return;

      const parentRect = parent.getBoundingClientRect();
      const scrollingDivRect = scrollingDiv.getBoundingClientRect();
      const viewHeight = window.innerHeight;
      // Amount of pixels to make the div sit higher
      const higherByPixels = 300;

      // Calculate the mid-position between the top of the parent and the bottom of the view.
      const midPosition = Math.max(0, Math.min(parentRect.bottom - scrollingDivRect.height, viewHeight / 2 - scrollingDivRect.height / 2)) - higherByPixels;

      // Calculate how much the div should be offset from the top of the parent to be at the mid-position.
      let yOffset = midPosition - parentRect.top + scrollingDivRect.height / 2;

      // Special case: when the parent is at the top of the viewport
      if (parentRect.top > 1) {
        yOffset = 0;
      }

      if (parentRect.bottom < viewHeight) {
        yOffset = parentRect.height;
      }

      // Clamp yOffset so the div does not go outside of the parent.
      yOffset = Math.max(0, Math.min(yOffset, parent.clientHeight - scrollingDiv.clientHeight));

      scrollingDiv.style.top = `${yOffset}px`;
    };

    window.addEventListener(`scroll`, handleScroll);

    return () => window.removeEventListener(`scroll`, handleScroll);
  }, []);

  function onChange(e) {
    setFormData({
      ...formData,
      [e.name]: e.value,
    });
  }

  function handleMainEditorChange({ json, html }) {
    setFormData({
      ...formData,
      main_json_content: json,
      main_html_content: html,
    });
  }

  function handleSecondaryEditorChange({ json, html }) {
    setFormData({
      ...formData,
      secondary_json_content: json,
      secondary_html_content: html,
    });
  }

  const onSave = async e => {
    if (e) {
      e.preventDefault();
    }

    const updateParams = {
      ...formData,
      org: organisationId,
      id: templateId,
    };

    const validateResult = await validateForm(updateParams, `EMAIL_TEMPLATE`);

    if (!validateResult.valid) {
      setInvalids(validateResult.invalids);

      return;
    }

    setInvalids([]);

    updateTemplate(validateResult.form);
  };

  function sendTestEmailFn() {
    sendTestEmail({
      org: organisationId,
      templateId,
    });
  }

  function onChangeFiles(e) {
    const files = filesResponse.files.filter(f => e.includes(f.id));

    setFormData({
      ...formData,
      attached_files: files,
    });
  }

  const attachedFilesDisplay = useMemo(() => {
    if (fileOptions.length === 0 && !filesLoading) {
      return `No Files Uploaded`;
    }

    if (!formData.attached_files || formData.attached_files.length === 0) {
      return `No Files Attached`;
    }

    if (formData.attached_files.length === 1) {
      return formData.attached_files[0].originalFileName;
    }

    return `${formData.attached_files.length} files attached`;

  }, [ formData.attached_files, fileOptions, filesLoading ]);

  const attachedFilesValue = useMemo(() => {
    if (!formData.attached_files) return [];

    return formData.attached_files.map(f => f.id);
  }
  , [ formData.attached_files ]);

  const version = template?.version < 1 ? `Draft` : template?.version;
  const formatted = format(new Date(template?.updated_at || null), `dd LLL yy h:mm a`);

  if (!template) return null;

  return (
    <Grid cols={ 12 }
      className={ `` }>
      <GridItem span={ 12 }>
        <When condition={ isDirty }>
          <Notification
            type={ `warning` }
          >
            { `You have unsaved changes` }
          </Notification>
        </When>
      </GridItem>
      <GridItem span={ 5 }>
        <Card>
          <MultiButtonCardHeader
            title={ template.name }
            description={ `Use the email builder to create your template` }
            secondaryDescription={ `Last updated on ${formatted}. Version: ${version}` }
            badge={ types[template.type] }
            badgeColor={ typeColors[template.type] }
          />
          <CardContent>
            <FormWithButton
              className={ `pt-6` }
              onSubmit={ onSave }
              submitText={ `Save Template` }
              loading={ isLoadingUpdate }
              invalids={ invalids }
            >
              <FormRow>
                <InputTextAddon
                  label={ `Template Name` }
                  value={ formData.name }
                  name={ `name` }
                  onChange={ onChange }
                  required
                  description={ `To identify the template in the policies. Your customers will not see this name.` }
                  invalid={ fieldIsValid(`name`, invalids) }
                />
              </FormRow>
              <FormRow>
                <InputTextAddon
                  label={ `Subject` }
                  value={ formData.subject }
                  name={ `subject` }
                  onChange={ onChange }
                  required
                  description={ `The subject line of the email. You can use merge tags from below here also` }
                  invalid={ fieldIsValid(`subject`, invalids) }
                />
              </FormRow>

              <FormRow className={ `space-y-2` }>
                <FormLabel>{ `Logo` }</FormLabel>
                <FormDescription>
                  { `Upload or update your logo in Organisation Settings` }
                </FormDescription>
              </FormRow>

              <FormRow className={ `space-y-2` }>
                <FormLabel>{ `Body` }</FormLabel>
                <FormDescription>{ `Use the merge tags to insert a dynamic value, E.g Due Date. Highlight text and select a link to create a link.` }</FormDescription>
                <TextEditor
                  mergeTags={ mergeTagsForPrimaryEditor }
                  onChange={ handleMainEditorChange }
                  value={ formData.main_json_content }
                  htmlValue={ formData.main_html_content }
                />
              </FormRow>

              <When condition={ template?.type === EmailTemplateTypes.REMINDER }>
                <CheckBox
                  label={ `Include Upcoming Penalties` }
                  description={ `When the invoice also has applicable late fee policies, include this information in the email` }
                  checked={ formData.include_upcoming_latefee }
                  onChange={ onChange }
                  name={ `include_upcoming_latefee` }
                  className={ `py-2` }
                />
              </When>
              <Transition show={ formData.include_upcoming_latefee }
                speed={ `slow` }>
                <div>
                  <FormRow className={ `space-y-2` }>
                    <FormLabel>{ `Upcoming Penalties Section` }</FormLabel>
                    <FormDescription>
                      { `Insert this content, using the merge tag "PenaltiesSection" above` }
                    </FormDescription>
                    <TextEditor
                      mergeTags={ mergeTagsForSecondaryEditor }
                      onChange={ handleSecondaryEditorChange }
                      value={ formData.secondary_json_content }
                      htmlValue={ formData.secondary_html_content }
                    />
                  </FormRow>
                </div>
              </Transition>

              <When condition={ template?.type === EmailTemplateTypes.REMINDER }>
                <FormRow className={ `space-y-2` }>
                  <FormLabel>{ `Suitable for?` }</FormLabel>
                  <FormDescription>
                    { `The language used in this email is suitable for the following invoice states.` }
                  </FormDescription>
                  <Grid cols={ 3 }>
                    <GridItem span={ 1 }>
                      <CheckBox
                        label={ `Before Due Date ` }
                        checked={ formData.suits_before_due }
                        onChange={ onChange }
                        name={ `suits_before_due` }
                        className={ `py-1` }
                      />
                    </GridItem>
                    <GridItem span={ 1 }>
                      <CheckBox
                        label={ `On Due Date` }
                        checked={ formData.suits_on_due }
                        onChange={ onChange }
                        name={ `suits_on_due` }
                        className={ `py-1` }
                      />
                    </GridItem>
                    <GridItem span={ 1 }>
                      <CheckBox
                        label={ `After Due Date` }
                        checked={ formData.suits_after_due }
                        onChange={ onChange }
                        name={ `suits_after_due` }
                        className={ `py-1` }
                      />
                    </GridItem>
                  </Grid>
                </FormRow>
              </When>

              <Select
                label={ `Signature Position` }
                selected={ formData.signature_position }
                nullable
                options={ positionOptions }
                onChange={ onChange }
                name={ `signature_position` }
                className={ `mb-4` }
              />

              { /*  Included attachement */ }
              <MultiSelect
                label={ `Attached Files` }
                options={ fileOptions }
                loading={ filesLoading }
                selected={ attachedFilesValue }
                onChange={ onChangeFiles }
                description={ `Attach files to this email template. These files will be included in the email as attachments.` }
                helpIcon={ `When applicable, invoice and statement PDFs are always attached.` }
                display={ attachedFilesDisplay }
                clearable
              />

              <div className={ `mt-4` }/>

              <When condition={ template?.policies?.length > 1 }>
                <Notification
                  type={ `info` }
                >
                  { `This template is used in ${template.policies.length} policies` }
                </Notification>
              </When>

            </FormWithButton>
          </CardContent>
        </Card>
      </GridItem>
      <ForwardRefGridItem span={ 7 }
        className={ `relative h-full` }
        ref={ parentRef }>
        <ForwardRefCard
          className={ `min-h-[700px] absolute inset-x-0 my-1/4 transition-all duration-500 ease-in-out` }
          style={ { transform: `translateY(-50%)` } }
          ref={ scrollingDivRef }
        >
          <MultiButtonCardHeader
            title={ `Live Preview` }
            description={ `Test emails will be sent to ${user?.email}` }
            secondaryDescription={
              settings?.logoUrl
                ? ``
                : `When no logo is loaded, the below placeholder will not be included on the email`
            }
            buttons={ [
              {
                buttonText: isDirty ? `Unsaved Changes` : `Send a test email`,
                onClick: sendTestEmailFn,
                loading: sendTestEmailLoading,
                disabled: isDirty,
                tooltip: `Send a test email, using real data if possible`,
              },
            ] }
          />

          <CardContent>
            <CustomIframe style={ {
              width: `100%`,
              height: `100%`,
              minHeight: `700px`,
            } }>
              { htmlParser.parse(html) }

            </CustomIframe>
          </CardContent>
        </ForwardRefCard>
      </ForwardRefGridItem>
    </Grid>
  );
}
