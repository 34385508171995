import { toCents, toDecimal } from "shared";
import { useState } from "react";

import { InputTextAddon, InputTextAddonProps } from "./InputTextAddon";

// A wrapper for text input
type CurrencyInputProps = InputTextAddonProps

/** Converts values saved as cents, to decimals for display and input and vice versa */
export function CurrencyInput({ value, name, onChange, ...props }: CurrencyInputProps) {
  const [localValue, setLocalValue] = useState(toDecimal(value));

  function onBlur() {
    onChange({
      name,
      value: toCents(localValue),
    });
  }

  function onChangeHook(e: { value: string | number, name: string }) {
    // The user will be typing in decimals,
    setLocalValue(e.value as number);
  }

  return (
    <InputTextAddon
      value={ localValue }
      onBlur={ onBlur }
      onChange={ onChangeHook }
      { ...props }
    />
  );
}
