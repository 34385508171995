import { GroupOptionsOnboarding } from "shared";

import { RadioCards } from "../../../../common/Atoms/RadioCards";

interface GroupOptionsProps {
  setGroupOption: (groupOptions: GroupOptionsOnboarding) => void;
  groupOption: GroupOptionsOnboarding;
}

const options = [
  {
    value: GroupOptionsOnboarding.EVERYONE,
    title: `Everyone`,
    description: `Apply the selected policies to everyone. No need to create any other groups.`,
  },
  {
    value: GroupOptionsOnboarding.BLACKLIST,
    title: `Only some contacts`,
    description: `Creates a second group for the contacts you wish to target. Everyone else remains in the "Default" group and will be unaffected.`,
  },
  {
    value: GroupOptionsOnboarding.WHITELIST,
    title: `Exclude some contacts`,
    description: `Creates a second group for the contacts you do not wish to target. Everyone else remains in the "Default" group and will have the selected policies applied to them.`,
  },
];

export function GroupOptions({ setGroupOption, groupOption }: GroupOptionsProps) {
  return (
    <RadioCards
      onChange={ v => setGroupOption(v.value as GroupOptionsOnboarding) }
      options={ options }
      selected={ groupOption }
    />
  );
}
