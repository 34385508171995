import { Outlet } from 'react-router';
import { ArrowTrendingUpIcon, DocumentMagnifyingGlassIcon, DocumentTextIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { When } from 'react-if';
import { testForErrorMap } from 'shared';

import { useGetSelectedOrganisation } from '../../hooks/useGetSelectedOrganisation';
import { Tabs } from '../../../common/Atoms/Tabs';
import { Notification } from '../../../common/Atoms/Notification';
import { useInvoice } from '../../hooks/useInvoice';
import { SmallSpinner } from '../../../common/Atoms/SmallSpinner';

const tabs = [
  { name: `Invoice`, href: ``, icon: DocumentTextIcon },
  { name: `Notes`, href: `notes`, icon: PencilSquareIcon },
  { name: `Preview Policies`, href: `policy-preview`, icon: DocumentMagnifyingGlassIcon },
  { name: `Portal Views`, href: `analytics`, icon: ArrowTrendingUpIcon },
];

export function InvoicePage() {
  const organisation = useGetSelectedOrganisation();
  const { invoice, isLoading } = useInvoice();

  if (isLoading) {
    return (
      <div className={ `flex justify-center items-center h-96` }>
        <SmallSpinner
          size={ `lg` }
          colored
        />
      </div>
    );
  }

  if (!invoice || !organisation) return null;

  return (
    <div>
      <Tabs
        tabs={ tabs }
      />
      <When condition={ invoice?.note && invoice.error }>
        <Notification
          type={ `error` }
          title={ `Error` }
          className={ `mb-6` }
        >
          { /* Move to backend transformer after invoiceAPI */ }
          { testForErrorMap(invoice.note) }
        </Notification>
      </When>
      <Outlet />
    </div>
  );
}
