import { IInvalidField, IPolicy } from "shared";
import { useMemo, useState } from "react";

import { useAccountingResources } from "../../../../hooks/useAccountingResources";
import { Toggle } from "../../../../../common/Atoms/Toggle";
import { Transition } from "../../../../../common/Atoms/Transition";
import { MultiSelect } from "../../../../../common/Atoms/MultiSelect";
import { fieldIsValid } from "../../../../lib/helper";
import { Grid } from "../../../../../common/Atoms/Grid";
import { GridItem } from "../../../../../common/Atoms/GridItem";

interface IBrandingSelectorProps {
  policy: IPolicy;
  invalids: IInvalidField[];
  handleChange: (obj: {
    name: keyof IPolicy;
    value: string
  }) => void;
}

export function BrandingSelector({
  policy,
  handleChange,
  invalids,
}: IBrandingSelectorProps) {
  const [showBrandingThemes, setShowBrandingThemes] = useState(policy.exclude_branding_themes?.length > 0);
  const { data: resources, isLoading } = useAccountingResources();

  const selectedThemes = useMemo(() => {
    return policy.exclude_branding_themes?.split(`,`) || [];
  }, [policy.exclude_branding_themes]);

  const displayValue = useMemo(() => {
    if (isLoading) {
      return `Loading...`;
    }

    if (!selectedThemes.length) {
      return `None Selected`;
    }

    return `${selectedThemes.length} selected`;
  }, [selectedThemes, isLoading]);

  const brandingOptions = useMemo(() => {
    if (!resources?.brandingThemes) {
      return [];
    }

    return resources.brandingThemes.map(theme => ({
      label: theme.name,
      value: theme.brandingThemeID,
    }));
  }, [resources?.brandingThemes]);

  function onBrandingToggle() {
    if (showBrandingThemes) {
      // Was showing branding before, so clear them
      handleChange({
        value: null,
        name: `exclude_branding_themes`,
      });
    }

    setShowBrandingThemes(!showBrandingThemes);
  }

  function handleBrandingChange(value: string[]) {
    handleChange({
      value: value.join(`,`),
      name: `exclude_branding_themes`,
    });
  }

  return (
    <>
      <Grid
        cols={ 3 }
      >
        <GridItem span={ 3 }>
          <Toggle
            label={ `Exclude branding themes` }
            description={ `Don't apply this policy to invoices that are using certain branding themes` }
            checked={ showBrandingThemes }
            onChange={ onBrandingToggle }
          />
        </GridItem>

        <GridItem span={ 3 }>
          <Transition
            show={ showBrandingThemes }
            speed={ `slow` }
          >
            <div>
              <MultiSelect
                options={ brandingOptions }
                onChange={ handleBrandingChange }
                invalid={ fieldIsValid(`exclude_branding_themes`, invalids) }
                selected={ selectedThemes }
                display={ displayValue }
              />
            </div>
          </Transition>
        </GridItem>
      </Grid>
    </>
  );
}
