import { useMemo, FC } from 'react';
import { DocumentIcon, CurrencyDollarIcon, EnvelopeIcon, ReceiptPercentIcon, BanknotesIcon, DocumentChartBarIcon, ChatBubbleBottomCenterIcon } from '@heroicons/react/24/outline';
import { IOrganisationMetrics, toFormattedString } from 'shared';
import { Else, If, Then, When } from 'react-if';

import { useGetSelectedOrganisation } from '../../../hooks/useGetSelectedOrganisation';
import { Paragraph } from '../../../../common/Atoms/Typography/Paragraph';
import { useOrgMetrics } from '../../../hooks/useOrgMetrics';
import { classNames } from '../../../../common/lib/classNames';
import { Card } from '../../../../common/Atoms/Card';
import { CardContent } from '../../../../common/Atoms/CardContent';
import { MultiButtonCardHeader } from '../../../../common/Atoms/MultiButtonCardHeader';
import { LoadingElementPlaceholder } from '../../../../common/Atoms/LoadingElementPlaceholder';

// Declare here for tailwind compiler
const rowClasses = {
  1: `grid-rows-1`,
  2: `grid-rows-2`,
  3: `grid-rows-3`,
  4: `grid-rows-4`,
};

const initStats: {
  id: number;
  name: string;
  stat: string | number;
  icon: FC<Parameters<typeof DocumentIcon>[0]>;
}[] = [
  { id: 2, name: `Late Fees Issued`, stat: ``, icon: CurrencyDollarIcon },
  { id: 7, name: `Statement Interest Charges Issued`, stat: ``, icon: BanknotesIcon },
  { id: 4, name: `Reminders Sent`, stat: ``, icon: EnvelopeIcon },
  { id: 5, name: `Discounts Issued`, stat: ``, icon: ReceiptPercentIcon },
  { id: 6, name: `Auto Statements Sent`, stat: ``, icon: DocumentChartBarIcon },
  { id: 8, name: `Total SMS Sent`, stat: ``, icon: ChatBubbleBottomCenterIcon },
];

function calcStat(id: number, metrics: IOrganisationMetrics) {
  switch (id) {
  case 2:
    return metrics?.totalPenalties || `-`;
  case 4:
    return metrics?.totalReminders || `-`;
  case 5:
    return metrics?.totalDiscounts || `-`;
  case 6:
    return metrics?.totalStatements || `-`;
  case 7:
    return metrics?.totalStatementInterests || `-`;
  case 8:
    return metrics?.totalSMS || `-`;
  }
}

interface IStatsProps {
  className?: string
}

export function Stats({ className }: IStatsProps) {
  const currentOrg = useGetSelectedOrganisation();
  const { data: metrics, isLoading } = useOrgMetrics();

  const stats = useMemo(() => {
    return initStats
      .filter(stat => calcStat(stat.id, metrics) !== `-`)
      .map(stat => {
        const s = {
          ...stat,
          stat: toFormattedString(calcStat(stat.id, metrics)),
        };

        return s;
      });

  }, [ currentOrg, metrics ]);

  return (
    <Card
      className={ classNames(`min-h-full`, className) }
    >
      <If condition={ isLoading }>
        <Then>
          <LoadingElementPlaceholder
            rows={ 3 }
            border={ false }
          />
        </Then>
        <Else>
          {
            () => (
              <>

                <MultiButtonCardHeader
                  title={ `In the last ${metrics?.previousDays} days` }
                />
                <CardContent>
                  <When condition={ !stats.length }>
                    <div className={ `flex justify-center items-center mt-16` }>
                      <Paragraph>
                        { `Recent activity will appear here.` }
                      </Paragraph>
                    </div>
                  </When>
                  <div className={
                    classNames(
                      `space-y-1 mt-3 grid grid-flow-col`,
                      rowClasses[stats.length] || `grid-rows-4`,
                    )
                  }>

                    { stats
                      .map(item => {
                        return (
                          <div
                            key={ item.id }
                            className={ `flex items-center justify-start` }
                          >
                            <div className={ `mr-2 bg-lateGreen-500 rounded-md p-1` }>
                              <item.icon className={ `h-6 w-6 text-white` }
                                aria-hidden={ `true` } />
                            </div>
                            <Paragraph
                              variant={ `primary` }
                              size={ `md` }
                              className={ `mr-1` }
                            >
                              { item.stat }
                            </Paragraph>
                            <Paragraph
                              variant={ `secondary` }
                            >
                              { item.name }
                            </Paragraph>
                          </div>
                        );
                      }) }
                  </div>
                </CardContent>
              </>
            )
          }
        </Else>
      </If>
    </Card>
  );
}
