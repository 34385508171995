import { When } from "react-if";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";

import { useAllowedFeature } from "../../../../hooks/useAllowedFeature";
import { classNames } from "../../../../../common/lib/classNames";
import { InputTextAddon } from "../../../../../common/Atoms/InputTextAddon";
import { Paragraph } from "../../../../../common/Atoms/Typography/Paragraph";
import { useSettings } from "../../../../hooks/useSettings";
import { TwoColFormRow } from "../../../Molecules/Form/TwoColFormRow";
import { UpgradeBadge } from "../../../Molecules/UpgradeBadge";
import { fieldIsValid } from "../../../../lib/helper";
import { useVerifyCustomDomainMutation } from "../../../../services/api/settingsApi/settings";
import { Button } from "../../../../../common/Atoms/Button";
import { useGeneralSettingsForm } from "../../../../hooks/useGeneralSettingsForm";

import { DNSInfo } from "./DNSInfo";

export function CustomDomain() {
  const allowedFeature = useAllowedFeature(`customDomain`);
  const [verify, { isLoading: verifyLoading }] = useVerifyCustomDomainMutation();
  const { formData, onChange, invalids, hasUnsavedChanges } = useGeneralSettingsForm();

  const { data: settings } = useSettings();

  function handleChange(e) {
    onChange(e.name, e.value);
  }

  const hasDomainValue = settings.customDomain;

  const badge = !allowedFeature && (
    <UpgradeBadge />
  );

  let addOnElement = null;

  if (hasDomainValue && settings.customDomainVerified) {
    addOnElement = (
      <span className={ `flex` }>
        <CheckCircleIcon className={ `h-5 w-5 text-green-500` } />
        <Paragraph
          variant={ `primary` }
          className={ `ml-1` }
        >
          { `Verified & Active` }
        </Paragraph>
      </span>
    );
  }

  if (hasDomainValue && !settings.customDomainVerified) {
    addOnElement = (
      <span className={ `flex` }>
        <XMarkIcon className={ `h-5 w-5 text-red-500` } />
        <Paragraph
          variant={ `error` }
          className={ `ml-1` }
        >
          { `Not verified` }
        </Paragraph>
      </span>
    );
  }

  return (
    <TwoColFormRow
      heading={ `Custom Domain` }
      badge={ badge }
      help={
        allowedFeature ?
          undefined:
          `Upgrade to "Pro" to unlock custom domains`
      }
    >
      <div className={ classNames(
        `space-y-2`,
        allowedFeature ? `` : `opacity-50`,
      ) }>
        <InputTextAddon
          label={ `URL` }
          value={ formData.customDomain || `` }
          name={ `customDomain` }
          onChange={ handleChange }
          disabled={ !allowedFeature }
          subHeading={ `Enter your domain using only the domain name and TLD (e.g. example.com).` }
          invalid={ fieldIsValid(`customDomain`, invalids) }
          placeholder={ `example.com` }
          addOnText={ addOnElement }
        />
        <When condition={ hasDomainValue && !!settings.dnsRecords }>
          {
            () => (
              <>
                <DNSInfo rows={ settings.dnsRecords } />
                <div className={ `flex justify-end pt-2` }>
                  <Button
                    onClick={ () => verify(settings.organisationId) }
                    loading={ verifyLoading }
                    disabled={ verifyLoading || settings.customDomainVerified || hasUnsavedChanges }
                    color={ `red` }
                    tooltip={
                      settings.customDomainVerified ?
                        `Domain already verified`
                        :
                        `Domain not yet verified`
                    }
                  >
                    { `Verify Domain` }
                  </Button>
                </div>
              </>
            )
          }
        </When>
      </div>
    </TwoColFormRow>
  );
}
