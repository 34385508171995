import { Card } from "../../../../../common/Atoms/Card";
import { ActionsTable } from "../../../Actions/ActionsTable";

export function GroupActionsTable() {
  return (
    <Card>
      <ActionsTable omitColumns={ [] } />
    </Card>
  );
}
