import { CheckIcon, XMarkIcon, ClipboardIcon } from "@heroicons/react/20/solid";
import { useCopyToClipboard } from 'usehooks-ts';
import { toast } from "react-toastify";

import { Paragraph } from "../../../../../common/Atoms/Typography/Paragraph";

interface DNSRow {
  type: string;
  host: string;
  data: string;
  verified: boolean;
}

interface DNSInfoProps {
  rows: DNSRow[];
}

export function DNSInfo({
  rows,
}: DNSInfoProps) {
  const [, copy] = useCopyToClipboard();

  async function handleCopy(text: string) {
    try {
      await copy(text);

      toast.success(`Copied to clipboard`, {
        autoClose: 200,
      });
    }
    catch (error) {
      toast.error(`Failed to copy to clipboard`);
    }
  }

  return (
    <div className={ `mt-8 flow-root` }>
      <Paragraph
        className={ `pb-4` }
      >
        { `The following DNS records are required to verify your domain. ` }
        { `Please add these records to your DNS provider. Then click verify domain.` }
      </Paragraph>
      <div className={ `-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8` }>
        <div className={ `inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8` }>
          <table className={ `min-w-full divide-y divide-gray-300` }>
            <thead>
              <tr className={ `divide-x divide-gray-200` }>
                <th scope={ `col` }
                  className={ `py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0` }>
                  { `Type` }
                </th>
                <th scope={ `col` }
                  className={ `px-4 py-3.5 text-left text-sm font-semibold text-gray-900` }>
                  { `Host/Name/Subdomain` }
                </th>
                <th scope={ `col` }
                  className={ `px-4 py-3.5 text-left text-sm font-semibold text-gray-900` }>
                  { `Data/Value/Content` }
                </th>
                <th scope={ `col` }
                  className={ `py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0` }>
                  { `Verified` }
                </th>
              </tr>
            </thead>
            <tbody className={ `divide-y divide-gray-200 bg-white` }>
              { rows.map(row => (
                <tr key={ row.host }
                  className={ `divide-x divide-gray-200` }>
                  <td className={ `whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0` }>
                    { row.type?.toUpperCase() }
                  </td>
                  <td className={ `whitespace-nowrap p-4 text-sm text-gray-500` }>
                    <span className={ `flex` }>
                      { row.host }
                      <ClipboardIcon className={ `h-5 w-5 ml-2 text-lateGreen-500 cursor-pointer` }
                        onClick={ () => handleCopy(row.host) }
                      />
                    </span>
                  </td>
                  <td className={ `whitespace-nowrap p-4 text-sm text-gray-500` }>
                    <span className={ `flex` }>
                      { row.data }
                      <ClipboardIcon className={ `h-5 w-5 ml-2 text-lateGreen-500 cursor-pointer` }
                        onClick={ () => handleCopy(row.data) }
                      />
                    </span>
                  </td>
                  <td className={ `whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0` }>
                    {
                      row.verified ? (
                        <CheckIcon className={ `h-5 w-5 text-green-500` } />
                      )
                        : (
                          <XMarkIcon className={ `h-5 w-5 text-red-500` } />
                        )
                    }
                  </td>
                </tr>
              )) }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
