import { When } from "react-if";
import { useParams } from "react-router";
import { useCallback, useMemo, useState } from "react";
import { IContactPerson, displayPhoneNumber } from "shared";
import { BeakerIcon } from "@heroicons/react/24/outline";

import { Heading } from "../../../common/Atoms/Typography/Heading";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { useIsSafeMode } from "../../hooks/useIsSafeMode";
import { Modal } from "../../../common/Atoms/Modal";
import { IOption, Select } from "../../../common/Atoms/Select";
import { InputTextAddon } from "../../../common/Atoms/InputTextAddon";
import { Button } from "../../../common/Atoms/Button";
import { useAiCallNowMutation } from "../../services/api/invoiceApi/invoice";
import { useGetSubscriptionMetadata } from "../../hooks/useGetSubscription";
import { Notification } from "../../../common/Atoms/Notification";

const CUSTOM_NUMBER = `CUSTOM_NUMBER`;

interface AICallNowModalProps {
  open: boolean;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  invoice: any;
}

export function AICallNowModal({ open, onClose, invoice }: AICallNowModalProps) {
  const { organisationId, invoiceId } = useParams();
  const safeModeOn = useIsSafeMode();
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string | null>(null);
  const [customPhoneNumber, setCustomPhoneNumber] = useState<string | null>(null);
  const [aiCallNowRequest, { isLoading: aiCallNowLoading }] = useAiCallNowMutation();
  const { subscriptionMetadata } = useGetSubscriptionMetadata();

  const phoneNumberOptions: IOption[] = useMemo(() => {
    if (!invoice) {
      return [];
    }

    if (!invoice.contact?.primaryContactPerson) {
      return [];
    }

    const options: IOption[] = [{
      label: `Enter a number...`,
      value: CUSTOM_NUMBER,
    }];

    const contactPerson = invoice.contact.primaryContactPerson as IContactPerson;

    const mobile = displayPhoneNumber(contactPerson.mobileCountryCode, contactPerson.mobileNumber);
    const defaultNumber = displayPhoneNumber(contactPerson.defaultCountryCode, contactPerson.defaultNumber);

    if (mobile) {
      options.unshift({
        label: `${mobile} (Mobile)`,
        value: mobile,
      });
    }

    if (defaultNumber) {
      options.unshift({
        label: `${defaultNumber} (Default)`,
        value: defaultNumber,
      });
    }

    return options;
  }, [invoice]);

  const getPhoneNumber = useCallback(() => {
    if (!selectedPhoneNumber) {
      return null;
    }

    if (selectedPhoneNumber === CUSTOM_NUMBER) {
      return customPhoneNumber;
    }

    return selectedPhoneNumber;
  }
  , [selectedPhoneNumber, customPhoneNumber]);

  const callEnabled = !!getPhoneNumber();

  async function callNow() {
    if (!callEnabled) return;

    const phoneNumber = getPhoneNumber();

    if (!phoneNumber) return;

    await aiCallNowRequest({
      organisationId,
      invoiceId,
      phoneNumber,
    });

    onClose();
  }

  if (!invoice) {
    return null;
  }

  return (
    <Modal
      open={ open }
      setOpen={ onClose }
      title={ `` }
    >
      <div className={ `flex justify-center flex-col items-center pb-6` }>
        <Heading>
          { `Send an AI call now.` }
        </Heading>

        { /* Info Section */ }
        <div className={ `mb-3` }>
          <Paragraph
            variant={ `primary` }
            className={ `` }
          >
            { `Immediately send an AI call to this customer to enquire about this invoice and when to expect payment.` }
          </Paragraph>
        </div>
        <Notification
          type={ `pro` }
          icon={ BeakerIcon }
          border
        >
          { `You have early access to this feature. It may not be perfect so please let us know if you have any feedback!` }
        </Notification>
      </div>
      <div className={ `mb-4 space-y-3` }>
        <Select
          options={ phoneNumberOptions }
          label={ `Select a phone number` }
          selected={ selectedPhoneNumber }
          onChange={ e => setSelectedPhoneNumber(e.value) }
        />
        <When condition={ selectedPhoneNumber === CUSTOM_NUMBER }>
          <InputTextAddon
            value={ customPhoneNumber }
            onChange={ e => setCustomPhoneNumber(e.value?.toString()) }
            label={ `Phone Number` }
            placeholder={ `Include country code e.g +44, +61, +1` }
          />
        </When>

        <Button
          className={ `w-full justify-center mt-3` }
          size={ `lg` }
          disabled={ !callEnabled  || aiCallNowLoading }
          loading={ aiCallNowLoading }
          onClick={ callNow }
        >
          { `Call Now ${safeModeOn ? `(even in safe mode)`: ``}` }
        </Button>

        <Paragraph
          variant={ `secondary` }
          className={ `text-center` }
        >
          { `Calls are charged at 1 credit per minute. Your current balance: ${subscriptionMetadata?.walletBalanceFormatted}` }
        </Paragraph>
      </div>
    </Modal>
  );
}
