export const FEATURE_REQUEST_FORM = `https://forms.gle/47vzMxoX3FCkxa457`;

export const HELP_CENTRE_HOME = `https://support.paidnice.com/en`;

export const WEBHOOKS_ARTICLE = `https://support.paidnice.com/en/articles/6761674-using-webhooks-in-paidnice`;

export const FIND_SUBSCRIPTION_ARTICLE = `https://support.paidnice.com/en/articles/8006142-can-t-see-the-active-subscription-for-my-organisation`;

export const ICS_ARTICLE = `https://support.paidnice.com/en/articles/8294008-inserting-reminder-events-into-customer-calendars`;

export const STATEMENT_INTEREST_ARTICLE = `https://support.paidnice.com/en/articles/8504399-statement-interest-charges`;

export const AUTO_STATEMENTS_ARTICLE = `https://support.paidnice.com/en/articles/8504361-auto-statements-overview`;

export const VIEW_STATEMENTS_ARTICLE = `https://support.paidnice.com/en/articles/8492493-viewing-and-customising-statements`;

export const ACTIONS_ARTICLE = `https://support.paidnice.com/en/articles/8504370-all-about-actions`;

export const ESCALATIONS_ARTICLE = `https://support.paidnice.com/en`; // TODO: write this

export const BANK_OF_ENGLAND_ARTICLE = `https://support.paidnice.com/en/articles/8856421-using-bank-of-england-interest-rates-when-calculating-interest`;

export const SAFE_MODE_ARTICLE = `https://support.paidnice.com/en/articles/6748984-what-is-safe-mode-and-how-does-it-work`;

export const POLICY_STATE_ARTICLE = `https://support.paidnice.com/en/articles/9614506-how-to-set-policies-to-run-automatically-manually-or-not-at-all`;

export const GROUPS_POLICIES_ARTICLE = `https://support.paidnice.com/en/articles/9244979-creating-workflows-with-groups-and-policies-in-paidnice`;
