import ms from "ms";

import { useFetchAICallsQuery } from "../services/api/aiCallsApi/aiCalls";

import { useGetSelectedOrganisation } from "./useGetSelectedOrganisation";

export function useAICalls() {
  const currentOrg = useGetSelectedOrganisation();

  const result = useFetchAICallsQuery({ organisationId: currentOrg.id }, {
    skip: !currentOrg.id,
    pollingInterval: ms(`30s`),
    skipPollingIfUnfocused: true,
  });

  return {
    ...result,
    calls: result.data?.calls || [],
    count: result.data?.total || 0,
  };
}
