import { useNavigate } from 'react-router';
import { When } from 'react-if';

import { Button } from '../../../common/Atoms/Button';
import { Card } from '../../../common/Atoms/Card';
import { CardContent } from '../../../common/Atoms/CardContent';
import { Grid } from '../../../common/Atoms/Grid';
import { GridItem } from '../../../common/Atoms/GridItem';
import { MultiButtonCardHeader } from '../../../common/Atoms/MultiButtonCardHeader';
import { Paragraph } from '../../../common/Atoms/Typography/Paragraph';
import { useUser } from '../../hooks/useUser';
import { useRsvpInviteMutation } from '../../services/api/userApi/user';
import { Notification } from '../../../common/Atoms/Notification';

export function PendingInvitations({ showContentOnly }) {
  const { data: user } = useUser();
  const [rsvpInvite, { isLoading: rsvpInviteLoading }] = useRsvpInviteMutation();

  const navigate = useNavigate();

  const emailVerified = user?.emailVerified;

  async function onRsvp(inviteId: number) {
    try {
      const result = await rsvpInvite({ inviteId });
      if (result) {
        // TODO: redirect to the just accepted org - there must be a better way to do this...
        const acceptedInvite = user.invites.find(i => i.id === inviteId);
        navigate(`/${acceptedInvite.organisationId}`);
        window.location.reload();
      }
    }
    catch (error) {
      console.error(error);
    }
  }

  if (!user || !user.invites.length) {
    return showContentOnly ? <Paragraph>{ `No pending invitations` }</Paragraph>: null;
  }

  function getContent() {
    return (
      <div className={ `pr-4 mr-4 border-gray-300 h-full` }>
        <When condition={ !emailVerified }>
          <Notification
            className={ `mt-6 mb-6` }
            type={ `info` }
          >
            { `You need to verify your email address before accepting invitations. If you did not receive a verification email please contact support.` }
          </Notification>
        </When>
        {
          user.invites.map(invite => (
            <div key={ invite.id }>
              <Grid
                className={ `mt-4` }
                cols={ 12 }>
                <GridItem span={ 6 }>{ `Invited to ${invite.organisationName} by ${invite.invitedBy}` }</GridItem>
                <GridItem span={ 6 }>
                  <Button
                    onClick={ () => onRsvp(invite.id) }
                    type={ `button` }
                    loading={ rsvpInviteLoading }
                    disabled={ !emailVerified }
                  >
                    { `Accept invitation` }
                  </Button>
                </GridItem>
              </Grid>
            </div>
          ))
        }
      </div>
    );
  }

  return showContentOnly ? getContent() : (
    <Card
      className={ `min-h-full mb-4` }
    >
      <MultiButtonCardHeader
        title={ `Pending Invitations` }
        description={ `You've been invited to join a Paidnice Organisation` }
      >
      </MultiButtonCardHeader>
      <CardContent>
        <div className={ `grid py-6` }>
          { getContent() }
        </div>
      </CardContent>
    </Card>
  );
}
