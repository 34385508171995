import { When } from 'react-if';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { XCircleIcon } from '@heroicons/react/20/solid';

import { env } from '../../../../../common/lib/env';
import { useApiRequest } from '../../../../hooks/useApiRequest';

interface IFileUploadProps {
  url?: string
  setUrl: (url: string) => void
  invalid?: {
    message: string
  }
}

export function FileUpload({
  url,
  setUrl,
  invalid,
}: IFileUploadProps) {
  const [file, setFile] = useState(null);
  // const [percent, setPercent] = useState(0)
  const [uploading, setUploading] = useState(false);

  const baseRequest = useApiRequest();
  function changeLogo() {
    setFile(null);
    setUrl(null);
    setUploading(false);
  }

  // Handles a file being picked

  async function handleChange(event) {
    try {
      const fileEvent = event.target.files[0];
      setFile(fileEvent);

      const formData = new FormData();

      formData.append(`image`, fileEvent);

      setUploading(true);

      const response = await baseRequest.post(`${env.baseApiUrl}email-templates/logo`,
        formData,
        {
          headers: {
            'Content-Type': `multipart/form-data`,
          },
        },
      );

      const { data } = response;

      setUploading(false);
      toast.success(`Logo uploaded successfully`);
      setUrl(data.url);
    }
    catch (err) {
      toast.error(err.response?.data || err.message);
      setFile(null);
      setUploading(false);
    }
  }

  return (
    <div className={ `flex items-center w-3/4 mt-2` }>
      <When condition={ !file && !url }>
        <label htmlFor={ `dropzone-file` }
          className={ `flex flex-col items-center justify-center w-full h-24 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600` }>
          <div className={ `flex flex-col items-center justify-center pt-2 pb-2` }>
            <svg aria-hidden={ `true` }
              className={ `w-8 h-8 mb-3 text-gray-400` }
              fill={ `none` }
              stroke={ `currentColor` }
              viewBox={ `0 0 24 24` }
              xmlns={ `http://www.w3.org/2000/svg` }>
              <path strokeLinecap={ `round` }
                strokeLinejoin={ `round` }
                strokeWidth={ `2` }
                d={ `M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12` }>
              </path>
            </svg>
            <p className={ `mb-2 text-sm text-gray-500 dark:text-gray-400` }>
              <span className={ `font-semibold` }>{ `Click to upload` }</span>
            </p>
            <p className={ `text-xs text-gray-500 dark:text-gray-400` }>{ `PNG, JPG or JPEG (Recommended Max Size 550 x 150px) (Resized automatically)` }</p>
          </div>
          <input
            id={ `dropzone-file` }
            type={ `file` }
            className={ `hidden` }
            disabled={ uploading === true }
            onChange={ handleChange }
            accept={ `image/png, image/jpeg, image/jpg` }
          />
        </label>
      </When>
      <When condition={ !!invalid }>
        <p className={ `text-red-500` }>{ invalid?.message } </p>
      </When>
      <When condition={ uploading === true }>
        <div >
          <p>{ `Uploading...` }</p>
          <div className={ `h-1 w-full bg-neutral-200 dark:bg-neutral-600` }>
          </div>
        </div>
      </When>
      <When condition={ uploading !== true && url }>
        <div className={ `rounded-lg p-6 uploadBackground` }>
          <a className={ `float-right` }
            href={ `#` }
            title={ `remove logo` }
            onClick={ changeLogo }> <XCircleIcon className={ `h-8 w-8 text-red-400 hover:text-red-600` }/></a>
          <img
            src={ url }
            className={ `App-logo` }
            alt={ `logo` } />
        </div>
      </When>
    </div>

  );
}
