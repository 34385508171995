import { TwoColFormRow } from "../../../Molecules/Form/TwoColFormRow";
import { TextEditor } from "../../../Molecules/TextEditor";
import { useGeneralSettingsForm } from "../../../../hooks/useGeneralSettingsForm";

export function EmailSignature() {
  const { onChangeMultiple, formData } = useGeneralSettingsForm();

  function handleChange({ html, json }) {
    onChangeMultiple({
      signatureHtml: html,
      signatureJson: JSON.stringify(json),
    });
  }

  return (
    <TwoColFormRow
      heading={ `Email Signature` }
      description={ `Your email signature will be appended to the bottom of all emails sent from your account.` }
    >
      <div className={ `max-w-[600px]` }>
        <TextEditor
          mergeTags={ [] }
          onChange={ handleChange }
          htmlValue={ formData.signatureHtml || `` }
          value={ formData.signatureJson ? JSON.parse(formData.signatureJson) : {} }
          mode={ `signature` }
        />
      </div>
    </TwoColFormRow>
  );
}
