import { DefaultizedPieValueType, PieChart, pieArcLabelClasses } from '@mui/x-charts';
import { useMemo } from 'react';

import { Card } from '../../../../common/Atoms/Card';
import { MultiButtonCardHeader } from '../../../../common/Atoms/MultiButtonCardHeader';
import { useOrgMetrics } from '../../../hooks/useOrgMetrics';

export const paidOnTimeColor = `#2ADACE`;

export const overdueColor = `#467691`;

export const paidLateColor = `#FF5E5C`;

export const outstandingColor = `#34454E`;

export function InvoiceOverdueChart() {
  const { data: metrics } = useOrgMetrics();

  const data = useMemo(() => {
    if (!metrics?.invoicesLastXDays) return [];

    const dataMap = {
      overdue: {
        label: `Overdue`,
        color: overdueColor,
      },
      paidOnTime: {
        label: `Paid`,
        color: paidOnTimeColor,
      },
      paidLate: {
        label: `Paid late`,
        color: paidLateColor,
      },
      outstanding: {
        label: `Outstanding`,
        color: outstandingColor,
      },
    };

    return Object.keys(metrics.invoicesLastXDays)
      .filter(key => (key in dataMap))
      .map(key => {
        return {
          ...dataMap[key],
          value: metrics.invoicesLastXDays[key],
        };
      });

  }, [metrics?.invoicesLastXDays]);

  const TOTAL = data.map(item => item.value).reduce((a, b) => a + b, 0);

  const getArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / TOTAL;

    return `${(percent * 100).toFixed(0)}%`;
  };

  const previousDays = metrics?.previousDays || 90;

  return (
    <Card className={ `min-h-full min-w-full` }>
      <MultiButtonCardHeader
        title={ `Invoices Due in Last ${previousDays} days` }
      />

      <div className={ `h-[300px] min-w-[400px]` } >

        <PieChart
          series={ [{
            data,
            paddingAngle: 5,
            innerRadius: 40,
            outerRadius: 120,
            arcLabel: getArcLabel,
            arcLabelMinAngle: 30,
          }] }
          sx={ {
            [`& .${pieArcLabelClasses.root}`]: {
              fill: `white`,
              fontSize: 14,
              fontWeight: 600,
            },
          } }
          slotProps={
            {
              legend: {
                padding: {
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: -10,
                },
                direction: `column`,
                position: {
                  vertical: `middle`,
                  horizontal: `right`,
                },
              },
            }
          }
        >
        </PieChart>
      </div>

    </Card>
  );
}
