import { policyConfig } from "shared";
import { DateTime } from "luxon";

import { fieldIsValid } from "../../../../../lib/helper";
import { Select } from "../../../../../../common/Atoms/Select";
import { useGetSelectedOrganisation } from "../../../../../hooks/useGetSelectedOrganisation";

import { IFieldProps } from "./fieldProps";

interface ITimeOfDayProps extends IFieldProps {
  labelOverride?: string;
  descriptionOverride?: string;
}

const config = policyConfig.hour_of_day;
const hourOptions = Array.from({ length: 24 }, (_, i) => ({
  value: i,
  label: (i < 10 ? `0${i}` : i) + `:00`,
}));

export function TimeOfDay({
  policyFormData,
  invalids,
  handleChange,
  labelOverride,
  descriptionOverride,
}: ITimeOfDayProps) {
  const currentOrg = useGetSelectedOrganisation();
  const description = descriptionOverride || `The time of day in your company timezone to schedule the action to run at.`;
  const label = labelOverride || config.label;
  const orgTz = currentOrg?.validatedTimezone;

  const dt = DateTime.local().setZone(orgTz);

  const formatted = dt.toLocaleString(DateTime.DATETIME_MED);
  const helpText = `Your current company time is: ${formatted}`;

  return (

    <Select
      label={ label }
      description={ description }
      helpIcon={ `The action may be ran slightly after the time dependent on your volumes` }
      options={ hourOptions }
      selected={ policyFormData.hour_of_day }
      onChange={ handleChange }
      helpText={ helpText }
      name={ `hour_of_day` }
      invalid={ fieldIsValid(`hour_of_day`, invalids) }
    />
  );
}
