import { NoOrganisationsConnected } from '../NoOrganisationsConnected';
import { useGetSelectedOrganisation } from '../../hooks/useGetSelectedOrganisation';

import { WidgetsRoot } from './Widgets/WidgetsRoot';

export function Dashboard() {
  const currentOrg = useGetSelectedOrganisation();

  if (!currentOrg) {
    return <NoOrganisationsConnected />;
  }

  return (
    <>
      { /* <div className={ `border-b border-gray-200 bg-white px-4 py-5 sm:px-6 rounded-lg shadow` }>
        <div className={ `-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap` }>
          <div className={ `ml-4 mt-2` }>
            <h3 className={ `text-lg font-medium leading-6 text-gray-900` }>{ name }</h3>
          </div>
        </div>
      </div> */ }

      { /* Widgets */ }
      <WidgetsRoot />
    </>
  );
}
