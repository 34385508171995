import { policyConfig } from "shared";
import { useMemo } from "react";

import { Select } from "../../../../../../common/Atoms/Select";
import { useAccountingResources } from "../../../../../hooks/useAccountingResources";
import { fieldIsValid } from "../../../../../lib/helper";

import { IFieldProps } from "./fieldProps";

export function NewInvoiceBrandingTheme({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {
  const { data: resources, isLoading, isFetching } = useAccountingResources();
  const label = policyConfig.new_invoice_branding_theme_id.label;

  const options = useMemo(() => {
    if (!resources?.brandingThemes) {
      return [];
    }

    return resources.brandingThemes.map(theme => ({
      label: theme.name,
      value: theme.brandingThemeID,
    }));
  }, [resources?.brandingThemes]);

  return (
    <Select
      nullable
      emptyText={ `Default` }
      name={ `new_invoice_branding_theme_id` }
      description={ `When issuing a new invoice. Use this branding theme` }
      helpIcon={ `Not applicable when updating an existing invoice.` }
      label={ label }
      options={ options }
      onChange={ e => handleChange({ value: e.value, name: e.name }) }
      selected={ policyFormData.new_invoice_branding_theme_id }
      invalid={ fieldIsValid(`new_invoice_branding_theme_id`, invalids) }
      loading={ isLoading || isFetching }
    />
  );
}
