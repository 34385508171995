import { IUserResponse } from 'shared';

import { CacheTags } from '../../types/cacheTags';
import { emptySplitApi } from '../baseApi';

export const userApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchUser: builder.query({
      query: () => ({
        url: `user`,
        method: `GET`,
      }),
      transformResponse: (response: IUserResponse) => response && ({
        ...response.user,
        name: `${response.user.firstName} ${response.user.lastName}`,
        intercomHash: response.intercomHash,
        adminOverride: response.adminOverride,
        featureFlags: response.featureFlags,
        orgs: response.orgs,
        invites: response.invites,
        notifications: response.notifications,
      }),
      providesTags: result => result && [{ type: CacheTags.User, id: result.id }, { type: CacheTags.User, id: `LIST` }],
    }),
    saveNotificationSettings: builder.mutation({
      query: data => ({
        url: `user/notification-settings`,
        method: `PUT`,
        data,
      }),
      invalidatesTags: [{ type: CacheTags.User, id: `LIST` }],
    }),
    inviteUser: builder.mutation({
      query: data => ({
        url: `user/invite`,
        method: `POST`,
        data,
      }),
      invalidatesTags: [{ type: CacheTags.User, id: `LIST` }, { type: CacheTags.Organisations, id: `LIST` }],
    }),
    rsvpInvite: builder.mutation({
      query: data => ({
        url: `user/invite/rsvp`,
        method: `PATCH`,
        data,
      }),
      invalidatesTags: [{ type: CacheTags.User, id: `LIST` }, { type: CacheTags.Organisations, id: `LIST` }],
    }),
    uninvite: builder.mutation({
      query: data => ({
        url: `user/uninvite`,
        method: `PATCH`,
        data,
      }),
      invalidatesTags: [{ type: CacheTags.User, id: `LIST` }, { type: CacheTags.Organisations, id: `LIST` }],
    }),
    dismissNotification: builder.mutation({
      query: id => ({
        url: `user/notification/${id}`,
        method: `DELETE`,
      }),
      invalidatesTags: [{ type: CacheTags.User, id: `LIST` }],
    }),
    dismissAllNotifications: builder.mutation({
      query: () => ({
        url: `user/notification`,
        method: `DELETE`,
      }),
      invalidatesTags: [{ type: CacheTags.User, id: `LIST` }],
    }),
    eventNotificationUpdate: builder.mutation({
      query: ({ emailOnMention, emailOnAssignment }) => ({
        url: `user/event-notification`,
        method: `PUT`,
        data: {
          emailOnMention,
          emailOnAssignment,
        },
      }),
      invalidatesTags: [{ type: CacheTags.User, id: `LIST` }],
    }),
  }),
});

export const {
  useFetchUserQuery,
  useSaveNotificationSettingsMutation,
  useInviteUserMutation,
  useRsvpInviteMutation,
  useUninviteMutation,
  useDismissNotificationMutation,
  useDismissAllNotificationsMutation,
  useEventNotificationUpdateMutation,
} = userApi;
