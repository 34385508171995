import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  call: false,
};

const modalSlice = createSlice({
  name: `modals`,
  initialState,
  reducers: {
    show: (state, action) => {
      state[action.payload] = true;
    },
    hide: (state, action) => {
      state[action.payload] = false;
    },
  },
});

export const { show, hide } = modalSlice.actions;

export const modalSlicerReducer = modalSlice.reducer;
