import { ActionType, EmailTemplateTypes } from 'shared';

export const types = {
  [EmailTemplateTypes.LATE_FEE]: `Late Fee`,
  [EmailTemplateTypes.REMINDER]: `Invoice Reminder`,
  [EmailTemplateTypes.AFTER_MARKED_PAID]: `Invoice Marked Paid`,
  [EmailTemplateTypes.DISCOUNT]: `Discount`,
  [EmailTemplateTypes.STATEMENT_EMAIL]: `Statement`,
  [EmailTemplateTypes.STATEMENT_INTEREST_EMAIL]: `Statement Interest`,
  [EmailTemplateTypes.QUOTE]: `Quote`,
  [EmailTemplateTypes.ANNOUNCEMENT]: `Announcement`,
};

export const typeColors = {
  [EmailTemplateTypes.LATE_FEE]: [ActionType.LATE_FEE],
  [EmailTemplateTypes.REMINDER]: [ActionType.REMINDER],
  [EmailTemplateTypes.AFTER_MARKED_PAID]: [ActionType.REMINDER],
  [EmailTemplateTypes.DISCOUNT]: [ActionType.DISCOUNT_APPLY],
  [EmailTemplateTypes.STATEMENT_EMAIL]: [ActionType.SEND_STATEMENT],
  [EmailTemplateTypes.STATEMENT_INTEREST_EMAIL]: [ActionType.LATE_FEE],
};

export const statusColors = {
  Draft: `gray`,
  Active: `green`,
};
