import { Modal } from "../../../common/Atoms/Modal";
import { CalEmbed } from "../Onboarding/CalEmbed";
import { useModals } from "../../hooks/useModals";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";

export function BookCallModal() {
  const { state, hide } = useModals();

  return (
    <Modal
      title={ `Book a free call with us` }
      open={ !!state.call }
      setOpen={ () => hide(`call`) }
    >
      <Paragraph className={ `mb-4` }>
        { `Get a demo, or simply have your settings checked by us. We are always happy to help. Please let us know if you cannot find a time that suits.` }
      </Paragraph>
      <CalEmbed />
    </Modal>
  );
}
