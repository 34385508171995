import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { langauge } from 'shared';
import { When } from 'react-if';

import { Modal } from "../../../common/Atoms/Modal";
import { useEmailModal } from "../../hooks/useEmailModal";
import { LoadingOverlay } from '../Molecules/LoadingOverlay';
import { Notification } from '../../../common/Atoms/Notification';
import { Paragraph } from '../../../common/Atoms/Typography/Paragraph';

export function EmailReportModal() {
  const { isOpen, close, data, isLoading } = useEmailModal();

  const rows = useMemo(() => {
    if (!data || !data.items || !isOpen) {
      return [];
    }

    return data.items.map(i => ({
      ...i,
      actionDate: i.action.executedOn ? new Date(i.action.executedOn) : null,
    }));

  }, [data, isOpen]);

  const content = isOpen ? (
    <DataGridPro
      rows={ rows }
      columns={ [
        { field: `email`, headerName: `Email`, flex: 1 },
        { field: `status`, headerName: `Status`, flex: 1 },
        {
          field: `action`,
          headerName: `Action`,
          flex: 1,
          renderCell: ({ row } ) => (
            <span>{ langauge.action[row.action.type].label }</span>
          ),
          valueFormatter: (value, row) => langauge.action[row.action.type].label,
        },
        { field: `reason`, headerName: `Reason`, flex: 2 },
        { field: `timestamp`, headerName: `Failure Time`, flex: 1, type: `dateTime`, valueGetter: value => new Date(value) },
        {
          field: `actionDate`,
          headerName: `Action Time`,
          flex: 1,
          type: `dateTime`,
        },
      ] }
      slots={ {
        toolbar: CustomToolbar,
      } }
      initialState={ {
        density: `compact`,
      } }
    />
  ) : null;

  return (
    <Modal
      title={ `Email Delivery Report` }
      open={ !!isOpen }
      setOpen={ close }
      width={ `stripe` }
      minHeight={ `400` }
    >
      <Paragraph
        variant={ `secondary` }
        className={ `px-4` }
      >
        { `This report shows the most recent email delivery failures for the last month. (Upto 500)` }
      </Paragraph>
      <LoadingOverlay
        loading={ isLoading }
      >
        <div className={ `mt-6` }>
          <When condition={ data?.warningMessage }>
            <Notification
              type={ `warning` }
              className={ `mb-4` }
            >
              { data?.warningMessage }
            </Notification>
          </When>
          { content }
        </div>
      </LoadingOverlay>
    </Modal>
  );
}

function CustomToolbar() {
  const exportProps ={
    printOptions: {
      disableToolbarButton: true,
    },
    csvOptions: {
      fileName: `email-report.csv`,
    },
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport { ...exportProps } />
    </GridToolbarContainer>
  );
}
