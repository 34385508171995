import { useFetchFilesQuery } from "../services/api/filesApi/files";

import { useGetSelectedOrganisation } from "./useGetSelectedOrganisation";

export function useFiles() {
  const currentOrg = useGetSelectedOrganisation();
  const filesApi = useFetchFilesQuery(currentOrg.id, {
    skip: !currentOrg,
  });

  if (!filesApi.data || !filesApi.data.files) {
    filesApi.data = { files: [], limitUsed: `` };
  }

  return filesApi;
}
