
import { env } from '../../common/lib/env';
import connectQBOButton from '../assets/QuickBooks-connect-button/connect-to-quickbooks-tall-default_2x.png';
import { Card } from '../../common/Atoms/Card';
import { MultiButtonCardHeader } from '../../common/Atoms/MultiButtonCardHeader';
import { Notification } from '../../common/Atoms/Notification';

import { XeroConnectButtonBlue } from './XeroConnectButtonBlue';
import { PendingInvitations } from './Common/PendingInvitations';

export function NoOrganisationsConnected() {

  return (
    <>
      <PendingInvitations showContentOnly={ false } />
      <Card>
        <MultiButtonCardHeader
          title={ `Get started: connect to Xero or QuickBooks` }
          description={ `To get started connect to Xero or QuickBooks by clicking the button below. Don't worry, once you connect Paidnice won't change anything in your accounting system until you are ready.` }
        />

        <div className={ `w-full grid grid-cols-3 mt-8` }>
          <div className={ `col-span-1` }></div>
          <div className={ `col-span-1 flex justify-center` }>
            <a href={ `${env.apiUrl}/connect/xero` }>
              <XeroConnectButtonBlue />
            </a>
            &nbsp;&nbsp;
            <a href={ `${env.apiUrl}/connect/quickbooks` }>
              <img width={ `245px` }
                style={ { width: `245px`, maxWidth: `none` } }
                src={ connectQBOButton } />
            </a>
          </div>
        </div>
        <div
          className={ `flex mt-64 justify-center` }
        >
          <Notification
            className={ `max-w-2xl` }
            type={ `info` }
            title={ `Joining an existing organisation?` }
          >
            { `If someone else has already connected your organisation to Paidnice, you just need to connect the same organisation yourself to gain access.` }
          </Notification>
        </div>
      </Card>
    </>
  );
}
