import { useCallback, useMemo } from "react";
import { DataGridPro, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton, getGridSingleSelectOperators, getGridStringOperators, gridClasses } from "@mui/x-data-grid-pro";
import { CustomerPaymentStatus, currencyFormatter, toDecimal } from "shared";
import { Tooltip } from "@mui/material";

import { Card } from "../../../common/Atoms/Card";
import { useCustomerPayments } from "../../hooks/useCustomerPayments";
import { useGetSelectedOrganisation } from "../../hooks/useGetSelectedOrganisation";
import { getTableHeight } from "../../lib/helper";
import { WrappedLink } from "../WrappedLink";
import { Badge } from "../../../common/Atoms/Badge";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { Heading } from "../../../common/Atoms/Typography/Heading";
import { MultiButtonCardHeader } from "../../../common/Atoms/MultiButtonCardHeader";

const statusOptions = Object.values(CustomerPaymentStatus).map(ps => ({
  label: ps,
  value: ps,
}));

export function CustomerPaymentsRoot() {
  const currentOrg = useGetSelectedOrganisation();
  const { data: customerPayments, isLoading } = useCustomerPayments();

  const rows = useMemo(() => {
    const result = customerPayments?.customerPayments.map(cp => ({
      ...cp,
      invoiceRows: cp.childPayments.reduce((acc, child) => {
        return acc.concat(child.invoicePayments);
      }, []),
    }));

    return result || [];
  }, [customerPayments?.customerPayments]);

  const columns: GridColDef<(typeof rows)[number]>[] = useMemo(() => {
    const r: GridColDef<(typeof rows)[number]>[] = [
      {
        field: `id`,
        headerName: `ID`,
        type: `number`,
      },
      {
        field: `paymentDateTime`,
        headerName: `Timestamp`,
        description: `The time the payment was started or completed at`,
        type: `string`,
        renderCell: ({ row }) => row.paymentDateTime ? (
          <>{ new Date(row.paymentDateTime).toLocaleString() }</>
        ) : <>{ new Date(row.createdAt).toLocaleString() }</>,
      },
      {
        field: `contact`,
        headerName: `Contact`,
        type: `string`,
        minWidth: 150,
        // flex: 0.75,
        filterOperators: getGridStringOperators().filter(op => op.value === `contains`),
        renderCell: ({ row }) =>  (
          <WrappedLink to={ `/contacts/${row.contact.id}` }>
            <span className={ `text-blue-600 underline whitespace-wrap` }>
              { row.contact.name }
            </span>
          </WrappedLink>
        ),
        sortable: false,
      },
      {
        field: `status`,
        headerName: `Status`,
        type: `singleSelect`,
        filterOperators: getGridSingleSelectOperators().filter(op => op.value === `is`),
        valueOptions: statusOptions,
        renderCell: ({ row }) => {
          if (row.status === CustomerPaymentStatus.PAID) {
            return (
              <Badge
                className={ `w-fit` }
                color={ `green` }
              >
                { `Paid` }
              </Badge>
            );
          }

          if (row.status === CustomerPaymentStatus.NO_PAYMENT_REQUIRED) {
            return (
              <Badge
                className={ `w-fit` }
                color={ `blue` }
              >
                { `No Payment Required` }
              </Badge>
            );
          }

          if (row.status === CustomerPaymentStatus.UNPAID) {
            return (
              <Tooltip title={ `The contact started a checkout but has not completed` }>
                <Badge
                  className={ `w-fit` }
                  color={ `red` }
                >
                  { `Started` }
                </Badge>
              </Tooltip>
            );
          }
        },
      },
      {
        field: `originalCurrency`,
        headerName: `Inv Currency`,
        description: `The currency the invoice was issued in`,
        type: `string`,
        maxWidth: 90,
      },
      {
        field: `transactionCurrency`,
        headerName: `TX Currency`,
        type: `string`,
        maxWidth: 90,
        description: `The currency Stripe converted the amounts to.`,
      },
      {
        field: `totalAmountCentsOriginalCurrency`,
        headerName: `Total Amount Inv Currency`,
        type: `number`,
        renderCell: ({ row }) => (
          <span>
            { currencyFormatter(row.originalCurrency)(toDecimal(row.totalAmountCentsOriginalCurrency)) }
          </span>
        ),
      },
      {
        field: `totalAmountCentsTransactionCurrency`,
        headerName: `Total Amount TX Currency`,
        type: `number`,
        renderCell: ({ row }) => (
          <span>
            { currencyFormatter(row.transactionCurrency)(toDecimal(row.totalAmountCentsTransactionCurrency)) }
          </span>
        ),
      },
      {
        field: `surchargeAmountCentsOriginalCurrency`,
        headerName: `Surcharge Revenue`,
        type: `number`,
        renderCell: ({ row }) => (
          <span>
            { currencyFormatter(row.originalCurrency)(toDecimal(row.surchargeAmountCentsOriginalCurrency)) }
          </span>
        ),
      },
      {
        field: `stripeFeeCentsTransactionCurrency`,
        headerName: `Stripe Fee`,
        type: `number`,
        renderCell: ({ row }) => (
          <span>
            { `(` }
            { currencyFormatter(row.transactionCurrency)(toDecimal(row.stripeFeeCentsTransactionCurrency)) }
            { `)` }
          </span>
        ),
      },
      {
        field: `stripePaymentIntentId`,
        headerName: `Stripe Payment`,
        type: `string`,
        width: 150,
        description: `Links to payment within your Stripe dashboard. Ensure you have logged into the correct account for links to work.`,
        renderCell: ({ row }) => row.stripePaymentIntentId ? (
          <a
            href={ `https://dashboard.stripe.com/payments/${row.stripePaymentIntentId}` }
            target={ `_blank` }
            rel={ `noreferrer noopener` }
          >
            <Paragraph
              variant={ `link` }
            >
              { `View In Stripe` }
            </Paragraph>
          </a>
        ) : `N/A`,
      },

    ];

    // Apply some duplicates
    return r.map(c => ({
      ...c,
      editable: false,
      headerClassName: `text-md text-gray-800 font-bold`,
    }));
  }, [rows]);

  const rowDetail = useCallback(({ row }: {row: (typeof rows)[number]}) => {
    if (!row.invoiceRows.length) {
      return null;
    }

    return (
      <div className={ `mb-2` }>
        <Heading
          size={ `sm` }
          className={ `border-b border-gray-300 mb-2` }
        >
          { `Invoices` }
        </Heading>
        {
          row.invoiceRows.map(inv => (
            <div
              key={ inv.id }
              className={ `ml-6 grid grid-cols-2 gap-32 border-b border-gray-200 p-1 w-fit` }
            >
              <Paragraph
                variant={ `link` }
                className={ `font-bold` }
              >
                <WrappedLink
                  to={ `/invoices/${inv.invoiceId}` }
                >
                  { inv.invoice.number }
                </WrappedLink>
              </Paragraph>

              <Paragraph>
                { currencyFormatter(row.originalCurrency)(toDecimal(inv.amountCents)) }
              </Paragraph>
            </div>
          ))
        }
      </div>
    );
  }, [rows]);

  if (!currentOrg?.stripeAccount) {
    // No stripe account, disable

    return null;
  }

  const heightClass = getTableHeight(rows?.length);

  return (
    <Card>
      <MultiButtonCardHeader
        title={ `Stripe Payments` }
        description= { `Payments made by customers using Stripe via Paidnice. Expand the row to see which invoices payment was applied against.` }
        secondaryDescription={ `Only Stripe payments made on the Paidnice portal are shown here. Payments made directly on other platforms are not included.` }
        badge={ `Early Access` }
        badgeColor={ `purple` }
        badgeTooltip={ `You have early access to integrated Stripe payments. Please let us know if there is anything we can improve on!` }
      />
      <div className={ heightClass + ` m-4` } >
        <DataGridPro
          sx={ {
            border: `none`,
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          } }

          rows={ rows }
          autosizeOnMount
          autosizeOptions={ {
            expand: true,
          } }
          getRowHeight={ () => `auto` }
          columns={ columns }
          loading={ isLoading }
          pageSizeOptions={ [15, 20, 50, 100, 300] }
          disableDensitySelector
          // Pagination
          pagination
          paginationMode={ `client` }
          // Filter
          filterDebounceMs={ 300 }
          filterMode={ `client` }

          // Sort
          sortingMode={ `client` }
          // Expanded rows for invoice detail
          getDetailPanelContent={ rowDetail }
          getDetailPanelHeight={ () => `auto` }
          // Slots
          slots={ {
            toolbar: CustomToolbar,

          } }
          slotProps={ {
            pagination: {
              showFirstButton: true,
              showLastButton: true,
            },
          } }
        />
      </div>
    </Card>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      { /* <div className={ `flex justify-between w-full` }> */ }
      <GridToolbarFilterButton />

      <GridToolbarColumnsButton />

      { /* <GridToolbarExport
          slotProps={ {
            tooltip: { title: `Export data` },
            button: { variant: `outlined` },
          } }
        /> */ }
      { /* </div> */ }
    </GridToolbarContainer>
  );
}
