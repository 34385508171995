import { createSlice } from '@reduxjs/toolkit';

export const initialState: {
  actionId: null | string,
  open: boolean
} = {
  actionId: null,
  open: false,
};

const actionDrawerSlice = createSlice({
  name: `actionDrawer`,
  initialState,
  reducers: {
    closeAndClear(state) {
      state.open = false;
      state.actionId = null;
    },
    open(state, action) {
      state.open = true;
      state.actionId = action.payload;
    },
  },
});

export const { closeAndClear, open } = actionDrawerSlice.actions;

export const actionDrawerReducer = actionDrawerSlice.reducer;
