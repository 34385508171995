import { ICustomerPaymentResponse } from 'shared';

import { CacheTags } from '../../types/cacheTags';
import { emptySplitApi } from '../baseApi';

export const customerPaymentsApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchCustomerPayments: builder.query<ICustomerPaymentResponse, { organisationId: string }>({
      query: ({ organisationId }) => ({
        url: `customerPayments`,
        method: `GET`,
        headers: {
          [`X-Organisation-Id`]: organisationId,
        },
      }),
      providesTags: [
        { type: CacheTags.CustomerPayments, id: `LIST` },
      ],
    }),
  }),
});

export const {
  useFetchCustomerPaymentsQuery,
} = customerPaymentsApi;
