import { policyConfig } from "shared";
import { useMemo } from "react";

import { Select } from "../../../../../../common/Atoms/Select";
import { useAccountingResources } from "../../../../../hooks/useAccountingResources";
import { fieldIsValid } from "../../../../../lib/helper";

import { IFieldProps } from "./fieldProps";

export function TrackingCategoryClass({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {
  const { data: resources, isLoading, isFetching } = useAccountingResources();
  const label = policyConfig.tracking_category_class.label;

  const options = useMemo(() => {
    if (!resources?.trackingCategoryClasses) {
      return [];
    }

    return resources.trackingCategoryClasses.map(tc => ({
      label: tc.label,
      value: tc.value,
    }));
  }, [resources?.trackingCategoryClasses]);

  return (
    <Select
      nullable
      emptyText={ `None` }
      name={ `tracking_category_class` }
      description={ `When issuing a late fee. Use this tracking category/class` }
      label={ label }
      options={ options }
      onChange={ e => handleChange({ value: e.value, name: e.name }) }
      selected={ policyFormData.tracking_category_class }
      invalid={ fieldIsValid(`tracking_category_class`, invalids) }
      loading={ isLoading || isFetching }
    />
  );
}
