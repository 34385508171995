import { FormLabel } from "./FormLabel";

interface TextAreaProps {
  label?: string;
  value: string | number;
  name?: string;
  onChange: (e: { value: string | number; name: string }) => void;
  placeholder?: string;
  className?: string;
  required?: boolean;
  rows?: number;
}

export function TextArea({
  label,
  value,
  name,
  onChange,
  placeholder,
  className,
  required,
  rows = 3,
}: TextAreaProps) {
  function handleChange(e) {
    const value = e.target.value;

    return onChange({
      value,
      name,
    });
  }

  return (
    <div className={ className }>
      <FormLabel name={ name }>
        { label }
      </FormLabel>
      <div className={ `mt-2 rounded-md shadow-sm` }>
        <textarea
          rows={ rows }
          name={ name }
          id={ name }
          className={ `block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lateGreen-600 sm:text-sm sm:leading-6` }
          placeholder={ placeholder }
          required={ required }
          value={ value }
          onChange={ handleChange }
        />
      </div>
    </div>
  );
}
