import React, { Fragment } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { When } from 'react-if';

import { classNames } from '../lib/classNames';

interface DrawerProps {
  title?: string
  open: boolean
  setOpen: (open: boolean) => void
  children: React.ReactNode
  size?: `sm` | `md` | `lg` | `xl`;
  className?: string;
  closeButton?: boolean;
}

const widths = {
  sm: `max-w-md`,
  md: `max-w-2xl`,
  lg: `max-w-3xl`,
  xl: `max-w-4xl`,
};

export function Drawer({
  title,
  open,
  setOpen,
  children,
  size = `md`,
  className,
  closeButton = true,
}: DrawerProps) {
  return (
    <Transition
      show={ open }
      as={ Fragment }>
      <Dialog as={ `div` }
        className={ `relative z-10` }
        onClose={ setOpen }
      >
        <div className={ `fixed inset-0` } />

        <div className={ `fixed inset-0 overflow-hidden` }>
          <div className={ `absolute inset-0 overflow-hidden` }>
            <div className={ `pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16` }>
              <TransitionChild
                as={ Fragment }
                enter={ `transform transition ease-in-out duration-500 sm:duration-700` }
                enterFrom={ `translate-x-full` }
                enterTo={ `translate-x-0` }
                leave={ `transform transition ease-in-out duration-500 sm:duration-700` }
                leaveFrom={ `translate-x-0` }
                leaveTo={ `translate-x-full` }
              >
                <DialogPanel className={
                  classNames(
                    `pointer-events-auto w-screen`,
                    widths[size],
                  )
                }>
                  <div className={
                    classNames(
                      `flex h-full flex-col overflow-y-scroll py-6 shadow-xl bg-white`,
                      className,
                    )
                  }>
                    <div className={ `px-4 sm:px-6` }>
                      <div className={ `flex items-start justify-between` }>
                        <DialogTitle className={ `text-lg font-medium text-gray-900` }>{ title }</DialogTitle>
                        <When condition={ closeButton }>
                          <div className={ `ml-3 flex h-7 items-center` }>
                            <button
                              type={ `button` }
                              className={ `rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lateGreen-500 focus:ring-offset-2` }
                              onClick={ () => setOpen(false) }
                            >
                              <span className={ `sr-only` }>{ `Close panel` }</span>
                              <XMarkIcon className={ `h-6 w-6` }
                                aria-hidden={ `true` } />
                            </button>
                          </div>
                        </When>
                      </div>
                    </div>
                    <div className={ `relative mt-6 flex-1 px-4 sm:px-6` }>
                      { children }
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
