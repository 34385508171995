import { policyConfig } from "shared";

import { fieldIsValid } from "../../../../../lib/helper";
import { CurrencyInput } from "../../../../../../common/Atoms/CurrencyInput";

import { IFieldProps } from "./fieldProps";

const config = policyConfig.min_fee_amount;

export function MinimumFeeAmount({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {
  const description = `When the total interest/fee amount is less than this, round up to this amount.`;

  return (
    <CurrencyInput
      label={ config.label }
      description={ description }
      value={ policyFormData.min_fee_amount }
      onChange={ handleChange }
      name={ `min_fee_amount` }
      invalid={ fieldIsValid(`min_fee_amount`, invalids) }
    />
  );
}
