import { ISenderDetail, currencyFormatter, toDecimal } from "shared";
import { When } from "react-if";
import { Tooltip } from "@mui/material";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import { DateTime } from "luxon";

import { Button } from "../../Atoms/Button";
import { Heading } from "../../Atoms/Typography/Heading";
import { classNames } from "../../lib/classNames";
import { Paragraph } from "../../Atoms/Typography/Paragraph";
import { useInvoiceDetails } from "../../hooks/useInvoiceDetails";
import { DatePopover } from "../DatePopover";

import { XeroStatusBadge } from "./XeroStatusBadge";
import { InvoiceContact } from "./InvoiceContact";
import { InvoiceSenderDetail } from "./InvoiceSenderDetail";
import { InvoiceLineItems } from "./InvoiceLineItems";

interface IInvoiceDocumentProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  invoice: any; //todo with invoice API
  onResync?: () => void;
  resyncLoading?: boolean;
  mode: `app` | `portal`;
  senderDetail: ISenderDetail;
}

export function InvoiceDocumentCommon({
  invoice,
  senderDetail,
  onResync,
  resyncLoading,
  mode,
}: IInvoiceDocumentProps) {
  const formatter = currencyFormatter(invoice?.xero_currency);

  const invoiceDetails = useInvoiceDetails(invoice);

  function renderCreditNotes() {
    if (!invoiceDetails) return null;

    return invoiceDetails.creditNotes.map(creditNote => {
      return (
        <tr key={ creditNote.creditNoteExternalId }>
          <th scope={ `row` }
            className={ `px-0 pb-0 pt-6 font-normal text-gray-700 sm:hidden` }>
            { `Minus Credit Note: ${creditNote.creditNoteNumber}` }
          </th>
          <th
            scope={ `row` }
            colSpan={ 3 }
            className={ `hidden px-0 pb-0 pt-6 text-right font-normal text-gray-700 sm:table-cell` }
          >
            { `Minus Credit Note: ${creditNote.creditNoteNumber}` }
          </th>
          <td className={ `pb-0 pl-8 pr-0 pt-6 text-right tabular-nums text-gray-900` }>
            { `- ` }{ formatter(toDecimal(creditNote.appliedAmountInCents)) }
          </td>
        </tr>
      );
    });
  }

  if (!invoiceDetails) return null;

  return (
    <div className={
      classNames(
        `-mx-4 px-4 py-8 sm:mx-0 sm:px-8 sm:pb-14 xl:px-16 xl:pb-20 xl:pt-16`,
      )
    }>
      <div className={ `flex justify-between` }>
        <div className={ `flex` }>
          <When condition={ mode === `app` }>
            <Heading>
              { invoiceDetails.number ? `Invoice #${invoiceDetails.number}` : `Invoice` }
            </Heading>
            <Tooltip title={ `Force a resync of this invoice with your accounting software.` }>
              <Button
                className={ `ml-2` }
                color={ `gray` }
                isIcon
                onClick={ onResync }
                loading={ resyncLoading }
              >
                <When condition={ !resyncLoading }>
                  <ArrowPathIcon className={ `h-4 w-4 ` }
                    aria-hidden={ `true` }
                  />
                </When>
              </Button>
            </Tooltip>
          </When>
        </div>
        <XeroStatusBadge
          status={ invoiceDetails.status }
          mode={ mode }
        />

      </div>

      <When condition={ invoiceDetails.reference.length < 50 }>
        <Paragraph
          as={ `h3` }
        >
          { `${invoiceDetails.reference}` }
        </Paragraph>
      </When>
      <dl className={ `mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2` }>
        <div className={ `sm:pr-4` }>
          <dt className={ `inline text-gray-500` }>{ `Issued on` }</dt>{ ` ` }
          <dd className={ `inline text-gray-700` }>
            <time>
              <DatePopover
                date={ invoiceDetails.issueDateString }
                organisationTimezone={ invoiceDetails.organisationTimezone }
                labelFormatPreset={ DateTime.DATE_HUGE }
              />
            </time>
          </dd>
        </div>
        <div className={ `mt-2 sm:mt-0 sm:pl-4` }>
          <dt className={ `inline text-gray-500` }>{ `Due on` }</dt>{ ` ` }
          <dd className={ `inline text-gray-700` }>
            <time>
              <DatePopover
                date={ invoiceDetails.dueDateString }
                organisationTimezone={ invoiceDetails.organisationTimezone }
                time={ `end` }
                labelFormatPreset={ DateTime.DATE_HUGE }
              />
            </time>
          </dd>
        </div>
        <div className={ `mt-6 border-t border-gray-900/5 pt-6 sm:pr-4` }>
          <InvoiceSenderDetail
            senderDetail={ senderDetail }
          />
        </div>
        <InvoiceContact
          invoiceDetails={ invoiceDetails }
          mode={ mode }
        />
      </dl>
      <table className={ `mt-16 w-full whitespace-nowrap text-left text-sm leading-6` }>
        <InvoiceLineItems lineItems={ invoiceDetails.lineItems } />
        <tfoot>
          <tr>
            <th scope={ `row` }
              className={ `px-0 pb-0 pt-6 font-normal text-gray-700 sm:hidden` }>
              { `Subtotal` }
            </th>
            <th
              scope={ `row` }
              colSpan={ 3 }
              className={ `hidden px-0 pb-0 pt-6 text-right font-normal text-gray-700 sm:table-cell` }
            >
              { `Subtotal` }
            </th>
            <td className={ `pb-0 pl-8 pr-0 pt-6 text-right tabular-nums text-gray-900` }>
              { invoiceDetails.subTotal }
            </td>
          </tr>
          <tr>
            <th scope={ `row` }
              className={ `pt-4 font-normal text-gray-700 sm:hidden` }>
              { `Tax` }
            </th>
            <th
              scope={ `row` }
              colSpan={ 3 }
              className={ `hidden pt-4 text-right font-normal text-gray-700 sm:table-cell` }
            >
              { `Tax` }
            </th>
            <td className={ `pb-0 pl-8 pr-0 pt-4 text-right tabular-nums text-gray-900` }>
              { invoiceDetails.tax }
            </td>
          </tr>
          <When condition={ invoice.total_discount_cents > 0 }>
            <tr>
              <th scope={ `row` }
                className={ `pt-4 font-normal text-gray-700 sm:hidden` }>
                { `Discounts` }
              </th>
              <th
                scope={ `row` }
                colSpan={ 3 }
                className={ `hidden pt-4 text-right font-normal text-gray-700 sm:table-cell` }
              >
                { `Discounts` }
              </th>
              <td className={ `pb-0 pl-8 pr-0 pt-4 text-right tabular-nums text-gray-900` }>
                { invoiceDetails.discounts }
              </td>
            </tr>
          </When>
          <tr>
            <th scope={ `row` }
              className={ `pt-4 font-semibold text-gray-900 sm:hidden` }>
              { `Total` }
            </th>
            <th
              scope={ `row` }
              colSpan={ 3 }
              className={ `hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell` }
            >
              { `Total` }
            </th>
            <td className={ `pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums text-gray-900` }>
              { invoiceDetails.total }
            </td>
          </tr>

          <tr>
            <th scope={ `row` }
              className={ `pt-4 font-normal text-gray-700 sm:hidden` }>
              { `Amount Paid` }
            </th>
            <th
              scope={ `row` }
              colSpan={ 3 }
              className={ `hidden pt-4 text-right font-normal text-gray-700 sm:table-cell` }
            >
              { `Amount Paid` }
            </th>
            <td className={ `pb-0 pl-8 pr-0 pt-4 text-right tabular-nums text-gray-900` }>
              { invoice.total_paid_cents > 0 ? `- ` : `` }{ invoiceDetails.paidAmount }
            </td>
          </tr>

          { renderCreditNotes() }

          <tr>
            <th scope={ `row` }
              className={ `pt-4 font-semibold text-gray-900 sm:hidden` }>
              { `Total Due` }
            </th>
            <th
              scope={ `row` }
              colSpan={ 3 }
              className={ `hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell` }
            >
              { `Total Due` }
            </th>
            <td className={ `pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums text-gray-900` }>
              { invoiceDetails.dueAmount }
              { invoiceDetails.currency }
            </td>
          </tr>
        </tfoot>
      </table>
      <When condition={ invoiceDetails.reference.length >= 50 }>
        <Paragraph>
          { `Invoice memo: ` }
          <br/>
          { `${invoiceDetails.reference}` }
        </Paragraph>
      </When>
    </div>
  );
}

