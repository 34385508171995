import { ActionStatus, ActionType, IActionResponseItem } from "shared";
import { useSelector } from "react-redux";
import { Parser } from "html-to-react";

import { useGetSelectedOrganisation } from "../../hooks/useGetSelectedOrganisation";
import { usePreviewActionQuery } from "../../services/api/actionApi/action";
import { RootState } from "../../store";
import { LoadingOverlay } from "../Molecules/LoadingOverlay";
import { Card } from "../../../common/Atoms/Card";
import { CustomIframe } from "../IFrame";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";

interface ActionPreviewSectionProps {
  action?: IActionResponseItem;
}

const htmlParser = Parser();

export function ActionPreviewSection({
  action,
}: ActionPreviewSectionProps) {
  const drawerProps = useSelector((s: RootState) => s.actionDrawer);

  const currentOrg = useGetSelectedOrganisation();

  const { data: actionPreviewData, isLoading, isUninitialized } = usePreviewActionQuery({
    actionId: drawerProps.actionId,
    organisationId: currentOrg?.id,
  }, {
    // TODO use action config to determine if we should skip
    skip: (
      !drawerProps.open ||
      !drawerProps.actionId ||
      !currentOrg ||
      (action?.status !== ActionStatus.PENDING && action?.status !== ActionStatus.APPROVAL_PENDING) ||
      action?.type !== ActionType.REMINDER
    ),
  });

  if (isUninitialized) {
    // Dont display anything if we are uninitialized / not applicable
    return null;
  }

  return (
    <LoadingOverlay
      loading={ isLoading }
    >
      <div className={ `min-h-[100px] mt-4` }>
        <Paragraph
          className={ `pb-2 ml-1` }
        >
          { `Email Preview` }
        </Paragraph>
        <Card className={ `rounded-md shadow-xl border-gray-100 border` }>
          <CustomIframe
            style={ {
              width: `100%`,
              height: `100%`,
              minHeight: `400px`,
            } }
          >
            { htmlParser.parse(actionPreviewData?.html || ``) }
          </CustomIframe>
        </Card>
      </div>
    </LoadingOverlay>
  );
}
