import { initializeApp } from 'firebase/app';

import { env } from '../../common/lib/env';

let fbApp;

if (env.environment === `production` && !env.isCypress) {
  // Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: `AIzaSyBGa_2diiYTsBInSNP1NmV7sC9uYBYhZqw`,
    authDomain: `latechaser-production.firebaseapp.com`,
    projectId: `latechaser-production`,
    storageBucket: `latechaser-production.appspot.com`,
    messagingSenderId: `897677138501`,
    appId: `1:897677138501:web:df3f117c32089a28b3212c`,
    measurementId: `G-9084J2EKDR`,
  };

  // Initialize Firebase
  fbApp = initializeApp(firebaseConfig);
}

if (env.environment !== `production` && !env.isCypress) {
  const firebaseConfig = {
    apiKey: `AIzaSyDQK11AllEkXNZEStKs1NSh4pvNSVX2DTc`,
    authDomain: `latechaser-staging.firebaseapp.com`,
    projectId: `latechaser-staging`,
    storageBucket: `latechaser-staging.appspot.com`,
    messagingSenderId: `946149842430`,
    appId: `1:946149842430:web:6c197ca634670d2e3774af`,
    measurementId: `G-DDZ9GX8JMB`,
  };

  // Initialize Firebase
  fbApp = initializeApp(firebaseConfig);
}

export {
  fbApp,
};
