import { fieldIsValid } from "../../../../../lib/helper";
import { DatePicker } from "../../../../../../common/Atoms/DatePicker";

import { IFieldProps } from "./fieldProps";

export function ApplyInvoicesFrom({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {
  return (
    <DatePicker
      className={ `min-w-full` }
      label={ `Applies to invoices due from` }
      onChange={ handleChange }
      name={ `apply_after_due_date_string` }
      invalid={ fieldIsValid(`apply_after_due_date_string`, invalids) }
      value={
        policyFormData.apply_after_due_date_string === null
          ? ``
          : policyFormData.apply_after_due_date_string
      }
    />
  );
}
