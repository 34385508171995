// To quickly assign a policy to a group
import { useMemo } from "react";
import { IPolicyResponseItem } from "shared";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { useParams } from "react-router";
import { isBefore } from "date-fns";

import { useGroups } from "../../../../hooks/useGroups";
import { MultiSelect } from "../../../../../common/Atoms/MultiSelect";
import { useGroupsForPolicy } from "../../../../hooks/useGroupsForPolicy";
import { useGetSelectedOrganisation } from "../../../../hooks/useGetSelectedOrganisation";

interface IGroupSelectorProps {
  policyFormData: IPolicyResponseItem;
  handleChange: (obj: { name: string; value: string | number | boolean | Date | number[] }, isGroupsChange?: boolean) => void;
}

export const NEW_UI_DATE = new Date(`2024-05-21`);

export function GroupSelector({
  policyFormData,
  handleChange,
}: IGroupSelectorProps) {
  const { data: groups } = useGroups();
  const { groupId } = useParams<{ groupId: string }>();
  const currentOrg = useGetSelectedOrganisation();

  const selectedGroups = useGroupsForPolicy(policyFormData);

  const options = useMemo(() => {
    return [...groups]
      .sort((a, b) => b.default ? 1 : -1)
      .map(g => ({
        label: g.title,
        value: g.id,
      }));
  }, [groups]);

  function onChange(value: number[]) {
    const groupIds = value;

    handleChange({
      name: `groupIds`,
      value: groupIds,
    }, true);
  }

  const shouldRender = useMemo(() => {
    if (policyFormData.policy?.system) {
      // Dont render when editing a system policy
      return false;
    }

    if (!groupId || policyFormData.groupIds?.length > 1) {
      return true;
    }

    if (
      currentOrg?.legacyUIUser &&
      policyFormData.policy.created_at &&
      isBefore(new Date(policyFormData.policy.created_at), NEW_UI_DATE)
    ) {
      // Legacy org with old policy, always render the dropdown
      return true;
    }

    const valueToSet = Number(groupId);

    if (policyFormData.groupIds && (
      policyFormData.groupIds.length !== 1 || !policyFormData.groupIds.includes(valueToSet)
    )) {
      handleChange({
        name: `groupIds`,
        value: [valueToSet],
      }, true);
    }

    return false;

  }, [groups, selectedGroups, groupId, policyFormData]);

  let displayValue:string | React.ReactNode = (<span>
    <ExclamationCircleIcon
      className={ `inline-block w-6 h-6 mr-2 text-orange-500` }
    />
    {
      `No groups selected`
    }
  </span>
  );

  if (selectedGroups.length === 1) {
    displayValue = selectedGroups[0].title;
  }

  if (selectedGroups.length > 1) {
    displayValue = `${selectedGroups[0].title} + ${selectedGroups.length - 1} more`;
  }

  if (!shouldRender) return null;

  return (
    <div
      className={ `bg-gray-50 p-4 shadow-md rounded-lg mb-6` }
      data-tour={ `policy-form-groups` }
    >
      <MultiSelect
        label={ `Assigned to Groups` }
        helpIcon={
          `Policies shared between groups will soon be removed.`
        }
        description={ `Does this policy apply to more than one group?` }
        options={ options }
        selected={ selectedGroups.map(g => g.id) }
        onChange={ onChange }
        display={ displayValue }
        warning={ selectedGroups.length === 0 ? `This policy is not attached to any groups.` : null }
      />
    </div>
  );
}
