import { IUpdateSettingsRequest } from "shared";
import { When } from "react-if";

import { OnChangeFn } from "../Main/GeneralSettings";
import { TwoColFormRow } from "../../../Molecules/Form/TwoColFormRow";
import { Toggle } from "../../../../../common/Atoms/Toggle";
import { useGetSelectedOrganisation } from "../../../../hooks/useGetSelectedOrganisation";

interface DefaultBehaviourProps {
  formData: IUpdateSettingsRequest;
  onChange: OnChangeFn;
}

export function DefaultBehaviour({ formData, onChange }: DefaultBehaviourProps) {
  const currentOrg = useGetSelectedOrganisation();

  function handleChangeBoolean({ name }) {
    onChange(name, !formData[name]);
  }

  const updateSentStatusText = currentOrg.accountingSystemName === `Xero` ?
    `When enabled, new late fee invoices will automatically be marked as sent in Xero, once the late fee email has been sent` :
    `When enabled, new late fee invoices will automatically be marked as sent Quickbooks, once the late fee email has been sent`;

  return (
    <TwoColFormRow
      heading={ `Default Behaviour` }
      description={ `Control some of the common default behaviours of Paidnice. These settings apply to all groups & policies within this organisation.` }
    >
      <div className={ `space-y-4` }>
        { /* TODO QBO  */ }
        <When condition={ currentOrg.accountingSystemName === `Xero` }>
          <Toggle
            label={ `Mark late fee invoices as sent` }
            checked={ formData.setSendFlagLateFee || false }
            name={ `setSendFlagLateFee` }
            description={ updateSentStatusText }
            onChange={ handleChangeBoolean }
            helpIcon={ `If the invoice is Draft, or no late fee email is sent. The invoice will not be marked as sent.` }
          />
        </When>

        <Toggle
          label={ `Autofreeze invoices` }
          name={ `autofreezeInvoice` }
          description={ `When enabled, Paidnice will automatically automatically freeze invoices to avoid policies applying unexpectedly. ` }
          helpIcon={ ` Enabling this option will freeze invoices if they have no applicable policies (whitelisted) or if they have a payment reversed. Frozen invoices will need to be manually unfrozen before invoice policies apply again.` }
          checked={ formData.autofreezeInvoice || false }
          onChange={ handleChangeBoolean }
        />

        <Toggle
          label={ `Freeze until expected payment date` }
          name={ `useExpectedPaymentDate` }
          description={ `When enabled, invoices that have an expected payment date in the future will not have any policies applied to them until that date.` }
          helpIcon={ `Works similar to freezing an invoice until a certain date.` }
          checked={ formData.useExpectedPaymentDate || false }
          onChange={ handleChangeBoolean }
        />

        <Toggle
          label={ `Only send to primary contact` }
          name={ `onlySendPrimaryContact` }
          description={ `When enabled, Paidnice will only send emails to the primary contact.` }
          helpIcon={ `If the primary contact has no email, but other contact persons do. Nothing will be sent.` }
          checked={ formData.onlySendPrimaryContact || false }
          onChange={ handleChangeBoolean }
        />

        <Toggle
          label={ `Sync notes after each action` }
          name={ `syncNotesOnAction` }
          description={ `When enabled, Paidnice will sync a note to your accounting system for each action taken.` }
          helpIcon={ `This creates a record of actions taken by Paidnice in your accounting system on each invoice or contact.` }
          checked={ formData.syncNotesOnAction || false }
          onChange={ handleChangeBoolean }
        />
      </div>
    </TwoColFormRow>
  );
}
