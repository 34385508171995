import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { closeEmailModal, openEmailModal } from "../slices/emailReportModal";
import { RootState } from "../store";
import { useFetchEmailReportQuery } from "../services/api/emailReportApi/emailReport";

export function useEmailModal() {
  const dispatch = useDispatch();
  const { organisationId } = useParams<{ organisationId: string }>();
  const state = useSelector((s: RootState) => s.emailReportModal);
  const apiResult = useFetchEmailReportQuery({ organisationId }, {
    skip: !state.isOpen || !organisationId,
  });

  return {
    ...apiResult,
    open: () => dispatch(openEmailModal()),
    close: () => dispatch(closeEmailModal()),
    isOpen: state.isOpen,
  };
}
