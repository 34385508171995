import { IAuditLogResponseItem } from "shared";

import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";

interface ChangeDetailProps {
  entry: IAuditLogResponseItem;
}

export function ChangeDetail({ entry }: ChangeDetailProps) {
  if (!entry.changeDetail?.length) {
    return (
      <div>
        <Paragraph
          variant={ `secondary` }
        >
          { `No changes to show` }
        </Paragraph>
      </div>
    );
  }

  return (
    <div>
      {
        entry.changeDetail.map((change, i) => {
          const old = (change.oldValue === undefined || change.oldValue === null) ? `Not Set` : change.oldValue;

          return (
            <div
              key={ i }
            >
              <Paragraph
                variant={ `secondary` }
              >
                { `${change.key}: ${old} -> ${change.newValue}` }
              </Paragraph>
            </div>
          );
        })
      }
    </div>
  );
}
