import React from 'react';

import { lateGreen700 } from '../../constants/color';
import { classNames }  from '../../../common/lib/classNames';

/** @deprecated use <SmallSpinner/> instead */
export const Spinner = props => {
  return (
    <div className={
      classNames(
        `flex justify-center m-auto col-auto min-h-full items-center flex-col`,
        props.center && `center-spinner`,
        props.className,
      )
    }>
      <svg width={ props.size || `200px` }
        height={ props.size || `200px` }
        xmlns={ `http://www.w3.org/2000/svg` }
        viewBox={ `0 0 100 100` }
        preserveAspectRatio={ `xMidYMid` }
        className={ `lds-eclipse` }
        style={ { background: `none` } }>
        <path stroke={ `none` }
          d={ `M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50` }
          fill={ props.hex || lateGreen700 }
          transform={ `rotate(42 50 51)` }>
          <animateTransform attributeName={ `transform` }
            type={ `rotate` }
            calcMode={ `linear` }
            values={ `0 50 51;360 50 51` }
            keyTimes={ `0;1` }
            dur={ `1s` }
            begin={ `0s` }
            repeatCount={ `indefinite` }>
          </animateTransform>
        </path>
      </svg>
      <p>{ props.message }</p>
    </div>
  );
};
