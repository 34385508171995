import { IInvalidField } from "shared";
import { useMemo, useState } from "react";
import { When } from "react-if";

import { ComboBox } from "../../../../../common/Atoms/ComboBox";
import { useAccountingResources } from "../../../../hooks/useAccountingResources";
import { FormDescription } from "../../../../../common/Atoms/FormDescription";
import { useDebounce } from "../../../../hooks/useDebounce";

interface AccountSelectorProps {
  label?: string;
  value?: string | null;
  onChange: (e: { value: string; name: string }) => void;
  name: string;
  invalid?: IInvalidField;
  description?: string;
  type?: `bank` | `income` | `expense`;
  helpIcon?: string;
  /** Is the value the account code or external account ID */
  valueKey?: `code` | `id`;
  /** Filter for this currency */
  onlyCurrency?: string;
}

export function AccountSelector({
  label,
  value,
  onChange,
  name,
  invalid,
  description,
  type = `income`,
  valueKey = `code`,
  helpIcon,
  onlyCurrency,
}: AccountSelectorProps) {

  // More than this many options and we don't render them all in the dropdown
  // the user needs to type a search first to reduce the list of options
  const MAX_OPTIONS_FOR_NORMAL_MODE = 500;

  const { data: accountingResources, isLoading } = useAccountingResources();
  const [search, setSearch] = useState(``);

  const debouncedQuery = useDebounce(search, 500);

  const accounts = isLoading ? [] : (accountingResources?.accounts || []);

  const selected = useMemo(() => {
    const found = accounts.find(account => account[valueKey] === value);

    if (!found) return null;

    return {
      label: found.label,
      value: found[valueKey],
    };
  }, [accounts, value, valueKey]);

  const options = useMemo(() => {
    return accounts.filter(account => {
      if (type !== account.type) return false;

      if (onlyCurrency && account.currencyCode.toUpperCase() !== onlyCurrency.toUpperCase()) return false;

      return (
        account.label.toLowerCase().includes(debouncedQuery.toLowerCase()) ||
        account[valueKey].toLowerCase().includes(debouncedQuery.toLowerCase())
      );
    })
      .map(account => ({
        label: account.label,
        value: account[valueKey],
      }))
    ;
  }, [accounts, debouncedQuery, onlyCurrency]);

  function handleChange(e) {
    return onChange({
      value: e?.value,
      name,
    });
  }

  // Only render the text box until the options are low enough - i.e they have entered a search query that finds fewer options
  if (options && options.length > MAX_OPTIONS_FOR_NORMAL_MODE) {

    return (
      <div className={ `flex flex-col` }>
        <ComboBox
          label={ label }
          options={ [] } // empty array is rendered until we have a more manageable list of results
          selected={ selected || null }
          query={ search }
          onQueryChange={ e => setSearch(e.target.value) }
          onSelect={ handleChange }
          invalid={ invalid }
          loading={ isLoading }
          placeholder={ `Search...` }
          helpIcon={ helpIcon }
          hideChevron={ true }
        />

        <When condition={ description }>
          <FormDescription>
            { description }
          </FormDescription>
        </When>
      </div>
    );
  }

  return (
    <>
      <ComboBox
        label={ label }
        options={ options }
        selected={ selected || null }
        query={ search }
        onQueryChange={ e => setSearch(e.target.value) }
        onSelect={ handleChange }
        invalid={ invalid }
        loading={ isLoading }
        placeholder={ `Search` }
        helpIcon={ helpIcon }
      />

      <When condition={ description }>
        <FormDescription>
          { description }
        </FormDescription>
      </When>
    </>

  );
}
