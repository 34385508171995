import { Fragment, useCallback, useMemo } from 'react';

import { Button } from '../../../common/Atoms/Button';
import { Dropdown } from '../../../common/Atoms/Dropdown';

const modeMap = {
  email: [`bold`, `italic`, `heading`, `align`, `mergeTags`, `links`, `divider`, `break`],
  sms: [`mergeTags`],
  signature: [`bold`, `italic`, `heading`, `align`, `divider`, `break`, `img`],
  note: [],
};

// SMS MODE IS SUPER BASIC, ONLY MERGE TAGS
interface MenuBarProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editor: any;
  mergeTags?: {
    text: string;
    value: string;
    new?: boolean;
    link?: boolean;
  }[];
  mode?: `email` | `sms` | `signature` | `note`;
}

export function MenuBar({ editor, mergeTags, mode }: MenuBarProps) {
  if (!editor) return null;

  const insertMergeTag = tag => {
    editor.chain().focus().insertContent(`{{{${tag}}}}`).run();
  };

  const setLink = (href: string) => {
    editor.commands.setLink({ href: `{{{${href}}}}` });
  };

  const headingOptions = [1, 2, 3, 4, 5, 6];

  const tags = useMemo(() => {
    if (!mergeTags) return [];

    return [
      mergeTags.map(t => ({
        label: t.text,
        onClick: () => insertMergeTag(t.value),
        badge: t.new && `New!`,
        badgeColor: t.new && `blue`,
      })),
    ];
  }, [ mergeTags ]);

  const links = useMemo(() => {
    if (!mergeTags) return [];

    return [mergeTags
      .filter(t => t.link)
      .map(t => ({
        label: t.text,
        onClick: () => setLink(t.value),
      }))];
  }, [ mergeTags ]);

  const headings = useMemo(() => {
    return [
      headingOptions.map(h => ({
        label: `h${h}`,
        onClick: () => editor.chain().focus().toggleHeading({ level: h }).run(),
        active: editor.isActive(`heading`, { level: h }),
      })),
      [
        {
          label: `normal`,
          onClick: () => editor.chain().focus().setParagraph().run(),
          active: editor.isActive(`paragraph`),
        },
      ],
    ];
  }, [editor]);

  const textAlign = useMemo(() => {
    return [
      [
        {
          label: `left`,
          onClick: () => editor.chain().focus().setTextAlign(`left`).run(),
          active: editor.isActive({ textAlign: `left` }),
        },
        {
          label: `center`,
          onClick: () => editor.chain().focus().setTextAlign(`center`).run(),
          active: editor.isActive({ textAlign: `center` }),
        },
        {
          label: `right`,
          onClick: () => editor.chain().focus().setTextAlign(`right`).run(),
          active: editor.isActive({ textAlign: `right` }),
        },
        {
          label: `justify`,
          onClick: () => editor.chain().focus().setTextAlign(`justify`).run(),
          active: editor.isActive({ textAlign: `justify` }),
        },
      ],
    ];
  }, [editor]);

  const addImage = useCallback(() => {
    const url = window.prompt(`Paste image url`);

    if (url) {
      editor.chain().focus().setImage({ src: url, style: { [`max-width`]: `100px` } }).run();
    }
  }, [editor]);

  const menuItems = {
    [`bold`]: (
      <div className={ `` }>
        <Button
          active={ editor.isActive(`bold`) }
          size={ `sm` }
          onClick={ () => editor.chain().focus().toggleBold().run() }
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleBold()
              .run()
          }
          className={ editor.isActive(`bold`) ? `is-active` : `` }
        >
          { `bold` }
        </Button>
      </div>
    ),
    [`italic`]: (
      <div className={ `` }>
        <Button
          active={ editor.isActive(`italic`) }
          size={ `sm` }
          onClick={ () => editor.chain().focus().toggleItalic().run() }
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleItalic()
              .run()
          }
          className={ editor.isActive(`italic`) ? `is-active` : `` }
        >
          { `italic` }
        </Button>
      </div>
    ),
    [`heading`]: (
      <div className={ `` }>
        <Dropdown
          theme={ `primary` }
          options={ headings }
          label={ `Headings` }
        />
      </div>
    ),
    [`align`]: (
      <div className={ `` }>
        <Dropdown
          theme={ `primary` }
          options={ textAlign }
          label={ `Align` }
        />
      </div>
    ),
    [`mergeTags`]: (
      <div className={ `justify-self-center` }>
        <Dropdown
          theme={ `primary` }
          options={ tags }
          label={ `Merge Tags` }
        />
      </div>
    ),
    [`links`]: (
      <div className={ `` }>
        <Dropdown
          theme={ `primary` }
          options={ links }
          label={ `Links` }
        />
      </div>
    ),
    [`divider`]: (
      <div className={ `` }>
        <Button
          onClick={ () => editor.chain().focus().setHorizontalRule().run() }
          size={ `sm` }
        >
          { `divider` }
        </Button>
      </div>
    ),
    [`break`]: (
      <div className={ `` }>
        <Button
          size={ `sm` }
          onClick={ () => editor.chain().focus().setHardBreak().run() }
        >
          { `break` }
        </Button>
      </div>
    ),
    [`img`]: (
      <div className={ `` }>
        <Button
          size={ `sm` }
          onClick={ addImage }
        >
          { `image` }
        </Button>
      </div>
    ),
  };

  const allowedItems = modeMap[mode];

  if (mode === `sms`) {
    return (
      <div
        className={ `
        flex
        flex-wrap
        gap-2
        bg-gray-200
        px-2 py-4
        rounded-t-md
      ` }>
        <div className={ `justify-self-center` }>
          <Dropdown
            theme={ `primary` }
            options={ tags }
            label={ `Merge Tags` }
            position={ mode === `sms` ? `right` : `left` }
          />
        </div>
      </div>
    );
  }

  if (mode === `note`) return null;

  return (
    <div
      className={ `
        flex
        flex-wrap
        gap-2
       bg-gray-200
        px-2 py-4
        rounded-t-md
      ` }>
      {
        allowedItems.map(item =>{
          return (
            <Fragment key={ `${item}_` }>
              { menuItems[item] }
            </Fragment>

          );
        })
      }
    </div>
  );
}
