import { ActionsTable } from "../../../Actions/ActionsTable";

export function ContactActivity() {
  return (
    <ActionsTable
      omitColumns={ [ `contact` ] }
      smallTabs
    />
  );
}
