import { useEffect, useState } from "react";
import { IPolicyPreviewResponse, PolicyType } from "shared";
import { When } from "react-if";
import { useNavigate } from "react-router";
import { Tooltip } from "@mui/material";
import { Parser } from "html-to-react";

import { Modal } from "../../../common/Atoms/Modal";
import { useApiRequest } from "../../hooks/useApiRequest";
import { env } from "../../../common/lib/env";
import { usePreviewPolicy } from "../../hooks/usePreviewPolicy";
import { InvoiceLineItems } from "../../../common/Components/Invoice/InvoiceLineItems";
import { Card } from "../../../common/Atoms/Card";
import { Heading } from "../../../common/Atoms/Typography/Heading";
import { LoadingOverlay } from "../Molecules/LoadingOverlay";
import { usePolicies } from "../../hooks/usePolicies";
import { Notification } from "../../../common/Atoms/Notification";
import { DatePopover } from "../../../common/Components/DatePopover";
import { useGetSelectedOrganisation } from "../../hooks/useGetSelectedOrganisation";
import { Button } from "../../../common/Atoms/Button";
import { useApplyPolicyPreviewMutation } from "../../services/api/invoiceApi/invoice";
import { useInvoice } from "../../hooks/useInvoice";
import { DescriptionList } from "../../../common/Atoms/DescriptionList";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { CustomIframe } from "../IFrame";

const htmlParser = Parser();

export function PreviewPolicyModal() {
  const { closePreviewPolicy, showModal, invoiceId, policyId, contactId } = usePreviewPolicy();
  const [applyPolicyPreview, { isLoading: applyLoading }] = useApplyPolicyPreviewMutation();
  const { data: policies } = usePolicies();
  const { policyCheck } = useInvoice();
  const request = useApiRequest();
  const [loading, setLoading] = useState(false);
  const [previewResult, setPreviewResult] = useState<IPolicyPreviewResponse>(null);
  const currentOrg = useGetSelectedOrganisation();
  const navigate = useNavigate();

  async function getData() {
    setLoading(true);

    try {
      const result = await request.get(`${env.baseApiUrl}policy/${policyId}/preview`, {
        params: {
          invoiceId,
          contactId,
        },
      });

      const data = result.data as IPolicyPreviewResponse;

      setLoading(false);
      setPreviewResult(data);
    }
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  async function apply() {
    await applyPolicyPreview({
      organisationId: currentOrg?.id,
      invoiceId,
      policyId,
    });

    // Then close the modal
    closePreviewPolicy();
    // Go to main invoice page
    navigate(`/${currentOrg?.id}/invoices/${invoiceId}`);
  }

  useEffect(() => {
    if (showModal && policyId) {
      if (invoiceId || contactId) {
        getData();
      }
    }

    // Clear state when modal is closed
    if (!showModal) {
      setPreviewResult(null);
    }
  }, [showModal, invoiceId, policyId, contactId]);

  const currentPolicy = policies?.find(p => p.policy.id === policyId);

  const footNotes:string[] = [];

  if (previewResult?.type === PolicyType.LATE_FEE && (previewResult?.now || previewResult?.whenScheduled)) {
    // If the policy would now normally apply
    if (!policyCheck?.policyResults[policyId]?.applies && policyCheck?.policyResults[policyId]?.reason) {
      footNotes.push(
        `This policy would not normally apply. Reason: ${policyCheck?.policyResults[policyId]?.reason}`,
      );
    }
  }

  const metadata = previewResult?.metadata || {};
  const metadataItems = Object.keys(metadata).map(key => ({
    title: key,
    content: metadata[key],
  }));

  const resourceType = contactId ? `Contact` : `Invoice`;

  return (
    <Modal
      open={ showModal }
      setOpen={ closePreviewPolicy }
      title={ `Previewing result for policy: "${currentPolicy?.policy?.title || ``}" on ${resourceType}: "${previewResult?.invoice?.number || previewResult?.contact?.name || `unknown`}"` }
      width={ `lg` }
    >
      <LoadingOverlay
        loading={ loading }
        className={ `p-4` }
      >
        <div className={ `space-y-4` }>
          <When condition={ !!previewResult?.message }>
            <Notification
              type={ `info` }
            >
              { previewResult?.message }
            </Notification>
          </When>

          <DescriptionList
            items={ metadataItems }
          />

          { /* Late Fee Type */ }
          <When condition={ previewResult?.type === PolicyType.LATE_FEE && !!previewResult?.now } >
            { () => (
              <Card className={ `bg-gray-100` }>
                <div className={ `flex justify-between` }>
                  <Heading>
                    { `If applied right now` }
                  </Heading>
                  <When condition={ previewResult?.type === PolicyType.LATE_FEE && previewResult?.now?.canApplyNow }>
                    <Tooltip title={ `Will apply this late fee now, ignoring some conditions!` }>
                      <Button
                        onClick={ apply }
                        loading={ applyLoading }
                        disabled={ applyLoading }
                        color={ `orange` }
                      >
                        { `Apply Now` }
                      </Button>
                    </Tooltip>
                  </When>
                </div>
                <InvoiceLineItems
                  lineItems={ previewResult?.type === PolicyType.LATE_FEE && previewResult.now.lineItems }
                  includeAccountCode
                />
                {
                  previewResult?.type === PolicyType.LATE_FEE
                   && previewResult?.now?.notes?.map(n => (
                     <Notification
                       key={ n.message }
                       type={ n.type }
                     >
                       { n.message }
                     </Notification>
                   ))
                }
              </Card>
            ) }
          </When>

          <When condition={
            (previewResult?.type === PolicyType.LATE_FEE || previewResult?.type === PolicyType.STATEMENT_LATE_FEE)
            && !!previewResult?.whenScheduled }
          >
            { () => (
              <Card className={ `bg-gray-100` }>
                <Heading className={ `flex` }>
                  { `If applied on the scheduled date (` }
                  <DatePopover
                    date={ previewResult?.actionOn }
                    organisationTimezone={ currentOrg?.validatedTimezone }
                    labelFormat={ `dd LLL yyyy HH:mm` }
                  />
                  { `)` }
                </Heading>
                <InvoiceLineItems
                  lineItems={
                    (previewResult?.type === PolicyType.LATE_FEE || previewResult?.type === PolicyType.STATEMENT_LATE_FEE)
                    && previewResult.whenScheduled.lineItems
                  }
                  includeAccountCode
                />

                {
                  (previewResult?.type === PolicyType.LATE_FEE || previewResult?.type === PolicyType.STATEMENT_LATE_FEE)
                  && previewResult?.whenScheduled?.notes?.map(n => (
                    <Notification
                      key={ n.message }
                      type={ n.type }
                    >
                      { n.message }
                    </Notification>
                  ))
                }
              </Card>
            ) }
          </When>

          { /* Reminder Type */ }
          {
            previewResult?.type === PolicyType.REMINDER && (
              <div className={ `min-h-[100px] mt-4` }>
                <Paragraph
                  className={ `pb-2 ml-1` }
                >
                  { `Email Preview` }
                </Paragraph>
                <Card className={ `rounded-md shadow-xl border-gray-100 border` }>
                  <CustomIframe
                    style={ {
                      width: `100%`,
                      height: `100%`,
                      minHeight: `400px`,
                    } }
                  >
                    { htmlParser.parse(previewResult?.html || ``) }
                  </CustomIframe>
                </Card>
              </div>
            )
          }
          <When condition={ !!footNotes.length }>
            {
              footNotes.map((note, i) => (
                <Notification
                  key={ i }
                  type={ `info` }
                >
                  { note }
                </Notification>
              ))
            }
          </When>
        </div>
      </LoadingOverlay>
    </Modal>
  );
}
