import { INote } from "shared";
import { useState } from "react";
import { When } from "react-if";
import { Tooltip } from "@mui/material";
import { JSONContent } from "@tiptap/core";

import { classNames } from "../../../common/lib/classNames";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { useAccountingSystemName } from "../../hooks/useAccountingSystemName";
import { useCurrentOrgUsers } from "../../hooks/useCurrentOrgUsers";

import { Note } from "./Note";
import { TextEditor } from "./TextEditor";

interface NotesProps {
  notes: INote[];
  enableNewNote?: boolean;
  onSave: (content: string, mentionedUsers: string[]) => Promise<void>;
  childrenOptions?: React.ReactNode;
  loading?: boolean;
}

export function Notes({ notes, enableNewNote, onSave, loading, childrenOptions }: NotesProps) {
  const accountingName = useAccountingSystemName();
  const [newNoteForm, setNewNoteForm] = useState({
    content: ``,
    error: ``,
    jsonContent: null,
  });
  const currentOrgUsers = useCurrentOrgUsers();

  // Recursively find mentions in the JSON content
  function findMentions(json: JSONContent): {label: string, value: string}[] {
    const mentions: {label: string, value: string}[] = [];

    json.content?.forEach(content => {
      if (content.type === `mention`) {
        const user = currentOrgUsers.find(user => user.label === content.attrs.id);

        if (user) {
          mentions.push(user);
        }
      }

      if (content.content) {
        mentions.push(...findMentions(content));
      }
    });

    return mentions;
  }

  function onChange({ text, json }: { text: string; json: JSONContent }) {
    if (text.length > 1500) {
      setNewNoteForm({ content: text, error: `Note must be less than 1500 characters`, jsonContent: json });

      return;
    }

    setNewNoteForm({ error: ``, content: text, jsonContent: json });
  }

  async function onNewNote(e: React.FormEvent) {
    e.preventDefault();

    const mentions = findMentions(newNoteForm.jsonContent);

    await onSave(newNoteForm.content, mentions.map(mention => mention.value));

    // Clear the form
    setNewNoteForm({ content: ``, error: ``, jsonContent: null });
  }

  return (
    <div>
      { /* Create a new note */ }
      <When condition={ enableNewNote }>
        <form
          className={ `border border-gray-200 rounded-md mb-3` }
          onSubmit={ onNewNote }
        >
          <div className={ `flex flex-col items-end` }>
            <div className={ `w-full` }>
              <TextEditor
                mode={ `note` }
                value={ newNoteForm.jsonContent }
                onChange={ e => onChange(e) }
                mentionValues={ currentOrgUsers.map(user => user.label) }
                placeholder={ `Write a note... use @ to mention someone` }
                editorClassname={ classNames(
                  `focus:border-none`,
                  newNoteForm.error ? `border border-red-500` : ``,
                ) }
              />
            </div>

            { childrenOptions ? childrenOptions : null }

            <Tooltip title={ `Notes are also automatically sent to ${accountingName}.` }>
              <span>
                <button
                  type={ `submit` }
                  className={ `border border-gray-200 rounded-md p-2 m-2 text-sm hover:bg-lateGreen-500 hover:text-white disabled:cursor-not-allowed disabled:hover:bg-gray-200 disabled:hover:text-gray-600 disabled:bg-gray-200 disabled:opacity-50` }
                  disabled={ loading  || !newNoteForm.content || !!newNoteForm.error }
                >
                  <When condition={ loading }>
                    { `Adding Note...` }
                  </When>
                  <When condition={ !loading }>
                    { `Add Note` }
                  </When>
                </button>
              </span>
            </Tooltip>
          </div>
          <When condition={ newNoteForm.error }>
            <Paragraph variant={ `error` }>
              { newNoteForm.error }
            </Paragraph>
          </When>
        </form>
      </When>

      { /* Display existing notes */ }
      <div className={ `w-full space-y-3` }>
        {
          notes.map(note => (
            <Note
              key={ note.id }
              note={ note }
            />
          ))
        }
      </div>
    </div>
  );
}
