import { policyConfig } from "shared";

import { fieldIsValid } from "../../../../../lib/helper";
import { Toggle } from "../../../../../../common/Atoms/Toggle";

import { IFieldProps } from "./fieldProps";

const config = policyConfig[`mark_as_sent`];

export function MarkAsSent({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {

  return (
    <Toggle
      label={ config.label }
      description={ `Mark the invoice as sent in Xero after sending.` }
      checked={ policyFormData.mark_as_sent || false }
      onChange={ handleChange }
      invalid={ fieldIsValid(`mark_as_sent`, invalids) }
      name={ `mark_as_sent` }
    />
  );
}
