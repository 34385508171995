import { useEffect, useState } from 'react';
import { PencilIcon } from '@heroicons/react/24/outline';
import { When } from 'react-if';
import { ISubscriptionResponseItem, SubscriptionStatus } from 'shared';

import { Button } from '../../../common/Atoms/Button';
import { Paragraph } from '../../../common/Atoms/Typography/Paragraph';
import { InputTextAddon } from '../../../common/Atoms/InputTextAddon';
import { useUpdateSubscriptionMutation } from '../../services/api/subscriptionApi/subscription';

interface SmsLimitProps {
  subscription: ISubscriptionResponseItem;
}

export function SmsLimit({ subscription }: SmsLimitProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [smsLimit, setSmsLimit] = useState<string | number | null>(subscription.smsMonthlyLimit);
  const [updateSubscription, { isLoading }] = useUpdateSubscriptionMutation();

  async function onSave() {
    await updateSubscription({
      id: subscription.id,
      update: {
        smsMonthlyLimit: Number(smsLimit),
      },
    });

    setIsEditing(false);
  }

  useEffect(() => {
    if (subscription?.smsMonthlyLimit !== null && subscription?.smsMonthlyLimit === undefined) {
      setSmsLimit(subscription.smsMonthlyLimit);
    }
  }, [subscription?.smsMonthlyLimit]);

  return (
    <>
      <div className={ `flex flex-row justify-start items-center` }>
        <When condition={ isEditing }>
          <InputTextAddon
            name={ `sms_limit` }
            value={ String(smsLimit) }
            onChange={ ({ value }) => setSmsLimit(value) }
          />
          <Button
            className={ `ml-2 mt-1` }
            onClick={ onSave }
            loading={ isLoading }
          >
            { `Save` }
          </Button>
        </When>
        <When condition={ !isEditing }>
          <Paragraph variant={ `secondary` }>
            { `${subscription?.usage?.currentSMSUsage} of ${subscription.smsMonthlyLimit} ` }
          </Paragraph>

          <Button
            icon={ <PencilIcon className={ `w-4 h-4` } /> }
            onClick={ () => setIsEditing(true) }
            color={ `none` }
          />
        </When>
      </div>
      <When condition={ subscription.status === SubscriptionStatus.TRIALING }>
        <Paragraph variant={ `help` }>
          { `SMS is limited to 10 messages during trial` }
        </Paragraph>
      </When>
    </>
  );
}
