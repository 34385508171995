import { Card } from "../../../common/Atoms/Card";
import { CardContent } from "../../../common/Atoms/CardContent";
import { MultiButtonCardHeader } from "../../../common/Atoms/MultiButtonCardHeader";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { ESCALATIONS_ARTICLE } from "../../constants/links";

import { EscalationsTable } from "./EscalationsTable";

export function EscalationsRoot() {
  return (
    <Card>
      <MultiButtonCardHeader
        title={ (
          <span>
            { `Escalations ` }
            <Paragraph
              as={ `span` }
              variant={ `link` }>
              <a
                href={ ESCALATIONS_ARTICLE }
                target={ `_blank` }
                rel={ `noopener noreferrer` }
              >
                { `(How Escalations Work)` }
              </a>
            </Paragraph>
          </span>
        ) }
        description={ `Use the filters to find Escalations` }
        secondaryDescription={ `Click on any Escalations to see more details` }
      // TODO: add buttons like 'snooze all' or 'cancel all'?
      />
      <CardContent>
        <EscalationsTable />
      </CardContent>
    </Card>
  );
}
