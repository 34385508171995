import { useCallback } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { When } from 'react-if';
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router';
import { InvoiceListItem, statusUIMapXero, toDecimal } from 'shared';

import { classNames }  from '../../../common/lib/classNames';
import { Badge } from '../../../common/Atoms/Badge';
import { Paragraph } from '../../../common/Atoms/Typography/Paragraph';
import { XeroStatusBadge } from '../../../common/Components/Invoice/XeroStatusBadge';
import { DatePopover } from '../../../common/Components/DatePopover';

import { InvoiceActions } from './InvoiceActions';

interface InvoiceRowProps {
  invoiceRow: InvoiceListItem;
  selected: boolean;
  setSelectedInvoices: (ids: string[]) => void;
  selectedInvoices: string[];
}

export function InvoiceRow({ invoiceRow, selected, setSelectedInvoices, selectedInvoices }: InvoiceRowProps) {
  const navigate = useNavigate();
  const {
    invoice,
    tz,
    actionTotals: totals,
  } = invoiceRow;

  const currency = invoice.currency;

  const formatter = useCallback(() => {
    return new Intl.NumberFormat(`en-US`, {
      style: `currency`,
      currency,
    });
  }, [ currency ]);

  return (
    <tr
      onClick={ () => navigate(`${invoice.id}`) }
      className={
        classNames(
          `px-4`,
          `cursor-pointer`,
          invoice.createdByUs ?
            (selected ? `bg-orange-200 hover:bg-orange-100`: `bg-orange-50 hover:bg-orange-100`) :
            (selected ? `bg-gray-50 hover:bg-gray-100`: `hover:bg-gray-100`),
        )
      }
    >
      <td className={ `relative w-12 px-6 sm:w-16 sm:px-8` }>
        <When condition={ selected }>
          <div className={ `absolute inset-y-0 left-0 w-0.5 bg-lateGreen-600` } />
        </When>
        <input
          type={ `checkbox` }
          className={ `absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-lateGreen-600 focus:ring-lateGreen-500 sm:left-6` }
          checked={ selected }
          onClick={ e => e.stopPropagation() }
          onChange={ e => {
            e.stopPropagation();
            e.preventDefault();

            setSelectedInvoices(
              e.target.checked
                ? [...selectedInvoices, invoice.id]
                : selectedInvoices.filter(id => id !== invoice.id),
            );
          } }
        />
      </td>
      <td
        className={ classNames(
          `whitespace-nowrap py-4 pr-3 text-sm font-medium truncate`,
          selected ? `text-lateGreen-600` : `text-gray-900`,
        ) }
      >
        <span className={ `flex truncate items-center` }>
          <Tooltip title={ invoice.number }>
            <p>
              { invoice.number ? invoice.number : `[No Number]` }
              <When condition={ invoice.createdByUs }>
                <Paragraph
                  as={ `span` }
                  variant={ `secondary` }
                  className={ `ml-2` }
                >
                  { `(Late Fee)` }
                </Paragraph>
              </When>
            </p>
          </Tooltip>
          <When condition={ invoice.frozen }>
            <Tooltip title={ `Processing on this invoice has been frozen` }>
              <Badge
                className={ `ml-2` }
                color={ `blue` }
              >
                { `Frozen` }
              </Badge>
            </Tooltip>
          </When>
          <When condition={ invoice.note }>
            { ` ` }
            <Tooltip title={ invoice.note }>
              <ExclamationTriangleIcon className={ `h-5 w-5 text-red-600 animate-pulse` } />
            </Tooltip>
          </When>
        </span>
        <Tooltip title={ invoice.reference }>
          <div className={ `text-gray-500 truncate` }>{ invoice.reference }</div>
        </Tooltip>
      </td>
      <td className={ `w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:w-auto sm:max-w-none sm:pl-6` }>
        <Tooltip title={ `Invoice Status` }>
          <XeroStatusBadge
            status={ invoice.externalStatus as keyof typeof statusUIMapXero }
            mode={ `app` }
          />
        </Tooltip>
      </td>
      { /* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> */ }
      <td className={ `w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:w-auto sm:max-w-none sm:pl-6 truncate` }>

        <Tooltip title={ invoiceRow.contact.name }>
          <span className={ `text-gray-900 truncate` }>{ invoiceRow.contact.name }</span>
        </Tooltip>

        <dl className={ `font-normal` }>
          <dd className={ `2xl:hidden mt-1 truncate text-gray-700` }>
            <Tooltip title={ `The Paidnice group this contact appears in` }>
              <span>{ invoiceRow.group.title }</span>
            </Tooltip>
          </dd>
        </dl>
      </td>

      <td className={ `hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 2xl:table-cell` }>
        { invoiceRow.group.title }
      </td>

      <td className={ `whitespace-nowrap px-3 py-4 text-sm text-gray-700` }>
        <Tooltip title={ formatter().format(toDecimal(invoice.totals.dueInCents)) }>
          <span>{ formatter().format(toDecimal(invoice.totals.dueInCents)) }</span>
        </Tooltip>

        <dl className={ `font-normal` }>
          <dd className={ `2xl:hidden mt-1 truncate text-gray-700` }>
            <Tooltip title={ `Invoice due date` }>
              <span>
                <DatePopover
                  date={ invoice.dueDate }
                  organisationTimezone={ tz }
                  labelFormat={ `ccc dd LLL yy` }
                  time={ `end` }
                />
              </span>
            </Tooltip>
          </dd>
        </dl>
      </td>

      <td className={ `hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 2xl:table-cell` }>
        <Tooltip title={ `Invoice due date` }>
          <span>
            <DatePopover
              date={ invoice.dueDate }
              organisationTimezone={ tz }
              labelFormat={ `ccc dd LLL yy` }
              time={ `end` }
            />
          </span>
        </Tooltip>
      </td>
      <td>
        <div className={ `flex flex-col items-start` }>
          <When condition={ totals.complete > 0 }>
            <Badge
              color={ `green` }
            >
              { `${totals.complete} Complete Action${totals.complete > 1 ? `s` : `` }` }
            </Badge>
          </When>

          <When condition={ totals.pending > 0 }>
            <Badge
              color={ `yellow` }
            >
              { `${totals.pending} Pending Action${totals.pending > 1 ? `s` : `` }` }
            </Badge>
          </When>

          <When condition={ totals.failed > 0 }>
            <Badge
              color={ `red` }
            >
              { `${totals.failed} Failed Action${totals.failed > 1 ? `s` : `` }` }
            </Badge>
          </When>

        </div>
      </td>
      <td className={ `whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6` }>
        <InvoiceActions invoiceRow={ invoiceRow } />
      </td>
    </tr>
  );
}
