import { useMemo, useState } from 'react';
import { PolicyTargetStatus } from 'shared';

import { InputTextAddon } from '../../../../../common/Atoms/InputTextAddon';
import { Grid } from '../../../../../common/Atoms/Grid';
import { GridItem } from '../../../../../common/Atoms/GridItem';
import { Select } from '../../../../../common/Atoms/Select';
import { fieldIsValid } from '../../../../lib/helper';
import { IPolicyProps } from '../PolicyItem';
import { TimeOfDay } from '../PolicyForm/Fields/TimeOfDay';
import { useGetSelectedOrganisation } from '../../../../hooks/useGetSelectedOrganisation';
import { SMSDefaultCountryCode } from '../PolicyForm/Fields/SMSDefaultCountryCode';

function calculateDayTime(value) {
  if (value === 0) {
    return `on`;
  }

  if (value < 0) {
    return `before`;
  }

  return `after`;
}

export function PolicyAICall(props: IPolicyProps) {
  const { policy, invalids, handleChange } = props;
  const [dayType, setDayType] = useState(calculateDayTime(policy.day_index));
  const currentOrg = useGetSelectedOrganisation();

  const dayTypes = useMemo(() => {
    const result =[
      {
        label: `After`,
        value: `after`,
      },
      {
        label: `On The`,
        value: `on`,
      },
    ];

    if (policy.target_status === PolicyTargetStatus.DUE) {
      result.unshift({
        label: `Before`,
        value: `before`,
      });
    }

    return result;
  }, [ policy.target_status ]);

  const invoiceStates = [
    {
      label: `Due Date`,
      value: PolicyTargetStatus.DUE,
    },
  ];

  const dayIndexValue = useMemo(() => {
    let str = policy.day_index.toString();

    if (str.startsWith(`-`)) {
      str = str.replace(`-`, ``);
    }

    return str;
  }, [ policy.day_index ]);

  function onDayIndexChange(e, day = dayType) {
    const { value } = e;

    let num = Number(value);

    if (Number.isNaN(num)) {
      handleChange({
        name: `day_index`,
        value,
      });

      return;
    }

    if (day === `before`) {
      num = num < 0 ? num : -num;
    }

    if (day === `after`) {
      num = num > 0 ? num : -num;
    }

    if (day === `on`) {
      num = 0;
    }

    handleChange({
      name: `day_index`,
      value: num,
    });
  }

  function handleDayTypeChange(e) {
    setDayType(e.value);

    onDayIndexChange({
      value: policy.day_index,
    }, e.value);
  }

  return (
    <>
      { /* RULE */ }
      <Grid cols={ 3 }
        className={ `items-end` }>
        <GridItem>
          <InputTextAddon
            value={ dayIndexValue }
            onChange={ onDayIndexChange }
            name={ `day_index` }
            invalid={ fieldIsValid(`day_index`, invalids) }
            addOnText={ `days ` }
            label={ `When to call` }
            disabled={ dayType === `on` }
          />
        </GridItem>
        <GridItem>
          <Select
            options={ dayTypes }
            selected={ dayType }
            onChange={ handleDayTypeChange }
          />
        </GridItem>
        <GridItem>
          <Select
            options={ invoiceStates }
            selected={ policy.target_status }
            onChange={ handleChange }
            name={ `target_status` }
            invalid={ fieldIsValid(`target_status`, invalids) }
          />
        </GridItem>
      </Grid>

      <Grid cols={ 2 }>
        <GridItem position={ `bottom` }>
          <TimeOfDay
            policyFormData={ policy }
            invalids={ invalids }
            handleChange={ handleChange }
            labelOverride={ `Time to call` }
            descriptionOverride={ `Will run within 2 hours of the selected time. Time is in your company timezone. (${currentOrg?.validatedTimezone})` }
          />
        </GridItem>

        <GridItem position={ `bottom` }>
          <SMSDefaultCountryCode
            policyFormData={ policy }
            invalids={ invalids }
            handleChange={ handleChange }
          />
        </GridItem>
      </Grid>
    </>
  );
}
