import { createSlice } from '@reduxjs/toolkit';
import { IPolicyResponseItem, objectsAreEqual } from 'shared';

export const initialState: {
  policy: null | IPolicyResponseItem,
  policyFormData: null | IPolicyResponseItem,
  formDataDirty: boolean,
  open: boolean;
} = {
  policy: null,
  policyFormData: null,
  formDataDirty: false,
  open: false,
};

const policyDrawerSlice = createSlice({
  name: `policyDrawer`,
  initialState,
  reducers: {
    closeAndClear(state) {
      state.open = false;
      state.policy = null;
      state.policyFormData = null;
      state.formDataDirty = false;
    },
    open(state, action) {
      // Set form data to the same as the policy on open
      state.open = true;
      state.policy = action.payload;
      state.policyFormData = action.payload;
      state.formDataDirty = false;
    },
    updateFormData(state, action) {
      state.policyFormData = action.payload;
      state.formDataDirty = !objectsAreEqual(state.policy, action.payload);
    },
    resetDirty(state) {
      state.formDataDirty = false;
    },
  },
});

export const { closeAndClear, open, updateFormData, resetDirty } = policyDrawerSlice.actions;

export const policyDrawerReducer = policyDrawerSlice.reducer;
