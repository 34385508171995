import { IPolicyResponseItem } from "shared";

import { useGroups } from "./useGroups";

export function useGroupsForPolicy(policy?: IPolicyResponseItem) {
  const { data: groups } = useGroups();

  if (!groups) return [];
  if (!policy) return [];

  const policyGroups = groups?.filter(g => policy.groupIds.includes(g.id));

  return policyGroups;
}
