import { When } from "react-if";
import { useMemo } from "react";
import { PolicyType, invoiceTypes, quoteTypes, statementTypes } from "shared";

import { useCurrentGroup } from "../../../../hooks/useGroups";
import { Notification } from "../../../../../common/Atoms/Notification";
import { classNames } from "../../../../../common/lib/classNames";

import { PolicyColumn } from "./PolicyColumn";
import { CreateNewPolicy } from "./CreateNewPolicy";

const colMap = {
  0: `grid-cols-1`,
  1: `grid-cols-1`,
  2: `grid-cols-2`,
  3: `md:grid-cols-2 lg:grid-cols-3`,
};

export function GroupPolicies() {
  const { policies } = useCurrentGroup();

  const invoicePolicies = useMemo(() => {
    if (!policies || !policies.length) {
      return [];
    }

    return policies
      .filter(x => invoiceTypes.includes(x.policy_type));
  }, [policies]);

  const statementPolicies = useMemo(() => {
    if (!policies || !policies.length) {
      return [];
    }

    return policies.filter(x => statementTypes.includes(x.policy_type));
  }, [policies]);

  const quotePolicies = useMemo(() => {
    if (!policies || !policies.length) {
      return [];
    }

    return policies.filter(x => quoteTypes.includes(x.policy_type));
  }, [policies]);

  const policyTypeCount = useMemo(() => {
    let count = 0;
    if (invoicePolicies.length) {
      count++;
    }
    if (statementPolicies.length) {
      count++;
    }

    if (quotePolicies.length) {
      count++;
    }

    return count;
  }, [invoicePolicies, statementPolicies, quotePolicies]);

  const hasMultipleLateFeeTypes = invoicePolicies.some(p => p.policy_type === PolicyType.LATE_FEE)
    && statementPolicies.some(p => p.policy_type === PolicyType.STATEMENT_LATE_FEE);

  return (
    <div>
      <CreateNewPolicy />
      { /* Show warning if contact and invoice late fee  */ }
      <When condition={ hasMultipleLateFeeTypes }>
        <Notification
          type={ `warning` }
          title={ `This group has both invoice late fee and statement interest charge policies. This may result in duplicate fees being applied!` }
          className={ `mb-4` }
        >
          { `We recommend deleting one late fee type to ensure you have only "Invoice" or "Contact" late fees.` }

        </Notification>
      </When>

      { /*  3 columns, one for each policy resource */ }
      <div className={ classNames(
        `grid grid-cols-1 gap-4`,
        colMap[policyTypeCount] || `grid-cols-1`,
      )
      }>
        <PolicyColumn type={ `invoice` } />
        <PolicyColumn type={ `contact` } />
        <PolicyColumn type={ `quote` } />
      </div>

      { /* TODO remove me when no longer autofreezing */ }
      <When condition={ policyTypeCount === 0 }>
        <Notification
          noIcon
          title={ `Group has no policies!` }
          className={ `mt-4` }
        >
          <ol className={ `list-disc` }>
            <li>
              { `Group members will have no action taken against them.` }
            </li>
            <li>
              { `Invoices may be automatically frozen for these group members ` }
            </li>
          </ol>
        </Notification>
      </When>
    </div>
  );
}
