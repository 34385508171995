import { useMemo } from 'react';

import { useGetSelectedOrganisation } from './useGetSelectedOrganisation';

export function useAccountingSystemName() {
  const organisation = useGetSelectedOrganisation();

  const accountingSystemName = useMemo(() => {

    if ( organisation?.type === `QBO`) {
      return `QuickBooks`;
    }
    else {
      return `Xero`;
    }

  }, [organisation?.type]);

  return accountingSystemName;
}
