import { useParams } from 'react-router';
import { useEffect, useRef } from 'react';
import { IInvoiceParams } from 'shared';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../store';
import { initialState, setParams } from '../slices/invoiceParams';
import { useFetchInvoicesQuery } from '../services/api/invoiceApi/invoice';

export function useGetInvoices() {
  const { organisationId } = useParams();
  const invoiceParams = useSelector((s: RootState) => s.invoiceParams) as IInvoiceParams;
  const pollingInterval = useRef(1000 * 10);

  const result = useFetchInvoicesQuery({
    ...invoiceParams,
    organisationId,
  }, {
    skip: !organisationId,
    pollingInterval: pollingInterval.current,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (result.error) {
      pollingInterval.current = undefined;
    }
  }, [result.error]);

  result.data = result.data || {
    invoices: [],
    count: 0,
  };

  return result;
}

export function useInvoiceParams() {
  const params = useSelector((s: RootState) => s.invoiceParams);
  const dispatch = useDispatch();

  const updateParams = (update: Partial<typeof params>) => {
    return dispatch(setParams(update));
  };

  const resetParams = (keepKeys?: string[]) => {
    const newState = { ...initialState };

    if (keepKeys) {
      keepKeys.forEach(key => {
        newState[key] = params[key];
      });
    }

    dispatch(setParams(newState));
  };

  return {
    updateParams,
    resetParams,
    params,
  };
}
