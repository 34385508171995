import { useEffect, useState } from "react";
import { IPolicy, PolicyCalculationType, policyConfig } from "shared";

export function useAdvancedPolicyFields(policyFormData?: IPolicy) {
  const [shownFields, setShownFields] = useState<(keyof typeof policyConfig)[]>([]);

  useEffect(() => {
    if (!policyFormData) return;
    const fields = Object.keys(policyConfig).filter(key => {
      const policyConfigItem = policyConfig[key];
      let advanced = policyConfigItem.advanced;

      if (policyConfigItem.advanced && Array.isArray(policyConfigItem.advanced)) {
        // When advanced is an array, it must include the policy type
        advanced = policyConfigItem.advanced.includes(policyFormData.policy_type);
      }

      if (!advanced) return false;

      // check the field applies to this policy type
      if (!policyConfig[key].appliesTo.includes(policyFormData.policy_type)) return false;

      // Allow false for due date
      if (key === `penalty_due_date` && policyFormData[key] !== null) return true;

      if (policyFormData[key]) return true;

      // Allow 0 for hours
      if (key === `hour_of_day` && policyFormData[key] === 0) return true;

      if (key === `fixed_fee_amount` && policyFormData.calculation_type === PolicyCalculationType.NOT_APPLICABLE) return true;

      return false;
    }) as (keyof typeof policyConfig)[];

    setShownFields([...new Set([...shownFields, ...fields])]);
  }, [policyFormData]);

  return shownFields;
}

