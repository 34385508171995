// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as SVG } from '../assets/Xero-Connect-Buttons/connect-blue.svg';

export function XeroConnectButtonBlue(props) {
  return (
    <button
      data-cy={ `xero-connect-button-1` }
      type={ `button` }
      { ...props }
    >
      <SVG />
    </button>
  );
}
