import { useMemo } from "react";

import { InputTextAddon } from "../../../../../common/Atoms/InputTextAddon";
import { fieldIsValid } from "../../../../lib/helper";
import { useSettings } from "../../../../hooks/useSettings";
import { useGetSelectedOrganisation } from "../../../../hooks/useGetSelectedOrganisation";
import { TwoColFormRow } from "../../../Molecules/Form/TwoColFormRow";
import { useAllowedFeature } from "../../../../hooks/useAllowedFeature";
import { useGeneralSettingsForm } from "../../../../hooks/useGeneralSettingsForm";

export function DefaultEmailSettings() {
  const { data: settings } = useSettings();
  const currentOrg = useGetSelectedOrganisation();
  const allowedCustomDomain = useAllowedFeature(`customDomain`);
  const { formData, onChange, invalids } = useGeneralSettingsForm();

  function handleChange(e) {
    onChange(e.name, e.value);
  }

  // Only show before the @
  const fromAddressDisplay = useMemo(() => {
    if (settings?.customDomainVerified && settings?.customDomain) {
      return formData?.fromAddress;
    }

    return `no-reply`;
  }, [ settings, currentOrg, formData ]);

  const fromPostfix = useMemo(() => {
    if (settings?.customDomainVerified && settings?.customDomain) {
      return `@${settings?.customDomain}`;
    }

    return `@mail.paidnice.com`;
  }, [ settings, currentOrg ]);

  const canEditFrom = settings?.customDomainVerified && allowedCustomDomain;

  const fromHelp = settings?.customDomainVerified ?
    `` :
    `A custom domain is required to change the "from" address`;

  return (
    <TwoColFormRow
      heading={ `Outbound Emails` }
    >
      <div className={ `space-y-2` }>
        <InputTextAddon
          label={ `Reply To Address (Required)` }
          value={ formData.replyToAddress || `` }
          name={ `replyToAddress` }
          onChange={ handleChange }
          invalid={ fieldIsValid(`replyToAddress`, invalids) }
          subHeading={ `This is the minimum required field we need to be able to send emails` }
        />

        <InputTextAddon
          label={ `BCC Address` }
          value={ formData.bccAddress || `` }
          name={ `bccAddress` }
          onChange={ handleChange }
          invalid={ fieldIsValid(`bccAddress`, invalids) }
        />

        <InputTextAddon
          label={ `CC Address` }
          value={ formData.ccAddress || `` }
          name={ `ccAddress` }
          onChange={ handleChange }
          invalid={ fieldIsValid(`ccAddress`, invalids) }
        />

        <InputTextAddon
          label={ `From Address` }
          value={ fromAddressDisplay || `` }
          name={ `fromAddress` }
          onChange={ handleChange }
          disabled={ !canEditFrom }
          invalid={ fieldIsValid(`fromAddress`, invalids) || fieldIsValid(`fromName`, invalids) }
          description={ fromHelp }
          prefix={ formData.fromName || `` }
          addOnText={ fromPostfix }
          prefixOnChange={ prefix => onChange(`fromName`, prefix) }
        />
      </div>
    </TwoColFormRow>
  );
}
