import { PolicyType, policyConfig } from "shared";

import { fieldIsValid } from "../../../../../lib/helper";
import { Toggle } from "../../../../../../common/Atoms/Toggle";
import { useGetSelectedOrganisation } from "../../../../../hooks/useGetSelectedOrganisation";

import { IFieldProps } from "./fieldProps";

const config = policyConfig.use_invoice_number_prefix;

export function InvoiceNumberPrefix({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {
  const currentOrg = useGetSelectedOrganisation();
  // Interest statement types have no original invoice number
  let helpIconText = policyFormData.policy_type === PolicyType.LATE_FEE ?
    `Uses a prefix of "PN-L" followed by a number. E.g "PN-L-1001"` :
    `Uses a prefix of "PN-S" followed by a number. E.g "PN-S-1001"`;

  let description = `Should we use custom invoice numbers.`;

  if (currentOrg.accountingSystemType === `QBO`) {
    helpIconText = `${helpIconText} Leave off to allow QBO to generate the invoice number.`;

    description = `${description} This will become your new current sequence if using custom transaction numbers!`;
  }

  return (
    <Toggle
      label={ config.label }
      helpIcon={ helpIconText }
      description={ description }
      checked={ policyFormData.use_invoice_number_prefix }
      onChange={ handleChange }
      invalid={ fieldIsValid(`use_invoice_number_prefix`, invalids) }
      name={ `use_invoice_number_prefix` }
    />
  );
}
