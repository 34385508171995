import { createSlice } from '@reduxjs/toolkit';
import { StatementRelativePeriods } from 'shared';

const initState: {
  currentCurrency: string;
  statementPeriod: StatementRelativePeriods;
} = {
  currentCurrency: ``,
  statementPeriod: StatementRelativePeriods.ALL_TIME_INC_FUTURE,
};

const contactPagePrefsSlice = createSlice({
  name: `contactPagePrefs`,
  initialState: initState,
  reducers: {
    setCurrentCurrency(state, action) {
      state.currentCurrency = action.payload;
    },
    setStatementPeriod(state, action) {
      state.statementPeriod = action.payload;
    },
  },
});

export const { setCurrentCurrency,  setStatementPeriod } = contactPagePrefsSlice.actions;

export const contactPagePrefsReducer = contactPagePrefsSlice.reducer;
