import { When } from "react-if";

import { classNames } from "../../../../common/lib/classNames";
import { Badge } from "../../../../common/Atoms/Badge";
import { Paragraph } from "../../../../common/Atoms/Typography/Paragraph";

import { IContactPersonRow, IContactTableRow } from "./ContactTable";

interface IContactPersonRowProps {
  personRow: IContactPersonRow;
  personIdx: number;
  selected: boolean;
  parentContact: IContactTableRow;
}

export function ContactPersonRow({ personRow, personIdx, selected, parentContact }: IContactPersonRowProps) {
  const name = personRow.isPrimary ?
    (
      <span className={ `flex items-center` }>
        { personRow.name }
        <Badge
          color={ `blue` }
          className={ `ml-2` }
        >
          { `Primary` }
        </Badge>
      </span>
    ) :
    personRow.name;

  return (
    <tr
      className={ classNames(
        personIdx === 0 ? `border-gray-300` : `border-gray-200`, `border-t`,
        selected && `bg-gray-50`,
      ) }
    >
      <td></td>
      <td className={ `whitespace-nowrap py-4 pl-4 pr-3 sm:pl-3` }>
        <Paragraph
          as={ `span` }
          variant={ `subHeading` }
        >
          { name }
        </Paragraph>
      </td>
      <td className={ `whitespace-nowrap px-3 py-4 text-sm text-gray-500` }>{ personRow.email }</td>
      <td className={ `whitespace-nowrap px-3 py-4 text-sm text-gray-500` }>{ personRow.mobileNumber }</td>
      <td className={ `whitespace-nowrap px-3 py-4 text-sm text-gray-500` }>
        { personRow.includeInNotifications ? `Yes` : `No` }
      </td>

      { /* Balances */ }
      <td className={ `whitespace-nowrap px-3 py-4 text-sm text-gray-500` }>
        <When condition={ personIdx === 0 }>
          <div className={ `flex flex-col items-center` }>
            <span>
              { parentContact.balances.outstanding }{ ` outstanding` }
            </span>
            <When condition={ parentContact.balances.overdue }>
              <span className={ `ml-2 text-red-600` }>
                { parentContact.balances.overdue }{ ` overdue` }
              </span>
            </When>
          </div>
        </When>
      </td>
    </tr>
  );
}
