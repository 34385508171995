import { Outlet } from "react-router";
import { useMemo } from "react";

import { ITab, Tabs } from "../../../../../common/Atoms/Tabs";
import { GroupHeading } from "../GroupHeading";
import { useActions } from "../../../../hooks/useActions";
import { useCurrentGroup } from "../../../../hooks/useGroups";

const formatter = new Intl.NumberFormat(`en`, { notation: `compact` });

export function GroupTableSectionRoot() {
  const { data: actions } = useActions();
  const { groupFormData, invalidPolicyCount } = useCurrentGroup();
  const validPolicyCount = (groupFormData.policies?.length || 0) - invalidPolicyCount;

  const tabs: ITab[] = useMemo(() => {
    return [
      {
        name: `Policies`,
        href: ``,
        badge: {
          text: validPolicyCount.toString(),
          color: `gray`,
        },
        secondaryBadge: invalidPolicyCount > 0 ? {
          text: invalidPolicyCount.toString(),
          className: `text-red-500 bg-red-100`,
          tooltip: `${invalidPolicyCount.toString()} incomplete ${invalidPolicyCount === 1 ? `policy` : `policies`}`,
        } : undefined,
      },
      {
        name: `Contacts`,
        href: `contacts`,
        badge: {
          text: formatter.format(groupFormData.contactsCount || 0),
          color: `gray`,
        },
      },
      {
        name: `Actions`,
        href: `actions`,
        badge: {
          text: formatter.format(actions?.totalPending || 0),
          color: `gray`,
        },
        secondaryBadge: actions?.totalPendingApproval ? {
          text: formatter.format(actions?.totalPendingApproval),
          className: `text-orange-500 bg-orange-100`,
          tooltip: `${formatter.format(actions?.totalPendingApproval)} actions require manual execution.`,
        } : undefined,
      },
    ];
  }, [validPolicyCount, invalidPolicyCount, groupFormData, actions]);

  return (
    <div>
      <GroupHeading />
      { /* <GroupInsights /> */ }
      <Tabs
        tabs={ tabs }
        tabStyle={ `v2` }
      />

      <Outlet />

    </div>
  );
}
