import { Case, Switch } from "react-if";
import { Tooltip } from "@mui/material";
import { IBadContact, MixpanelEvents } from "shared";
import { useParams } from "react-router";
import { FaceSmileIcon } from "@heroicons/react/24/outline";

import { Card } from "../../../../common/Atoms/Card";
import { MultiButtonCardHeader } from "../../../../common/Atoms/MultiButtonCardHeader";
import { Paragraph } from "../../../../common/Atoms/Typography/Paragraph";
import { Badge } from "../../../../common/Atoms/Badge";
import { CardContent } from "../../../../common/Atoms/CardContent";
import { WrappedLink } from "../../WrappedLink";
import { LoadingElementPlaceholder } from "../../../../common/Atoms/LoadingElementPlaceholder";
import { useFetchSlowPayersMetricsQuery } from "../../../services/api/organisationApi/organisation";
import { Button } from "../../../../common/Atoms/Button";
import { classNames } from "../../../../common/lib/classNames";
import { mixpanelService } from "../../../../common/lib/mixpanel";
import { useAdhocActionModal } from "../../../hooks/useAdhocActionModal";

function renderBalances(contact: IBadContact) {
  const overdueBalances: string[] = [];

  // for any non-zero balances, add the overdue string to the list
  contact.balances.filter(b => b.overdue > 0).forEach(balance => {
    overdueBalances.push(balance.overdueString);
  });

  return (
    <div className={ `max-w-fit place-self-center` }>
      <Paragraph
        className={ `text-red-500 font-normal` }
      >
        <Tooltip title={ `Overdue balance` }>
          <span>
            { overdueBalances.join(`, `) }
          </span>
        </Tooltip>
      </Paragraph>
    </div>
  );
}

function renderOutstandingBalances(contact: IBadContact) {
  const outstanding: string[] = [];

  // for any non-zero balances, add the overdue string to the list
  contact.balances.filter(b => b.outstanding > 0).forEach(balance => {
    outstanding.push(balance.outstandingString);
  });

  return (
    <div className={ `max-w-fit place-self-center` }>
      <Paragraph
        className={ `font-light text-xs` }
      >
        <Tooltip title={ `Outstanding balance` }>
          <span>
            { outstanding.join(`, `) }
          </span>
        </Tooltip>
      </Paragraph>
    </div>
  );
}

export function BadContacts() {
  const { organisationId } = useParams<{ organisationId: string }>();
  const { openSendStatement, openChargeInterest } = useAdhocActionModal();

  const { data: response, isLoading } = useFetchSlowPayersMetricsQuery({ orgId: organisationId }, {
    skip: !organisationId,
    refetchOnMountOrArgChange: true,
  });

  function onChargeInterest(contact: IBadContact) {
    openChargeInterest(contact.id, contact.name);

    mixpanelService.trackMixpanelEvent(MixpanelEvents.DashboardChargeInterestNowClick);
  }

  function onSendStatement(contact: IBadContact) {
    openSendStatement(contact.id, contact.name);

    mixpanelService.trackMixpanelEvent(MixpanelEvents.DashboardSendStatementNowClick);
  }

  const badContacts = response?.slowestPayers || [];

  return (
    <Card className={ `w-full h-full` }>
      <MultiButtonCardHeader
        title={ `Slowest Payers` }
        description={ `Contacts with the oldest overdue invoices` }
      />
      <CardContent className={ `mt-4` }>
        <Switch>
          <Case condition={ isLoading }>
            <LoadingElementPlaceholder
              border={ false }
              rows={ 6 }
            />
          </Case>
          <Case condition={ !badContacts.length }>
            <div className={ `min-h-[200px] h-full flex flex-col items-center justify-center text-center` }>
              <FaceSmileIcon className={ `w-12 h-12 mx-auto text-gray-300` } />
              <Paragraph variant={ `secondary` }>
                { `You have no slow payers!` }
              </Paragraph>
            </div>
          </Case>
          <Case condition={ badContacts.length }>
            <div className={ `space-y-1` }>
              { badContacts.map(contact => {
                return (
                  <div
                    className={ `grid grid-cols-3 pb-1` }
                    key={ contact.id }
                  >

                    { /* Name link */ }
                    <WrappedLink
                      to={ `/contacts/${contact.id}` }
                    >
                      <Paragraph
                        variant={ `link` }
                        className={ `truncate` }
                      >
                        { contact.name }
                      </Paragraph>
                    </WrappedLink>

                    { /* Balances */ }
                    { renderBalances(contact) }

                    { /* Charge Interest */ }
                    <div className={ classNames(
                      `col-span-1 flex justify-end items-start mb-1`,
                    ) }>
                      <Button
                        size={ `sm` }
                        color={ `lateGreenOutline` }
                        onClick={ () => onChargeInterest(contact) }
                        className={ `font-light` }
                      >
                        { `Charge Interest` }
                      </Button>
                    </div>

                    { /* Age */ }
                    <div className={ `max-w-fit place-self-start` }>
                      <Tooltip title={ `Age of oldest overdue invoice` }>
                        <Badge
                          color={ `red` }
                        >
                          { contact.daysOverdue.max }
                          { ` days` }
                        </Badge>
                      </Tooltip>
                    </div>

                    { renderOutstandingBalances(contact) }

                    { /* Send Statement */ }
                    <div className={ classNames(
                      `col-span-1 flex justify-end items-end`,
                    ) }>
                      <Button
                        size={ `sm` }
                        color={ `lateGreenOutline` }
                        onClick={ () => onSendStatement(contact) }
                        className={ `font-light` }
                      >
                        { `Send Statement` }
                      </Button>
                    </div>
                  </div>
                );
              })
              }
            </div>
          </Case>

        </Switch>
      </CardContent>
    </Card>
  );
}
