import { IInvalidField, IPolicy, PolicyType } from "shared";
import { useMemo, useState } from "react";

import { useAccountingResources } from "../../../../hooks/useAccountingResources";
import { Toggle } from "../../../../../common/Atoms/Toggle";
import { Transition } from "../../../../../common/Atoms/Transition";
import { MultiSelect } from "../../../../../common/Atoms/MultiSelect";
import { fieldIsValid } from "../../../../lib/helper";
import { Grid } from "../../../../../common/Atoms/Grid";
import { GridItem } from "../../../../../common/Atoms/GridItem";

interface IAccountMultiSelectorProps {
  policy: IPolicy;
  invalids: IInvalidField[];
  handleChange: (obj: {
    name: keyof IPolicy;
    value: string
  }) => void;
}

export function AccountMultiSelector({
  policy,
  handleChange,
  invalids,
}: IAccountMultiSelectorProps) {
  const [showExcludeAccounts, setShowExcludeAccounts] = useState(policy.exclude_account_lines?.length > 0);
  const { data: resources, isLoading } = useAccountingResources();

  const descriptionText = policy.policy_type === PolicyType.LATE_FEE || policy.policy_type === PolicyType.STATEMENT_LATE_FEE ?
    `Exclude these invoice lines when calculating percentages, if all invoice lines are excluded, this policy will not apply to the invoice` :
    `If all invoice lines are excluded, this policy will not apply to the invoice` ;

  const selectedAccounts = useMemo(() => {
    return policy.exclude_account_lines?.split(`,`) || [];
  }, [policy.exclude_account_lines]);

  const displayValue = useMemo(() => {
    if (isLoading) {
      return `Loading...`;
    }

    if (!selectedAccounts.length) {
      return `None Selected`;
    }

    return `${selectedAccounts.length} selected`;
  }, [selectedAccounts, isLoading]);

  const accounts = useMemo(() => {
    if (isLoading) {
      return [];
    }

    if (!resources?.accounts) {
      return [];
    }

    return resources.accounts.map(account => ({
      label: account.label,
      value: account.code,
    }));
  }, [resources?.accounts, isLoading]);

  function onExcludeAccountsToggle() {
    if (showExcludeAccounts) {
      handleChange({
        value: null,
        name: `exclude_account_lines`,
      });
    }

    setShowExcludeAccounts(!showExcludeAccounts);
  }

  function handleExcludeAccountsChange(value: string[]) {
    handleChange({
      value: value.join(`,`),
      name: `exclude_account_lines`,
    });
  }

  return (
    <>
      <Grid
        cols={ 3 }
      >
        <GridItem span={ 3 }>
          <Toggle
            label={ `Exclude invoice lines` }
            description={ descriptionText }
            checked={ showExcludeAccounts }
            onChange={ onExcludeAccountsToggle }
          />
        </GridItem>

        <GridItem span={ 3 }>
          <Transition
            show={ showExcludeAccounts }
            speed={ `slow` }
          >
            <div>
              <MultiSelect
                options={ accounts }
                onChange={ handleExcludeAccountsChange }
                invalid={ fieldIsValid(`exclude_account_lines`, invalids) }
                selected={ selectedAccounts }
                display={ displayValue }
              />
            </div>
          </Transition>
        </GridItem>
      </Grid>
    </>
  );
}
