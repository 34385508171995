import { GroupRuleType, IGroupDefaultRule } from "shared";
import { useMemo } from "react";

import { useAccountingLanguge } from "../../../../../hooks/useAccountingLanguage";
import { Select } from "../../../../../../common/Atoms/Select";
import { Heading } from "../../../../../../common/Atoms/Typography/Heading";
import { useAllGroupRules, useGroups } from "../../../../../hooks/useGroups";
import { useContacts } from "../../../../../hooks/useContacts";
import { Button } from "../../../../../../common/Atoms/Button";

interface IGroupRuleProps {
  rule: IGroupDefaultRule
  onChange: (rule: IGroupDefaultRule) => void
  onSave: () => void
  onCancel: () => void
}

export function NewGroupRule({ rule, onChange, onSave, onCancel }: IGroupRuleProps) {
  const accLanguage = useAccountingLanguge();
  const { data: groups } = useGroups();
  const { data: contacts } = useContacts();
  const allGroupRules = useAllGroupRules();

  function getCondition(r = rule.type) {
    if (r === GroupRuleType.EXTERNAL_AGGREGATE) {
      return accLanguage.groups.externalAggregateGroupRule;
    }
  }

  const groupOptions = useMemo(() => {
    if (!groups) {
      return [];
    }

    return groups.map(g => ({
      label: g.title,
      value: g.id,
    }));
  }, [ groups ]);

  const externalAggregateNameOptions = useMemo(() => {
    if (!contacts || !contacts.externalAggregateNames) return [];

    return contacts.externalAggregateNames
      .filter(name => !!name)
      .map(name => ({
        label: name,
        value: name,
        disabled: allGroupRules.some(r => r.value === name),
      }));
  }, [contacts]);

  const triggerOptions = useMemo(() => {
    return Object.values(GroupRuleType).map(t => ({
      label: getCondition(t),
      value: t,
    }));
  }, [ accLanguage ]);

  const selectedTrigger = useMemo(() => {
    return triggerOptions.find(t => t.value === rule.type);
  }, [ triggerOptions, rule.type ]);

  function handleTriggerChange(e: { value: string, name: string }) {
    onChange({
      ...rule,
      type: e.value as GroupRuleType,
    });
  }

  function handleValueChange(e: { value: string, name: string }) {
    onChange({
      ...rule,
      value: e.value,
    });
  }

  function handleGroupChange(e: { value: number, name: string }) {
    onChange({
      ...rule,
      groupId: e.value,
    });
  }

  function renderValueField() {
    if (rule.type === GroupRuleType.EXTERNAL_AGGREGATE) {
      return (
        <Select
          options={ externalAggregateNameOptions }
          selected={ rule.value }
          onChange={ handleValueChange }
        />
      );
    }
  }

  const valid = (rule.value && rule.groupId && rule.type) || false;

  return (
    <div className={ `border-y border-gray-200 py-6 w-full` }>
      <div className={ `grid grid-cols-3 gap-4` }>
        { /* When section */ }
        <div className={ `col-span-1` }>
          <Heading className={ `self-center` }>
            { `When` }
          </Heading>
          <Select
            options={ triggerOptions }
            selected={ selectedTrigger.value }
            onChange={ handleTriggerChange }
          />
        </div>

        { /*  Is Section */ }
        <div>
          <div>
            <Heading className={ `self-center` }>
              { `is` }
            </Heading>
          </div>
          <div
          >
            { renderValueField() }
          </div>
        </div>

        <div className={ `col-span-1` }>
          <div
          >
            <Heading className={ `self-center` }>
              { `then assign contact to Paidnice group:` }
            </Heading>
          </div>

          <div
          >
            <Select
              options={ groupOptions }
              selected={ rule.groupId }
              onChange={ handleGroupChange }
            />
          </div>
        </div>
      </div>
      <div className={ `flex justify-center pt-6 space-x-4` }>
        <Button
          onClick={ onCancel }
          color={ `gray` }
        >
          { `Cancel` }
        </Button>

        <Button
          disabled={ !valid }
          onClick={ onSave }
        >
          { `Save Rule` }
        </Button>
      </div>
    </div>
  );
}
