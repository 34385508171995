import { CacheTags } from '../../types/cacheTags';
import { emptySplitApi } from '../baseApi';

export const paymentsApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchPlans: builder.query({
      query: () => ({
        url: `payments/plans`,
        method: `GET`,
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      transformResponse: (response: any) => response?.plans,
      providesTags: [
        { type: CacheTags.Settings, id: `LIST` },
      ],
    }),
  }),
});

export const {
  useFetchPlansQuery,
} = paymentsApi;
