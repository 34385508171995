import { PolicyType } from "shared";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { useMemo } from "react";
import { Tooltip } from "@mui/material";

import { classNames } from "../../../../common/lib/classNames";

export interface FeaturesProps {
  chosenFeatures: PolicyType[];
  setChosenFeatures: (features: PolicyType[]) => void;
}

export function Features({ chosenFeatures, setChosenFeatures }: FeaturesProps) {
  function handleChange(feature: PolicyType) {
    const isSelected = chosenFeatures.includes(feature);

    if (isSelected) {
      setChosenFeatures(chosenFeatures.filter(f => f !== feature));
    }
    else {
      if (feature === PolicyType.LATE_FEE && chosenFeatures.includes(PolicyType.STATEMENT_LATE_FEE)) {
        // Remove the statement late fee if late fee is selected
        setChosenFeatures([...chosenFeatures.filter(f => f !== PolicyType.STATEMENT_LATE_FEE), feature]);

        return;
      }

      if (feature === PolicyType.STATEMENT_LATE_FEE && chosenFeatures.includes(PolicyType.LATE_FEE)) {
        // Remove the late fee if statement late fee is selected
        setChosenFeatures([...chosenFeatures.filter(f => f !== PolicyType.LATE_FEE), feature]);

        return;
      }

      setChosenFeatures([...chosenFeatures, feature]);
    }
  }

  const features: {
    type: PolicyType;
    title: string;
    description: string;
    isSelected: boolean;
    disabled?: boolean;
    tooltip?: string;
  }[] = useMemo(() => {
    return [
      {
        type: PolicyType.LATE_FEE,
        title: `Invoice Late Fees & Interest`,
        description: `Automatically charge late fees and interest on overdue invoices, for each invoice`,
        isSelected: chosenFeatures.includes(PolicyType.LATE_FEE),
        disabled: chosenFeatures.includes(PolicyType.STATEMENT_LATE_FEE),
        tooltip: chosenFeatures.includes(PolicyType.STATEMENT_LATE_FEE) ? `You can't have both Invoice and Contact Late Fee enabled at the same time` : undefined,
      },
      {
        type: PolicyType.STATEMENT_LATE_FEE,
        title: `Contact Late Fees & Interest`,
        description: `Automatically charge late fees and interest on overdue invoices, consolidated per contact on a recurring basis`,
        isSelected: chosenFeatures.includes(PolicyType.STATEMENT_LATE_FEE),
        disabled: chosenFeatures.includes(PolicyType.LATE_FEE),
        tooltip: chosenFeatures.includes(PolicyType.LATE_FEE) ? `You can't have both Invoice and Contact Late Fee enabled at the same time` : undefined,
      },
      {
        type: PolicyType.REMINDER,
        title: `Email Reminders & Notifications`,
        description: `Automatically send reminders and schedule invoice sending using customisable templates`,
        isSelected: chosenFeatures.includes(PolicyType.REMINDER),
      },
      {
        type: PolicyType.STATEMENT,
        title: `Automatic Statement Sending`,
        description: `Automatically send statements to contacts on a recurring weekly or monthly basis.`,
        isSelected: chosenFeatures.includes(PolicyType.STATEMENT),
      },
      {
        type: PolicyType.ESCALATION,
        title: `Escalations & Manual Follow Ups`,
        description: `Automatically escalate overdue invoices and schedule manual follow ups, such as phone calls or letters`,
        isSelected: chosenFeatures.includes(PolicyType.ESCALATION),
      },
      {
        type: PolicyType.QUOTE_EMAIL,
        title: `Automated Quote Emails`,
        description: `Automatically follow up on outstanding quotes before or after the expiry dates`,
        isSelected: chosenFeatures.includes(PolicyType.QUOTE_EMAIL),
      },
      {
        type: PolicyType.SMS,
        title: `SMS Reminders & Notifications`,
        description: `Automatically send SMS reminders to your contacts`,
        isSelected: chosenFeatures.includes(PolicyType.SMS),
      },
      {
        type: PolicyType.DISCOUNT,
        title: `Prompt Payment Discounts`,
        description: `Automatically apply discounts to invoices which will be removed if not paid within the chosen time frame`,
        isSelected: chosenFeatures.includes(PolicyType.DISCOUNT),
      },
    ];
  }, [chosenFeatures]);

  return (
    <div className={ `grid grid-cols-3 lg:grid-cols-4 gap-6` }>
      {
        features.map(feature => {
          const { isSelected, disabled, tooltip } = feature;

          const content = (
            <button
              key={ feature.type }
              className={ classNames(
                isSelected ? `border-transparent` : `border-gray-300`,
                disabled && `opacity-50`,
                // active ? `border-lateGreen-500 ring-2 ring-lateGreen-500` : ``,
                `relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none`,
              ) }
              onClick={ () => handleChange(feature.type) }
            >
              <>
                <span className={ `flex flex-1` }>
                  <span className={ `flex flex-col` }>
                    <span className={ `block text-sm font-medium text-gray-900` }>
                      { feature.title }
                    </span>

                    <span className={ `mt-1 flex items-center text-sm text-gray-500` }>
                      { feature.description }
                    </span>
                  </span>
                </span>

                <CheckCircleIcon
                  className={ classNames(
                    !isSelected ? `invisible` : ``, `h-5 w-5 text-lateGreen-600`,
                  ) }
                  aria-hidden={ true }
                />
                <span
                  className={ classNames(
                    isSelected ? `border` : `border-2`,
                    isSelected ? `border-lateGreen-500` : `border-transparent`,
                    `pointer-events-none absolute -inset-px rounded-lg`,
                  ) }
                  aria-hidden={ `true` }
                />

              </>
            </button>
          );

          if (!tooltip) {
            return content;
          }

          return (
            <Tooltip
              key={ feature.type }
              title={ tooltip }
            >
              { content }
            </Tooltip>
          );
        })
      }
    </div>
  );
}
