import { Card } from "../../../../../common/Atoms/Card";
import { CardContent } from "../../../../../common/Atoms/CardContent";
import { Divider } from "../../../../../common/Atoms/Divider";
import { useGeneralSettingsForm } from "../../../../hooks/useGeneralSettingsForm";
import { SaveAndError } from "../SaveAndError";

import { DefaultBehaviour } from "./DefaultBehaviour";
import { GroupDefaultSettings } from "./GroupAdvanced/GroupDefaultSettings";
import { Webhooks } from "./Webhooks";

export function AdvancedRoot() {
  const {
    formData,
    onChange,
  } = useGeneralSettingsForm();

  return (
    <Card>
      <CardContent className={ `pt-6` }>
        <DefaultBehaviour
          formData={ formData }
          onChange={ onChange }
        />

        <Divider weight={ `light` } />
        { /* Group Rules */ }
        <GroupDefaultSettings />

        <Webhooks
          formData={ formData }
          onChange={ onChange }
        />

        <SaveAndError
        />
      </CardContent>
    </Card>
  );
}
