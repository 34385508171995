import { createSlice } from '@reduxjs/toolkit';

export interface IPreviewPolicyState {
  showModal: boolean;
  invoiceId: string | null;
  policyId: number | null;
  contactId: number | null;
}

const initialState = {
  showModal: false,
  invoiceId: null,
  policyId: null,
  contactId: null,
};

const previewPolicySlice = createSlice({
  name: `previewPolicy`,
  initialState,
  reducers: {
    setState(state, action) {
      Object.assign(state, action.payload);
    },
  },
});

export const { setState } = previewPolicySlice.actions;

export const previewPolicyReducer = previewPolicySlice.reducer;
