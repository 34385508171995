import { GroupRuleType, IGroupDefaultRule } from "shared";
import { ArrowDownCircleIcon, ArrowUpCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { Tooltip } from "@mui/material";

import { useAccountingLanguge } from "../../../../../hooks/useAccountingLanguage";
import { useGroups } from "../../../../../hooks/useGroups";
import { Heading } from "../../../../../../common/Atoms/Typography/Heading";
import { Button } from "../../../../../../common/Atoms/Button";

interface SavedRuleProps {
  rule: IGroupDefaultRule
  onDelete: (rule: IGroupDefaultRule) => void
  onPriorityChange: (rule: IGroupDefaultRule, type: string) => void
  loading?: boolean
}

export function SavedRule({ rule, onDelete, loading, onPriorityChange }: SavedRuleProps) {
  const accLanguage = useAccountingLanguge();
  const { data: groups } = useGroups();

  function renderTriggerValue() {
    if (rule.type === GroupRuleType.EXTERNAL_AGGREGATE) {
      return accLanguage.groups.externalAggregateGroupRule;
    }
  }

  function renderValueField() {
    if (rule.type === GroupRuleType.EXTERNAL_AGGREGATE) {
      return rule.value;
    }
  }

  function renderGroupField() {
    if (!groups) {
      return null;
    }

    const g = groups.find(g => g.id === rule.groupId);

    if (!g) {
      return null;
    }

    return g.title;
  }

  return (
    <div className={ `border-y border-gray-200 py-6 w-full flex justify-between px-6` }>
      <div
        className={ `flex justify-between items-center space-x-6` }>

        <div>
          <Heading className={ `self-center` }>
            { `When` }
          </Heading>
        </div>
        <div>
          { renderTriggerValue() }
        </div>
        <div>
          <Heading className={ `self-center` }>
            { `is` }
          </Heading>
        </div>
        <div
        >
          { renderValueField() }
        </div>

        <div
        >
          <Heading className={ `self-center` }>
            { `then assign contact to Paidnice group:` }
          </Heading>
        </div>

        <div
        >
          { renderGroupField() }
        </div>
      </div>
      <div className={ `flex` }>
        <div className={ ` border-l border-gray-300 pl-6 ml-4 flex justify-end items-center space-x-1` }>
          <Button
            tooltip={ `Move Rule Up` }
            onClick={ () => onPriorityChange(rule, `up`) }
            color={ `gray` }
            icon={ <ArrowUpCircleIcon className={ `h-8 w-8` } /> }
            isIcon
            loading={ loading }
          />

          <Button
            tooltip={ `Move Rule Down` }
            onClick={ () => onPriorityChange(rule, `down`) }
            color={ `gray` }
            icon={ <ArrowDownCircleIcon className={ `h-8 w-8 ` } /> }
            loading={ loading }
            isIcon
          />
        </div>

        <div className={ ` border-l border-gray-300 pl-6 ml-4 flex justify-between items-center` }>
          <Tooltip title={ `Delete Rule` }>
            <Button
              onClick={ () => onDelete(rule) }
              color={ `red` }
            >
              <XCircleIcon className={ `h-5 w-5` } />
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
