import { policyConfig } from "shared";

import { fieldIsValid } from "../../../../../lib/helper";
import { Toggle } from "../../../../../../common/Atoms/Toggle";

import { IFieldProps } from "./fieldProps";

const config = policyConfig[`first_time_only`];

export function FirstTimeOnly({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {

  return (
    <Toggle
      label={ config.label }
      description={ `Only apply this late fee if the invoice has no other late fees applied.` }
      checked={ policyFormData.first_time_only || false }
      onChange={ handleChange }
      invalid={ fieldIsValid(`first_time_only`, invalids) }
      name={ `first_time_only` }
    />
  );
}
