import { Fragment, useMemo, useRef } from 'react';
import {  Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import {
  Bars3BottomLeftIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { Link, useLocation } from 'react-router-dom';
import { When } from 'react-if';
import { Tooltip } from '@mui/material';

import { classNames }  from '../../../common/lib/classNames';
import { OrgSelection } from '../OrgSelection';
import { env } from '../../../common/lib/env';
import { useUser } from '../../hooks/useUser';
import { FEATURE_REQUEST_FORM, HELP_CENTRE_HOME, SAFE_MODE_ARTICLE } from '../../constants/links';
import { Paragraph } from '../../../common/Atoms/Typography/Paragraph';
import { Toggle } from '../../../common/Atoms/Toggle';
import { useSettings } from '../../hooks/useSettings';
import { useHasValidSubscription } from '../../hooks/useHasValidSubscription';
import { useGetSelectedOrganisation } from '../../hooks/useGetSelectedOrganisation';
import { useUpdateSettingsMutation } from '../../services/api/settingsApi/settings';
import { HelpLinkIcon } from '../Molecules/HelpLinkIcon';
import { useGetSubscriptionMetadata } from '../../hooks/useGetSubscription';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';

export function Navigation({
  setSidebarOpen,
}) {
  const { data: user } = useUser();
  const { pathname } = useLocation();
  const buttonRef = useRef(null);
  const { data: settings } = useSettings();
  const currentOrg = useGetSelectedOrganisation();
  const hasValidSub = useHasValidSubscription();
  const [ updateSettings, { isLoading } ] = useUpdateSettingsMutation();
  const { subscriptionMetadata } = useGetSubscriptionMetadata();
  const featureFlags = useFeatureFlags();

  const userNavigation = useMemo(() => {
    const arr: {
      name: string;
      to?: string;
      href?: string;
      props?: {
        rel: string;
        target: string;
      };
      tourTarget?: string;
      onClick?: () => void;
    }[] = [
      {
        name: `My Account`,
        to: `/account`,
      },
      {
        name: user?.notifications?.length ? `Notifications (${user.notifications.length})` : `Notifications`,
        to: `/notifications`,
      },
      {
        name: `Billing & Subscriptions`,
        to: `/billing`,
        tourTarget: `billing`,
      },
      { name: `Sign out`, href: `${env.apiUrl}/logout` },
      {
        name: `Help Articles`,
        href: HELP_CENTRE_HOME,
        props: {
          rel: `noopener noreferrer`,
          target: `__blank`,
        },
      },
      {
        name: `Request a Feature`,
        href: FEATURE_REQUEST_FORM,
        props: {
          rel: `noopener noreferrer`,
          target: `__blank`,
        },
      },
    ];

    if (featureFlags?.ai && subscriptionMetadata?.walletBalanceFormatted) {
      arr.push({
        name: `Credit Balance: ${subscriptionMetadata.walletBalanceFormatted}`,
        to: `#`,
      });
    }

    return arr;
  }, [currentOrg, user]);

  function onSafeModeChange() {
    if (!settings) {
      // Should never be here
      console.error(`Settings not loaded - safe mode change attempted`);

      return;
    }

    if (pathname.startsWith(`/billing`)) {
      // Should never be here
      console.error(`Safe mode change attempted from billing page`);

      return;
    }

    if (!currentOrg?.id) {
      // Should never be here
      console.error(`No current org - safe mode change attempted`);

      return;
    }

    updateSettings({
      organisationId: currentOrg.id,
      settings: {
        ...settings,
        active: !settings.active,
      },
    });
  }

  let safeModeTooltip = `Safe mode prevents automatic processing of invoices. Turn off safe mode when you are ready to start applying policies automatically.`;

  if (hasValidSub && settings?.active) {
    safeModeTooltip = `Safe mode is currently off - Invoices will be processed automatically.`;
  }

  return (
    <div className={ `sticky top-0 z-10 flex h-16  bg-white shadow` }>
      <button
        type={ `button` }
        className={ `border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-lateGreen-500 md:hidden` }
        onClick={ () => setSidebarOpen(true) }
      >
        <span className={ `sr-only` }>{ `Open sidebar` }</span>
        <Bars3BottomLeftIcon className={ `h-6 w-6` }
          aria-hidden={ `true` } />
      </button>
      { /* Main Nav Bar */ }
      <div className={ `flex flex-1 justify-between px-4` }>

        { /* Left Section */ }
        <div className={ `flex flex-1 items-center` }>
          <When condition={ !pathname.startsWith(`/billing`) }>
            <div className={ `w-full flex` }>

              <div className={ `w-1/3 md:w-1/4` }>
                <OrgSelection />
              </div>

              <When condition={ currentOrg?.id }>
                { /* Safe Mode */ }
                <Tooltip title={ safeModeTooltip }>
                  <div className={ `flex items-center ml-32 md:ml-28 lg:ml-12 xl:ml-4` }>
                    <Toggle
                      checked={ !settings?.active }
                      onChange={ onSafeModeChange }
                      checkedClassname={ `bg-orange-500` }
                      uncheckedClassname={ `bg-green-500` }
                      loading={ isLoading }
                    />
                    <Paragraph
                      variant={ `secondary` }
                      className={ `ml-2 underline hidden md:flex` } // Hide on mobile
                    >
                      { !settings?.active ? `Safe Mode On` : `Safe Mode Off` }
                    </Paragraph>
                    <HelpLinkIcon
                      className={ `ml-2` }
                      href={ SAFE_MODE_ARTICLE }
                    />
                  </div>
                </Tooltip>
              </When>
            </div>
          </When>

        </div>

        { /* Right Section */ }
        <div className={ `ml-4 flex items-center md:ml-6` }>
          { /* Profile dropdown */ }
          <Menu as={ `div` }
            className={ `relative ml-3` }>
            <div>
              <MenuButton
                ref={ buttonRef }
                data-tour={ `topNavButton` }
                className={ `flex max-w-xs items-center rounded-full bg-white text-sm ring-gray-300 outline-none ring-1 ring-offset-2 focus:ring-2 focus:ring-lateGreen-500 focus:ring-offset-2` }
              >
                <span className={ `sr-only` }>{ `Open user menu` }</span>
                <ChevronDownIcon className={ `h-5 w-5 text-gray-400 mr-1` }
                  aria-hidden={ `true` } />
                <span className={ `mr-2` }>
                  { `Manage Account` }
                </span>
                <When condition={ !!user }>
                  {
                    () => (
                      <Tooltip title={ user.name }>
                        <img
                          className={ `h-8 w-8 rounded-full` }
                          src={ `https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${user.name}` }
                          alt={ `` }
                        />
                      </Tooltip>
                    )
                  }
                </When>
              </MenuButton>
            </div>
            <Transition
              as={ Fragment }
              enter={ `transition ease-out duration-100` }
              enterFrom={ `transform opacity-0 scale-95` }
              enterTo={ `transform opacity-100 scale-100` }
              leave={ `transition ease-in duration-75` }
              leaveFrom={ `transform opacity-100 scale-100` }
              leaveTo={ `transform opacity-0 scale-95` }
            >
              <MenuItems className={ `absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none` }>
                { userNavigation.map(item => (
                  <MenuItem key={ item.name }>
                    { ({ active }) => {
                      if (item.href) {
                        if (item.href === `#`) {
                          return (
                            <a
                              href={ item.href }
                              onClick={ e => e.preventDefault() }
                              className={ classNames(
                                active ? `bg-gray-100` : ``,
                                `block px-4 py-2 text-sm text-gray-700 cursor-default`,
                              ) }
                              data-tour={ item.tourTarget }
                              {
                                ...item.props
                              }
                            >
                              { item.name }
                            </a>
                          );
                        }

                        return (
                          <a
                            href={ item.href }
                            className={ classNames(
                              active ? `bg-gray-100` : ``,
                              `block px-4 py-2 text-sm text-gray-700`,
                            ) }
                            data-tour={ item.tourTarget }
                            {
                              ...item.props
                            }
                          >
                            { item.name }
                          </a>
                        );
                      }

                      if (item.onClick) {
                        return (
                          <a
                            href={ `#` }
                            data-tour={ item.tourTarget }
                            className={ classNames(
                              active ? `bg-gray-100` : ``,
                              `block px-4 py-2 text-sm text-gray-700`,
                            ) }
                            {
                              ...item.props
                            }
                            onClick={ e => {
                              e.preventDefault();
                              item.onClick();
                            } }
                          >
                            { item.name }
                          </a>
                        );
                      }

                      return (
                        <Link
                          to={ item.to }
                          className={ classNames(
                            active ? `bg-gray-100` : ``,
                            `block px-4 py-2 text-sm text-gray-700`,
                          ) }
                        >
                          { item.name }
                        </Link>
                      );
                    } }
                  </MenuItem>
                )) }
              </MenuItems>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
}
