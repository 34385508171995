import { useMemo } from "react";
import { StatementRelativePeriods, currencyFormatter, toDecimal } from "shared";

import { Heading } from "../../../../../common/Atoms/Typography/Heading";
import { Paragraph } from "../../../../../common/Atoms/Typography/Paragraph";
import { Select } from "../../../../../common/Atoms/Select";
import { LoadingOverlay } from "../../../Molecules/LoadingOverlay";
import { getMonthNameFromPeriod } from "../../../../lib/helper";
import { classNames } from "../../../../../common/lib/classNames";
import { useContact } from "../../../../hooks/useContact";

const periodOptions = [
  {
    label: `View all outstanding`,
    value: StatementRelativePeriods.ALL_TIME,
  },
  {
    label: `View all outstanding & future`,
    value: StatementRelativePeriods.ALL_TIME_INC_FUTURE,
  },
  {
    label: `Issued this month`,
    value: StatementRelativePeriods.THIS_MONTH,
  },
  {
    label: `Issued last Month`,
    value: StatementRelativePeriods.PREVIOUS_MONTH,
  },
  {
    label: `Issued upto end of last month`,
    value: StatementRelativePeriods.UPTO_PREVIOUS_MONTH,
  },
];

export function ContactStats() {
  const { data: contact, isLoading, isFetching, setStatementPeriod, statementPeriod, currentCurrency, setCurrentCurrency } = useContact();
  const possibleCurrencies = contact?.possibleCurrencies || [];

  const stats = useMemo(() => {
    if (!contact?.invoices || !contact?.extraData?.statement) {
      return [];
    }

    const invoices = contact.invoices;
    const statement = contact.extraData.statement;

    const formatter = currencyFormatter(currentCurrency);

    const result = [
      {
        name: `Total Invoices`,
        value: `${invoices.length}`,
        info: possibleCurrencies?.length > 1 ? `(All Currencies)` : `(All time)`,
      },
    ];

    const totalRevenueCents = invoices
      .filter(i => i.currency === currentCurrency)
      .reduce((acc, invoice) => {
        return acc + invoice.totals.totalInCents;
      }, 0);

    const currentCurrencyStatement = statement.currencySections.find(i => i.currency === currentCurrency);

    if (!currentCurrencyStatement) {
      return result;
    }

    const outstandingInCents = currentCurrencyStatement.totalOutstandingInCents;

    const overdueInCents = currentCurrencyStatement.totalOverdueInCents;

    const currentPeriodMessage = `(${getMonthNameFromPeriod(statementPeriod)})`;

    result.unshift(...[
      {
        name: `All Time Revenue`,
        value: `${formatter(toDecimal(totalRevenueCents))}`,
        info: `(All time)`,
      },
      {
        name: `Outstanding Amount`,
        value: `${formatter(toDecimal(outstandingInCents))}`,
        info: currentPeriodMessage,
      },
      {
        name: `Overdue Amount`,
        value: `${formatter(toDecimal(overdueInCents))}`,
        info: currentPeriodMessage,
      },
    ]);

    return result;
  }, [contact?.extraData, currentCurrency]);

  if (!contact) {
    return null;
  }

  const cols = stats.length;

  const colsClass = {
    1: ``,
    2: `sm:grid-cols-2 lg:grid-cols-2`,
    3: `sm:grid-cols-3 lg:grid-cols-3`,
    4: `sm:grid-cols-4 lg:grid-cols-4`,
  };

  return (
    <>

      <div className={ `py-4 flex` }>
        <div className={ `w-[200px]` }>
          <Select
            selected={ statementPeriod }
            onChange={ e => setStatementPeriod(e.value as StatementRelativePeriods) }
            options={ periodOptions }
          />
        </div>

        <div className={ `w-[100px] ml-1` }>
          <Select
            selected={ currentCurrency }
            onChange={ e => setCurrentCurrency(e.value) }
            options={ possibleCurrencies.map(currency => ({ label: currency, value: currency })) }
          />
        </div>
      </div>

      <LoadingOverlay
        loading={ isLoading || isFetching }
      >
        <dl className={
          classNames(
            `mx-auto grid grid-cols-1 gap-px bg-gray-900/5`,
            colsClass[cols],
          )
        }>
          { stats.map(stat => (
            <div
              key={ stat.name }
              className={ `flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-4 sm:px-6 xl:px-8` }
            >
              <dt>
                <Paragraph>
                  { stat.name }

                  <Paragraph
                    size={ `sm` }
                    variant={ `help` }
                    as={ `span` }>
                    { ` ` }
                    { stat.info }
                  </Paragraph>
                </Paragraph>
              </dt>
              <dd className={ `w-full flex-none tracking-tight` }>
                <Heading size={ `2xl` }>
                  { stat.value }
                </Heading>
              </dd>
            </div>
          )) }
        </dl>
      </LoadingOverlay>
    </>
  );
}
