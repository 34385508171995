
import { useNavigate } from "react-router";

import { useGetSelectedOrganisation } from "../../hooks/useGetSelectedOrganisation";
import { Badge } from "../../../common/Atoms/Badge";
import { useGetSubscriptionMetadata } from "../../hooks/useGetSubscription";

interface UpgradeBadgeProps {
  size?: `xs` | `sm` | `md` | `lg`;
}

export function UpgradeBadge({
  size = `xs`,
}: UpgradeBadgeProps) {
  const currentOrg = useGetSelectedOrganisation();
  const navigate = useNavigate();
  const {
    isBillingOwner,
    subscriptionMetadata,
  } = useGetSubscriptionMetadata();

  if (!currentOrg) return null;

  function onClick(e) {
    e.stopPropagation();
    e.preventDefault();

    if (subscriptionMetadata?.id && isBillingOwner) {
      navigate(`/billing/subscriptions/${subscriptionMetadata.id}`);
    }
  }

  return (
    <button
      onClick={ onClick }
    >
      <Badge
        color={ `purple` }
        message={ `PRO` }
        size={ size } />
    </button>
  );
}
