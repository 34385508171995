import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';

import { env } from '../../../common/lib/env';
import { mixpanelService } from '../../../common/lib/mixpanel';

const baseConfig = {
  withCredentials: true,
  baseURL: env.baseApiUrl,
};

export const orgHeader = `X-Organisation-Id`;

export const baseRequest = axios.create(baseConfig);

export interface ErrorPayload {
    status: number
    data: {
      message: string
    }
}

export interface ErrorResponse {
  error: ErrorPayload,
}

// https://redux-toolkit.js.org/rtk-query/usage-with-typescript
export const axiosBaseQuery = (): BaseQueryFn<
  AxiosRequestConfig,
  unknown,
  unknown
  > => async requestOpts => {
  try {
    // Always append the org
    const axiosConfig = {
      ...requestOpts,
      ...baseConfig,
    };

    const result = await axios(axiosConfig);

    return {
      data: result.data,
      meta: {
        headers: result.headers,
      },
    };
  }
  catch (axiosError: unknown) {
    const err = axiosError as AxiosError;

    const errorRes: ErrorResponse = {
      error: {
        status: err.response?.status,
        data: {
          message: err.response?.data as string || err.message,
        },
      },
    };

    if (errorRes.error.status === 401 && requestOpts.method === `GET`) {
      // Reset the mixpanel client
      mixpanelService.logout();
      // REDIRECT TO LOGIN
      window.location.href = `${env.apiUrl}/login?redirect=${window.location.href}`;
    }

    return errorRes;
  }
};
