import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { When } from 'react-if';
import { Tooltip } from '@mui/material';
import { MixpanelEvents } from 'shared';

import { Toggle } from '../../../../common/Atoms/Toggle';
import { useActionInvoiceMutation, useSetExpectedPaymentDateMutation } from '../../../services/api/invoiceApi/invoice';
import { Card } from '../../../../common/Atoms/Card';
import { Paragraph } from '../../../../common/Atoms/Typography/Paragraph';
import { Divider } from '../../../../common/Atoms/Divider';
import { useAccountingSystemName } from '../../../hooks/useAccountingSystemName';
import { useGetSelectedOrganisation } from '../../../hooks/useGetSelectedOrganisation';
import { env } from '../../../../common/lib/env';
import { useActionTableModels } from '../../../hooks/useActions';
import { ExpectedPaymentDate } from '../../Common/ExpectedPaymentDate';
import { useInvoice } from '../../../hooks/useInvoice';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { AICallNowModal } from '../AICallNowModal';
import { Badge } from '../../../../common/Atoms/Badge';
import { useAdhocActionModal } from '../../../hooks/useAdhocActionModal';
import { mixpanelService } from '../../../../common/lib/mixpanel';

export function InvoiceActions({ invoiceDetails }) {
  const { organisationId, invoiceId } = useParams();
  const { setFilterModel, setCurrentTab } = useActionTableModels();
  const organisation = useGetSelectedOrganisation();
  const [setExpectedDate, { isLoading: expectedDateLoading }] = useSetExpectedPaymentDateMutation();
  const { invoice } = useInvoice();
  const [ actionInvoice] = useActionInvoiceMutation();
  const featureFlags = useFeatureFlags();
  const [aiCallNowModalOpen, setAiCallNowModalOpen] = useState(false);
  const { openSendInvoiceEmail, openIssueLateFee } = useAdhocActionModal();

  // TODO: QBO link
  const showLink = organisation.type === `XERO`;

  const navigate = useNavigate();

  function goToActions(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();
    setCurrentTab(null);

    setFilterModel({
      items: [
        {
          field: `invoiceNumber`,
          operator: `contains`,
          value: invoice.xero_number,
          id: Math.round(Math.random() * 1e5),
        },
      ],
    });

    navigate(`/${invoice?.organisation_id}/actions?persist=true`);
  }

  const handleAction = async action => {
    actionInvoice({ invoiceId: invoice.id, action, organisationId });
  };

  function onGoToContact(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (e) {
      e.preventDefault();
    }

    navigate(`/${organisation.id}/contacts/${invoice.contact.id}`);
  }

  const onFrozenToggle = () => {
    const action = invoiceDetails.frozen ? `reset` : `freeze`;
    handleAction(action);
  };

  async function expectedPaymentDateSave(date: string) {
    await setExpectedDate({
      date,
      invoiceId,
      organisationId,
    });
  }

  function onSendReminder() {
    openSendInvoiceEmail(invoiceId, invoiceDetails.number);

    mixpanelService.trackMixpanelEvent(MixpanelEvents.InvoicePageSendReminderNowClick);
  }

  function onIssueLateFee() {
    openIssueLateFee(invoiceId, invoiceDetails.number);

    mixpanelService.trackMixpanelEvent(MixpanelEvents.InvoicePageIssueLateFeeNowClick);
  }

  return (
    <div className={ `lg:col-start-3 lg:row-end-1` }>
      <AICallNowModal
        open={ aiCallNowModalOpen }
        onClose={ () => setAiCallNowModalOpen(false) }
        invoice={ invoice }
      />

      <Card>
        { /* <When condition={ invoiceDetails.frozen }>
            <div className={ `flex-none self-end pt-2 pr-2` }>
              <dt className={ `sr-only` }>{ `Status` }</dt>

              <Badge color={ `blue` }
                message={ `Frozen` }
                size={ `md` }/>
            </div>
          </When> */ }
        <Toggle
          className={ `min-w-full mb-4` }
          label={ `Freeze Processing` }
          description={ `Prevent any further processing on this invoice.` }
          checked={ invoiceDetails?.frozen }
          onChange={ onFrozenToggle }
          name={ `skip` }
        />

        <div className={ `flex justify-between pt-2` }>
          <Paragraph>
            { `Expected Payment Date` }
          </Paragraph>
          <ExpectedPaymentDate
            onSave={ expectedPaymentDateSave }
            currentDate={ invoiceDetails.expectedPaymentDate }
            loading={ expectedDateLoading }
          />
        </div>

        <Divider weight={ `light` } />

        <div className={ `space-y-4` }>
          <When condition={ featureFlags?.ai && invoice.xero_status === `AUTHORISED` }>
            <Paragraph>
              <a
                href={ `#` }
                onClick={ e => {
                  e.preventDefault();
                  setAiCallNowModalOpen(true);
                } }
                className={ `flex` }
              >
                { `Call Customer with AI ` }<span aria-hidden={ `true` }
                  className={ `mr-1` }>&rarr;</span>
                { ` ` }
                <Tooltip title={ `You have early access to this feature. It may not be perfect so please let us know if you have any feedback!` }>
                  <Badge
                    color={ `blue` }
                  >
                    { `Early Access` }
                  </Badge>
                </Tooltip>

              </a>
            </Paragraph>
          </When>
          <Paragraph
            className={ `mb-4` }
          >
            <a
              href={ `#` }
              onClick={ e => {
                e.preventDefault();
                onSendReminder();
              } }
              target={ `_blank` }
              rel={ `noopener noreferrer` }
            >
              { `Send Reminder Email ` }<span aria-hidden={ `true` }>&rarr;</span>
            </a>
          </Paragraph>

          <Paragraph
            className={ `mb-4` }
          >
            <a
              href={ `#` }
              onClick={ e => {
                e.preventDefault();
                onIssueLateFee();
              } }
              target={ `_blank` }
              rel={ `noopener noreferrer` }
            >
              { `Issue Late Fee ` }<span aria-hidden={ `true` }>&rarr;</span>
            </a>
          </Paragraph>

          <Paragraph
            className={ `mb-4` }
          >
            <a
              href={ `#` }
              onClick={ goToActions }
              target={ `_blank` }
              rel={ `noopener noreferrer` }
            >
              { `View Invoice Actions ` }<span aria-hidden={ `true` }>&rarr;</span>
            </a>
          </Paragraph>
          <Paragraph
            className={ `mb-4` }
          >
            <a
              href={ `#` }
              onClick={ onGoToContact }
              target={ `_blank` }
              rel={ `noopener noreferrer` }
            >
              { `View Contact Statement ` }<span aria-hidden={ `true` }>&rarr;</span>
            </a>
          </Paragraph>
          <When condition={ showLink }>
            <Paragraph>
              <a
                href={ invoiceDetails.externalLink }
                target={ `_blank` }
                rel={ `noopener noreferrer` }
              >
                { `View in ` + useAccountingSystemName() }<span aria-hidden={ `true` }>&rarr;</span>
              </a>
            </Paragraph>
          </When>
          <Paragraph>
            <a
              href={ `${env.portalWebUrl}/i/${invoiceDetails.hash}` }
              target={ `_blank` }
              rel={ `noopener noreferrer` }
              className={ `flex` }
            >
              { `View in Customer Portal ` }<span aria-hidden={ `true` }
                className={ `mr-1` }>&rarr;</span>
            </a>
          </Paragraph>
        </div>
      </Card>
    </div>
  );
}
