/* eslint-disable no-case-declarations */
import { ArrowUturnLeftIcon, CalendarDaysIcon, CalendarIcon, CloudArrowDownIcon, CurrencyDollarIcon, NoSymbolIcon, PauseCircleIcon, PlayCircleIcon } from '@heroicons/react/20/solid';
import { useMemo } from 'react';
import { InvoiceEventType } from 'shared';

import { useInvoice } from "../../../hooks/useInvoice";
import { LoadingOverlay } from "../../Molecules/LoadingOverlay";
import { classNames } from '../../../../common/lib/classNames';
import { Card } from '../../../../common/Atoms/Card';
import { IHeroIcon } from '../../../types/types';
import { Paragraph } from '../../../../common/Atoms/Typography/Paragraph';

const iconMap: {
  [key in InvoiceEventType]: {
    icon: IHeroIcon;
    className: string;
    readableEvent: string;
  }
} = {
  [InvoiceEventType.AUTO_FROZEN]: {
    className: `bg-blue-500`,
    icon: PauseCircleIcon,
    readableEvent: `Invoice was automatically frozen due to no policies in group`,
  },
  [InvoiceEventType.MANUAL_FROZEN]: {
    className: `bg-blue-500`,
    icon: PauseCircleIcon,
    readableEvent: `Invoice was manually frozen`,
  },
  [InvoiceEventType.UNFROZEN]: {
    icon: PlayCircleIcon,
    className: `bg-green-500`,
    readableEvent: `Invoice was unfrozen`,
  },
  [InvoiceEventType.PAID]: {
    icon: CurrencyDollarIcon,
    className: `bg-green-500`,
    readableEvent: `Invoice was marked as paid`,
  },
  [InvoiceEventType.PAYMENT_REVERSED]: {
    icon: ArrowUturnLeftIcon,
    className: `bg-orange-500`,
    readableEvent: `Invoice previously marked as paid was marked as unpaid`,
  },
  [InvoiceEventType.VOIDED]: {
    icon: NoSymbolIcon,
    className: `bg-gray-500`,
    readableEvent: `Invoice was voided`,
  },
  [InvoiceEventType.IMPORTED]: {
    icon: CloudArrowDownIcon,
    className: `bg-lateGreen-300`,
    readableEvent: `Invoice was imported into Paidnice`,
  },
  [InvoiceEventType.DUE_DATE_CHANGED]: {
    icon: CalendarIcon,
    className: `bg-yellow-500`,
    readableEvent: `Invoice due date was changed from #from to #to`,
  },
  [InvoiceEventType.EXPECTED_PAYMENT_DATE_CHANGED]: {
    icon: CalendarDaysIcon,
    className: `bg-indigo-500`,
    readableEvent: ``, // SHould have a message override
  },
};

export function InvoiceEvents() {
  const { invoice, isLoading } = useInvoice();

  const timeline = useMemo(() => {
    if (!invoice) return [];

    if (!invoice.events) return [];

    const createdEvent = invoice.events.find(e => e.type === InvoiceEventType.IMPORTED);

    if (!createdEvent) {
      console.error(`InvoiceEvents: No imported event found for invoice ${invoice.id}`);

      return [];
    }

    return invoice.events.map(e => {
      const row = {
        id: e.id,
        content: ``,
        datetime: new Date(e.createdAt).toLocaleString(),
        icon: iconMap[e.type].icon,
        iconBackground: iconMap[e.type].className,
      };

      switch (e.type) {
      case InvoiceEventType.DUE_DATE_CHANGED:

        const mostRecentEventPrior = invoice.events
          .filter(oe => (Number(oe.id) < Number(e.id)) && oe.invoiceDueDateString)
          .sort((a, b) => Number(b.id) - Number(a.id))[0];

        row.content = iconMap[e.type].readableEvent.replace(`#from`, mostRecentEventPrior.invoiceDueDateString).replace(`#to`, e.invoiceDueDateString);

        break;
      default:
        row.content = iconMap[e.type].readableEvent;
        break;
      }

      if (e.displayMessage) {
        row.content = e.displayMessage;
      }

      return row;
    });
  }, [invoice?.events]);

  return (
    <Card className={ `mt-6 flow-root` }>

      <LoadingOverlay loading={ isLoading }>
        <ul role={ `list` }
          className={ `-mb-8` }>
          { timeline.map((event, eventIdx) => (
            <li key={ event.id }>
              <div className={ `relative pb-8` }>
                { eventIdx !== timeline.length - 1 ? (
                  <span className={ `absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200` }
                    aria-hidden={ `true` } />
                ) : null }
                <div className={ `relative flex space-x-3` }>
                  <div>
                    <span
                      className={ classNames(
                        event.iconBackground,
                        `h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white`,
                      ) }
                    >
                      <event.icon className={ `h-5 w-5 text-white` }
                        aria-hidden={ `true` } />
                    </span>
                  </div>
                  <div className={ `flex min-w-0 flex-1 justify-between space-x-4 pt-1.5` }>
                    <div>
                      <p className={ `text-sm text-gray-500` }>
                        { event.content }{ ` ` }
                      </p>
                    </div>
                    <div className={ `whitespace-nowrap text-right text-sm text-gray-500` }>
                      <time dateTime={ event.datetime }>{ event.datetime }</time>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          )) }
        </ul>

        <Paragraph
          className={ `mt-6` }
          variant={ `help` }
        >
          { `Only a few key invoice lifecycle events are recorded here. Refer to actions for things like late fees & emails.` }
        </Paragraph>
      </LoadingOverlay>
    </Card>
  );
}
