import { IGetSettingsResponse, IUpdateSettingsRequest } from 'shared';

import { orgHeader } from '../axiosBaseQuery';
import { emptySplitApi } from '../baseApi';
import { CacheTags } from '../../types/cacheTags';

export interface UpdateSettingsPayload {
  organisationId: string;
  settings: IUpdateSettingsRequest;
}

export const settingsApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchSettings: builder.query<IGetSettingsResponse, string>({
      query: organisationId => ({
        url: `settings`,
        method: `GET`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      providesTags: [
        { type: CacheTags.Settings, id: `LIST` },
      ],
    }),
    updateSettings: builder.mutation<IGetSettingsResponse, UpdateSettingsPayload>({
      query: ({ organisationId, settings }) => ({
        url: `settings`,
        method: `PATCH`,
        headers: {
          [orgHeader]: organisationId,
        },
        data: settings,
      }),
      invalidatesTags: [
        { type: CacheTags.Settings, id: `LIST` },
      ],
    }),
    verifyCustomDomain: builder.mutation<void, string>({
      query: organisationId => ({
        url: `settings/customDomain/verify`,
        method: `GET`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [
        { type: CacheTags.Settings, id: `LIST` },
      ],
    }),
  }),
});

export const {
  useFetchSettingsQuery,
  useUpdateSettingsMutation,
  useVerifyCustomDomainMutation,
} = settingsApi;
