import { Outlet } from 'react-router';

import { PreviewPolicyModal } from './Modals/PreviewPolicyModal';

export function OrganisationRoot() {
  return (
    <>
      <PreviewPolicyModal />
      <Outlet />
    </>
  );
}
