import { Card } from "../../../../../common/Atoms/Card";
import { CardContent } from "../../../../../common/Atoms/CardContent";
import { ColorPicker } from "../../../../../common/Atoms/ColorPicker";
import { FormDescription } from "../../../../../common/Atoms/FormDescription";
import { FormLabel } from "../../../../../common/Atoms/FormLabel";
import { Notification } from "../../../../../common/Atoms/Notification";
import { Toggle } from "../../../../../common/Atoms/Toggle";
import { useGeneralSettingsForm } from "../../../../hooks/useGeneralSettingsForm";
import { TwoColFormRow } from "../../../Molecules/Form/TwoColFormRow";
import { SaveAndError } from "../SaveAndError";

export function PortalRoot() {
  const {
    formData,
    onChange,
  } = useGeneralSettingsForm();

  function handleChange() {
    onChange(`portalViewAllInvoices`, !formData.portalViewAllInvoices);
  }

  return (
    <Card>
      <CardContent className={ `pt-6` }>
        <TwoColFormRow
          heading={ `Portal Permissions` }
          description={ `Control the default access types for invoice & customer portals` }
        >
          <Toggle
            label={ `Allowed to view all invoices?` }
            description={ `When accessed via the invoice portal, can the customer see all other invoices addressed to them?` }
            name={ `portalViewAllInvoices` }
            checked={ formData.portalViewAllInvoices || false }
            onChange={ handleChange }
          />

        </TwoColFormRow>

        <div className={ `mt-2` } />
        <TwoColFormRow
          heading={ `Portal Branding` }
          description={ `` }
        >
          <div className={ `flex justify-between` }>
            <div>
              <FormLabel>
                { `Branding Color` }
              </FormLabel>
              <FormDescription>
                { `Set the main color to appear in the portal.` }
              </FormDescription>
            </div>
            <ColorPicker
              value={ formData.primaryColor }
              onChange={ value => onChange(`primaryColor`, value) }
            />
          </div>
        </TwoColFormRow>

        <Notification
          className={ `mt-6` }
          type={ `info` }
        >
          { `Logo and payment terms/footer are set under general settings` }
        </Notification>
        <SaveAndError
        />
      </CardContent>
    </Card>
  );
}
