import { useFetchOrganisationsQuery } from '../services/api/organisationApi/organisation';

export function useOrganisationsIncludeDisconnected() {
  const result = useFetchOrganisationsQuery(null, {
    pollingInterval: 1000 * 60,
  });

  if (!result.data) {
    result.data = [];
  }

  // sort the disconnected orgs to the end
  result.data = result.data.slice().sort((a, b) => {
    if (a.connected && !b.connected) {
      return -1;
    }
    if (!a.connected && b.connected) {
      return 1;
    }

    return 0;
  });

  return result;
}
