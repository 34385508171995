import { Heading } from "../../../../common/Atoms/Typography/Heading";
import { useCurrentGroup } from "../../../hooks/useGroups";

import { GroupTableSectionRoot } from "./GroupTableSection/GroupTableSectionRoot";

export function GroupV2Root() {
  const { groupFormData } = useCurrentGroup();

  if (!groupFormData) {
    return (
      <div className={ `flex justify-center items-center h-64` }>
        <Heading
          variant={ `secondary` }
        >
          { `Group not found or deleted` }
        </Heading>
      </div>
    );
  }

  return (
    <GroupTableSectionRoot />
  );
}
