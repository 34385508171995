import { IInvalidField, IUpdateSettingsRequest } from "shared";
import { useState } from "react";

import { useGetSelectedOrganisation } from "../../../../hooks/useGetSelectedOrganisation";
import { Divider } from "../../../../../common/Atoms/Divider";
import { Grid } from "../../../../../common/Atoms/Grid";
import { GridItem } from "../../../../../common/Atoms/GridItem";
import { Paragraph } from "../../../../../common/Atoms/Typography/Paragraph";
import { TwoColFormRow } from "../../../Molecules/Form/TwoColFormRow";
import { useTimezoneList } from "../../../../hooks/useTimezoneList";
import { ComboBox } from "../../../../../common/Atoms/ComboBox";

import { OrgContactDetails } from "./OrgContactDetails";
import { OnChangeFn } from "./GeneralSettings";

interface OrganisationDetailsProps {
  formData: IUpdateSettingsRequest;
  onChange: OnChangeFn;
  invalids: IInvalidField[];
  hasUnsavedChanges: boolean;
}

export function OrganisationDetails({
  formData,
  onChange,
  invalids,
  hasUnsavedChanges,
}: OrganisationDetailsProps) {
  const [tzSearch, setTzSearch] = useState(``);
  const organisation = useGetSelectedOrganisation();
  const {
    allOptions,
    filteredOptions,
  } = useTimezoneList(tzSearch);

  const showTimeZone = organisation?.accountingSystemName !== `Xero`;

  return (
    <>
      <TwoColFormRow
        heading={ `Organisation Details` }
        help={ `Some of this information is used in the generated statements & invoices if applicable` }
      >
        <OrgContactDetails
          formData={ formData }
          onChange={ onChange }
          invalids={ invalids }
          hasUnsavedChanges={ hasUnsavedChanges }
        />

        <Grid cols={ 4 }
          className={ `mt-6` }
        >
          <GridItem span={ 1 }>
            <Paragraph>
              { `Timezone` }
            </Paragraph>
          </GridItem>
          <GridItem span={ 3 }>
            {
              showTimeZone ? (
                <ComboBox
                  description={ `The timezone is prepopulated based on your accounting system settings if applicable, changing it here will only update in Paidnice.` }
                  options={ filteredOptions }
                  selected={ allOptions.find(tz => tz.value === formData.timezone) || null }
                  onSelect={ ({ value }) => onChange(`timezone`, value) }
                  query={ tzSearch }
                  onQueryChange={ e => setTzSearch(e.target.value) }
                />
              ) : (
                <Paragraph
                  variant={ `secondary` }
                >
                  { formData.timezone }
                </Paragraph>
              )
            }
          </GridItem>

          <GridItem span={ 1 }>
            <Paragraph>
              { `Default Currency` }
            </Paragraph>
          </GridItem>
          <GridItem span={ 3 }>
            <Paragraph
              variant={ `secondary` }
            >
              { organisation.baseCurrency }
            </Paragraph>
          </GridItem>

          <GridItem span={ 1 }>
            <Paragraph>
              { `Billing Managed By` }
            </Paragraph>
          </GridItem>
          <GridItem span={ 3 }>
            <Paragraph
              variant={ `secondary` }
            >
              { organisation.billingManagedBy || `-` }
            </Paragraph>
          </GridItem>
        </Grid>

      </TwoColFormRow>
      <Divider weight={ `light` } />
    </>
  );
}
