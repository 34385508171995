import { IPolicy, accountingSystemSpecificTerm, accountingSystemTooltip } from 'shared';
import { useMemo } from 'react';

import { useGetSelectedOrganisation } from './useGetSelectedOrganisation';

export function useAccountingSystemTerm(term) {
  const organisation = useGetSelectedOrganisation();

  const accountingTerm = useMemo(() => {
    return accountingSystemSpecificTerm(organisation, term);
  }, [organisation?.type, term]);

  return accountingTerm;
}

export function useAccountingSystemTooltip(policyField: keyof IPolicy) {
  const organisation = useGetSelectedOrganisation();

  const accountingTooltip = useMemo(() => {
    return accountingSystemTooltip(organisation, policyField);
  }, [organisation?.type, policyField]);

  return accountingTooltip;
}
