import React from 'react';
import { Description, Label, Radio, RadioGroup } from '@headlessui/react';

import { classNames }  from '../lib/classNames';

import { ChangeFunction } from './types';

interface IRadioStackProps {
  options: {
    value: string;
    title: string;
    description?: string;
  }[];
  selected: string;
  onChange: ChangeFunction<string, string>
  label?: string;
  name?: string;
}

export function RadioStack({
  options,
  selected,
  onChange,
  label,
  name,
}: IRadioStackProps) {
  const selectedValue = options.find(o => o.value === selected);

  function handleChange(value) {
    return onChange({
      value: value.value,
      name,
    });
  }

  return (
    <RadioGroup value={ selectedValue }
      onChange={ handleChange }>
      <Label className={ `text-sm font-medium text-gray-900 text-left` }>
        { label }
      </Label>

      <div className={ `isolate mt-1 -space-y-px rounded-md bg-white shadow-sm` }>
        { options.map(option => (
          <Radio
            key={ option.value }
            value={ option }
            className={ ({ focus }) =>
              classNames(
                focus ? `border-lateGreen-500 ring-2 ring-lateGreen-500` : ``,
                `border-gray-300 relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none`,
              )
            }
          >
            { ({ focus, checked }) => (
              <>
                <span
                  className={ classNames(
                    checked ? `bg-lateGreen-600` : `bg-white`,
                    focus ? `ring-2 ring-offset-2 ring-lateGreen-500` : ``,
                    `border-gray-300 mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center`,
                  ) }
                  aria-hidden={ `true` }
                >
                  <span className={ `rounded-full bg-white w-1.5 h-1.5` } />
                </span>
                <span className={ `ml-3 flex flex-col` }>
                  <Label
                    as={ `span` }
                    className={ classNames(
                      checked ? `text-lateGreen-900` : `text-gray-900`,
                      `block text-sm font-medium text-left`,
                    ) }
                  >
                    { option.title }
                  </Label>
                  <Description
                    as={ `span` }
                    className={ classNames(checked ? `text-lateGreen-700` : `text-gray-500`, `block text-sm`) }
                  >
                    { option.description }
                  </Description>
                </span>
              </>
            ) }
          </Radio>
        )) }
      </div>
    </RadioGroup>
  );
}
