import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { DateTime } from 'luxon';
import { ClockIcon } from '@heroicons/react/24/outline';
import { Tooltip } from '@mui/material';

import { useGetSelectedOrganisation } from '../hooks/useGetSelectedOrganisation';
import { env } from '../../common/lib/env';
import { useOrganisationsIncludeDisconnected } from '../hooks/useOrganisationsIncludeDisconnected';
import { IOption, Select } from '../../common/Atoms/Select';
import { useUser } from '../hooks/useUser';

import { QuickBooksConnectModal } from './Settings/QuickBooksConnectModal';
import { ReconnectModal } from './Settings/ReconnectModal';

export function OrgSelection() {
  const { data: user } = useUser();
  const { data: orgs } = useOrganisationsIncludeDisconnected();
  const organisation = useGetSelectedOrganisation();
  const [showQuickBooksConnectModal, setShowQuickBooksConnectModal] = useState(false);
  const [showReconnectModal, setShowReconnectModal] = useState(false);
  const [reconnectModalType, setReconnectModalType] = useState(``);

  const navigate = useNavigate();

  const data: IOption[] = useMemo(() => {
    const opts: {
      label: string;
      value: string;
      selected?: boolean;
    }[] = orgs.map(org => {

      return {
        label: `${org.legalName}`,
        value: (org.connected || user?.adminOverride) ? org.id : `reconnect-${org.accountingSystemName.toLocaleLowerCase()}-${org.id}`,
        ...(org.connected ? {} : { description: `Disconnected - click to reconnect` } ),
        selected: organisation?.id === org.id,
      };
    });

    opts.push({
      label: `+ Connect to Xero`,
      value: `add-xero`,
    });

    opts.push({
      label: `+ Connect to QuickBooks`,
      value: `add-qbo`,
    });

    return opts;
  }, [ orgs, organisation ]);

  const clockHint = useMemo(() => {
    if (!organisation) {
      return null;
    }
    const orgTz = organisation.validatedTimezone;

    const dt = DateTime.local().setZone(orgTz);

    const formatted = dt.toLocaleString(DateTime.DATETIME_MED);

    return (
      <Tooltip title={ `Local Company Time: ${formatted}` }>
        <ClockIcon className={ `h-4 w-4 text-gray-600` } />
      </Tooltip>
    );

  }, [ organisation ]);

  function onChange(e) {
    if (e.value === `add-xero`) {
      return window.location.href=`${env.apiUrl}/connect/xero`;
    }
    if (e.value === `add-qbo`) {
      setShowQuickBooksConnectModal(true);

      return;
    }
    if (e.value.startsWith(`reconnect-xero`)) {
      setShowReconnectModal(true);
      setReconnectModalType(`Xero`);

      return;
    }

    if (e.value.startsWith(`reconnect-quickbooks`)) {
      setShowReconnectModal(true);
      setReconnectModalType(`QuickBooks`);

      return;
    }

    navigate(`${e.value}`);
  }

  return (
    <>
      <Select
        suffixElement={ clockHint }
        options={ data }
        selected={ organisation?.id }
        onChange={ onChange }
        valueClassname={ `max-w-[120px] lg:max-w-[155px] xl:max-w-[160px] 2xl:max-w-[210px] truncate` }
      />
      <QuickBooksConnectModal
        onCancel={ () => setShowQuickBooksConnectModal(false) }
        show={ showQuickBooksConnectModal }
      />
      <ReconnectModal
        onCancel={ () => setShowReconnectModal(false) }
        show={ showReconnectModal }
        type = { reconnectModalType }
      />
    </>
  );
}
