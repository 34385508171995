import { createSlice } from '@reduxjs/toolkit';

export interface IEmailReportModal {
  isOpen: boolean;
}

export const initialState: IEmailReportModal = {
  isOpen: false,
};

const emailReportModalSlice = createSlice({
  name: `emailReportModal`,
  initialState,
  reducers: {
    openEmailModal(state) {
      state.isOpen = true;
    },
    closeEmailModal(state) {
      state.isOpen = false;
    },
  },
});

export const { openEmailModal, closeEmailModal } = emailReportModalSlice.actions;

export const emailReportModalReducer = emailReportModalSlice.reducer;
