import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

import { Modal } from "../../../common/Atoms/Modal";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { Divider } from "../../../common/Atoms/Divider";
import { Button } from "../../../common/Atoms/Button";

interface ITransferWarningModalProps {
  show: boolean,
  onConfirm: () => void,
  onCancel: () => void,
  confirmLoading?: boolean,
}

export function TransferWarningModal({
  show,
  onConfirm,
  onCancel,
  confirmLoading,
}: ITransferWarningModalProps) {
  return (
    <Modal
      setOpen={ onCancel }
      open={ show }
      title={ `Are you sure you want to take over billing for this organisation?` }
      icon={ <ExclamationTriangleIcon className={ `text-orange-500 h-5 w-5` } /> }
    >
      <div>
        <Divider
          weight={ `light` }
          spacing={ `small` }
        />

        <div className={ `space-y-2` }>
          <Paragraph
            variant={ `primary` }
          >
            { `This will create a new subscription for this organisation managed by you.` }
          </Paragraph>

          <Paragraph
            variant={ `primary` }

          >
            { `You will need to choose a plan and enter your card details on the new subscription.` }
          </Paragraph>
        </div>

        <div className={ `mt-4 flex justify-center space-x-4` }>
          <Button
            color={ `orange` }
            onClick={ onConfirm }
            loading={ confirmLoading }
            disabled={ confirmLoading }
          >
            { `Create New Subscription` }
          </Button>

          <Button
            color={ `gray` }
            onClick={ onCancel }
            disabled={ confirmLoading }
          >
            { `Cancel` }
          </Button>
        </div>
      </div>
    </Modal>
  );
}
