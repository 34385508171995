import { IEscalationResponseItem } from "shared";
import { useMemo } from "react";

import { DescriptionItem, DescriptionList } from "../../../../common/Atoms/DescriptionList";

interface ContactDetailsProps {
  escalation: IEscalationResponseItem;
}

export function ContactDetails({ escalation }: ContactDetailsProps) {
  const items: DescriptionItem[] = useMemo(() => {
    if (!escalation || !escalation?.contact) return [];

    return [
      {
        title: `Contact Name`,
        content: escalation.contact.name,
      },
      {
        title: `Primary Contact Name`,
        content: (escalation.contact.primaryContactPerson?.firstName || ``) + ` ` + (escalation.contact.primaryContactPerson?.lastName || ``),
      },
      {
        title: `Primary Contact Email`,
        content: escalation.contact.primaryContactPerson?.email,
      },
      {
        title: `Primary Contact Default Phone`,
        content: (escalation.contact.primaryContactPerson?.defaultCountryCode || ``) + (escalation.contact?.primaryContactPerson?.defaultNumber || ``),
      },
      {
        title: `Primary Contact Mobile Phone`,
        content: (escalation.contact.primaryContactPerson?.mobileCountryCode || ``) + (escalation.contact?.primaryContactPerson?.mobileNumber || ``),
      },
    ];
  }, [escalation]);

  return (
    <DescriptionList
      items={ items }
      title={ `Contact Details` }/>
  );
}
