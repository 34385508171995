import { ActionType, PolicyType } from "shared";

import { useFeatureFlags } from "./useFeatureFlags";

export function useAllowedTypes() {
  const featureFlags = useFeatureFlags();

  return{
    actionTypes: Object.values(ActionType).filter(t => {
      if (t === ActionType.AI_CALL_INVOICE) {
        return !!featureFlags.ai;
      }

      return true;
    }),
    policyTypes: Object.values(PolicyType).filter(t => {
      if (t === PolicyType.AI_CALL) {
        return !!featureFlags.ai;
      }

      return true;
    }),
  };
}
