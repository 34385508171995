import { createSlice } from '@reduxjs/toolkit';
import { IInvalidField, IUpdateSettingsRequest } from 'shared';

export interface ISettingsState {
  formData: IUpdateSettingsRequest;
  invalids: IInvalidField[];
  hasUnsavedChanges: boolean;
}

const initialState: ISettingsState = {
  formData: {},
  invalids: [],
  hasUnsavedChanges: false,
};

const settingsStateSlice = createSlice({
  name: `settingsState`,
  initialState,
  reducers: {
    setFormData(state, action) {
      state.formData = action.payload;
    },
    updateFormData(state, action) {
      state.formData = {
        ...state.formData,
        ...action.payload,
      };
    },
    setUnsavedChanges(state, action) {
      state.hasUnsavedChanges = action.payload;
    },
    setInvalids(state, action) {
      state.invalids = action.payload;
    },
  },
});

export const { setFormData, updateFormData, setInvalids, setUnsavedChanges } = settingsStateSlice.actions;

export const settingsStateReducer = settingsStateSlice.reducer;
