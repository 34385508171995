import { fieldIsValid } from "../../../../../lib/helper";
import { Toggle } from "../../../../../../common/Atoms/Toggle";

import { IFieldProps } from "./fieldProps";

export function ContactTaxRate({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {
  return (
    <Toggle
      label={ `Use Contacts Tax Rate` }
      description={ `Override the above accounts default tax rate with the contacts tax rate` }
      checked={ policyFormData.use_contact_tax_rate }
      onChange={ handleChange }
      invalid={ fieldIsValid(`use_contact_tax_rate`, invalids) }
      name={ `use_contact_tax_rate` }
    />
  );
}
