import { Link } from 'react-router-dom';
import { allowedSubscriptionStatus, SubscriptionStatus, SubscriptionVerficationStatus } from 'shared';

import { useGetSubscriptionMetadata } from '../hooks/useGetSubscription';
import { useGetSelectedOrganisation } from '../hooks/useGetSelectedOrganisation';
import { Strap } from '../../common/Atoms/Strap';
import { useOnboarding } from '../hooks/useOnboarding';

export function SubscriptionStrap() {
  const {
    subscriptionMetadata,
    isBillingOwner,
  } = useGetSubscriptionMetadata();
  const currentOrg = useGetSelectedOrganisation();
  const { isOnboarding } = useOnboarding();

  if (!subscriptionMetadata || ([`active`, `trial`, `trialing`].includes(subscriptionMetadata.status) && subscriptionMetadata.verificationStatus === SubscriptionVerficationStatus.VERIFIED)) {
    return null;
  }

  if (isOnboarding) {
    return null;
  }

  if (allowedSubscriptionStatus.includes(subscriptionMetadata.status) && subscriptionMetadata.verificationStatus === SubscriptionVerficationStatus.UNVERIFIED) {
    return (
      <Strap
        type={ `info` }
      >
        { `We are currently verifying your account. This should be complete within 1 business day. Email sending is limited.` }
      </Strap>
    );
  }

  if (SubscriptionStatus.PAST_DUE === subscriptionMetadata.status || SubscriptionStatus.UNPAID === subscriptionMetadata.status) {
    if (isBillingOwner) {
      return (
        <Strap
          type={ `warning` }
        >
          { `Your subscription is past due. ` }
          <Link
            to={ `/billing` + (subscriptionMetadata.id ? `/subscriptions/${subscriptionMetadata.id}` : ``) }
            className={ `underline` }
          >
            { `Update payment details` }
          </Link>
        </Strap>
      );
    }

    return (
      <Strap
        type={ `warning` }
      >
        { `Your subscription is past due. Please contact the billing owner ${currentOrg.billingManagedBy ? `(${currentOrg.billingManagedBy}) ` : ``}to update payment details.` }
      </Strap>
    );
  }

  if (SubscriptionStatus.CANCELED === subscriptionMetadata.status) {
    return (
      <Strap
        type={ `warning` }
      >
        { `Your subscription has been canceled. ` }
        <Link
          to={ `/billing` + (subscriptionMetadata.id ? `/subscriptions/${subscriptionMetadata.id}` : ``) }
          className={ `underline` }
        >
          { `Reactivate subscription` }
        </Link>
      </Strap>
    );
  }

  if (currentOrg.freeActionsUsed >= currentOrg.freeActionLimit) {
    return (
      <Strap
        type={ `warning` }
      >
        { `${currentOrg.freeActionsUsed} out of ${currentOrg.freeActionLimit} free actions used. ` }
        <Link
          to={ `/billing` + (subscriptionMetadata.id ? `/subscriptions/${subscriptionMetadata.id}` : ``) }
          className={ `underline` }
        >
          { `Choose a plan now` }
        </Link>
        { `.` }
      </Strap>
    );
  }

  if (currentOrg.freeActionsUsed < currentOrg.freeActionLimit) {
    return (
      <Strap
        type={ `info` }
      >
        { `${currentOrg.freeActionsUsed} out of ${currentOrg.freeActionLimit} free actions used. ` }
        <Link
          to={ `/billing` + (subscriptionMetadata.id ? `/subscriptions/${subscriptionMetadata.id}` : ``) }
          className={ `underline` }
        >
          { `Upgrade` }
        </Link>
        { `.` }
      </Strap>
    );
  }

  return null;
}
