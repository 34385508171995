import { Fragment, useMemo } from 'react';
import { Tooltip } from '@mui/material';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import {
  ArrowTopRightOnSquareIcon,
  PauseCircleIcon,
  PlayCircleIcon,
  ChevronDownIcon,
  DocumentCheckIcon,
} from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router';
import { InvoiceListItem } from 'shared';

import { classNames }  from '../../../common/lib/classNames';
import { useGetSelectedOrganisation } from '../../hooks/useGetSelectedOrganisation';
import { useActionInvoiceMutation } from '../../services/api/invoiceApi/invoice';

interface InvoiceActionsProps {
  invoiceRow: InvoiceListItem;
}

export function InvoiceActions({ invoiceRow }: InvoiceActionsProps) {
  const navigate = useNavigate();
  const currentOrg = useGetSelectedOrganisation();
  const [actionInvoice] = useActionInvoiceMutation();

  // TODO: QBO link
  const showLink = currentOrg.type === `XERO`;
  const invoice = invoiceRow.invoice;

  const options = useMemo(() => {
    const opts = [
      {
        title: `Preview Policies`,
        action: () => navigate(`${invoice.id}/policy-preview`),
        icon: DocumentCheckIcon,
        tooltip: `See which policies do and do not apply to this invoices`,
      },
    ];

    if (showLink) {
      opts.unshift({
        title: `View In Xero`,
        action: handleViewXero,
        icon: ArrowTopRightOnSquareIcon,
        tooltip: `View this invoice in Xero`,
      });
    }

    if (invoice.frozen) {
      opts.push({
        title: `Resume Processing`,
        action: resumeAction,
        icon: PlayCircleIcon,
        tooltip: `Resume Processing`,
      });
    }
    else {
      opts.push({
        title: `Freeze Future Processing`,
        action: skipAction,
        icon: PauseCircleIcon,
        tooltip: `Stop any further processing happening on this invoice`,
      });
    }

    return opts;
  }, [ invoice ]);

  function handleViewXero() {
    // No link, build one
    const l = `https://go.xero.com/organisationlogin/default.aspx?shortcode=${currentOrg.shortcode}&redirecturl=/AccountsReceivable/View.aspx?InvoiceID=${invoice.externalId}`;

    window.open(l, `_blank`);

    return;
  }

  function skipAction() {
    actionInvoice({
      invoiceId: invoice.id,
      action: `freeze`,
      organisationId: currentOrg.id,
    });
  }

  function resumeAction() {
    actionInvoice({
      invoiceId: invoice.id,
      action: `reset`,
      organisationId: currentOrg.id,
    });
  }

  function handleOptionClick(e, item) {
    e.preventDefault();
    e.stopPropagation();

    item.action();
  }

  function onViewInvoice(e) {
    e.preventDefault();
    e.stopPropagation();

    navigate(`${invoice.id}`);
  }

  return (
    <>
      <div className={ `inline-flex rounded-md shadow-sm` }>
        <button
          type={ `button` }
          onClick={ e => onViewInvoice(e) }
          className={ `relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-lateGreen-500 focus:outline-none focus:ring-1 focus:ring-lateGreen-500` }
        >
          { `View Invoice` }
        </button>
        <Menu as={ `div` }
          className={ `relative -ml-px block` }>
          <MenuButton
            onClick={ e => e.stopPropagation() }
            className={ `relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-lateGreen-500 focus:outline-none focus:ring-1 focus:ring-lateGreen-500` }
          >
            <span className={ `sr-only` }>{ `Open options` }</span>
            <ChevronDownIcon className={ `h-5 w-5` }
              aria-hidden={ `true` } />
          </MenuButton>
          <Transition
            as={ Fragment }
            enter={ `transition ease-out duration-100` }
            enterFrom={ `transform opacity-0 scale-95` }
            enterTo={ `transform opacity-100 scale-100` }
            leave={ `transition ease-in duration-75` }
            leaveFrom={ `transform opacity-100 scale-100` }
            leaveTo={ `transform opacity-0 scale-95` }
          >
            <MenuItems className={ `absolute right-0 z-10 mt-2 -mr-1 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none` }>
              <div className={ `py-1` }>
                { options.map(item => (
                  <MenuItem key={ item.title }>
                    { ({ focus }) => (
                      <Tooltip title={ item.tooltip }
                        placement={ `left` }>
                        <a
                          href={ `#` }
                          onClick={ e => handleOptionClick(e, item) }
                          className={ classNames(
                            focus ? `bg-gray-100 text-gray-900` : `text-gray-700`,
                            `block px-4 py-2 text-sm`,
                          ) }
                        >
                          <div className={ `flex justify-between` }>
                            <item.icon className={ `h-5 w-5` }
                              aria-hidden={ `true` } />
                            { item.title }
                          </div>
                        </a>
                      </Tooltip>
                    ) }
                  </MenuItem>
                )) }
              </div>
            </MenuItems>
          </Transition>
        </Menu>
      </div>
    </>
  );
}
