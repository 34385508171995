import { AIVoiceConfig } from "shared";
import { useState } from "react";

import { Card } from "../../../common/Atoms/Card";
import { MultiButtonCardHeader } from "../../../common/Atoms/MultiButtonCardHeader";
import { Select } from "../../../common/Atoms/Select";
import { useGeneralSettingsForm } from "../../hooks/useGeneralSettingsForm";
import { Button } from "../../../common/Atoms/Button";
import { Modal } from "../../../common/Atoms/Modal";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { Divider } from "../../../common/Atoms/Divider";

const maxCallDurationOptions = Array.from({ length: 5 }, (_, i) => ({
  value: i + 1,
  label: `${i + 1} minute${i === 0 ? `` : `s`}`,
}));

const aiVoiceOptions = Object.keys(AIVoiceConfig).map(k => ({
  value: k,
  label: AIVoiceConfig[k].label,
}));

export function AICallSettings() {
  const { formData, onChange, onSave, hasUnsavedChanges, updateLoading } = useGeneralSettingsForm();
  const [showSettings, setShowSettings] = useState(false);

  function onClose() {
    setShowSettings(false);
  }

  return (
    <Card>
      <MultiButtonCardHeader
        title={ `AI Calls` }
        description={ `View AI call logs and manage settings.` }
        helpText={ `Schedule AI calls automatically using AI Call policies on group pages or manually on invoice pages` }
        noDivider={ true }
        buttons={ [
          {
            buttonText: `Settings`,
            onClick: () => { setShowSettings(true); },
          },
        ]
        }
      />

      <Modal
        title={ `` }
        open={ showSettings }
        setOpen={ onClose }
      >
        <div className={ `flex flex-col items-center mt-6` }>
          <Paragraph variant={ `primary` }>
            { `Configure the default AI agent settings, such as accent & maximum call duration.` }
          </Paragraph>

          <Divider weight={ `light` } />

          <div className={ `mt-4 space-y-3 min-w-[450px]` }>
            <Select
              label={ `Max Call Duration` }
              options={ maxCallDurationOptions }
              name={ `aiMaxCallDuration` }
              selected={ formData.aiMaxCallDuration }
              onChange={ e => onChange(`aiMaxCallDuration`, e.value) }
              description={ `The maximum duration for AI calls.` }
            />
          </div>

          <div className={ `mt-4 space-y-3 min-w-[450px]` }>
            <Select
              label={ `AI Voice` }
              options={ aiVoiceOptions }
              name={ `aiVoice` }
              selected={ formData.aiVoice }
              onChange={ e => onChange(`aiVoice`, e.value) }
              description={ `Select a voice and accent for the AI agent.` }
            />
          </div>

          <div className={ `flex justify-end mt-3` }>
            <Button
              onClick={ onSave }
              type={ `button` }
              disabled={ !hasUnsavedChanges || updateLoading }
              loading={ updateLoading }
            >
              { `Save` }
            </Button>
          </div>
        </div>
      </Modal>
    </Card>
  );
}
