import { useParams } from "react-router";

import { useFetchContactTagsQuery } from "../services/api/contactTagsApi/contactTags";

export function useContactTags() {
  const { organisationId } = useParams<{ organisationId?: string}>();

  const result = useFetchContactTagsQuery({ organisationId }, {
    skip: !organisationId,
  });

  return {
    ...result,
    tags: result.data?.tags || [],
  };
}
