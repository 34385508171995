import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../store";
import { hide, show } from "../slices/modals";

export function useModals() {
  const dispatch = useDispatch();
  const modalState = useSelector((s: RootState) => s.modals);

  return {
    state: modalState,
    show: (modal: keyof typeof modalState) => dispatch(show(modal)),
    hide: (modal: keyof typeof modalState) => dispatch(hide(modal)),
  };
}
