
import { IAICallItem, langauge, IAICallAnalysis } from "shared";
import { When } from "react-if";
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Tooltip } from "@mui/material";

import { Card } from "../../../common/Atoms/Card";
import { Heading } from "../../../common/Atoms/Typography/Heading";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { classNames } from "../../../common/lib/classNames";

interface ITranscriptProps {
  call: IAICallItem;
  compact?: boolean;
}

function renderUser(string: string) {
  if (string === `assistant` || string === `agent-action`) {
    return `Assistant`;
  }

  if (string === `user`) {
    return `Customer`;
  }

  return string;
}

export function AICallTranscription({ call, compact }: ITranscriptProps) {
  if (!call || !call?.transcripts || !call?.transcripts.length) {
    return null;
  }

  const transcripts = call.transcripts;
  const analysis = call.analysis;

  return (
    <Card className={ `mt-2` }>
      <When condition={ call?.summary }>
        <div className={ `mb-3` }>
          <Heading>{ `Summary` }</Heading>
          <Paragraph variant={ `help` }
            className={ `mt-2` }>
            { call.summary }
          </Paragraph>
        </div>
      </When>

      <When condition={ analysis && Object.keys(analysis).length > 0 }>
        <div className={ `mb-3` }>
          <Heading>{ `Outcomes` }</Heading>
          <div className={
            classNames(
              `grid gap-4`,
              compact ? `grid-cols-2` : `grid-cols-4`,
            )
          }>
            { Object.keys(analysis).map((key: keyof IAICallAnalysis ) => (
              <OutcomeField key={ key + call.id }
                name={ key }
                value={ analysis[key] }/>
            )) }
          </div>
        </div>
      </When>
      <Heading>{ `Conversation Transcription` }</Heading>
      <div className={ `mx-auto p-4` }>
        { transcripts.map(transcript => (
          <div
            key={ transcript.id }
            className={
              classNames(
                `mb-4 p-4 rounded-lg`,
                transcript.user === `user` && `bg-gray-100 text-left self-start mr-12`,
                transcript.user === `assistant` && `bg-blue-100 text-right self-end ml-12`,
                transcript.user === `agent-action` && `bg-orange-100 text-center self-center ml-12`,
              )
            }
          >
            <div className={ `text-sm text-gray-600` }>{ new Date(transcript.created_at).toLocaleString() }</div>
            <div className={ `mt-1` }>
              <span className={ `font-semibold` }>{ renderUser(transcript.user) }</span>{ `: ` }{ transcript.text }
            </div>
          </div>
        )) }
      </div>
    </Card>
  );
}

function OutcomeField({ name, value }: { name: keyof IAICallAnalysis, value: string | boolean | undefined | null }) {
  function renderValue() {
    if (typeof value === `boolean`) {
      if (name === `unhappyCustomer`) {
        return value ?
          (
            <Tooltip title={ `Customer seemed upset` }>
              <p>
                { `🙁` }
              </p>
            </Tooltip>
          )
          :
          <Tooltip title={ `Customer seemed happy` }>
            <p>
              { `🙂` }
            </p>
          </Tooltip>;
      }

      return value ?
        <CheckIcon className={ `h-5 w-5 text-green-400` } /> :
        <XMarkIcon className={ `h-5 w-5 text-red-400` } />;
    }

    if (!value || value === `null`) {
      return `N/A`;
    }

    return value;
  }

  return (
    <div className={ `flex w-full h-8 items-center justify-between bg-gray-50 p-4 rounded-md mt-2` }>
      <Paragraph
        variant={ `primary` }
        className={ `font-bold` }
      >
        { langauge.aiAnalysis[name] }
      </Paragraph>
      <div>
        { renderValue() }
      </div>
    </div>
  );
}
