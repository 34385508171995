import { Card } from "../../../common/Atoms/Card";
import { useInvoice } from "../../hooks/useInvoice";
import { useCreateNoteMutation } from "../../services/api/invoiceApi/invoice";
import { Notes } from "../Molecules/Notes";

export function InvoiceNotes() {
  const { data: invoice } = useInvoice();
  const [postNewNote, { isLoading: isPostingNote }] = useCreateNoteMutation();

  if (!invoice) return null;

  async function onNewNote(content: string, mentionedUsers: string[]) {
    // Create the note
    await postNewNote({
      organisationId: invoice.invoice.organisation_id,
      invoiceId: invoice.invoice.id,
      content,
      mentionedUsers,
    });
  }

  return (
    <Card>
      { /* Display existing notes */ }
      <Notes
        notes={ invoice.notes }
        loading={ isPostingNote }
        enableNewNote
        onSave={ onNewNote }
      />
    </Card>
  );
}
