import { useMemo } from "react";
import { EyeIcon } from "@heroicons/react/24/outline";
import { EndUserAnalyticEventTypes } from "shared";

import { Card } from "../../../common/Atoms/Card";
import { MultiButtonCardHeader } from "../../../common/Atoms/MultiButtonCardHeader";
import { useInvoice } from "../../hooks/useInvoice";
import { LoadingOverlay } from "../Molecules/LoadingOverlay";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { Heading } from "../../../common/Atoms/Typography/Heading";
import { CardContent } from "../../../common/Atoms/CardContent";
import { ITimeLineFeedItem, TimelineFeed } from "../../../common/Atoms/TimelineFeed";

// TODO - move to shared
const languageMap = {
  eventType: {
    [EndUserAnalyticEventTypes.PORTAL_PAGE_VIEW]: `Portal Page View`,
    [EndUserAnalyticEventTypes.STATEMENT_DOWNLOAD]: `Statement Downloaded`,
  },
};

export function InvoiceAnalytics() {
  const { invoice, isLoading } = useInvoice();

  if (!invoice) return null;

  const pageViews: ITimeLineFeedItem[] = useMemo(() => {
    if (!invoice?.analyticEvents) return [];

    return invoice.analyticEvents.map(e => {
      return {
        id: e.id,
        description: languageMap.eventType[e.type],
        timestamp: e.createdAt,
        icon: EyeIcon,
      };
    });
  }, [invoice]);

  return (
    <LoadingOverlay loading={ isLoading }>
      <Card>
        <MultiButtonCardHeader
          title={ `Portal Page Views` }
          description={ `See when the invoice was viewed in the customer facing portal` }
          secondaryDescription={ `This is a new feature, historic data may not be available` }
          cta={ (
            <div className={ `flex flex-col items-end` }>
              <Heading
                size={ `2xl` }
              >
                { pageViews.length }
              </Heading>
              <Paragraph>
                { `Views` }
              </Paragraph>
            </div>
          ) }
        />
        <CardContent>
          <TimelineFeed
            feed={ pageViews }
            timezone={ invoice.tz }
          />
        </CardContent>
      </Card>
    </LoadingOverlay>
  );
}
