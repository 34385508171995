import { useMemo, useState } from "react";
import { GroupRuleType, IGroupDefaultRule, IGroupUpdate } from "shared";
import { PlusCircleIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { When } from "react-if";

import { useAllGroupRules, useGroups } from "../../../../../hooks/useGroups";
import { Card } from "../../../../../../common/Atoms/Card";
import { useUpdateGroupMutation, useUpdateRulePriorityMutation } from "../../../../../services/api/groupApi/group";
import { useGetSelectedOrganisation } from "../../../../../hooks/useGetSelectedOrganisation";
import { LoadingOverlay } from "../../../../Molecules/LoadingOverlay";
import { Button } from "../../../../../../common/Atoms/Button";
import { classNames } from "../../../../../../common/lib/classNames";
import { Paragraph } from "../../../../../../common/Atoms/Typography/Paragraph";
import { TwoColFormRow } from "../../../../Molecules/Form/TwoColFormRow";
import { Select } from "../../../../../../common/Atoms/Select";
import { validateForm } from "../../../../../services/forms/validate";
import { Divider } from "../../../../../../common/Atoms/Divider";

import { NewGroupRule } from "./NewGroupRule";
import { SavedRule } from "./SavedRule";

export function GroupDefaultSettings() {
  const { data: groups } = useGroups();
  const currentOrg = useGetSelectedOrganisation();
  const [updateGroup, { isLoading }] = useUpdateGroupMutation();
  const [updatePriority, { isLoading: isPriorityLoading }] = useUpdateRulePriorityMutation();
  const [currentRuleForm, setCurrentRuleForm] = useState<IGroupDefaultRule | null>(null);
  const allGroupRules = useAllGroupRules();

  const groupOptions = useMemo(() => {
    if (!groups) {
      return [];
    }

    return groups.map(g => ({
      label: g.title,
      value: g.id,
    }));
  }, [ groups ]);

  const defaultGroup = useMemo(() => {
    if (!groups) {
      return null;
    }

    const d = groups.find(g => g.default);

    return d;
  }, [ groups ]);

  function onNewRule() {
    if (!groups || !defaultGroup) return;

    setCurrentRuleForm({
      type: GroupRuleType.EXTERNAL_AGGREGATE,
      groupId: defaultGroup?.id,
      value: null,
      priority: 99999,
    });
  }

  function onNewRuleSave() {
    if (!currentRuleForm) return;

    const g = groups.find(g => g.id === currentRuleForm.groupId);

    if (!g) {
      throw new Error(`Group not found`);
    }

    updateGroup({
      id: currentRuleForm.groupId,
      organisationId: currentOrg.id,
      update: {
        ...g,
        rules: [
          ...g.rules,
          currentRuleForm,
        ],
      },
    });

    setCurrentRuleForm(null);
  }

  async function onDefaultChange(e: { value: number, name: string }) {
    const g = groups.find(g => g.id === e.value);

    if (!g) {
      throw new Error(`Group not found`);
    }

    const validateResult = await validateForm<IGroupUpdate>(g, `GROUP`);

    updateGroup({
      id: e.value,
      organisationId: currentOrg.id,
      update: {
        ...validateResult.form,
        default: true,
      },
    });
  }

  function onDeleteRule(rule: IGroupDefaultRule) {
    if (!groups) return;

    const g = groups.find(g => g.id === rule.groupId);

    if (!g) {
      throw new Error(`Group not found`);
    }

    updateGroup({
      id: rule.groupId,
      organisationId: currentOrg.id,
      update: {
        ...g,
        rules: g.rules.filter(r => r.id !== rule.id),
      },
    });
  }

  function onPriorityChange(rule: IGroupDefaultRule, type: `up` | `down`) {
    if (!groups) return;

    const g = groups.find(g => g.id === rule.groupId);

    if (!g) {
      throw new Error(`Group not found`);
    }

    updatePriority({
      id: rule.groupId,
      organisationId: currentOrg.id,
      ruleId: rule.id,
      type,
    });
  }

  if (!allGroupRules.length) {
    // Discourage future use, should now use policy filters
    return null;
  }

  return (
    <>
      <TwoColFormRow
        heading={ `Auto-Assign Rules` }
        description={ `Rules for automatically assigning contacts to groups.` }
      >
        <LoadingOverlay
          loading={ isLoading }
        >
          <div className={
            classNames(
              allGroupRules.length > 0 ? `pt-8` : ``,
            )
          }>
            {
              allGroupRules.map(r => (
                <SavedRule
                  key={ r.id }
                  rule={ r }
                  onDelete={ onDeleteRule }
                  onPriorityChange={ onPriorityChange }
                  loading={ isPriorityLoading }
                />
              ))
            }
          </div>

          <div className={ `flex justify-center py-12` }>
            <Button
              onClick={ onNewRule }
              disabled={ !!currentRuleForm }
              icon={ <PlusCircleIcon className={ `h-5 w-5 ml-2` } /> }
            >
              { `Add New Rule` }
            </Button>
          </div>

          <When condition={ !!currentRuleForm }>
            <NewGroupRule
              rule={ currentRuleForm! }
              onChange={ setCurrentRuleForm }
              onSave={ onNewRuleSave }
              onCancel={ () => setCurrentRuleForm(null) }
            />
          </When>

          <TwoColFormRow
            headingVariant={ `secondary` }
            heading={ `` }
            description={ (
              <Paragraph
                className={ `pt-4` }
                variant={ `secondary` }
              >
                { `When no rules apply, ` }
                <strong>{ `new` }</strong>
                { ` contacts will be assigned to this group: ` }
              </Paragraph>
            ) }
            ratio={ [6, 6] }
          >
            <Select
              label={ `` }
              options={ groupOptions }
              selected={ defaultGroup?.id }
              onChange={ onDefaultChange }
            />
          </TwoColFormRow>
        </LoadingOverlay>
      </TwoColFormRow>
      <div className={ `space-y-3 pt-6` }>
        <Card className={ `flex items-center` }>
          <div>
            <QuestionMarkCircleIcon className={ `h-6 w-6 text-orange-500 mr-1.5` } />
          </div>
          <Paragraph>
            { `These rules only apply when a contact is first seen by Paidnice, or when the contact is updated in your Accounting System.` }
          </Paragraph>
        </Card>

        <Card className={ `flex items-center` }>
          <div>
            <QuestionMarkCircleIcon className={ `h-6 w-6 text-orange-500 mr-1.5` } />
          </div>
          <Paragraph>
            { `If a contact matches more than one rule, the first rule will be applied.` }
          </Paragraph>
        </Card>

        <Card className={ `flex items-center` }>
          <div>
            <QuestionMarkCircleIcon className={ `h-6 w-6 text-orange-500 mr-1.5` } />
          </div>
          <Paragraph>
            { `If a contact is not new, and no longer matches any rules, then they will remain in their current group.` }
          </Paragraph>
        </Card>

      </div>
      <Divider weight={ `light` } />
    </>
  );
}
