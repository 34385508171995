import { IAdhocActionResponse, ISingleContactResponse, StatementRelativePeriods } from 'shared';

import { orgHeader } from '../axiosBaseQuery';
import { emptySplitApi } from '../baseApi';
import { CacheTags } from '../../types/cacheTags';

export const singleContactApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchContact: builder.query<ISingleContactResponse, { organisationId: string, contactId: number, statementPeriod: StatementRelativePeriods }>({
      query: ({ organisationId, contactId, statementPeriod }) => ({
        url: `contact/${contactId}`,
        method: `GET`,
        headers: {
          [orgHeader]: organisationId,
        },
        params: {
          statementPeriod,
        },
      }),
      providesTags: result => {
        return result ?
          [
            { type: CacheTags.SingleContact as const, id: result.id },
          ]
          : [];
      },
    }),
    creditReportContact: builder.mutation<void, { organisationId: string, contactId: number }>({
      query: ({ organisationId, contactId }) => ({
        url: `contact/credit-report`,
        method: `POST`,
        data: {
          action: `creditReport`,
          contactId,
        },
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [{ type: CacheTags.Organisations, id: `LIST` }],
    }),
    syncInvoices: builder.mutation<void, { organisationId: string, contactId: number }>({
      query: ({ organisationId, contactId }) => ({
        url: `contact/sync-invoices`,
        method: `POST`,
        data: {
          action: `sync-invoices`,
          contactId,
        },
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [{ type: CacheTags.Organisations, id: `LIST` }],
    }),
    sendStatementNow: builder.mutation<void, { organisationId: string, contactId: number, templateId: string, statementPeriod: StatementRelativePeriods }>({
      query: ({ organisationId, contactId, templateId, statementPeriod }) => ({
        url: `contact/${contactId}/send/statement`,
        method: `GET`,
        params: {
          templateId,
          statementPeriod,
        },
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [{ type: CacheTags.Organisations, id: `LIST` }],
    }),

    adhocInterestCharge: builder.mutation<IAdhocActionResponse, { organisationId: string, contactId: number }>({
      query: ({ organisationId, contactId }) => ({
        url: `contact/${contactId}/adhoc/statement-interest-charge`,
        method: `POST`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [
        { type: CacheTags.Organisations, id: `LIST` },
        { type: CacheTags.SingleContact as const },
        { type: CacheTags.Actions, id: `LIST` },
        { type: CacheTags.BadContacts, id: `LIST` },
      ],
    }),

    // REPLACES SEND STATEMENT NOW
    adhocStatement: builder.mutation<IAdhocActionResponse, { organisationId: string, contactId: number }>({
      query: ({ organisationId, contactId }) => ({
        url: `contact/${contactId}/adhoc/statement`,
        method: `POST`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [
        { type: CacheTags.Organisations, id: `LIST` },
        { type: CacheTags.SingleContact as const },
        { type: CacheTags.Actions, id: `LIST` },
        { type: CacheTags.BadContacts, id: `LIST` },
      ],
    }),

    renableCalls: builder.mutation<{ id: number }, { organisationId: string, contactId: number }>({
      query: ({ organisationId, contactId }) => ({
        url: `contact/${contactId}/enable-calls`,
        method: `POST`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: result => [{ type: CacheTags.Organisations, id: `LIST` }, { type: CacheTags.SingleContact as const, id: result.id }],
    }),

    createContactNote: builder.mutation<void, { organisationId: string, contactId: number, content: string, mentionedUsers: string[] }>({
      query: ({ organisationId, contactId, content, mentionedUsers }) => ({
        url: `contact/${contactId}/note`,
        method: `POST`,
        data: {
          content,
          mentionedUsers,
        },
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: [{ type: CacheTags.SingleContact as const }],
    }),
  }),

});

export const {
  useFetchContactQuery,
  useLazyFetchContactQuery,
  useCreditReportContactMutation,
  useSyncInvoicesMutation,
  useSendStatementNowMutation,
  useRenableCallsMutation,
  useCreateContactNoteMutation,
  useAdhocInterestChargeMutation,
  useAdhocStatementMutation,
} = singleContactApi;
