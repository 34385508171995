import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { env } from '../../common/lib/env';

const identifier = env.logrocketAppId;

if (env.environment === `production` && !env.isCypress) {
  LogRocket.init(identifier);
  setupLogRocketReact(LogRocket);
}
