import { toast } from 'react-toastify';
import { AnyAction, type Middleware } from '@reduxjs/toolkit';

import { ErrorPayload } from '../../main/services/api/axiosBaseQuery';

const handledActions = [
  `updateGroup`,
  `fetchGroups`,
  `addContactToGroup`,
  `removeContactFromGroup`,
  `searchContacts`,
  `saveWelcomeQuestions`, //Silent update
  `createSystemPolicy`,
  `updatePolicy`,
  `adhocInterestCharge`,
  `adhocStatement`,
  `previewAction`,
  `previewPolicy`,
];

const successOverides = {
  [`syncContacts`]: `Contact sync has started. Please check back shortly.`,
  [`updateSettings`]: `Settings Updated`,
  [`eventNotificationUpdate`]: `Notification settings updated`,
};

/** Custom middleware for display error toast - or success? */
export const rtkQueryActionLogger: Middleware = () => next => (action: AnyAction) => {
  // Find the rejected action type for api calls
  const regexError = /api\/(.*)\/rejected/;
  const regexSuccess = /api\/(.*)\/fulfilled/;

  const endpointName = action.meta?.arg?.endpointName;

  if (!handledActions.includes(endpointName)) {
    if (regexError.test(action.type)) {
      const errorPayload = action.payload as ErrorPayload;

      if (typeof errorPayload?.data === `string`) {
        errorPayload.data = {
          message: errorPayload.data,
        };
      }

      // Error is not being handled elsewhere, so we'll display it
      let errorMessage = errorPayload?.data?.message || `Unknown error occured`;

      if (errorPayload?.status === 500) {
        // Display a generic message to user
        errorMessage = `An unknown error occured`;
      }

      else if (errorPayload?.status === 404) {
        errorMessage = `Not found`;
      }

      else if (action?.error?.name === `ConditionError`) {
        return next(action);
      }

      // Can ignore this side effect error
      if (errorMessage !== `Aborted due to condition callback returning false.`) {
        toast.error(errorMessage, {
          toastId: errorMessage,
        });
      }

    }

    // Dont display success for any fetches
    if (regexSuccess.test(action.type) && !endpointName.includes(`fetch`)) {
      if (action.meta?.arg?.originalArgs?.action === `resync`) {
        // Dont display success for resync
        return next(action);
      }

      if (action.payload?.warning) {
        toast.warn(action.payload.warning, {
          autoClose: 2500,
        });

        // Log for audit
        console.error(`Warning:`, action.payload.warning);

        return next(action);
      }

      if (action.payload?.success) {
        toast.success(action.payload.success, {
          autoClose: 4000,
        });

        return next(action);
      }

      // Display a generic success message
      const successMessage = successOverides[endpointName] || `Success!`;

      if (endpointName === `addContactGroup`) {
        const messages = [
          `${action.payload?.successCount} contacts added.`,
        ];

        if (action.payload?.alreadyExistsCount) {
          messages.push(`${action.payload?.alreadyExistsCount} contacts already exist in another group and where not added.`);
        }

        messages.forEach(message => {
          toast.success(message, {
            autoClose: 1500,
          });
        });
      }
      else {
        toast.success(successMessage, {
          autoClose: 1500,
        });
      }
    }
  }

  return next(action);
};
