import { policyConfig } from "shared";

import { fieldIsValid } from "../../../../../lib/helper";
import { CurrencyInput } from "../../../../../../common/Atoms/CurrencyInput";

import { IFieldProps } from "./fieldProps";

const config = policyConfig.maximum_threshold;

export function MaximumDue({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {
  return (
    <CurrencyInput
      label={ config.label }
      // description={ `Apply this policy when this amount or below is overdue on the invoice.` }
      helpIcon={ `Blank or 0 means no limit.` }
      value={ policyFormData.maximum_threshold }
      onChange={ handleChange }
      name={ `maximum_threshold` }
      invalid={ fieldIsValid(`maximum_threshold`, invalids) }
    />
  );
}
