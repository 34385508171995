import { format } from "date-fns";
import { DateFormats } from "shared";
import { useEffect, useMemo, useState } from "react";

import { Card } from "../../../common/Atoms/Card";
import { CardContent } from "../../../common/Atoms/CardContent";
import { InputTextAddon } from "../../../common/Atoms/InputTextAddon";
import { MultiButtonCardHeader } from "../../../common/Atoms/MultiButtonCardHeader";
import { useUser } from "../../hooks/useUser";
import { TwoColFormRow } from "../Molecules/Form/TwoColFormRow";
import { Divider } from "../../../common/Atoms/Divider";
import { useOrganisationsIncludeDisconnected } from "../../hooks/useOrganisationsIncludeDisconnected";
import { Button } from "../../../common/Atoms/Button";
import { useEventNotificationUpdateMutation, useSaveNotificationSettingsMutation } from "../../services/api/userApi/user";
import { Toggle } from "../../../common/Atoms/Toggle";
import { Heading } from "../../../common/Atoms/Typography/Heading";
import { FormDescription } from "../../../common/Atoms/FormDescription";
import { PendingInvitations } from "../Common/PendingInvitations";
import { LoadingOverlay } from "../Molecules/LoadingOverlay";

function determineLoginMethod(sub: string) {
  if (sub.toLowerCase().includes(`xero`)) {
    return `Xero`;
  }
  if (sub.toLowerCase().includes(`qbo`)) {
    return `QuickBooks`;
  }

  return `Email`;
}

interface INotificationsState {
  weekly: string[];
}

export function MyAccount() {
  const { data: user } = useUser();
  const { data: orgs, isLoading } = useOrganisationsIncludeDisconnected();
  const [saveNotifications, { isLoading: saveNotificationsLoading }] = useSaveNotificationSettingsMutation();
  const [ currentNotifications, setCurrentNotifications ] = useState<INotificationsState>({ weekly: [] });
  const [ updateNotifications, { isLoading: updateNotificationsLoading } ] = useEventNotificationUpdateMutation();

  const orgOptions = useMemo(() => {
    if (isLoading) {
      return [];
    }

    if (!orgs) {
      return [];
    }

    const options = orgs.map(org => ({
      label: org.connected ? `${org.legalName}` : `${org.legalName} (Disconnected)`,
      value: org.id,
      disabled: !org.connected,
    }));

    return options;
  }, [orgs, isLoading]);

  useEffect(() => {
    setCurrentNotifications({
      weekly: user.orgs.filter(o => o.weeklyEmail).map(org => org.id),
    });
  }, [ user ]);

  function handleWeeklyNotificationChange(value: string) {
    let newValue = [...currentNotifications.weekly];

    if (newValue.includes(value)) {
      newValue = newValue.filter(v => v !== value);
    }
    else {
      newValue.push(value);
    }

    setCurrentNotifications({
      ...currentNotifications,
      weekly: newValue,
    });
  }

  async function handleSaveNotifications() {
    if (!user) return;

    saveNotifications({
      currentNotifications,
    });

    return;
  }

  function handleAssignmentNotificationChange() {
    updateNotifications({
      emailOnAssignment: !user.emailOnAssignment,
      emailOnMention: user.emailOnMention,
    });
  }

  function handleMentionNotificationChange() {
    updateNotifications({
      emailOnAssignment: user.emailOnAssignment,
      emailOnMention: !user.emailOnMention,
    });
  }

  if (!user) {
    return null;
  }

  return (
    <Card>
      <MultiButtonCardHeader
        title={ `My Account` }
      />
      <CardContent className={ `mt-4` }>
        <TwoColFormRow
          heading={ `Personal Information` }
        >
          <div
            className={ `space-y-3` }>

            <div
              className={ `flex items-end justify-between w-full` }
            >
              <InputTextAddon
                className={ `w-1/2 mr-6` }
                label={ `First Name` }
                value={ user.firstName }
                disabled={ true }
                onChange={ () => {} }
              />

              <InputTextAddon
                className={ `w-1/2` }
                label={ `Last Name` }
                value={ user.lastName }
                disabled={ true }
                onChange={ () => {} }
              />
            </div>

            <div>
              <InputTextAddon
                label={ `Email` }
                value={ user.email }
                disabled={ true }
                onChange={ () => {} }
              />
            </div>

            <div className={ `flex items-end justify-between w-full space-x-3` }>
              <InputTextAddon
                label={ `First Signed Up` }
                value={ format(new Date(user.createdAt), DateFormats.UI_DISPLAY_SHORT) }
                disabled={ true }
                onChange={ () => {} }
              />

              <InputTextAddon
                label={ `Login Count` }
                value={ user.loginsCount }
                disabled={ true }
                onChange={ () => {} }
              />

              <InputTextAddon
                label={ `Login Method` }
                value={ determineLoginMethod(user.sub) }
                disabled={ true }
                onChange={ () => {} }
              />
            </div>
          </div>

        </TwoColFormRow>
        <Divider/>
        <TwoColFormRow
          heading={ `Email Notifications` }
        >
          <LoadingOverlay
            loading={ updateNotificationsLoading }
          >
            { /* Assignee and mention notifications */ }
            <div
              className={ `space-y-3 mb-4` }>

              <Heading>
                { `Events` }
              </Heading>
              <Toggle
                label={ `Assignment` }
                description={ `Email notification sent when you are assigned to an item such as an escalation or invoice.` }
                checked={ user.emailOnAssignment }
                onChange={ handleAssignmentNotificationChange }
              />

              <Toggle
                label={ `Mention` }
                description={ `Email notification sent when you are mentioned in a note.` }
                checked={ user.emailOnMention }
                onChange={ handleMentionNotificationChange }
              />

            </div>

            <Divider
              weight={ `light` }
              spacing={ `small` }
            />

            { /* Weekly org summary */ }
            <div
              className={ `space-y-3` }>

              <Heading>
                { `Weekly Email Report` }
              </Heading>
              <FormDescription>
                { `Select organisations you wish to receive a weekly summary email for.` }
              </FormDescription>
              {
                orgOptions.map(org => {
                  const selected = currentNotifications.weekly.includes(org.value);

                  return (
                    <Toggle
                      key={ org.value }
                      label={ org.label }
                      disabled={ org.disabled }
                      checked={ selected }
                      onChange={ () => handleWeeklyNotificationChange(org.value) }
                    />
                  );
                })
              }

            </div>
          </LoadingOverlay>
        </TwoColFormRow>
        <div className={ `mb-6` } />
        <div className={ `flex justify-end` }>
          <Button
            loading={ saveNotificationsLoading }
            loadingText={ `Saving...` }
            onClick={ handleSaveNotifications }
          >
            { `Save Notifications` }
          </Button>
        </div>
        <Divider/>
        <TwoColFormRow
          heading={ `Invitations` }
        >
          <div
            className={ `space-y-3` }>

            <PendingInvitations showContentOnly={ true } />
          </div>
        </TwoColFormRow>
      </CardContent>
    </Card>
  );
}
