import { useParams } from "react-router";
import { useMemo } from "react";

import { useEscalations } from "../../../hooks/useEscalations";
import { Card } from "../../../../common/Atoms/Card";
import { MultiButtonCardHeader } from "../../../../common/Atoms/MultiButtonCardHeader";
import { LoadingOverlay } from "../../Molecules/LoadingOverlay";

import { Overview } from "./Overview";
import { EscalationNotes } from "./EscalationNotes";
import { ContactDetails } from "./ContactDetails";
import { EscalationActions } from "./EscalationActions";

export function EscalationPageRoot() {
  const { escalationId } = useParams<{ escalationId: string }>();
  const { data: escalationsData, isLoading } = useEscalations();

  const currentEscalation = useMemo(() => {
    if (!escalationsData || !escalationsData.escalations) {
      return null;
    }

    return escalationsData.escalations.find(escalation => escalation.id === Number(escalationId));
  }
  , [escalationId, escalationsData]);

  return (
    <LoadingOverlay
      loading={ isLoading }
    >
      <div className={ `grid gap-4 grid-cols-1 lg:grid-cols-12` }>
        <Card className={ `lg:col-span-12` }>
          <MultiButtonCardHeader
            title={ `Escalation: ${currentEscalation?.contact?.name} | ${currentEscalation?.invoice?.xero_number}` }
            noDivider
          />
        </Card>

        <div className={ `lg:col-span-12` }>
          <EscalationActions escalation={ currentEscalation } />
        </div>

        <div className={ `lg:col-span-8` }>
          <Overview escalation={ currentEscalation } />
        </div>

        <div className={ `lg:col-span-4` }>
          <ContactDetails escalation={ currentEscalation } />
        </div>

        <div className={ `lg:col-span-12` }>
          <EscalationNotes escalation={ currentEscalation } />
        </div>
      </div>
    </LoadingOverlay>
  );
}
