import React from 'react';
import { When } from 'react-if';

import { classNames } from '../lib/classNames';

interface DividerProps {
  text?: string
  weight?: `light` | `normal` | `bold`;
  spacing?: `small` | `medium`;
}

const weights = {
  light: `border-gray-200`,
  normal: `border-gray-300`,
  bold: `border-gray-500`,
};

const spacings = {
  small: `py-4`,
  medium: `py-6`,
};

export function Divider({
  text,
  weight = `normal`,
  spacing = `medium`,
}: DividerProps) {
  return (
    <div className={
      classNames(
        `relative`,
        spacings[spacing],
      )
    }>
      <div className={ `absolute inset-0 flex items-center` }
        aria-hidden={ `true` }>
        <div className={
          classNames(
            `w-full`,
            `border-t`,
            weights[weight],
          )
        }
        />
      </div>
      <When condition={ text }>
        <div className={ `relative flex justify-center rounded-lg` }>
          <span className={ `bg-white px-2 text-sm text-gray-500 rounded-xl` }>{ text }</span>
        </div>
      </When>
    </div>
  );
}
