
import { Paragraph } from '../../../common/Atoms/Typography/Paragraph';

export function PlanItem({ item }) {
  return (
    <div className={ `flex flex-row` }>
      <item.icon className={ `w-6 h-6` } />
      <div className={ `ml-2` }>
        <span className={ `text-base text-gray-900` }>
          { item.text }
        </span>
        <Paragraph variant={ `help` }
          size={ `xs` }>
          { item.subText }
        </Paragraph>
      </div>
    </div>
  );
}
