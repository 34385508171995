import { useEffect, useState } from 'react';
import { PencilIcon } from '@heroicons/react/24/outline';
import { When } from 'react-if';

import { Button } from '../../../common/Atoms/Button';
import { Paragraph } from '../../../common/Atoms/Typography/Paragraph';
import { DatePicker } from '../../../common/Atoms/DatePicker';
import { useGetSelectedOrganisation } from '../../hooks/useGetSelectedOrganisation';
import { DatePopover } from '../../../common/Components/DatePopover';

interface ExpectedPaymentDateProps {
  onSave: (date: string) => Promise<void>;
  currentDate?: string;
  loading?: boolean;
}

export function ExpectedPaymentDate({ onSave, currentDate, loading }: ExpectedPaymentDateProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [expectedDate, setExpectedDate] = useState<string | undefined>(currentDate);
  const organisation = useGetSelectedOrganisation();

  async function onSaveHandler() {
    await onSave(expectedDate);

    setIsEditing(false);
  }

  useEffect(() => {
    if (currentDate !== null && currentDate !== undefined) {
      setExpectedDate(currentDate);
    }
  }, [currentDate]);

  return (
    <>
      <div className={ `flex flex-row justify-start items-center` }>
        <When condition={ isEditing }>
          <DatePicker
            className={ `min-w-0` }
            onChange={ ({ value }) => setExpectedDate(value)  }
            name={ `expectedPaymentDate` }
            value={
              expectedDate
                ? expectedDate
                : ``
            }
          />
          <Button
            className={ `ml-2 mt-1` }
            onClick={ onSaveHandler }
            loading={ loading }
            disabled={ !expectedDate }
          >
            { `Save` }
          </Button>
        </When>
        <When condition={ !isEditing }>
          <Paragraph variant={ `secondary` }>
            { expectedDate ? (
              <DatePopover
                date={ expectedDate }
                labelFormat={ `ccc dd LLL yyyy` }
                organisationTimezone={ organisation.validatedTimezone }
              />
            ): `Not set` }
          </Paragraph>

          <Button
            icon={ <PencilIcon className={ `w-4 h-4` } /> }
            onClick={ () => setIsEditing(true) }
            color={ `none` }
          />
        </When>
      </div>
    </>
  );
}
