import { EscalationOutcomeLabels, IEscalationOutcome, IEscalationResponseItem, PolicyEscalationType } from "shared";
import { When } from "react-if";
import { useMemo, useState } from "react";

import { Notes } from "../../Molecules/Notes";
import { useAddNoteMutation } from "../../../services/api/escalationApi/escalation";
import { Select } from "../../../../common/Atoms/Select";
import { Card } from "../../../../common/Atoms/Card";

interface EscalationNotesProps {
  escalation: IEscalationResponseItem;
}

export function EscalationNotes({ escalation }: EscalationNotesProps) {
  const [addNoteApiCall, { isLoading: addNoteLoading }] = useAddNoteMutation();
  const [ outcome, setOutcome ] = useState(``);

  const noteOptions = useMemo(() => {
    const options = [];
    if (!escalation) return options;
    if (escalation.escalationType !== PolicyEscalationType.CALL) return options;

    // Calls can have several outcomes
    const outcomes = [
      IEscalationOutcome.NO_RESPONSE,
      IEscalationOutcome.LEFT_MESSAGE,
      IEscalationOutcome.SPOKE_TO_CUSTOMER,
    ];

    options.push(...outcomes.map(o => {
      return {
        label: EscalationOutcomeLabels[o],
        value: o,
      };
    }));

    return options;
  }, [escalation]);

  async function addNote(content: string, mentionedUsers: string[]) {
    if (!escalation) return;

    await addNoteApiCall({
      organisationId: escalation.organisationId,
      escalationId: escalation.id,
      note: content,
      mentionedUsers,
      outcome: outcome,
    });

    setOutcome(null);
  }

  if (!escalation) {
    return null;
  }

  return (
    <Card>
      <Notes
        notes={ escalation?.notes }
        loading={ addNoteLoading }
        enableNewNote
        childrenOptions={ <When condition={ noteOptions && noteOptions.length > 0 }>
          <Select
            className={ `mt-2` }
            emptyText={ `Optionally, select a call outcome...` }
            options={ noteOptions }
            selected={ outcome }
            onChange={ e => setOutcome(e.value) }
            nullable
          />
        </When> }
        onSave={ addNote }
      />
    </Card>
  );
}
