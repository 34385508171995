import { IQuotesResponse } from 'shared';

import { CacheTags } from '../../types/cacheTags';
import { emptySplitApi } from '../baseApi';

export const quotesApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchQuotes: builder.query<IQuotesResponse, { organisationId: string }>({
      query: ({ organisationId }) => ({
        url: `quotes`,
        method: `GET`,
        headers: {
          [`X-Organisation-Id`]: organisationId,
        },
      }),
      providesTags: [{
        type: CacheTags.Quotes,
      }],
    }),
    syncQuotes: builder.mutation<void, { organisationId: string }>({
      query: ({ organisationId }) => ({
        url: `quotes/sync/all`,
        method: `GET`,
        headers: {
          [`X-Organisation-Id`]: organisationId,
        },
      }),
      invalidatesTags: [{ type: CacheTags.Quotes }, { type: CacheTags.Organisations }],
    }),
  }),
});

export const {
  useFetchQuotesQuery,
  useSyncQuotesMutation,
} = quotesApi;
