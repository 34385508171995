import React from 'react';

import { classNames }  from '../../../../common/lib/classNames';

interface IFormRowProps {
  children: React.ReactNode;
  id?: string;
  className?: string;
}

export function FormRow({ children, id, className }: IFormRowProps) {
  return (
    <div
      className={
        classNames(
          `mb-6`,
          className,
          className?.includes(`space-y-`) ? `` : `space-y-6`,
        )
      }
      id={ id }
    >
      { children }
    </div>
  );
}
