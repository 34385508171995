import { ChevronDownIcon, ChevronUpIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Switch, Case, When } from 'react-if';

import { useInvoiceParams } from '../../hooks/useGetInvoices';

export function TableHeading({ heading, dataKey, sortable = true }) {
  const { params, updateParams } = useInvoiceParams();

  function handleSortColumn() {
    const newSortType = params.sortType === `asc` ? `desc` : `asc`;

    updateParams({
      ...params,
      sortColumn: dataKey,
      sortType: newSortType,
    });
  }

  return (
    <>
      <a href={ `#` }
        className={ `group flex flex-row` }
        onClick={ handleSortColumn }>
        { heading }
        <When condition={ sortable }>
          <span className={ `h-5 w-5 ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300` }>
            <Switch>
              <Case condition={ params.sortColumn !== dataKey }>
                <ChevronUpDownIcon className={ `h-5 w-5` }
                  aria-hidden={ `true` } />
              </Case>
              <Case condition={ params.sortType === `desc` }>
                <ChevronDownIcon className={ `h-5 w-5` }
                  aria-hidden={ `true` } />
              </Case>
              <Case condition={ params.sortType === `asc` }>
                <ChevronUpIcon className={ `h-5 w-5` }
                  aria-hidden={ `true` } />
              </Case>
            </Switch>
          </span>
        </When>
      </a>
    </>
  );
}
