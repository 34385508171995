
import { Modal } from "../../../common/Atoms/Modal";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { Divider } from "../../../common/Atoms/Divider";
import { env } from '../../../common/lib/env';
import connectQBOButton from '../../assets/QuickBooks-connect-button/connect-to-quickbooks-tall-default_2x.png';

interface QuickBooksConnectModal {
  onCancel: () => void;
  show: boolean;
}

export function QuickBooksConnectModal({ onCancel, show }: QuickBooksConnectModal) {
  function onClose() {
    // This is the top X, treat as cancel
    onCancel();
  }

  return (
    <Modal
      title={ `` }
      open={ show }
      setOpen={ onClose }
    >

      <div className={ `flex flex-col items-center mt-6` }>
        <Paragraph variant={ `primary` }>
          { `To get started connect to QuickBooks by clicking the button below. Don't worry, once you connect Paidnice won't change anything in your accounting system until you are ready.` }
        </Paragraph>

        <Divider weight={ `light` } />

        <div>
          <a href={ `${env.apiUrl}/connect/quickbooks` }>
            <img width={ `245px` }
              style={ { width: `245px`, maxWidth: `none` } }
              src={ connectQBOButton } />
          </a>
        </div>
      </div>

    </Modal>
  );
}

