import { useEffect, useMemo, useState } from "react";
import { GroupOptionsOnboarding, IOrganisationMarketingData, MixpanelEvents, OnboardingSteps, PolicyType } from "shared";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import { When } from "react-if";

import { Card } from "../../../../common/Atoms/Card";
import { Heading } from "../../../../common/Atoms/Typography/Heading";
import { Paragraph } from "../../../../common/Atoms/Typography/Paragraph";
import { useGetSelectedOrganisation } from "../../../hooks/useGetSelectedOrganisation";
import { Button } from "../../../../common/Atoms/Button";
import { useSaveOnboardingCompleteMutation, useSaveOnboardingGroupPoliciesMutation, useSaveOnboardingSkippedMutation, useSaveWelcomeQuestionsMutation } from "../../../services/api/organisationApi/organisation";
import { Notification } from "../../../../common/Atoms/Notification";
import { mixpanelService } from "../../../../common/lib/mixpanel";
import { useOrganisations } from "../../../hooks/useOrganisations";
import { LoadingOverlay } from "../../Molecules/LoadingOverlay";

import { WelcomeQuestions } from "./WelcomeQuestions";
import { Features } from "./Features";
import { GroupOptions } from "./GroupOptions";
import { FinalNotes } from "./FinalNotes";

export function OnboardingRoot() {
  const currentOrg = useGetSelectedOrganisation();
  const { isLoading, isFetching } = useOrganisations();
  const [ welcomeQuestions, setWelcomeQuestions ] = useState<IOrganisationMarketingData>({
    version: 1,
  });
  const [saveWelcomeQuestions, { isLoading: marketingLoading }] = useSaveWelcomeQuestionsMutation();
  const [saveOnboardingGroupsPolicies, { isLoading: groupLoading }] = useSaveOnboardingGroupPoliciesMutation();
  const [saveOnboardingComplete, { isLoading: completeLoading }] = useSaveOnboardingCompleteMutation();
  const [saveOnboardingSkipped, { isLoading: skippedLoading }] = useSaveOnboardingSkippedMutation();
  const [chosenFeatures, setChosenFeatures] = useState<PolicyType[]>([]);
  const [groupOption, setGroupOption] = useState<GroupOptionsOnboarding>(GroupOptionsOnboarding.EVERYONE);

  const [onboardingStep, setOnboardingStep] = useState<OnboardingSteps>(currentOrg?.onboarding.currentStep || OnboardingSteps.MARKETING_DATA);

  const steps: {
    [key in OnboardingSteps]: {
      title: string;
      subtitle: string;
      help?: string;
      component: JSX.Element;
    }
  } = useMemo(() => {
    return {
      [OnboardingSteps.MARKETING_DATA]: {
        title: `Welcome to Paidnice!`,
        subtitle: `We're excited to have you on board. To help us understand your needs, please answer a few questions.`,
        component: <WelcomeQuestions
          welcomeQuestions={ welcomeQuestions }
          setWelcomeQuestions={ setWelcomeQuestions }
        />,
      },
      [OnboardingSteps.FEATURES]: {
        title: `What brings you to Paidnice?`,
        subtitle: `Select what you're looking to do with Paidnice. We will create these as "Policies" for you. You can always add more and change later.`,
        component: <Features
          chosenFeatures={ chosenFeatures }
          setChosenFeatures={ setChosenFeatures }
        />,
      },
      [OnboardingSteps.GROUPS]: {
        title: `Who should have the previously selected features (policies) applied to them?`,
        subtitle: `Paidnice uses "groups" to decide which contacts have various actions applied to them. Here are some common examples of use cases for groups. You can add more groups later if you want even more control.`,
        help: `Groups are just one way to control who your policies apply to. You can find more ways to filter in each policy's settings.`,
        component: <GroupOptions
          setGroupOption={ setGroupOption }
          groupOption={ groupOption }
        />,
      },
      [OnboardingSteps.FINAL_NOTES]: {
        title: `Final Steps`,
        subtitle: `You're 90% set up and ready to go. You can use Paidnice for free for the first 20 actions. When you're ready, complete the final steps such as starting your subscription`,
        // help: `Click "Go to Group" to start configuring your selected policies.`,
        component: <FinalNotes />,
      },
    };
  }, [welcomeQuestions, chosenFeatures, groupOption]);

  useEffect(() => {
    if (currentOrg && currentOrg.onboarding.currentStep !== onboardingStep) {
      if (onboardingStep === OnboardingSteps.GROUPS) {
        // Do nothing, groups is never returned from servers
      }
      else {
        setOnboardingStep(currentOrg.onboarding.currentStep);
      }
    }
  }, [currentOrg, onboardingStep]);

  async function handleNext() {
    if (!currentOrg) return;

    if (onboardingStep === OnboardingSteps.MARKETING_DATA) {
      saveWelcomeQuestions({
        ...welcomeQuestions,
        organisationId: currentOrg.id,
        dismissed: true, // Always mark as dismissed
      });

      return;
    }

    if (onboardingStep === OnboardingSteps.FEATURES) {
      // Need to know group preferences before we can move on, so go to next screen
      if (chosenFeatures.length === 0) {
        toast.error(`Please select at least one feature to continue`);

        return;
      }

      setOnboardingStep(OnboardingSteps.GROUPS);

      return;
    }

    if (onboardingStep === OnboardingSteps.GROUPS) {
      saveOnboardingGroupsPolicies({
        organisationId: currentOrg.id,
        groupOption,
        selectedPolicies: chosenFeatures,
      });

      setOnboardingStep(OnboardingSteps.FINAL_NOTES);

      return;
    }

    if (onboardingStep === OnboardingSteps.FINAL_NOTES) {
      // All done
      onComplete();

      return;
    }
  }

  function gotToFeatures() {
    setOnboardingStep(OnboardingSteps.FEATURES);
  }

  function onComplete() {
    saveOnboardingComplete(currentOrg.id);

    mixpanelService.trackMixpanelEvent(MixpanelEvents.OnboardingCompleted);
  }

  function onSkipped() {
    saveOnboardingSkipped(currentOrg.id);

    mixpanelService.trackMixpanelEvent(MixpanelEvents.OnboardingSkipped);
  }

  const buttonText = onboardingStep === OnboardingSteps.FINAL_NOTES ? `View  Group` : `Next`;
  const loading = marketingLoading || groupLoading || completeLoading || skippedLoading || isLoading || isFetching;

  return (
    <LoadingOverlay
      loading={ loading }
    >
      <Card
        className={ `min-h-[80vh] flex flex-col justify-between` }
      >
        { /* Main */ }
        <div className={ `py-4 px-6` }>
          <Heading
            size={ `xl` }
            variant={ `default` }
            className={ `mb-4` }
          >
            { steps[onboardingStep].title }
          </Heading>

          <Paragraph>
            { steps[onboardingStep].subtitle }
          </Paragraph>

          <div className={ `mt-4` }>
            { steps[onboardingStep].component }
          </div>

          <When condition={ !!steps[onboardingStep]?.help }>
            <div className={ `mt-6` } >
              <Notification
                type={ `info` }
              >
                { steps[onboardingStep].help }
              </Notification>
            </div>
          </When>

          { /* Save buttons */ }
          <div className={ `flex w-full justify-center mt-12` }>
            <When condition={ onboardingStep === OnboardingSteps.GROUPS }>
              <Button
                size={ `2xl` }
                onClick={ gotToFeatures }
                className={ `mr-4` }
              >
                <ArrowLeftIcon className={ `h-5 w-5 mr-2` } />
                { `Back` }
              </Button>
            </When>
            <Button
              size={ `2xl` }
              onClick={ handleNext }
            >
              { buttonText }
              <ArrowRightIcon className={ `h-5 w-5 ml-2` } />
            </Button>
          </div>
        </div>

        { /* Footer */ }
        <div className={ `self-center` }>
          <a
            href={ `#` }
            onClick={ e => {
              e.preventDefault();
              onSkipped();
            } }
          >
            <Paragraph
              variant={ `link` }
            >
              { `My use case is more complex, skip guided setup` }
            </Paragraph>
          </a>
        </div>
      </Card>
    </LoadingOverlay>
  );
}
