import { FormLabel } from "../../../../../../common/Atoms/FormLabel";
import { fieldIsValid } from "../../../../../lib/helper";
import { Assignee } from "../../../../Common/Assignee";

import { IFieldProps } from "./fieldProps";

export function DefaultAssignee({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {
  return (
    <div className={ `w-full` }>
      <FormLabel>
        { `Default Assignee` }
      </FormLabel>
      <Assignee
        current={ policyFormData.assignee_user_id || null }
        onChange={ value =>
          handleChange({
            name: `assignee_user_id`,
            value,
          })
        }
        invalid={ fieldIsValid(`assignee_user_id`, invalids) }
      />
    </div>
  );
}
