import superagent from 'superagent';
import { StripePricesLookupKeys } from 'shared';

import { env } from '../../common/lib/env';

const baseUrl = `${env.apiUrl}/api/v1`;

export async function apiRequest(method = `get`, endpoint, data = {}, query?: {
  [key: string]: unknown
}) {
  const dataMethod = method.toLowerCase() === `get` ? `query` : `send`;
  const headers = {
    Accept: `application/json`,
  };

  try {
    const response = await superagent[method](baseUrl + endpoint)
      .set(headers)[dataMethod](data)
      .withCredentials()
      .query(query);

    return response;
  }
  catch (err) {
    if (err.status === 401) {
      // REDIRECT TO LOGIN
      window.location.href = `${env.apiUrl}/login?redirect=${window.location.href}`;

      throw new Error(`Not logged in!`);
    }

    if (err.response?.text) {
      throw new Error(err.response.text);
    }

    throw err;
  }
}

export function getPlansFromStripe() {
  return apiRequest(`get`, `/payments/get-plans`);
}

export function createCheckout(lookupKey: StripePricesLookupKeys, subscriptionId: string, changePlan?:boolean) {
  // Rewardful.com, get the referal code if exists
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rewardfulReferralCode = (window as any).Rewardful?.referral;

  console.log(`rewardfulReferralCode`, rewardfulReferralCode);

  return apiRequest(`post`, `/payments/create-checkout-session`, { lookupKey, subscriptionId, changePlan, rewardfulReferralCode });
}

export function processNowApi(invoiceId, org) {
  return apiRequest(`get`, `/invoice/${invoiceId}/process-now`, { org });
}
