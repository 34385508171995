import { useLocation } from 'react-router';

import { useFetchOrganisationsQuery } from '../services/api/organisationApi/organisation';

import { useUser } from './useUser';

export function useOrganisations() {
  const location = useLocation();
  const { data: user } = useUser();

  const isOnboarding = location.pathname.includes(`onboarding`);

  const result = useFetchOrganisationsQuery(null, {
    // Dont poll in onboarding
    pollingInterval: isOnboarding ? undefined : 1000 * 60,
    skipPollingIfUnfocused: true,
  });

  if (!result.data) {
    result.data = [];
  }

  // filter disconnected orgs when not admin
  if (!user?.adminOverride) {
    result.data = result.data.filter(org => org.connected);
  }

  return result;
}
