import { policyConfig } from "shared";

import { fieldIsValid } from "../../../../../lib/helper";
import { Toggle } from "../../../../../../common/Atoms/Toggle";

import { IFieldProps } from "./fieldProps";

const config = policyConfig[`only_webhook`];

export function OnlyWebhooks({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {
  return (
    <Toggle
      label={ config.label }
      description={ `Will only trigger a webhook (if enabled) & will NOT send directly to contact.` }
      checked={ policyFormData.only_webhook || false }
      onChange={ handleChange }
      invalid={ fieldIsValid(`only_webhook`, invalids) }
      name={ `only_webhook` }
    />
  );
}
