import { format } from 'date-fns';
import { DateFormats } from 'shared';

import { useGetSelectedOrganisation } from './useGetSelectedOrganisation';
import { useSubscriptions } from './useSubscriptions';

export function useGetSubscriptionMetadata() {
  const organisation = useGetSelectedOrganisation();
  const { data: subscriptions } = useSubscriptions();

  let subscriptionMetadata = organisation?.subscriptionMetadata;

  const isBillingOwner = !!subscriptions?.find(
    subscription => subscriptionMetadata && subscription.id === subscriptionMetadata.id,
  );

  if (subscriptionMetadata) {
    subscriptionMetadata = { ...subscriptionMetadata };
    subscriptionMetadata.currentPeriodStartsOn = format(new Date(subscriptionMetadata.currentPeriodStartsOn), DateFormats.UI_DISPLAY_SHORT);
    subscriptionMetadata.currentPeriodEndsOn = format(new Date(subscriptionMetadata.currentPeriodEndsOn), DateFormats.UI_DISPLAY_SHORT);
  }

  return {
    subscriptionMetadata,
    isBillingOwner,
  };
}
