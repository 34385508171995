export enum CacheTags {
  Contacts = `Contacts`,
  SingleContact = `SingleContact`,
  Policies = `Policies`,
  Organisations = `Organisations`,
  OrganisationMetrics = `OrganisationMetrics`,
  Groups = `Groups`,
  Settings = `Settings`,
  Subscriptions = `Subscriptions`,
  Actions = `Actions`,
  Escalations = `Escalations`,
  EmailTemplates = `EmailTemplates`,
  Invoices = `Invoices`,
  User = `User`,
  Accounts = `Accounts`,
  BrandingThemes = `BrandingThemes`,
  AuditLogs = `AuditLogs`,
  StripeAccount = `StripeAccount`,
  CustomerPayments = `CustomerPayments`,
  Files = `Files`,
  ActionCreateStatus = `ActionCreateStatus`,
  AICalls = `AICalls`,
  ContactTags = `ContactTags`,
  Quotes = `Quotes`,
  EmailReport = `EmailReport`,
  BadContacts = `BadContacts`,
  BadInvoices = `BadInvoices`,
  SystemPolicies = `SystemPolicies`,
  PreviewPolicy = `PreviewPolicy`,
}

