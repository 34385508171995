import { Card } from "../../../../../common/Atoms/Card";
import { CardContent } from "../../../../../common/Atoms/CardContent";
import { Divider } from "../../../../../common/Atoms/Divider";
import { useGeneralSettingsForm } from "../../../../hooks/useGeneralSettingsForm";
import { SaveAndError } from "../SaveAndError";

import { CustomDomain } from "./CustomDomain";
import { DefaultEmailSettings } from "./DefaultEmail";
import { EmailSignature } from "./EmailSignature";

export function EmailRoot() {
  const {
    waiting,
  } = useGeneralSettingsForm();

  return (
    <Card>
      <CardContent>
        <DefaultEmailSettings/>
        <div className={ `mt-4` } />
        <EmailSignature />
        <Divider weight={ `light` } />
        {
          !waiting && (
            <CustomDomain/>
          )
        }
        <SaveAndError />
      </CardContent>
    </Card>
  );
}
