import { IContactParams, IContactResponse } from 'shared';

import { orgHeader } from '../axiosBaseQuery';
import { emptySplitApi } from '../baseApi';
import { CacheTags } from '../../types/cacheTags';

export const contactsApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchContacts: builder.query<IContactResponse, IContactParams>({
      query: ({ organisationId, ...params }) => ({
        url: `contact`,
        method: `GET`,
        params: {
          ...params,
        },
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      providesTags: result => {
        return result?.contacts ?
          [
            ...result.contacts.map(({ id }) => ({ type: CacheTags.Contacts as const, id })),
            { type: CacheTags.Contacts as const, id: `LIST` },
          ]
          : [{ type: CacheTags.Contacts as const, id: `LIST` }];
      },
    }),
    searchContacts: builder.query<IContactResponse, { organisationId: string, searchStr: string }>({
      query: ({ organisationId, searchStr }) => ({
        url: `contact`,
        method: `GET`,
        params: {
          searchStr,
        },
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      providesTags: result => {
        return result?.contacts ?
          [
            ...result.contacts.map(({ id }) => ({ type: CacheTags.Contacts as const, id })),
            { type: CacheTags.Contacts as const, id: `LIST` },
          ]
          : [{ type: CacheTags.Contacts as const, id: `LIST` }];
      },
    }),
    syncContacts: builder.mutation<void, string>({
      query: org => ({
        url: `contact`,
        method: `POST`,
        data: {
          action: `sync`,
        },
        headers: {
          [orgHeader]: org,
        },
      }),
      invalidatesTags: [{ type: CacheTags.Organisations, id: `LIST` }],
    }),
    applyTag: builder.mutation<void, { organisationId: string; contactIds: number[]; tag: string }>({
      query: ({ organisationId, contactIds, tag }) => ({
        url: `contact/tags`,
        method: `POST`,
        data: {
          contactIds,
          tag,
        },
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: () => [{ type: CacheTags.Contacts }, { type: CacheTags.SingleContact }],
    }),
    clearTags: builder.mutation<void, { organisationId: string; contactIds: number[], tagName?: string }>({
      query: ({ organisationId, contactIds, tagName }) => ({
        url: `contact/tags`,
        method: `DELETE`,
        data: {
          contactIds,
          tagName,
        },
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: () => [{ type: CacheTags.Contacts }, { type: CacheTags.SingleContact }],
    }),
    sendAnnouncement: builder.mutation<void, { organisationId: string; contactIds: number[], templateId?: string }>({
      query: ({ organisationId, contactIds, templateId }) => ({
        url: `contact/send/announcement`,
        method: `PUT`,
        data: {
          contactIds,
          templateId,
        },
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: () => [{ type: CacheTags.Contacts }, { type: CacheTags.SingleContact }],
    }),
  }),
});

export const {
  useFetchContactsQuery,
  useLazyFetchContactsQuery,
  useLazySearchContactsQuery,
  useApplyTagMutation,
  useSyncContactsMutation,
  useClearTagsMutation,
  useSendAnnouncementMutation,
} = contactsApi;
