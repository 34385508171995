import { Tooltip } from "@mui/material";
import { When } from "react-if";
import { displayPhoneNumber } from "shared";

import { Heading } from "../../Atoms/Typography/Heading";
import { Badge } from "../../Atoms/Badge";
import { WrappedLink } from "../../../main/components/WrappedLink";
import { InvoiceDetails } from "../../lib/invoiceHelper";

interface InvoiceContactProps {
  invoiceDetails: InvoiceDetails;
  mode: `app` | `portal`;
}

export function InvoiceContact({ invoiceDetails, mode }: InvoiceContactProps) {

  const name = mode === `app` ? (
    <WrappedLink
      to={ `/contacts/${invoiceDetails.contact.id}` }
      className={ `hover:underline text-indigo-600 hover:text-indigo-800 ` }
    >

      { invoiceDetails.contact.name }
    </WrappedLink>
  ) : (
    invoiceDetails.contact.name
  );

  return (
    <div className={ `mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6` }>
      <dt className={ `font-semibold text-gray-900` }>{ `To` }</dt>
      <dd className={ `mt-2 text-gray-500` }>
        <div className={ `flex mb-1` }>
          <Heading
            variant={ `secondary` }
          >
            { name }
          </Heading>
          <When condition={ mode === `app` }>
            <Tooltip title={ `The Paidnice group that this contact belongs to` }>
              <Badge
                className={ `ml-2 max-h-[75px]` }
                color={ `gray` }
              >
                { invoiceDetails.groupTitle }
              </Badge>
            </Tooltip>

            <When condition={ !!invoiceDetails.sent_to_customer }>
              <Tooltip title={ `This invoice has been sent` }>
                <Badge
                  className={ `ml-2 max-h-[75px]` }
                  color={ `gray` }
                >
                  { `Sent` }
                </Badge>
              </Tooltip>
            </When>
          </When>

        </div>
        { invoiceDetails.contact.primaryContactPerson.email }
        <br />
        <div>
          <div>
            { displayPhoneNumber(invoiceDetails.contact.primaryContactPerson.mobileCountryCode, invoiceDetails.contact.primaryContactPerson.mobileNumber) }
          </div>
          <div>
            { displayPhoneNumber(invoiceDetails.contact.primaryContactPerson.defaultCountryCode, invoiceDetails.contact.primaryContactPerson.defaultNumber) }
          </div>
        </div>
      </dd>
    </div>
  );
}
