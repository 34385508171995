import { getAnalytics, logEvent } from 'firebase/analytics';

import { env } from '../../common/lib/env';

import { fbApp } from './firebase';

const analytics = env.isCypress ? null : getAnalytics(fbApp);

const events = {
  beginCheckout: `begin_checkout`,
  checkoutSuccess: `checkout_success`,
  saveWelcomeQuestions: `save_welcome_questions`,
  askLaterWelcomeQuestions: `ask_later_welcome_questions`,
  connectAccounting: `connect_accounting`,
};

function logAnalyticEvent(name, params) {
  if (analytics) {
    logEvent(analytics, name, params);
  }
}

export {
  logAnalyticEvent,
  events,
  analytics,
};
