import { langauge } from "shared";
import { Else, If, Then, When } from "react-if";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";

import { Badge } from "../../../../../common/Atoms/Badge";
import { Paragraph } from "../../../../../common/Atoms/Typography/Paragraph";
import { useContact } from "../../../../hooks/useContact";

export function ApplicableContactPolicies() {
  const { data: contact } = useContact();

  const policyChecks = contact?.applicablePolicies ? Object.values(contact.applicablePolicies) : [];

  return (
    <div className={ `` }>
      {
        policyChecks.map((policyCheck, index) => (
          <div
            className={ `grid grid-cols-6 min-h-[52px] w-full border-b border-gray-200` }
            key={ index }>
            <div className={ `flex flex-col lg:flex-row col-span-2 justify-center lg:items-center lg:justify-start` }>
              <Paragraph>
                { policyCheck.policy.title }
              </Paragraph>

              <div>
                <Badge
                  className={ `ml-2 max-w-fit max-h-fit` }
                >
                  { langauge.policyType[policyCheck.policy.type] }
                </Badge>
              </div>
            </div>

            <Paragraph className={ `flex items-center` }>
              { policyCheck.type === `contact` ? `Contact Level` : `Invoice Level` }
            </Paragraph>

            <div className={ `col-span-2 flex items-center` }>
              <div className={ `flex flex-col` }>
                <Paragraph>
                  { policyCheck.message }
                </Paragraph>
                <When condition={ policyCheck.type === `invoice` && policyCheck.applicableInvoiceCount > 0 }>
                  <Paragraph variant={ `help` }>
                    { policyCheck.applicableInvoiceCount } { ` applicable invoices` }
                  </Paragraph>
                </When>

                <When condition={ policyCheck.type === `invoice` && policyCheck.nonApplicableInvoiceCount > 0 }>
                  <Paragraph variant={ `help` }>
                    { policyCheck.nonApplicableInvoiceCount }{ ` non applicable invoices. (View more info on invoice page)` }
                  </Paragraph>
                </When>
              </div>
            </div>

            <div className={ `flex justify-center items-center` }>
              <If condition={ policyCheck.appliesToContact }>
                <Then>
                  <CheckCircleIcon
                    className={ `w-6 h-6 text-green-400` }
                  />
                </Then>
                <Else>
                  <XCircleIcon
                    className={ `w-6 h-6 text-red-400` }
                  />
                </Else>
              </If>
            </div>
          </div>
        ))
      }
    </div>
  );
}
