import { useMemo, useEffect } from "react";
import { IUpdateSettingsRequest, objectsAreEqual, settingUpdateSchema, yupErrorMessageParse } from "shared";
import { useDispatch, useSelector } from "react-redux";

import { OnChangeFn } from "../components/Settings/General/Main/GeneralSettings";
import { useUpdateSettingsMutation } from "../services/api/settingsApi/settings";
import { RootState } from "../store";
import { setFormData, setInvalids, setUnsavedChanges, updateFormData } from "../slices/settingsState";

import { useGetSelectedOrganisation } from "./useGetSelectedOrganisation";
import { useSettings } from "./useSettings";

export function useGeneralSettingsForm() {
  const dispatch = useDispatch();
  const settingsState = useSelector((state: RootState) => state.settingsState);
  const { formData, invalids, hasUnsavedChanges } = settingsState;

  const currentOrg = useGetSelectedOrganisation();
  const { data: settings, isLoading: waiting } = useSettings();
  const [ updateSettings, { isLoading: updateLoading }] = useUpdateSettingsMutation();

  // Keep track of unsaved changes
  useEffect(() => {
    // Remove extra fields
    const settingsClone = { ...settings };

    if (settingsClone?.recentWebhooks) {
      delete settingsClone.recentWebhooks;
    }

    dispatch(setUnsavedChanges(!objectsAreEqual(formData, settingsClone)));
  }, [ formData, settings ]);

  const globalInvalids = useMemo(() => {
    return invalids.filter(i => !i.path);
  }, [ invalids ]);

  useEffect(() => {
    // Remove extra fields
    const settingsClone = { ...settings };

    if (settingsClone?.recentWebhooks) {
      delete settingsClone.recentWebhooks;
    }

    dispatch(setFormData({
      ...settingsClone,
    }));
  }, [ settings ]);

  const onChange: OnChangeFn = (field, value) => {
    dispatch(updateFormData({
      [field]: value,
    }));
  };

  const onChangeMultiple = (values: Partial<IUpdateSettingsRequest>) => {
    dispatch(updateFormData({
      ...values,
    }));
  };

  async function onSave() {
    const valid = await validate(settingsState.formData);

    if (valid) {
      updateSettings({
        organisationId: currentOrg?.id,
        settings: {
          ...settingsState.formData,
        },
      });
    }
  }

  async function validate(settings: IUpdateSettingsRequest) {
    try {
      const valid = await settingUpdateSchema.validate(settings, {
        abortEarly: false,
        strict: false,
      });

      dispatch(setInvalids([]));

      return valid;
    }
    catch (err) {
      console.error(err);

      const errorMessages = yupErrorMessageParse(err);

      if (Array.isArray(errorMessages)) {
        setInvalids(errorMessages);
      }
      else {
        dispatch(setInvalids([{
          message: `An Unknown Error has occured.`,
        }]));
      }

      return false;
    }
  }

  return {
    formData,
    onChange,
    onSave,
    invalids,
    waiting: waiting || Object.keys(settings || {}).length === 0,
    updateLoading,
    hasUnsavedChanges,
    globalInvalids,
    onChangeMultiple,
  };
}
