import { useGetSelectedOrganisation } from "./useGetSelectedOrganisation";

export function useCurrentOrgUsers() {
  const currentOrg = useGetSelectedOrganisation();

  if (!currentOrg || !currentOrg.users) {
    return [];
  }

  return currentOrg.users.map(user => ({
    label: `${user.firstName} ${user.lastName} (${user.email})`,
    value: user.id,
  }));
}
