import { Heading } from './Typography/Heading';
import { Paragraph } from './Typography/Paragraph';

export function TableHeading({
  title,
  description,
  button,
}) {
  return (
    <div className={ `sm:flex sm:items-center` }>
      <div className={ `sm:flex-auto` }>
        <Heading>
          { title }
        </Heading>
        <Paragraph
          variant={ `secondary` }
          className={ `mt-2` }>
          { description }
        </Paragraph>
      </div>
      <div className={ `mt-4 sm:ml-16 sm:mt-0 sm:flex-none` }>
        { button }
      </div>
    </div>

  );
}
