import { Fragment, useMemo, useState } from "react";
import { EllipsisVerticalIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { When } from "react-if";
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";

import { Heading } from "../../../../common/Atoms/Typography/Heading";
import { useCurrentGroup } from "../../../hooks/useGroups";
import { InputTextAddon } from "../../../../common/Atoms/InputTextAddon";
import { Button } from "../../../../common/Atoms/Button";
import { DropdownOption } from "../../../../common/Atoms/Dropdown";
import { Modal } from "../../../../common/Atoms/Modal";
import { Paragraph } from "../../../../common/Atoms/Typography/Paragraph";
import { useDeleteGroupMutation } from "../../../services/api/groupApi/group";
import { classNames } from "../../../../common/lib/classNames";
import { Card } from "../../../../common/Atoms/Card";

export function GroupHeading() {
  const { groupFormData, onChange, onSave, updateLoading, isDirty } = useCurrentGroup();
  const [ deleteGroup ] = useDeleteGroupMutation();
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  async function handleSave() {
    await onSave();

    setShowEdit(false);
  }

  const dropdownOptions: DropdownOption[] = useMemo(() => {
    const options: DropdownOption[] = [];

    if (!groupFormData?.default) {
      options.push({
        label: `Delete Group`,
        onClick: () => setConfirmDelete(true),
      });
    }

    return options;
  }, [groupFormData]);

  function onDelete(e) {
    e?.preventDefault();

    deleteGroup({
      id: groupFormData.id,
      organisationId: groupFormData.organisationId,
    });
  }

  if (!groupFormData) {
    return null;
  }

  return (
    <>
      <Modal
        setOpen={ setConfirmDelete }
        open={ confirmDelete }
        title= { `Are you sure you want to delete this group?` }
      >
        <Paragraph>
          {
            `By deleting this group, any contacts assigned will be moved to the default group. Are you sure you want to delete this group?`
          }
        </Paragraph>

        <div className={ `flex justify-end space-x-2 mt-4` }>
          <Button
            onClick={ () => setConfirmDelete(false) }
            color={ `gray` }
          >
            { `Cancel` }
          </Button>
          <Button
            onClick={ onDelete }
            color={ `red` }
          >
            { `Delete Group` }
          </Button>
        </div>
      </Modal>
      <Card>
        <div className={ `` }>
          <div className={ `` }>
            {
              showEdit ? (
                <div className={ `flex items-start space-x-2` }>
                  <InputTextAddon
                    required
                    value={ groupFormData.title }
                    onChange={ e => onChange(`title`, e.value) }
                    name={ `title` }
                    description={ `Give this group a nickname. Customers will not see this.` }
                  />
                  <Button
                    className={ `mt-2` }
                    color={ `gray` }
                    onClick={ () => setShowEdit(false) }
                  >
                    { `Cancel` }
                  </Button>
                  <Button
                    className={ `mt-2` }
                    onClick={ handleSave }
                    loading={ updateLoading }
                    disabled={ !isDirty || updateLoading }
                  >
                    { `Save` }
                  </Button>
                </div>
              ) : (
                <div className={ `flex justify-between items-start` }>
                  <div className={ `flex space-x-1 items-center` }>
                    <Heading>
                      { groupFormData.title }
                    </Heading>
                    <button
                      onClick={ () => setShowEdit(true) }
                    >
                      <PencilSquareIcon className={ `w-6 h-6 text-lateGreen-600` } />
                    </button>
                  </div>

                  <When condition={ !!dropdownOptions.length }>
                    { /* Edit button */ }

                    <div className={ `flex flex-none items-center gap-x-4` }>
                      <Menu as={ `div` }
                        className={ `relative flex-none` }>
                        <MenuButton
                          className={ `-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900` }
                          onClick={ e => {
                            e.stopPropagation();

                          } }>
                          <span className={ `sr-only` }>{ `Open options` }</span>
                          <EllipsisVerticalIcon className={ `h-5 w-5` }
                            aria-hidden={ `true` } />
                        </MenuButton>
                        <Transition
                          as={ Fragment }
                          enter={ `transition ease-out duration-100` }
                          enterFrom={ `transform opacity-0 scale-95` }
                          enterTo={ `transform opacity-100 scale-100` }
                          leave={ `transition ease-in duration-75` }
                          leaveFrom={ `transform opacity-100 scale-100` }
                          leaveTo={ `transform opacity-0 scale-95` }
                        >
                          <MenuItems className={ `absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none` }>
                            {
                              dropdownOptions.map(option => (
                                <MenuItem key={ option.label }>
                                  { ({ focus }) => (
                                    <a
                                      href={ `#` }
                                      onClick={ e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        option.onClick();
                                      } }
                                      className={ classNames(
                                        focus ? `bg-gray-50` : ``,
                                        `block px-3 py-1 text-sm leading-6 text-gray-900`,
                                      ) }
                                    >
                                      { option.label }
                                    </a>
                                  ) }
                                </MenuItem>
                              ))
                            }
                          </MenuItems>
                        </Transition>
                      </Menu>
                    </div>
                  </When>
                </div>
              )
            }

            { /*  Group Summary */ }
            { /* <div className={ `grid grid-cols-2` }>
              <When condition={ groupFormData.default }>
                <div className={ `col-span-2` }>
                  <Divider weight={ `light` } />
                </div>

                <Paragraph className={ `mr-2` }>
                  { `Default Group` }
                </Paragraph>
                <Paragraph variant={ `secondary` }>
                  { `When a contact is seen for the first time. They will be added to this group automatically.` }
                </Paragraph>

              </When>
            </div> */ }
          </div>
        </div>
      </Card>
    </>
  );
}
