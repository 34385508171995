import { PaymentProviderType } from "shared";

import { Toggle } from "../../../../../common/Atoms/Toggle";
import { useGetSelectedOrganisation } from "../../../../hooks/useGetSelectedOrganisation";
import { useDisablePinchPaymentsMutation, useEnablePinchPaymentsMutation } from "../../../../services/api/organisationApi/organisation";
import { TwoColFormRow } from "../../../Molecules/Form/TwoColFormRow";
import pinchLogo from "../../../../assets/pinch-logo.svg";

export function PinchPayments() {
  const [enablePinchPayments, { isLoading: isEnableLoading }] = useEnablePinchPaymentsMutation();
  const [disablePinchPayments, { isLoading: isDisableLoading }] = useDisablePinchPaymentsMutation();
  const currentOrg = useGetSelectedOrganisation();

  const enabled = !!currentOrg.paymentProviders.find(p => p.type === PaymentProviderType.PINCH);

  function handleChange() {
    if (!enabled) {
      enablePinchPayments(currentOrg.id);
    }
    else {
      disablePinchPayments(currentOrg.id);
    }
  }

  return (
    <TwoColFormRow
      heading={ `` }
      description={ <img src={ pinchLogo }
        className={ `h-6` } /> }
    >
      <Toggle
        label={ `Enable Pinch Payments` }
        description={ `Adds ability to add Pinch Payment links to emails. Adds pay now buttons to statement PDFs & customer portal pages. You must already be a customer of Pinch Payments.` }
        checked={ enabled }
        loading={ isEnableLoading || isDisableLoading }
        onChange={ handleChange }
      />
    </TwoColFormRow>
  );
}
