import { useMemo } from "react";

import { MultiSelect } from "../../../../../common/Atoms/MultiSelect";
import { useContact } from "../../../../hooks/useContact";
import { useContactTags } from "../../../../hooks/useContactTags";
import { useGetSelectedOrganisation } from "../../../../hooks/useGetSelectedOrganisation";
import { Badge } from "../../../../../common/Atoms/Badge";
import { Paragraph } from "../../../../../common/Atoms/Typography/Paragraph";
import { useApplyTagMutation, useClearTagsMutation } from "../../../../services/api/contactsApi/contacts";

export function TagSelector() {
  const { data: contact } = useContact();
  const currentOrg = useGetSelectedOrganisation();
  const [applyTag, { isLoading: applyLoading }] = useApplyTagMutation();
  const [removeTag, { isLoading: removeLoading }] = useClearTagsMutation();
  const { tags } = useContactTags();

  if (!contact) {
    return null;
  }

  if (!currentOrg) {
    return null;
  }

  function onChange(newValue: string[]) {
    if (newValue.length < contact.tags.length) {
      // A tag was removed,

      const removedTag = contact.tags.find(tag => !newValue.includes(tag));

      removeTag({
        organisationId: currentOrg.id,
        contactIds: [contact.id],
        tagName: removedTag,
      });

      return;
    }

    const addedTag = newValue.find(tag => !contact.tags.includes(tag));

    applyTag({
      organisationId: currentOrg.id,
      contactIds: [contact.id],
      tag: addedTag,
    });
  }

  const displayValue = useMemo(() => {
    if (!contact.tags.length) {
      return <Paragraph variant={ `help` }>{ `No tags applied` }</Paragraph>;
    }

    if (contact.tags.length === 1) {
      return (
        <Badge
          color={ `gray` }
          className={ `max-w-fit mr-2` }
        >
          { contact.tags[0] }
        </Badge>
      );
    }

    return (
      <span className={ `flex` }>
        <Badge
          color={ `gray` }
          className={ `max-w-fit mr-2` }
        >
          { contact.tags[0] }
        </Badge>
        <Paragraph variant={ `help` }>
          { ` + ${contact.tags.length - 1} more` }
        </Paragraph>
      </span>
    );
  }, [contact.tags]);

  return (
    <MultiSelect
      onChange={ onChange }
      options={ tags.map(tag => ({ value: tag, label: tag })) }
      selected={ contact.tags }
      display={ displayValue }
      loading={ applyLoading || removeLoading }
    />
  );
}
