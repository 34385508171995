import { useCallback, useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { BlandCallStatus } from "shared";

import { useAICalls } from "../../hooks/useAICalls";
import { capitalizeFirstLetter, getTableHeight } from "../../lib/helper";
import { Card } from "../../../common/Atoms/Card";
import { Badge } from "../../../common/Atoms/Badge";
import { DatePopover } from "../../../common/Components/DatePopover";
import { useGetSelectedOrganisation } from "../../hooks/useGetSelectedOrganisation";

import { AICallTranscription } from "./Transcription";

function getColor(status: BlandCallStatus) {
  switch (status) {
  case BlandCallStatus.COMPLETE:
    return `green`;
  case BlandCallStatus.QUEUED:
  case BlandCallStatus.NEW:
  case BlandCallStatus.ALLOCATED:
    return `blue`;
  default:
    return `red`;
  }
}

export function AICallTable() {
  const { calls } = useAICalls();
  const currentOrg = useGetSelectedOrganisation();

  const getDetailPanelContent = useCallback((callId: number) => {
    const call = calls.find(c => c.id === callId);

    if (!call) {
      return null;
    }

    return <AICallTranscription call={ call } />;
  }, [calls]);

  const rows = useMemo(() => {
    return calls;
  }, [calls]);

  const columns: GridColDef<typeof rows[number]>[] = useMemo(() => {
    const r: GridColDef<typeof rows[number]>[] = [
      {
        flex: 1,
        field: `status`,
        headerName: `Status`,
        renderCell: ({ row }) => {
          return (
            <div className={ `flex items-center h-full` }>
              <Badge
                color={ getColor(row.status) }
                className={ `max-w-fit` }
              >
                { capitalizeFirstLetter(row.status) }
              </Badge>
            </div>
          );
        },
      },
      {
        flex: 2,
        field: `to`,
        headerName: `To`,
      },
      {
        flex: 2,
        field: `from`,
        headerName: `From`,
      },
      {
        flex: 1,
        field: `durationMinutes`,
        headerName: `Call Duration`,
      },
      {
        flex: 2,
        field: `createdAt`,
        headerName: `Time`,
        minWidth: 150,
        renderCell: ({ row }) => (
          <DatePopover
            date={ row.createdAt }
            labelFormat={ `dd LLL yyyy HH:mm` }
            organisationTimezone={ currentOrg?.validatedTimezone }
          />
        ),
      },
      {
        flex: 1,
        field: `cost`,
        headerName: `Credit Cost`,
      },
    ];

    return r.map(c => ({
      ...c,
      editable: false,
      filterable: false,
      sortable: false,
      headerClassName: `text-md text-gray-800 font-bold`,
    }));

  }, [rows]);

  const heightClass = getTableHeight(rows?.length);

  return (
    <Card className={ heightClass }>
      <DataGridPro
        // sx={ {
        //   border: `none`,
        //   [`& .${gridClasses.cell}`]: {
        //     py: 1,
        //   },
        // } }
        rows={ rows }
        columns={ columns }
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        disableColumnReorder
        hideFooter
        autosizeOnMount
        getDetailPanelContent={ ({ row }) => getDetailPanelContent(row.id) }
        autosizeOptions={ {
          expand: true,
        } }

      />
    </Card>
  );
}
