import { PolicyIncludeExcludeType } from "shared";

import { fieldIsValid } from "../../../../../lib/helper";
import { Select } from "../../../../../../common/Atoms/Select";
import { useContactTags } from "../../../../../hooks/useContactTags";
import { MultiSelect } from "../../../../../../common/Atoms/MultiSelect";

import { IFieldProps } from "./fieldProps";

export function ContactTagFilter({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {
  const { tags } = useContactTags();
  const selectedTags = policyFormData.contact_tags?.split(`,`) || [];

  function onTagsChange(tags: string[]) {
    if (!tags.length) {
      handleChange({
        name: `contact_tags`,
        value: null,
      });

      return;
    }

    handleChange({
      name: `contact_tags`,
      value: tags.filter(f => f).join(`,`),
    });
  }

  return (
    <div>
      <div className={ `flex justify-between items-center space-x-4` }>
        <div className={ `w-full` }>
          <Select
            options={ [
              { value: PolicyIncludeExcludeType.EXCLUDE, label: `Exclude contacts with tags:` },
              { value: PolicyIncludeExcludeType.INCLUDE, label: `Include only contacts with tags:` },
            ] }
            selected={ policyFormData.contact_tags_exclude_include }
            onChange={ handleChange }
            name={ `contact_tags_exclude_include` }
            invalid={ fieldIsValid(`contact_tags_exclude_include`, invalids) }
            valueClassname={ `text-gray-500` }
          />
        </div>

        <div>
          <MultiSelect
            options={ tags.map(tag => ({ value: tag, label: tag })) }
            selected={ selectedTags }
            onChange={ onTagsChange }
            invalid={ fieldIsValid(`contact_tags`, invalids) }
            display={ `${selectedTags.length} tags selected` }
            valueClassname={ `text-gray-500` }
          />
        </div>
      </div>
    </div>
  );
}
