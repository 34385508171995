import { When } from "react-if";
import { ActionStatus, langauge, MixpanelEvents, PolicyType } from "shared";
import { useState } from "react";
import { toast } from "react-toastify";

import { useAdhocActionModal } from "../../../hooks/useAdhocActionModal";
import { LoadingOverlay } from "../../Molecules/LoadingOverlay";
import { Notification } from "../../../../common/Atoms/Notification";
import { useApiRequest } from "../../../hooks/useApiRequest";
import { Paragraph } from "../../../../common/Atoms/Typography/Paragraph";
import { env } from "../../../../common/lib/env";
import { Heading } from "../../../../common/Atoms/Typography/Heading";
import { GROUPS_POLICIES_ARTICLE } from "../../../constants/links";
import { mixpanelService } from "../../../../common/lib/mixpanel";

export function ResultSection() {
  const { result, type, applyLoading, view } = useAdhocActionModal();
  const [downloadingStatement, setDownloadingStatement] = useState(false);
  const request = useApiRequest();

  async function downloadStatement(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, statementId: number) {
    e.preventDefault();

    if (downloadingStatement) return;

    if (!result?.action?.id) return;

    setDownloadingStatement(true);
    try {
      const response = await request({
        method: `GET`,
        responseType: `blob`,
        url: `${env.baseApiUrl}actions/${result.action.id}/statement/${statementId}/download`,
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement(`a`);
      link.href = url;
      link.setAttribute(`download`, `historic_statement_${result.action.id}_${statementId}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }
    catch (e) {
      const responseObj = await e.response?.data?.text();
      toast.error(`${responseObj ? `${responseObj}` : `Could not download statement.`}`);
    }

    setDownloadingStatement(false);
  }

  if (view !== `result`) return null;

  const successInstructions = [
    PolicyType.STATEMENT,
    PolicyType.STATEMENT_LATE_FEE,
  ].includes(type) ? `View on the contact's page` : `View on the invoice page`;

  return (
    <LoadingOverlay
      loading={ applyLoading }
      message={ `Applying policy now...` }
    >
      <div className={ `mt-4 min-h-full` }>
        { /* Empty State */ }
        <When condition={ !result }>
          { () => (
            <div
              className={ `min-h-[250px] flex flex-col items-center justify-center` }
            >
              <div className={ `flex flex-col items-center border border-slate-50 rounded-lg p-12 shadow-sm` }>
                <Heading
                  variant={ `secondary` }
                >
                  { `Looking to automate this?` }
                </Heading>
                <a
                  href={ GROUPS_POLICIES_ARTICLE }
                  target={ `_blank` }
                  rel={ `noreferrer noopener` }
                  onClick={ () => mixpanelService.trackMixpanelEvent(MixpanelEvents.PreviewAutomateThisLinkClick) }
                >
                  <Paragraph
                    variant={ `link` }
                    className={ `mt-2` }
                  >
                    { `Use policies to configure automatic actions.` }
                  </Paragraph>
                </a>
              </div>
            </div>
          ) }
        </When>
        { /* FAILED STATE */ }
        <When condition={ result?.action?.status === ActionStatus.FAILED }>
          { () => (
            <Notification
              type={ `error` }
            >
              { result?.action?.errorMessage }
            </Notification>
          ) }
        </When>

        <When condition={ result?.action?.status === ActionStatus.COMPLETE }>
          { () => (
            <div>
              <Notification
                type={ `success` }
              >
                { `A ${langauge.policy.adhocVerb?.[type]} has been sent! - ${successInstructions}` }
              </Notification>

              { /* If statement type */ }
              <When condition={ type === PolicyType.STATEMENT }>
                <div className={ `mt-2` }>
                  { result?.statements?.map(statement => (
                    <a
                      onClick={ e => downloadStatement(e, statement.id) }
                      key={ statement.id }
                    >
                      <Paragraph
                        variant={ `link` }
                      >
                        { `Download ${statement.currency} Statement` }
                      </Paragraph>
                    </a>
                  )) }
                </div>
              </When>
            </div>
          ) }
        </When>
      </div>

    </LoadingOverlay>

  );
}
