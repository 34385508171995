import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { statusUIMapXero, pageLimit, toDecimal } from 'shared';
import { When } from 'react-if';
import { ExportToCsv } from 'export-to-csv';

import { classNames }  from '../../../common/lib/classNames';
import { Pagination } from '../../../common/Atoms/Pagination';
import { InputTextAddon } from '../../../common/Atoms/InputTextAddon';
import { Select } from '../../../common/Atoms/Select';
import { useGetSelectedOrganisation } from '../../hooks/useGetSelectedOrganisation';
import { useActionInvoicesMutation } from '../../services/api/invoiceApi/invoice';
import { useGetInvoices, useInvoiceParams } from '../../hooks/useGetInvoices';
import { Grid }  from '../../../common/Atoms/Grid';
import { GridItem } from '../../../common/Atoms/GridItem';
import { useDebounce } from '../../hooks/useDebounce';
import { LoadingOverlay } from '../Molecules/LoadingOverlay';
import { Button } from '../../../common/Atoms/Button';

import { InvoicesHeading } from './InvoicesHeading';
import { TableHeading } from './TableHeading';
import { InvoiceRow } from './InvoiceRow';

const xeroStatusOptions = Object.keys(statusUIMapXero).map(key => {
  return {
    ...statusUIMapXero[key],
    label: statusUIMapXero[key].name,
    value: key,
  };
});

const dueDateOptions = [
  { label: `Past due`, value: `overdue` },
  { label: `Due soon`, value: `duesoon` },
  { label: `Due later`, value: `duelater` },
];

export function InvoiceTable() {
  const checkbox = useRef();
  const { data: invoiceRes, isLoading: filterLoading, isFetching } = useGetInvoices();

  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [actionInvoices, { isLoading: actionInvoiceLoading }] = useActionInvoicesMutation();
  const [invoiceTextSearch, setInvoiceTextSearch] = useState(``);
  const organisation = useGetSelectedOrganisation();
  const { params: invoiceParams, updateParams } = useInvoiceParams();

  const debouncedSearchStr = useDebounce(invoiceTextSearch, 350);

  const invoiceRows = invoiceRes?.invoices || [];
  const count = invoiceRes?.count || 0;

  const {
    page,
    xeroStatus,
    dueDate,
    limit,
  } = invoiceParams;

  useEffect(() => {
    updateParams({
      ...invoiceParams,
      page: 1,
      invoiceTextSearch: debouncedSearchStr,
    });
  }, [debouncedSearchStr]);

  useEffect(() => {
    if (invoiceTextSearch !== invoiceParams.invoiceTextSearch) {
      setInvoiceTextSearch(invoiceParams.invoiceTextSearch || ``);
    }
  }, [invoiceParams.invoiceTextSearch]);

  useLayoutEffect(() => {
    const isIndeterminate = selectedInvoices.length > 0 && selectedInvoices.length < invoiceRows.length;
    setChecked(selectedInvoices.length === invoiceRows.length);
    setIndeterminate(isIndeterminate);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    checkbox.current.indeterminate = isIndeterminate;
  }, [selectedInvoices, invoiceRows]);

  function toggleAll() {
    setSelectedInvoices(checked || indeterminate ? [] : invoiceRows.map(i => i.invoice.id));
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  function onSkipSelected() {
    actionInvoices({
      action: `freeze`,
      organisationId: organisation.id,
      invoiceIds: selectedInvoices,
    });
  }

  function onResetSelected() {
    actionInvoices({
      action: `reset`,
      organisationId: organisation.id,
      invoiceIds: selectedInvoices,
    });
  }

  function onDueDateChange(e) {
    updateParams({
      ...invoiceParams,
      page: 1,
      dueDate: e.value,
    });
  }

  function onXeroStatusChange(e) {
    updateParams({
      ...invoiceParams,
      page: 1,
      xeroStatus: e.value,
    });
  }

  function onExport() {
    const options = {
      fieldSeparator: `,`,
      quoteStrings: `"`,
      decimalSeparator: `.`,
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      headers: [`number`, `ref`, `invoice date`, `due date`, `total`, `due`, `status`, `contact`, `group`, `created_by_paidnice`],
    };

    const result = [];

    invoiceRows.forEach(item => {
      const {
        number,
        reference,
        issueDate,
        dueDate,
        totals,
        externalStatus,
        createdByUs,
      } = item.invoice;

      result.push({
        number,
        ref: reference,
        'invoice date': issueDate,
        'due date': dueDate,
        total: toDecimal(totals.totalInCents),
        due: toDecimal(totals.dueInCents),
        status: externalStatus,
        contact: item.contact.name,
        group: item.group.title,
        created_by_paidnice: createdByUs ? `Yes` : `No`,
      });
    });

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(result);
  }

  return (
    <div
      className={
        classNames(
          `px-4 sm:px-6 lg:px-8`,
        )
      }>
      <InvoicesHeading />
      <div className={ `mt-8 flex flex-col` }>
        <div className={ `-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8` }>
          <div className={ `inline-block min-w-full py-2 align-middle md:px-2 lg:px-8` }>
            <div className={ `relative bg-white overflow-x-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg` }>

              <When condition={ invoiceRows.length }>
                <Pagination
                  limit={ pageLimit }
                  total={ Number(count) || 0 }
                  activePage={ page }
                  onChangePage={ value => updateParams({ ...invoiceParams, page: value }) }
                  count={ invoiceRows.length }
                  subMessage={ `Click on an invoice to view more details` }
                  className={ `px-4 py-2` }
                />
              </When>

              <Grid cols={ 12 }
                className={ `p-4` }>
                <GridItem span={ 11 }>
                  <Grid cols={ 4 }
                    colsSmallScreen={ 2 }
                    gapX={ 8 }>
                    <GridItem
                      span={ 1 }
                      position={ `bottom` }
                    >
                      <InputTextAddon
                        label={ `Invoice Search` }
                        subHeading={
                          `Search by invoice number, contact name or reference`
                        }
                        className={ `w-full` }
                        value={ invoiceTextSearch }
                        onChange={ e => setInvoiceTextSearch(e.value as string) }
                        placeholder={ `E.g INV-0001` }
                        addOnText={
                          <MagnifyingGlassIcon
                            className={ `h-5 w-5` }
                            aria-hidden={ `true` }
                          />
                        }
                      />
                    </GridItem>
                    <GridItem
                      span={ 1 }
                      position={ `bottom` }
                    >
                      <Select
                        label={ `Invoice Status` }
                        options={ xeroStatusOptions }
                        selected={ xeroStatus }
                        onChange={ onXeroStatusChange }
                        nullable
                      />
                    </GridItem>
                    <GridItem
                      span={ 1 }
                      position={ `bottom` }
                    >
                      <Select
                        label={ `Due Date` }
                        options={ dueDateOptions }
                        selected={ dueDate }
                        onChange={ onDueDateChange }
                        nullable
                      />
                    </GridItem>

                  </Grid>
                  { selectedInvoices.length > 0 && (
                    <div className={ `py-3 space-x-1` }>
                      <Button
                        onClick={ onSkipSelected }
                        type={ `button` }
                        disabled={ actionInvoiceLoading }
                        loading={ actionInvoiceLoading }
                      >
                        { `Freeze Selected` }
                      </Button>
                      <Button
                        onClick={ onResetSelected }
                        type={ `button` }
                        disabled={ actionInvoiceLoading }
                        loading={ actionInvoiceLoading }
                      >
                        { `Unfreeze Selected` }
                      </Button>
                    </div>
                  ) }
                </GridItem>
              </Grid>

              <LoadingOverlay loading={ filterLoading || isFetching }>
                <div className={ `flex flex-col justify-between min-h-[30rem]` }>
                  <table className={ `w-full px-4 table-fixed divide-y divide-gray-300 border-t-1 border-gray-300` }>
                    <thead className={ `bg-gray-50` }>
                      <tr>
                        <th scope={ `col` }
                          className={ `relative w-12 px-6 sm:w-16 sm:px-8` }>
                          <input
                            type={ `checkbox` }
                            className={ `absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-lateGreen-600 focus:ring-lateGreen-500 sm:left-6` }
                            ref={ checkbox }
                            checked={ checked }
                            onChange={ toggleAll }
                          />
                        </th>
                        <th
                          scope={ `col` }
                          className={
                            classNames(
                              `min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900`,
                            )
                          }>
                          <TableHeading
                            heading={ `Number` }
                            dataKey={ `xero_number` }
                          />
                        </th>
                        <th
                          scope={ `col` }
                          className={
                            classNames(
                              `px-3 py-3.5 text-left text-sm font-semibold text-gray-900`,
                            )
                          }>
                          <TableHeading heading={ `Invoice Status` }
                            dataKey={ `xero_status` } />
                        </th>
                        <th
                          scope={ `col` }
                          className={
                            classNames(
                              `px-3 py-3.5 text-left text-sm font-semibold text-gray-900`,
                            )
                          }>
                          <TableHeading
                            heading={ `Contact` }
                            dataKey={ `xero_contact_name` }
                          />
                        </th>
                        <th
                          scope={ `col` }
                          className={
                            classNames(
                              `hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 2xl:table-cell`,
                            )
                          }>
                          <TableHeading
                            heading={ `Group` }
                            dataKey={ `groupTitle` }
                            sortable={ false }
                          />
                        </th>
                        <th
                          scope={ `col` }
                          className={
                            classNames(
                              `hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 2xl:table-cell`,
                            )
                          }>
                          <TableHeading
                            heading={ `Amount Due` }
                            dataKey={ `xero_amount_due` }
                          />
                        </th>
                        <th
                          scope={ `col` }
                          className={
                            classNames(
                              `px-3 py-3.5 text-left text-sm font-semibold text-gray-900`,
                            )
                          }>
                          <TableHeading heading={ `Date Due` }
                            dataKey={ `due_date_string` } />
                        </th>
                        <th
                          scope={ `col` }
                          className={
                            classNames(
                              `px-3 py-3.5 text-left text-sm font-semibold text-gray-900`,
                            )
                          }>
                          { /* Summary Texts */ }
                        </th>
                        <th scope={ `col` }
                          className={ `px-3 py-3.5 text-left text-sm font-semibold text-gray-900` }
                        />
                      </tr>
                    </thead>
                    <tbody className={ `divide-y divide-gray-200 bg-white` }>
                      { invoiceRows.map(item => (
                        <InvoiceRow
                          key={ item.invoice.id }
                          invoiceRow={ item }
                          selected={ selectedInvoices.includes(item.invoice.id) }
                          selectedInvoices={ selectedInvoices }
                          setSelectedInvoices={ setSelectedInvoices }
                        />
                      )) }
                    </tbody>
                  </table>
                  <When condition={ invoiceRows.length }>
                    <Pagination
                      limit={ limit }
                      total={ Number(count) || 0 }
                      activePage={ page }
                      onChangePage={ value => updateParams({ ...invoiceParams, page: value }) }
                      count={ invoiceRows.length }
                      className={ `px-4 py-2` }
                      onExport={ onExport }
                      perPageOptions={ [ 50, 100, 250, 500 ] }
                      onPerPageChange={ limit => updateParams({ ...invoiceParams, limit }) }
                    />
                  </When>
                </div>
              </LoadingOverlay>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
