import { Description, Label, Radio, RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { When } from 'react-if';

import { classNames }  from '../lib/classNames';

import { ChangeFunction } from './types';

const colsClass = {
  1: `lg:grid-cols-1`,
  2: `lg:grid-cols-2`,
  3: `lg:grid-cols-3`,
  4: `lg:grid-cols-4`,
};

interface IRadioCardsProps {
  options: {
    value: string;
    title: string;
    description?: string;
  }[]
  selected: string;
  onChange: ChangeFunction<string, string>
  label?: string;
  cols?: number;
  name?: string;
  description?: string;
}

export function RadioCards({
  options,
  selected,
  onChange,
  label,
  cols,
  name,
  description,
}: IRadioCardsProps) {
  const selectedValue = options.find(o => o.value === selected);

  function handleChange(value) {
    return onChange({
      value: value.value,
      name,
    });
  }

  return (
    <RadioGroup value={ selectedValue }
      onChange={ handleChange }>
      <Label className={ `text-sm font-medium text-gray-900` }>
        { label }
      </Label>

      <When condition={ description }>
        <p className={ `mt-1 text-sm text-gray-500` }>
          { description }
        </p>
      </When>
      <div className={
        classNames(
          `mt-4 grid grid-cols-1 gap-y-6 sm:gap-x-4`,
          colsClass[cols],
        )
      }
      >
        { options.map(option => (
          <Radio
            key={ option.value }
            value={ option }
            className={ ({ checked, focus }) =>
              classNames(
                checked ? `border-transparent` : `border-gray-300`,
                focus ? `border-lateGreen-500 ring-2 ring-lateGreen-500` : ``,
                `relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none`,
              )
            }
          >
            { ({ checked, focus }) => (
              <>
                <span className={ `flex flex-1` }>
                  <span className={ `flex flex-col` }>
                    <Label as={ `span` }
                      className={ `block text-sm font-medium text-gray-900` }>
                      { option.title }
                    </Label>
                    <Description as={ `span` }
                      className={ `mt-1 flex items-center text-sm text-gray-500` }>
                      { option.description }
                    </Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={ classNames(!checked ? `invisible` : ``, `h-5 w-5 text-lateGreen-600`) }
                  aria-hidden={ `true` }
                />
                <span
                  className={ classNames(
                    focus ? `border` : `border-2`,
                    checked ? `border-lateGreen-500` : `border-transparent`,
                    `pointer-events-none absolute -inset-px rounded-lg`,
                  ) }
                  aria-hidden={ `true` }
                />
              </>
            ) }
          </Radio>
        )) }
      </div>
    </RadioGroup>

  );
}
