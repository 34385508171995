import { allowedSubscriptionStatus } from 'shared';

import { useGetSubscriptionMetadata } from './useGetSubscription';
import { useGetSelectedOrganisation } from './useGetSelectedOrganisation';

export function useHasValidSubscription(returnTrueWhenNoOrg?: boolean) {
  const { subscriptionMetadata } = useGetSubscriptionMetadata();
  const organisation = useGetSelectedOrganisation();
  if (
    subscriptionMetadata?.status && allowedSubscriptionStatus.includes(subscriptionMetadata.status)
  ) {
    return true;
  }

  if (!organisation && returnTrueWhenNoOrg) {
    return true;
  }

  return false;
}
