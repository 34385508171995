import { When } from "react-if";

import { Grid } from "../../../../common/Atoms/Grid";
import { GridItem } from "../../../../common/Atoms/GridItem";
import { Heading } from "../../../../common/Atoms/Typography/Heading";
import { FormDescription } from "../../../../common/Atoms/FormDescription";
import { Paragraph } from "../../../../common/Atoms/Typography/Paragraph";

interface TwoColFormRowProps {
  children: React.ReactNode;
  heading: string;
  headingHelpLink?: string;
  description?: string | React.ReactNode;
  secondaryDescription?: string;
  help?: string;
  headingVariant?: `default` | `secondary`;
  ratio?: [number, number];
  badge?: React.ReactNode;
}

export function TwoColFormRow({
  children,
  heading,
  headingHelpLink,
  description,
  secondaryDescription,
  help,
  headingVariant = `default`,
  ratio = [4, 8],
  badge,
}: TwoColFormRowProps) {
  return (
    <Grid cols={ 12 }>
      <GridItem span={ ratio[0] }>
        <Heading
          variant={ headingVariant }
          helpLink={ headingHelpLink }
        >
          { heading }
          <When condition={ !!badge }>
            { ` ` }
            { badge }
          </When>
        </Heading>
        <When condition={ !!description }>
          {
            typeof description === `string` ? (
              <FormDescription>
                { description }
              </FormDescription>
            ) : description
          }
        </When>
        <When condition={ secondaryDescription }>
          <FormDescription>
            { secondaryDescription }
          </FormDescription>
        </When>
        <When condition={ help }>
          <Paragraph variant={ `help` }>
            { help }
          </Paragraph>
        </When>
      </GridItem>

      <GridItem
        span={ ratio[1] }
        className={
          `mt-3`
        }
      >
        { children }
      </GridItem>
    </Grid>
  );
}
