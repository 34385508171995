import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";

import { useGetSelectedOrganisation } from "./useGetSelectedOrganisation";
import { useGroups } from "./useGroups";

export function useOnboarding() {
  const currentOrg = useGetSelectedOrganisation();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: groups } = useGroups();

  useEffect(() => {
    if (!currentOrg) {
      return;
    }

    const groupWithPolicies = groups?.find(group => group.policies.length > 0);

    if (
      [`NOT_STARTED`, `IN_PROGRESS`].includes(currentOrg.onboarding.status) &&
      !location.pathname.includes(`onboarding`)
    ) {
      // Onboarding in progress but not on page, redirect
      navigate(`${currentOrg.id}/onboarding`);
    }

    if ((currentOrg.onboarding.status === `COMPLETED` || currentOrg.onboarding.status === `SKIPPED`) && location.pathname.includes(`onboarding`)) {
      // Onboarding completed but still on page, redirect
      if (currentOrg.onboarding.status === `COMPLETED` && groupWithPolicies) {
        navigate(`${currentOrg.id}/groups/${groupWithPolicies.id}`);
      }
      else {
        navigate(`${currentOrg.id}`);
      }
    }
  }, [location.pathname, currentOrg, groups]);

  if (!currentOrg) {
    return {
      isOnboarding: false,
    };
  }

  return {
    isOnboarding: [`NOT_STARTED`, `IN_PROGRESS`].includes(currentOrg.onboarding.status),
  };
}
