import { IInvalidField, IUpdateSettingsRequest } from "shared";

import { Grid } from "../../../../../common/Atoms/Grid";
import { GridItem } from "../../../../../common/Atoms/GridItem";
import { InputTextAddon } from "../../../../../common/Atoms/InputTextAddon";
import { fieldIsValid } from "../../../../lib/helper";
import { TextArea } from "../../../../../common/Atoms/TextArea";
import { useGetSelectedOrganisation } from "../../../../hooks/useGetSelectedOrganisation";
import { Toggle } from "../../../../../common/Atoms/Toggle";
import { StatementPreview } from "../StatementPreview";

import { OnChangeFn } from "./GeneralSettings";

interface IOrgAddressProps {
  formData: IUpdateSettingsRequest;
  onChange: OnChangeFn;
  invalids: IInvalidField[];
  hasUnsavedChanges: boolean;
}

export function OrgContactDetails({ formData, onChange, invalids, hasUnsavedChanges }: IOrgAddressProps) {
  const currentOrg = useGetSelectedOrganisation();

  function handleChange(e) {
    onChange(e.name, e.value);
  }

  function handleAddressChange(e) {
    const update = {
      ...formData.companyAddress,
      [e.name.split(`.`)[1]]: e.value,
    };

    const key = e.name.split(`.`)[0];
    onChange(key, update);
  }

  return (
    <Grid cols={ 6 }>
      <GridItem span={ 2 }>
        <InputTextAddon
          label={ `Legal Name` }
          disabled={ true }
          value={ currentOrg?.legalName || `` }
          onChange={ () => {} }
        />
      </GridItem>

      <GridItem span={ 2 }>
        <InputTextAddon
          label={ `Display Name` }
          disabled={ true }
          value={ currentOrg?.displayName || `` }
          onChange={ () => {} }
        />
      </GridItem>

      <GridItem span={ 2 }>
        <Toggle
          label={ `Use display name` }
          checked={ formData.useDisplayName || false }
          onChange={ handleChange }
          name={ `useDisplayName` }
          helpText={ `Turn on if you wish to have all outbound customer items to use the display name instead of the legal name when possible.` }
        />
      </GridItem>

      { /* Row One */ }
      <GridItem span={ 4 }>
        <InputTextAddon
          label={ `Company Number ` }
          placeholder={ `The registration number or equivalent` }
          value={ formData.companyNumber || `` }
          name={ `companyNumber` }
          onChange={ handleChange }
          invalid={ fieldIsValid(`companyNumber`, invalids) }
        />
      </GridItem>

      <GridItem span={ 2 }>
        <InputTextAddon
          label={ `Company Number Type` }
          placeholder={ `E.g ABN, CRN, etc.` }
          value={ formData.companyNumberType || `` }
          name={ `companyNumberType` }
          onChange={ handleChange }
          invalid={ fieldIsValid(`companyNumberType`, invalids) }
        />
      </GridItem>

      { /* Row Two */ }
      <GridItem span={ 2 }>
        <InputTextAddon
          label={ `Contact Number` }
          placeholder={ `The contact phone number for the company` }
          value={ formData.companyContactNumber || `` }
          name={ `companyContactNumber` }
          onChange={ handleChange }
          invalid={ fieldIsValid(`companyContactNumber`, invalids) }
        />
      </GridItem>
      <GridItem span={ 2 }>
        <InputTextAddon
          label={ `Contact Email` }
          placeholder={ `Company contact number` }
          value={ formData.companyEmail || `` }
          name={ `companyEmail` }
          onChange={ handleChange }
          invalid={ fieldIsValid(`companyEmail`, invalids) }
        />
      </GridItem>
      <GridItem span={ 2 }>
        <InputTextAddon
          label={ `Tax Number` }
          placeholder={ `The tax number if applicable. E.g VAT, GST` }
          value={ formData.taxNumber || `` }
          name={ `taxNumber` }
          onChange={ handleChange }
          invalid={ fieldIsValid(`taxNumber`, invalids) }
        />
      </GridItem>

      { /* Row Three */ }
      <GridItem span={ 6 }>
        <InputTextAddon
          label={ `Address Line One` }
          value={ formData.companyAddress?.lineOne || `` }
          name={ `companyAddress.lineOne` }
          onChange={ handleAddressChange }
          invalid={ fieldIsValid(`companyAddress`, invalids) }
        />
      </GridItem>

      { /* Row Four */ }
      <GridItem span={ 4 }>
        <InputTextAddon
          label={ `Address Line Two` }
          value={ formData.companyAddress?.lineTwo || `` }
          name={ `companyAddress.lineTwo` }
          onChange={ handleAddressChange }
          invalid={ fieldIsValid(`companyAddress`, invalids) }
        />
      </GridItem>
      <GridItem span={ 2 }>
        <InputTextAddon
          label={ `City` }
          value={ formData.companyAddress?.city || `` }
          name={ `companyAddress.city` }
          onChange={ handleAddressChange }
          invalid={ fieldIsValid(`companyAddress`, invalids) }
        />
      </GridItem>

      { /* Row Five */ }
      <GridItem span={ 2 }>
        <InputTextAddon
          label={ `State / County` }
          value={ formData.companyAddress?.state || `` }
          name={ `companyAddress.state` }
          onChange={ handleAddressChange }
          invalid={ fieldIsValid(`companyAddress`, invalids) }
        />
      </GridItem>
      <GridItem span={ 2 }>
        <InputTextAddon
          label={ `Postcode / Zip` }
          value={ formData.companyAddress?.country || `` }
          name={ `companyAddress.country` }
          onChange={ handleAddressChange }
          invalid={ fieldIsValid(`companyAddress`, invalids) }
        />
      </GridItem>
      <GridItem span={ 2 }>
        <InputTextAddon
          label={ `Country` }
          value={ formData.companyAddress?.postalCode || `` }
          name={ `companyAddress.postalCode` }
          onChange={ handleAddressChange }
          invalid={ fieldIsValid(`companyAddress`, invalids) }
        />
      </GridItem>

      { /* Row Six */ }
      <GridItem span={ 4 }>
        <TextArea
          label={ `Statement & Invoice Footer` }
          placeholder={ `This is where you can add payment info and other information to appear on the Online Invoice & PDF statement.` }
          value={ formData.statementFooter || `` }
          name={ `statementFooter` }
          rows={ 5 }
          onChange={ handleChange }
        />
      </GridItem>
      <GridItem
        position={ `bottom` }
        span={ 2 }
        className={ `justify-center` }
      >
        <StatementPreview
          hasUnsavedChanges={ hasUnsavedChanges }
        />
      </GridItem>
    </Grid>
  );
}
