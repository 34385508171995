import { IEmailReportResponse } from 'shared';

import { CacheTags } from '../../types/cacheTags';
import { emptySplitApi } from '../baseApi';

export const emailReportApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchEmailReport: builder.query<IEmailReportResponse, { organisationId: string }>({
      query: ({ organisationId }) => ({
        url: `email-report`,
        method: `GET`,
        headers: {
          [`X-Organisation-Id`]: organisationId,
        },
      }),
      providesTags: [
        { type: CacheTags.EmailReport, id: `LIST` },
      ],
    }),
  }),
});

export const {
  useFetchEmailReportQuery,
} = emailReportApi;
