import { policyConfig } from "shared";
import { useMemo } from "react";

import { Select } from "../../../../../../common/Atoms/Select";
import { useAccountingResources } from "../../../../../hooks/useAccountingResources";
import { fieldIsValid } from "../../../../../lib/helper";

import { IFieldProps } from "./fieldProps";

export function Location({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {
  const { data: resources, isLoading, isFetching } = useAccountingResources();
  const label = policyConfig.accounting_system_location.label;

  const options = useMemo(() => {
    if (!resources?.locations) {
      return [];
    }

    return resources.locations.map(l => ({
      label: l.label,
      value: l.value,
    }));
  }, [resources?.locations]);

  return (
    <Select
      nullable
      emptyText={ `None` }
      name={ `accounting_system_location` }
      description={ `When issuing a late fee. Use this location` }
      label={ label }
      options={ options }
      onChange={ e => handleChange({ value: e.value, name: e.name }) }
      selected={ policyFormData.accounting_system_location }
      invalid={ fieldIsValid(`accounting_system_location`, invalids) }
      loading={ isLoading || isFetching }
    />
  );
}
