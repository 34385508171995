import { IContact } from "shared";
import { useMemo, useState } from "react";
import { Case, Default, If, Switch } from "react-if";

import { Modal } from "../../../../../common/Atoms/Modal";
import { Paragraph } from "../../../../../common/Atoms/Typography/Paragraph";
import { Divider } from "../../../../../common/Atoms/Divider";
import { useAccountingSystemName } from "../../../../hooks/useAccountingSystemName";
import { Card } from "../../../../../common/Atoms/Card";
import { Grid } from "../../../../../common/Atoms/Grid";
import { GridItem } from "../../../../../common/Atoms/GridItem";
import { DescriptionList } from "../../../../../common/Atoms/DescriptionList";
import { Button } from "../../../../../common/Atoms/Button";
import { useGetSelectedOrganisation } from "../../../../hooks/useGetSelectedOrganisation";
import { CheckBox } from "../../../../../common/Atoms/CheckBox";
import { useCreditReportContactMutation } from "../../../../services/api/contactsApi/contact";

interface QuickBooksConnectModalProps {
  onCancel: () => void;
  show: boolean;
  contact: IContact;
}

export function OrderCreditReportModal({ onCancel, show, contact }: QuickBooksConnectModalProps) {

  const [ creditReportContact ] = useCreditReportContactMutation();
  const organisation = useGetSelectedOrganisation();

  const [checked, setChecked] = useState(false);

  const handleChecked = () => {
    setChecked(!checked);
  };

  function onClose() {
    // This is the top X, treat as cancel
    onCancel();
  }

  function orderReport() {
    creditReportContact({ organisationId: organisation.id, contactId: contact.id });
    onClose();
  }

  const supportedCountries = [
    `United States`,
    `USA`,
    `US`,
    `Canada`,
    `CA`,
    `United Kingdom`,
    `UK`,
    `Australia`,
    `AU`,
    `New Zealand`,
    `NZ`,
  ];

  const readyToOrder = useMemo(() => {
    if (!contact) {
      return false;
    }

    return contact?.name && contact?.country && (contact?.state || contact?.zipcode || contact?.city || contact?.business_number || contact?.tax_number);
  }
  , [contact]);

  const items = useMemo(() => {
    if (!contact) {
      return [];
    }

    const items = [
      {
        title: `Name`,
        content: contact?.name || `missing`,
      },
      {
        title: `Country`,
        content: contact?.country || `missing`,
      },
      {
        title: `State/Region`,
        content: contact?.state || `missing`,
      },
      {
        title: `Zip/Postcode`,
        content: contact?.zipcode || `missing`,
      },
      {
        title: `City`,
        content: contact?.city || `missing`,
      },
      {
        title: `Company number`,
        content: contact?.business_number || `missing`,
      },
      {
        title: `Tax number`,
        content: contact?.tax_number || `missing`,
      },
    ];

    return items;
  }
  , [contact]);

  return (
    <Modal
      title={ `` }
      open={ show }
      setOpen={ onClose }
    >
      <h3 className={ `text-lg font-medium leading-6 text-gray-900 flex` }>
        { `Order a company credit report` }
      </h3>
      <Paragraph variant={ `primary` }>
        { `Paidnice can order a company credit report for this customer for you by submitting the information below. If any information is missing please update your contact in ` + useAccountingSystemName() +` to proceed.` }
      </Paragraph>
      <Divider weight={ `light` } />
      <Grid cols={ 12 }>
        <GridItem span={ 6 }>
          <Card>
            <DescriptionList items={ items } />
          </Card>
        </GridItem>
        <GridItem span={ 6 }>
          <Card>

            <Switch>
              <Case condition={ !readyToOrder }>
                <h6 className={ `text-sm font-medium leading-6 text-gray-900` }>
                  { `Missing information` }
                </h6>
                <div className={ `text-sm leading-6 text-gray-900` }>
                  <p className={ `mt-2` }>{ `We need more contact information to generate your report.` }</p>
                  <p className={ `mt-2` }>{ `Please add more information to the contact in ${useAccountingSystemName()} so the report can be generated.` }</p>
                </div>
              </Case>
              { /* US and Canada */ }
              <Case condition={ contact?.country === `United States` || contact?.country === `USA` || contact?.country === `US` || contact?.country === `Canada` || contact?.country === `CA` }>
                <h6 className={ `text-sm font-medium leading-6 text-gray-900` }>
                  { `Order an Experian ProfilePlus Report` }
                </h6>
                <div className={ `text-sm leading-6 text-gray-900` }>
                  <p className={ `mt-2` }>{ `To order a report, click the button below, you will receive an invoice by email in order to purchase the report.` }</p>
                  <p className={ `mt-2` }>{ `Typical delivery time is 1 business day.` }</p>
                  <p className={ `mt-2` }>{ `Price is US$50 per report.` }</p>
                </div>
                <h6 className={ `text-sm font-medium leading-6 text-gray-900 mt-2` }>
                  { `What's in the report?` }
                </h6>
                <div className={ `text-sm leading-6 text-gray-900` }>
                  <p className={ `mt-2` }>{ `Intelliscore and Financial Stability Risk Rating, Detailed credit and business info, Detailed financial payment info, In-depth credit history w/trades` }</p>
                </div>

              </Case>
              { /* UK */ }
              <Case condition={ contact?.country === `United Kingdom` || contact?.country === `UK` }>
                <h6 className={ `text-sm font-medium leading-6 text-gray-900` }>
                  { `Order an Experian Credit Check Report` }
                </h6>
                <div className={ `text-sm leading-6 text-gray-900` }>
                  <p className={ `mt-2` }>{ `To order a report, click the button below, you will receive an invoice by email in order to purchase the report.` }</p>
                  <p className={ `mt-2` }>{ `Typical delivery time is 1 business day.` }</p>
                  <p className={ `mt-2` }>{ `Price is £25 per report.` }</p>
                </div>
                <h6 className={ `text-sm font-medium leading-6 text-gray-900 mt-2` }>
                  { `What's in the report?` }
                </h6>
                <div className={ `text-sm leading-6 text-gray-900` }>
                  <p className={ `mt-2` }>{ `Full business credit reports include: Score, Credit limit, Credit rating, Directors information, Payment performance` }</p>
                </div>

              </Case>
              <Case condition={ contact?.country === `Australia` || contact?.country === `AU` }>
                <h6 className={ `text-sm font-medium leading-6 text-gray-900` }>
                  { `Order an Equifax SwiftCheck Report` }
                </h6>
                <div className={ `text-sm leading-6 text-gray-900` }>
                  <p className={ `mt-2` }>{ `To order a report, click the button below, you will receive an invoice by email in order to purchase the report.` }</p>
                  <p className={ `mt-2` }>{ `Typical delivery time is 1 business day.` }</p>
                  <p className={ `mt-2` }>{ `Price is A$50 per report.` }</p>
                </div>
                <h6 className={ `text-sm font-medium leading-6 text-gray-900 mt-2` }>
                  { `What's in the report?` }
                </h6>
                <div className={ `text-sm leading-6 text-gray-900` }>
                  <p className={ `mt-2` }>{ `Credit Score, ASIC Information, Shareholder Details, Defaults, Court Actions, External Administration information` }</p>
                </div>

              </Case>
              <Case condition={ contact?.country === `New Zealand` || contact?.country === `NZ` }>
                <h6 className={ `text-sm font-medium leading-6 text-gray-900` }>
                  { `Order a Centrix Commercial Report` }
                </h6>
                <div className={ `text-sm leading-6 text-gray-900` }>
                  <p className={ `mt-2` }>{ `To order a report, click the button below, you will receive an invoice by email in order to purchase the report.` }</p>
                  <p className={ `mt-2` }>{ `Typical delivery time is 1 business day.` }</p>
                  <p className={ `mt-2` }>{ `Price is NZ$50 per report.` }</p>
                </div>
                <h6 className={ `text-sm font-medium leading-6 text-gray-900 mt-2` }>
                  { `What's in the report?` }
                </h6>
                <div className={ `text-sm leading-6 text-gray-900` }>
                  <p className={ `mt-2` }>{ `The report includes a commercial credit score that is multifunctional and looks at both the companies risk of failure and propensity to pay, allowing you to make informed decision by providing a complete view of a company risk profile.` }</p>
                </div>
              </Case>
              <Default>
                <h6 className={ `text-sm font-medium leading-6 text-gray-900` }>
                  { `Sorry we can't help` }
                </h6>
                <div className={ `text-sm leading-6 text-gray-900` }>
                  <p className={ `mt-2` }>{ `We're currently only able to generate reports in USA, Canada, United Kingdom, Australia and New Zealand.` }</p>
                  <p className={ `mt-2` }>{ `Our goal is to support all customer locations, please contact us using the chat to let us know which country you need a report in.` }</p>
                </div>
              </Default>
            </Switch>
          </Card>
          <If condition={ readyToOrder && supportedCountries.includes(contact?.country) }>
            <Card
              className={ `mt-4` }
            >
              <CheckBox
                label={ `Authorization` }
                description={ `I confirm this customer is a company and I am authorizing Paidnice to order a credit report on my behalf.` }
                checked={ checked }
                onChange={ handleChecked }
                name={ `authorized` }
                className={ `py-2` }
              />

              <Button
                disabled={ !checked }
                className={ `mt-8` }
                onClick={ orderReport }
              >
                { `Order Credit Report` }
              </Button>
            </Card>
          </If>
        </GridItem>
      </Grid>
    </Modal>
  );
}

