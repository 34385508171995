import { useMemo } from 'react';

import { useGetSelectedOrganisation } from './useGetSelectedOrganisation';

export function useAccountingSystemTaxApplicability() {
  const organisation = useGetSelectedOrganisation();

  const accountingSystemTaxApplicability = useMemo(() => {

    if ( organisation?.type === `QBO`) {
      // US is always exclusive, so we return false
      return organisation?.region === `US` ? false : true;
    }
    else {
      return true; //always applicable for Xero
    }

  }, [organisation?.type]);

  return accountingSystemTaxApplicability;
}
