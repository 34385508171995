import { PolicyType, policyConfig } from "shared";

import { fieldIsValid } from "../../../../../lib/helper";
import { Toggle } from "../../../../../../common/Atoms/Toggle";

import { IFieldProps } from "./fieldProps";

const config = policyConfig[`parent_only`];

export function ParentOnly({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {

  return (
    <Toggle
      label={ config.label }
      description={ policyFormData.policy_type === PolicyType.STATEMENT_LATE_FEE ?
        `Only apply this policy to parent customers - will include any child customer invoices in the calculation.`
        : `Only apply this policy to parent customers - will not send statements to child customers.` }
      checked={ policyFormData.parent_only || false }
      onChange={ handleChange }
      invalid={ fieldIsValid(`parent_only`, invalids) }
      name={ `parent_only` }
    />
  );
}
