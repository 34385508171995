import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react';
import { When } from 'react-if';
import { IInvalidField } from 'shared';
import { Tooltip } from '@mui/material';

import { classNames }  from '../lib/classNames';
import { HelpIcon } from '../../main/components/Molecules/HelpIcon';

import { FormDescription } from './FormDescription';

interface ComboBoxProps {
  options: {
    label: string;
    value: string;
  }[];
  onSelect: (e: { value: string; label: string }) => void;
  query: string;
  onQueryChange: (e: { target: { value: string } }) => void;
  label?: string;
  selected?: {
    label: string;
    value: string;
  };
  loading?: boolean;
  placeholder?: string;
  invalid?: boolean | IInvalidField;
  helpIcon?: string;
  description?: string;
  hideChevron?: boolean;
}

export function ComboBox({
  options,
  onSelect,
  query,
  onQueryChange,
  label,
  selected,
  loading,
  placeholder,
  invalid,
  helpIcon,
  description,
  hideChevron,
}: ComboBoxProps) {
  return (
    <Combobox as={ `div` }
      value={ selected }
      onChange={ onSelect }
    >
      <Label
        className={ `flex text-sm font-medium text-gray-700 ` }>
        { label }
        <When condition={ !!helpIcon }>
          <HelpIcon
            className={ `ml-1` }
            tooltip={ helpIcon }
          />
        </When>
      </Label>
      <div className={ `relative mt-1` }>
        <ComboboxInput
          className={
            classNames(
              `w-full rounded-md bg-white py-2 pl-3 pr-10 shadow-sm focus:border-lateGreen-500 focus:outline-none focus:ring-1 focus:ring-lateGreen-500 sm:text-sm`,
              invalid ? `border-red-600` : `border-gray-300`,
            )
          }
          onChange={ onQueryChange }
          displayValue={ () => loading ? `loading..` : (query || selected?.label || ``) }
          onBlur={ () => onQueryChange({ target: { value: `` } }) }
          placeholder={ placeholder }
        />
        <ComboboxButton
          className={
            classNames(
              `absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none`,
            )
          }>
          <When condition={ !hideChevron }>
            <ChevronUpDownIcon className={ `h-5 w-5 text-gray-400` }
              aria-hidden={ `true` } />
          </When>
        </ComboboxButton>

        <When condition={ !loading  && options.length > 0 }>
          <ComboboxOptions className={ `absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm` }>
            { options.map(option => (
              <ComboboxOption
                key={ option.value }
                value={ option }
                className={ ({ focus }) =>
                  classNames(
                    `relative cursor-default select-none py-2 pl-3 pr-9`,
                    focus ? `bg-lateGreen-600 text-white` : `text-gray-900`,
                  )
                }
              >
                { ({ focus, selected }) => (
                  <>
                    <Tooltip
                      title={ option.label }
                      placement={ `left` }
                    >
                      <span className={ classNames(`block truncate`, selected && `font-semibold`) }>{ option.label }</span>
                    </Tooltip>

                    { selected && (
                      <span
                        className={ classNames(
                          `absolute inset-y-0 right-0 flex items-center pr-4`,
                          focus ? `text-white` : `text-lateGreen-600`,
                        ) }
                      >
                        <CheckIcon className={ `h-5 w-5` }
                          aria-hidden={ `true` } />
                      </span>
                    ) }
                  </>
                ) }
              </ComboboxOption>
            )) }
          </ComboboxOptions>
        </When>
      </div>
      <FormDescription>
        { description }
      </FormDescription>
    </Combobox>
  );
}
