import React, { useMemo } from 'react';
import { When } from 'react-if';

import { classNames }  from '../lib/classNames';

export function getClassnameForStackable(stackable) {
  switch (stackable) {
  case `sm`:
    return {
      heading: `hidden lg:table-cell`,
      row: {
        stacked: `sm:hidden`,
        main: `hidden lg:table-cell`,
      },
    };
  case `lg`:
    return {
      heading: `hidden sm:table-cell`,
      row: {
        stacked: `sm:hidden`,
        main: `hidden sm:table-cell`,
      },
    };
  default:
    return {
      heading: ``,
    };
  }
}

export function TableRow({ row, headings, onRowClick }) {
  const hasAnyStacked = useMemo(() => {
    return headings.filter(r => r.stackable);
  }, [headings]);

  return (
    <tr
      className={
        classNames(
          onRowClick && `cursor-pointer hover:bg-gray-50`,
        )
      }
      onClick={ () => onRowClick && onRowClick(row) }
    >
      {
        headings.map((r, i) => {
          return (
            <td
              key={ i }
              className={
                classNames(
                  `text-sm py-4`,
                  i === 0 ? `w-full max-w-0 text-gray-900 font-medium sm:w-auto sm:max-w-none sm:pl-0` : `px-3 text-gray-500 text-center`,
                  r.textAlign === `right` && `text-right`,
                  r.textAlign === `left` && `text-left`,
                  getClassnameForStackable(r.stackable).row?.main,
                )
              }
            >
              <When condition={ hasAnyStacked.length > 0 && i === 0 }>
                <dl className={ `font-normal lg:hidden` }>
                  {
                    hasAnyStacked.map((r, i) => {
                      return (
                        <dd
                          key={ i }
                          className={
                            classNames(
                              `mt-1 truncate`,
                              getClassnameForStackable(r.stackable).row?.stacked,
                              i === 0 ? `text-gray-700` : `text-gray-500`,
                            )
                          }>
                          { row[r.name] }
                        </dd>
                      );
                    })
                  }
                </dl>
              </When>
              { row[r.name] }
            </td>
          );
        })
      }
    </tr>
  );
}

