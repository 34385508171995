import { classNames } from "../lib/classNames";

interface LoadingElementPlaceholderProps {
  /** How many text rows to emulate */
  rows?: number;
  className?: string;
  border?: boolean;
}

export function LoadingElementPlaceholder({ rows = 4, className, border = true }: LoadingElementPlaceholderProps) {
  const displayRows = Array.from({ length: rows }).map((_, index) => {
    if (index % 2 === 0) {
      return (
        <div key={ index }
          className={ `h-2 bg-slate-300 rounded` }
        />
      );

    }

    return (
      <div key={ index }
        className={ `space-y-3` }>
        <div className={ `grid grid-cols-3 gap-4` }>
          <div className={ `h-2 bg-slate-300 rounded col-span-2` }></div>
          <div className={ `h-2 bg-slate-300 rounded col-span-1` }></div>
        </div>
      </div>
    );
  });

  return (
    <div className={ classNames(
      `p-4 w-full mx-auto`,
      className,
      border ? `border shadow-sm rounded-md ` : ``,
    ) }>
      <div className={ `animate-pulse flex space-x-4` }>
        <div className={ `flex-1 space-y-6 py-1` }>
          {
            displayRows
          }
        </div>
      </div>
    </div>
  );
}
