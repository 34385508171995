import { policyConfig } from "shared";

import { fieldIsValid } from "../../../../../lib/helper";
import { CurrencyInput } from "../../../../../../common/Atoms/CurrencyInput";

import { IFieldProps } from "./fieldProps";

const config = policyConfig.fixed_fee_amount;

export function StatementChargeFixedAmount({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {

  return (
    <CurrencyInput
      label={ config.label }
      description={ `Apply a fixed fee line item to the late fee. Set to 0 to not add. Uses the contacts, or organisations default currency.` }
      value={ policyFormData.fixed_fee_amount }
      onChange={ handleChange }
      name={ `fixed_fee_amount` }
      invalid={ fieldIsValid(`fixed_fee_amount`, invalids) }
      dataType={ `number` }
    />
  );
}
