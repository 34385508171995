import { validatePolicy, emailTemplateSchema, yupErrorMessageParse, groupSchema } from 'shared';

export async function validateForm<T>(form: unknown, type: `POLICY` | `EMAIL_TEMPLATE` | `GROUP`) {
  let schemaValidator = null;

  switch (type) {
  case `POLICY`:
    schemaValidator = async function(pol) {
      return await validatePolicy(pol, false);
    };
    break;
  case `EMAIL_TEMPLATE`:
    schemaValidator = async function(template) {
      return await emailTemplateSchema.validate(template, { abortEarly: false });
    };
    break;
  case `GROUP`:
    schemaValidator = async function(template) {
      return await groupSchema.noUnknown().validate(template, { abortEarly: false });
    };
    break;
  }

  if (!schemaValidator) {
    console.error(`No schemaValidator found for type`, type);

    return {
      valid: false,
      invalids: [],
    };
  }

  try {
    const valid = await schemaValidator(form);

    return {
      valid: true,
      form: valid as T,
      invalids: [],
    };
  }
  catch (err) {

    const errorMessages = yupErrorMessageParse(err);

    if (Array.isArray(errorMessages)) {
      return {
        valid: false,
        invalids: errorMessages,
      };
    }

    console.error(`Error parsing yup error messages`, err);

    return {
      valid: false,
      invalids: [],
    };
  }
}
