import { Outlet } from 'react-router';
import { Cog6ToothIcon, AtSymbolIcon, WrenchScrewdriverIcon, ComputerDesktopIcon, CurrencyDollarIcon, DocumentChartBarIcon } from '@heroicons/react/24/outline';

import { Tabs } from '../../../../common/Atoms/Tabs';
import { LoadingOverlay } from '../../Molecules/LoadingOverlay';
import { useGeneralSettingsForm } from '../../../hooks/useGeneralSettingsForm';

export function SettingsRoot() {
  const { waiting } = useGeneralSettingsForm();

  const tabs = [
    {
      name: `General`,
      href: ``,
      icon: Cog6ToothIcon,
    },
    {
      name: `Email Preferences`,
      href: `email`,
      icon: AtSymbolIcon,
    },
    {
      name: `Statement Settings`,
      href: `statement`,
      icon: DocumentChartBarIcon,
    },
    {
      name: `Portal Settings`,
      href: `portal`,
      icon: ComputerDesktopIcon,
    },
    {
      name: `Advanced`,
      href: `advanced`,
      icon: WrenchScrewdriverIcon,
    },
    {
      name: `Payment Providers`,
      href: `payments`,
      icon: CurrencyDollarIcon,
      new: true,
    },
  ];

  return (
    <>
      <Tabs
        tabs={ tabs }
      />

      <LoadingOverlay
        loading={ waiting }
      >
        <Outlet  />
      </LoadingOverlay>
    </>
  );
}
