import { useParams } from "react-router";

import { useFetchOrganisationMetricsQuery } from "../services/api/organisationApi/organisation";

export function useOrgMetrics() {
  const { organisationId } = useParams<{ organisationId: string }>();
  const result = useFetchOrganisationMetricsQuery(organisationId, {
    skip: !organisationId,
    refetchOnMountOrArgChange: true,
    pollingInterval: 1000 * 30, // 30 seconds
    skipPollingIfUnfocused: true,
  });

  return result;
}
