import { useFetchPlansQuery } from '../services/api/paymentsApi/payment';

export function usePlans() {
  const result = useFetchPlansQuery({});

  if (!result.data) {
    result.data = [];
  }

  return result;
}
