
import { useState } from "react";
import { toast } from "react-toastify";

import { Button } from "../../../../common/Atoms/Button";
import { useApiRequest } from "../../../hooks/useApiRequest";
import { env } from "../../../../common/lib/env";

interface StatementPreviewProps {
  hasUnsavedChanges: boolean;
}

export function StatementPreview({ hasUnsavedChanges }: StatementPreviewProps) {
  const [downloading, setDownloading] = useState(false);
  const request = useApiRequest();

  async function downloadAsPDF() {
    setDownloading(true);

    try {
      const result = await request({
        method: `GET`,
        responseType: `blob`,
        url: `${env.baseApiUrl}contact/preview/statement`,
      });

      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement(`a`);
      link.href = url;
      link.setAttribute(`download`, `test_statement_outstanding.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    catch (e) {
      const responseObj = await e.response?.data?.text();
      toast.error(`${responseObj ? `${responseObj}` : `Could not create statement.`}`);
    }

    setDownloading(false);
  }

  return (
    <Button
      loading={ downloading }
      onClick={ downloadAsPDF }
      disabled={ hasUnsavedChanges }
      tooltip={ `To view statement with real data, send a test email of statement type under "Email Templates"` }
    >
      { hasUnsavedChanges ? `Preview (Unsaved Changes)` : `Preview Statement` }
    </Button>
  );
}
