import { env } from "../../common/lib/env";

export function MaintenanceBanner() {
  if (!env.maintenanceMode) return null;

  return (
    <div
      className={ `bg-red-700 text-white text-center p-4 h-28 flex justify-center items-center` }
    >
      { `We are currently undergoing maintenance. You may experience some issues. Please check back later.` }
    </div>
  );
}
