import { Tooltip } from "@mui/material";
import { ActionStatus } from "shared";
import { When } from "react-if";

import { Button } from "../../../common/Atoms/Button";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { QuickFilterTypes, useActionTableModels } from "../../hooks/useActions";

export function ActionQuickFilters() {
  const { onQuickFilter, currentTab } = useActionTableModels();

  const hasFilters = currentTab === ActionStatus.PENDING || currentTab === ActionStatus.COMPLETE;

  if (!hasFilters) {
    return null;
  }

  return (
    <div className={ `mb-1` }>
      <Paragraph
        variant={ `help` }
      >
        { `Quick Filters` }
      </Paragraph>
      <div className={ `space-x-2` }>
        <When condition={ currentTab === ActionStatus.PENDING }>
          <Tooltip title={ `Actions that are pending and also due to be processed within immediately` }>
            <Button
              color={ `gray` }
              onClick={ () => onQuickFilter(QuickFilterTypes.pendingAndDue) }
            >
              { `Scheduled Now` }
            </Button>
          </Tooltip>
        </When>

        <When condition={ currentTab === ActionStatus.COMPLETE }>
          <Tooltip title={ `View successful actions completed in the last 2 days` }>
            <Button
              color={ `gray` }
              onClick={ () => onQuickFilter(QuickFilterTypes.recentlyCompleted) }
            >
              { `Recently Complete` }
            </Button>
          </Tooltip>
        </When>
      </div>
    </div>
  );
}
