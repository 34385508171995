import { Toggle } from '../../../../../common/Atoms/Toggle';
import { TwoColFormRow } from '../../../Molecules/Form/TwoColFormRow';

import { OnChangeFn } from './GeneralSettings';

interface SafeModeProps {
  safeModeOn: boolean
  setSafeModeOn: OnChangeFn
}

export function SafeMode({ safeModeOn, setSafeModeOn }: SafeModeProps) {
  function onChange() {
    setSafeModeOn(`active`, !!safeModeOn);
  }

  return (
    <TwoColFormRow
      heading={ `Safe Mode` }
    >
      <Toggle
        label={ `Safe Mode` }
        checked={ safeModeOn }
        onChange={ onChange }
        description={ `Safe mode prevents automatic processing of invoices. Turn off safe mode when you are ready to start applying policies automatically.` }
        secondaryDescription={ `Pending actions will still be created in safe mode, so you can see which actions will occur when safe mode is turned off.` }
      />
    </TwoColFormRow>
  );
}
