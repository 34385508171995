import { policyConfig } from "shared";

import { fieldIsValid } from "../../../../../lib/helper";
import { Toggle } from "../../../../../../common/Atoms/Toggle";

import { IFieldProps } from "./fieldProps";

export function DoNotSendEmailCustomer({
  policyFormData,
  invalids,
  handleChange,
}: IFieldProps) {
  const label = policyConfig.do_not_send_email_to_customer.label;

  return (
    <Toggle
      label={ label }
      helpIcon={ `Only send a copy of the email to your reply to address.` }
      checked={ policyFormData.do_not_send_email_to_customer || false }
      onChange={ handleChange }
      name={ `do_not_send_email_to_customer` }
      invalid={ fieldIsValid(`do_not_send_email_to_customer`, invalids) }
    />
  );
}
