
import { When } from "react-if";

import { Modal } from "../../../common/Atoms/Modal";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { Divider } from "../../../common/Atoms/Divider";
import { env } from '../../../common/lib/env';
import connectQBOButton from '../../assets/QuickBooks-connect-button/connect-to-quickbooks-tall-default_2x.png';
import { XeroConnectButtonBlue } from "../XeroConnectButtonBlue";

interface ReconnectModal {
  onCancel: () => void;
  show: boolean;
  type: string;
}

export function ReconnectModal({ onCancel, show, type }: ReconnectModal) {
  function onClose() {
    // This is the top X, treat as cancel
    onCancel();
  }

  return (
    <Modal
      title={ `` }
      open={ show }
      setOpen={ onClose }
    >

      <div className={ `flex flex-col items-center mt-6` }>
        <Paragraph variant={ `primary` }>
          { `Paidnice is unable to connect to this organisation, please reconnect to ${type} by clicking the button below. Once reconnected all settings will be restored.` }
        </Paragraph>

        <Divider weight={ `light` } />

        <When condition={ type === `Xero` }>
          <div>
            <a href={ `${env.apiUrl}/connect/xero` }>
              <XeroConnectButtonBlue />
            </a>
          </div>
        </When>
        <When condition={ type === `QuickBooks` }>
          <div>
            <a href={ `${env.apiUrl}/connect/quickbooks` }>
              <img width={ `245px` }
                style={ { width: `245px`, maxWidth: `none` } }
                src={ connectQBOButton } />
            </a>
          </div>
        </When>

      </div>

    </Modal>
  );
}

