import { useParams } from 'react-router';

import { useFetchPoliciesQuery } from '../services/api/policyApi/policy';

export function usePolicies() {
  const { organisationId } = useParams();
  const result = useFetchPoliciesQuery(
    organisationId,
    {
      skip: !organisationId,
    });

  return {
    ...result,
    data: result.data?.policies,
    policyFilters: result.data?.policyFilters || [],
  };
}
