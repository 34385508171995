import { currencyFormatter, IEscalationOutcome, IEscalationResponseItem, toDecimal } from "shared";
import { useMemo } from "react";
import { DateTime } from "luxon";
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

import { DescriptionItem, DescriptionList } from "../../../../common/Atoms/DescriptionList";
import { getEscalationDisplayStatus } from "../EscalationsTable";
import { WrappedLink } from "../../WrappedLink";
import { ExpectedPaymentDate } from "../../Common/ExpectedPaymentDate";
import { useAddNoteMutation, useAssignEscalationMutation, useClearAssignedEscalationMutation } from "../../../services/api/escalationApi/escalation";
import { useGetSelectedOrganisation } from "../../../hooks/useGetSelectedOrganisation";
import { Assignee } from "../../Common/Assignee";
import { DatePopover } from "../../../../common/Components/DatePopover";

interface OverviewProps {
  escalation: IEscalationResponseItem;
}

export function Overview({ escalation }: OverviewProps) {
  const [addNoteApiCall] = useAddNoteMutation();
  const [assignEscalationApiCall, { isLoading: assignLoading }] = useAssignEscalationMutation();
  const [clearAssignedUserApiCall, { isLoading: clearAssignLoading }] = useClearAssignedEscalationMutation();
  const organisation = useGetSelectedOrganisation();

  // For esclation save
  async function onSaveExpectedDate(date: string) {
    if (!escalation) return;

    if (!date) return;

    const formattedDate = DateTime.fromISO(date, { zone: organisation.validatedTimezone }).toFormat(`ccc dd LLL yyyy`);

    await addNoteApiCall({
      organisationId: escalation.organisationId,
      escalationId: escalation.id,
      expectedPaymentDate: date ? date : null,
      note: `Expected payment date set to ${formattedDate}`,
      outcome: IEscalationOutcome.SET_EXPECTED_PAYMENT_DATE,
    });
  }

  function assign(userId: string | null) {
    if (!escalation) return;

    if (!userId) {
      unassign();

      return;
    }

    assignEscalationApiCall({
      organisationId: escalation.organisationId,
      escalationId: escalation.id,
      userId,
    });
  }

  function unassign() {
    if (!escalation) return;

    if (!escalation.assignedToUserId) return;

    clearAssignedUserApiCall({
      organisationId: escalation.organisationId,
      escalationId: escalation.id,
    });
  }

  const items = useMemo(() => {
    if (!escalation) return [];

    const formatter = currencyFormatter(escalation.invoice?.xero_currency);

    const statusContent = getEscalationDisplayStatus(escalation);

    const contactContent = (
      <WrappedLink
        to={ `/contacts/${escalation?.contact.id}` }
        className={ `hover:underline text-indigo-600 hover:text-indigo-800 ` }
      >
        { escalation?.contact?.name }
      </WrappedLink>
    );

    const assigneeContent = (
      <Assignee
        current={ escalation?.assignedToUserId || null }
        onChange={ assign }
        loading={ assignLoading || clearAssignLoading }
      />
    );

    const commonProps: DescriptionItem[] = [
      {
        title: `Status`,
        content: statusContent,
      },
      {
        title: `Assignee`,
        content: assigneeContent,
      },
      {
        title: `Customer`,
        content: contactContent,
      },
      {
        title: `Expected Payment Date`,
        content: <ExpectedPaymentDate
          onSave={ onSaveExpectedDate }
          currentDate={ escalation.invoice?.expected_payment_date_str }
        />,
      },
      {
        title: `Invoice Number`,
        content: escalation.invoice?.xero_number ? (
          <WrappedLink
            to={ `/invoices/${escalation.invoice?.id}` }
            className={ `text-blue-600 underline flex items-start` }
          >
            { escalation.invoice?.xero_number }
            <ArrowTopRightOnSquareIcon
              className={ `inline-block w-4 h-4 ml-1` }
              aria-hidden={ `true` }
            />
          </WrappedLink>
        ) : `-`,
      },
      {
        title: `Invoice Total Due`,
        content: formatter(toDecimal(escalation.invoice?.xero_amount_due)),
      },
      {
        title: `Invoice Due Date`,
        content: <DatePopover
          date={ escalation.invoice?.due_date_string }
          labelFormat={ `ccc dd LLL yyyy` }
          organisationTimezone={ organisation.validatedTimezone }
        />,
      },
      {
        title: `Escalation Date`,
        content: <DatePopover
          date={ escalation.escalationDate }
          labelFormat={ `ccc dd LLL yyyy` }
          organisationTimezone={ organisation.validatedTimezone }
        />,
      },
      {
        title: `Instructions`,
        content: escalation.escalationSteps,
      },
    ];

    return commonProps;
  }, [escalation, assignLoading, clearAssignLoading, organisation]); //TODO: more dependencies

  return (
    <DescriptionList
      items={ items }
      title={ `Escalation Details` }
    />
  );
}
