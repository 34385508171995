import { mergeTags } from 'shared';

import { env } from '../../common/lib/env';

import { useGetSelectedOrganisation } from './useGetSelectedOrganisation';

//import { useSettings } from '../hooks/useSettings';

export function useMergeTags(type: keyof typeof mergeTags) {
  const currentOrg = useGetSelectedOrganisation();
  if (!type) return {
    asArray: [],
    asTestData: {},
  };

  const result = mergeTags[type];

  // const { data: settings } = useSettings();
  // if (!settings.paymentProviders) {
  //   delete result[`PaymentLink`];
  // }

  // TODO: make this conditional on settings, for now totally hide it from the UI
  delete result[`PaymentLink`];

  const asTestData = {};

  Object.keys(result).forEach(key => {
    if (result[key].devOnly && !env.isDev) {
      delete result[key];

      return; // skip this key
    }

    if (result[key].appliesToAccounting && !result[key].appliesToAccounting.includes(currentOrg.type)) {
      delete result[key];

      return; // skip this key
    }

    if (result[key].requiresPaymentProvider) {
      const currentProviders = currentOrg.paymentProviders.map(p => p.type);

      if (!currentProviders.includes(result[key].requiresPaymentProvider)) {
        delete result[key];

        return; // skip this key
      }
    }

    asTestData[result[key].value] = result[key].sample;
  });

  const asArray = Object.values(result);

  return {
    asArray,
    asTestData,
  };
}
