import { langauge, statementColumnOrder, StatementLogoPosition } from "shared";
import { When } from "react-if";

import { Card } from "../../../../../common/Atoms/Card";
import { CardContent } from "../../../../../common/Atoms/CardContent";
import { Notification } from "../../../../../common/Atoms/Notification";
import { Select } from "../../../../../common/Atoms/Select";
import { useGeneralSettingsForm } from "../../../../hooks/useGeneralSettingsForm";
import { fieldIsValid } from "../../../../lib/helper";
import { TwoColFormRow } from "../../../Molecules/Form/TwoColFormRow";
import { SaveAndError } from "../SaveAndError";
import { StatementPreview } from "../StatementPreview";
import { Grid } from "../../../../../common/Atoms/Grid";
import { GridItem } from "../../../../../common/Atoms/GridItem";
import { Toggle } from "../../../../../common/Atoms/Toggle";
import { InputTextAddon } from "../../../../../common/Atoms/InputTextAddon";
import { FormLabel } from "../../../../../common/Atoms/FormLabel";
import { FormDescription } from "../../../../../common/Atoms/FormDescription";
import { Paragraph } from "../../../../../common/Atoms/Typography/Paragraph";
import { classNames } from "../../../../../common/lib/classNames";

const positionOptions = [
  {
    value: StatementLogoPosition.Right,
    label: `Right (Best for square logos)`,
  },
  {
    value: StatementLogoPosition.Top,
    label: `Top (Best for wide logos)`,
  },
];

export function StatementRoot() {
  const {
    formData,
    onChange,
    hasUnsavedChanges,
    invalids,
  } = useGeneralSettingsForm();

  return (
    <Card>
      <CardContent className={ `pt-6` }>
        <TwoColFormRow
          heading={ `Statement Settings` }
          description={ `Control the look, feel and included data on your statements.` }
        >
          <Grid cols={ 12 }>
            <GridItem span={ 6 }>
              <Select
                name={ `statementLogoPosition` }
                label={ `Statement Logo Position` }
                options={ positionOptions }
                selected={ formData.statementLogoPosition }
                onChange={ v => onChange(`statementLogoPosition`, v.value) }
                invalid={ fieldIsValid(`statementLogoPosition`, invalids) }
              />
            </GridItem>

            <GridItem span={ 12 }>
              <Toggle
                label={ `Include Credit Notes` }
                description={ `Show customers any outstanding credit notes and related data such as net balance.` }
                name={ `includeCreditNotes` }
                checked={ formData.includeCreditNotes || false }
                onChange={ () => onChange(`includeCreditNotes`, !formData.includeCreditNotes) }
              />
            </GridItem>

            <GridItem span={ 12 }>
              <Toggle
                label={ `Disable statement links` }
                description={ `Disable links in the statement PDFs to view the invoice in the Paidnice customer portal.` }
                name={ `disableStatementLinks` }
                checked={ formData.disableStatementLinks || false }
                onChange={ () => onChange(`disableStatementLinks`, !formData.disableStatementLinks) }
              />
            </GridItem>

            <GridItem span={ 12 }>
              <Toggle
                label={ `Always use legal name` }
                description={ `Always use your organisation's legal name, even when "Use Display Name" is enabled.` }
                name={ `statementAlwaysLegalName` }
                checked={ formData.statementAlwaysLegalName || false }
                onChange={ () => onChange(`statementAlwaysLegalName`, !formData.statementAlwaysLegalName) }
              />
            </GridItem>
            { /* Aged Analysis BreakDown */ }
            <GridItem span={ 12 }>
              <FormLabel>
                { `Aged Analysis Breakdown` }
              </FormLabel>
              <FormDescription>
                { `Adjust the brackets of the aged debts shown on the statement. Clear to remove the breakpoint.` }
              </FormDescription>
              <div className={ `grid grid-cols-3 gap-8` }>
                <InputTextAddon
                  dataType={ `number` }
                  name={ `agedAnalysisBreakpointOne` }
                  description={ `Aged Analysis Breakpoint One` }
                  value={ formData.agedAnalysisBreakpointOne || `` }
                  onChange={ v => onChange(`agedAnalysisBreakpointOne`, (v.value)) }
                  invalid={ fieldIsValid(`agedAnalysisBreakpointOne`, invalids) }
                  addOnText={ `days` }
                />

                <InputTextAddon
                  dataType={ `number` }
                  name={ `agedAnalysisBreakpointTwo` }
                  description={ `Aged Analysis Breakpoint Two` }
                  value={ formData.agedAnalysisBreakpointTwo || `` }
                  onChange={ v => onChange(`agedAnalysisBreakpointTwo`, (v.value)) }
                  invalid={ fieldIsValid(`agedAnalysisBreakpointTwo`, invalids) }
                  addOnText={ `days` }
                  clearable
                />

                <InputTextAddon
                  dataType={ `number` }
                  name={ `agedAnalysisBreakpointThree` }
                  description={ `Aged Analysis Breakpoint Three` }
                  value={ formData.agedAnalysisBreakpointThree || `` }
                  onChange={ v => onChange(`agedAnalysisBreakpointThree`, (v.value)) }
                  invalid={ fieldIsValid(`agedAnalysisBreakpointThree`, invalids) }
                  addOnText={ `days` }
                  clearable
                />
              </div>
            </GridItem>
          </Grid>

          { /* Statement Column Preferences */ }
          <div className={ `mt-6 mb-2` }>
            <FormLabel>
              { `Statement Column Preferences` }
            </FormLabel>
            <FormDescription>
              { `Choose which columns to show on your statements.` }
            </FormDescription>
          </div>
          <When condition={ !!formData.statementColumnSettings }>
            { () => (
              <div>
                {
                  statementColumnOrder
                    .map((key, i) => {
                      return (
                        <div
                          className={ classNames(
                            `grid grid-cols-6 p-1 rounded-sm`,
                            i % 2 === 0 ? `bg-gray-100` : ``,
                          ) }
                          key={ i }
                        >

                          <div className={ `col-span-4 flex items-center` }>
                            <Paragraph variant={ `secondary` }>
                              { langauge.settings.statement.statementColumnSettings[key].label }
                            </Paragraph>
                          </div>

                          <div className={ `col-span-2` }>
                            <Select
                              selected={ formData.statementColumnSettings[key] }
                              onChange={ v => {
                                onChange(`statementColumnSettings`, {
                                  ...formData.statementColumnSettings,
                                  [key]: v.value,
                                });
                              } }
                              options={ [
                                {
                                  value: `show`,
                                  label: `Show`,
                                },
                                {
                                  value: `hide`,
                                  label: `Hide`,
                                },
                                {
                                  value: `truncate`,
                                  label: `Shorten`,
                                },
                              ] }
                            />
                          </div>
                        </div>
                      );
                    })
                }
              </div>
            ) }
          </When>

        </TwoColFormRow>

        <Notification
          className={ `mt-6` }
          type={ `info` }
        >
          { `Logo and payment terms/footer are set under "general"` }
        </Notification>

        <div className={ `flex justify-end mt-2` }>
          <StatementPreview
            hasUnsavedChanges={ hasUnsavedChanges }
          />
        </div>
        <SaveAndError
          className={ `mt-2` }
        />
      </CardContent>
    </Card>
  );
}
