import React from 'react';

import { classNames }  from '../../../common/lib/classNames';

interface IFormProps {
  children: React.ReactNode;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit?: any; // TODO extend native props
}

export function Form({ children, className, ...props }: IFormProps) {
  return (
    <form { ...props }
      className={
        classNames(
          `h-full`,
          className,
        )
      }>
      { children }
    </form>
  );
}
