import { Button } from "../../../../../common/Atoms/Button";
import { Divider } from "../../../../../common/Atoms/Divider";
import { useActions } from "../../../../hooks/useActions";
import { useInvoice } from "../../../../hooks/useInvoice";
import { useRecalculateActionsMutation } from "../../../../services/api/actionApi/action";
import { LoadingOverlay } from "../../../Molecules/LoadingOverlay";
import { Timeline } from "../../../TimeLine/Timeline";

export function ActionOverview() {
  const { data: actionsData, isLoading, isFetching } = useActions();
  const { invoice } = useInvoice();
  const [recalculateActions, { isLoading: recalculateLoading }] = useRecalculateActionsMutation();

  const actions = actionsData?.actions;

  function onRecalc() {
    recalculateActions({
      organisationId: invoice.organisation_id,
      invoiceId: invoice.id,
    });
  }

  return (
    <div className={ `lg:col-start-3 bg-white p-6 ring-1 ring-gray-900/5 rounded-lg min-h-full` }>
      { /* Activity feed */ }
      <div className={ `flex justify-between` }>
        <div className={ `max-w-[60%]` }>
          <h2 className={ `text-sm font-semibold leading-6 text-gray-900 flex items-center` }>{ `Action Overview` }</h2>
          <p className={ `text-sm text-gray-500` }>
            { `Calculated automatically several times daily.` }
          </p>
        </div>
        <Button
          onClick={ onRecalc }
          loading={ recalculateLoading }
        >
          { `Recalculate` }
        </Button>
      </div>
      <LoadingOverlay
        loading={ isLoading || isFetching }
        contrast={ `light` }
      >
        <Divider />
        <Timeline
          actions={ actions }
          viewMoreSearchStr={ invoice?.xero_number }
          context={ `invoice` }
          emptyMessage={
            (
              <span>
                { `No upcoming events.` }
                <br/>
                <br/>
                { `Use the "Find Matching Policies" tool to find why policies may not apply` }
              </span>
            )
          }/>
      </LoadingOverlay>
    </div>
  );
}
