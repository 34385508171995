import { IInvalidField, IPolicy, langauge, PolicyState, validatePolicySync } from "shared";
import { useMemo, useState } from "react";
import { PauseCircleIcon, PencilSquareIcon, PlayCircleIcon, StopCircleIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

import { InputTextAddon } from "../../../../../../common/Atoms/InputTextAddon";
import { fieldIsValid } from "../../../../../lib/helper";
import { Heading } from "../../../../../../common/Atoms/Typography/Heading";
import { Card } from "../../../../../../common/Atoms/Card";
import { ThreeWayToggle, ToggleOption } from "../../../../../../common/Atoms/ThreeWayToggle";

interface IPolicyTitleProps {
  policy: IPolicy;
  invalids: IInvalidField[];
  handleChange: (obj: { name: string; value: string | number | boolean | Date }) => void;
}

export function PolicyTitle({
  policy,
  invalids,
  handleChange,
}: IPolicyTitleProps) {
  const [isEditing, setIsEditing] = useState(false);

  const policyValid = useMemo(() => {
    let policyValidVar = true;

    try {
      validatePolicySync(policy);
    }
    catch (e) {
      console.warn(e);
      policyValidVar = false;
    }

    return policyValidVar;
  }, [policy]);

  const stateOptions: ToggleOption[] = useMemo(() => {
    const invalidTooltip = `Policy is incomplete. Please update policy first`;

    return [
      {
        icon: PlayCircleIcon,
        tooltip: policyValid ? langauge.policy.state.active.tooltip : invalidTooltip,
        value: PolicyState.ACTIVE,
        color: `green`,
        disabled: !policyValid || policy?.state === PolicyState.ACTIVE,
        name: `Active`,
      },
      {
        tooltip: policyValid ? langauge.policy.state.approval.tooltip : invalidTooltip,
        value: PolicyState.APPROVAL,
        color: `orange`,
        icon: PauseCircleIcon,
        disabled: !policyValid || policy?.state === PolicyState.APPROVAL,
        name: `Draft`,
      },
      {
        tooltip: langauge.policy.state.inactive.tooltip,
        value: PolicyState.INACTIVE,
        color: `red`,
        icon: StopCircleIcon,
        disabled: !policyValid || policy?.state === PolicyState.INACTIVE,
        name: `Inactive`,
      },
    ];
  }, [policyValid, policy?.state]);

  if (policy?.system) {
    // system policy does not need top bar
    return null;
  }

  if (isEditing) {
    return (
      <Card className={ `flex w-full items-end p-4 shadow-md rounded-lg` }>
        <InputTextAddon
          value={ policy.title }
          onChange={ handleChange }
          required
          label={ `Policy name` }
          subHeading={ `Customers will not be able to see this name.` }
          name={ `title` }
          className={ `w-full` }
          invalid={ fieldIsValid(`title`, invalids) }
        />

        <button
          type={ `button` }
          className={ `ml-2 h-10` }
          color={ `gray` }
          onClick={ () => setIsEditing(false) }
        >
          <CheckCircleIcon className={ `w-8 h-8 text-lateGreen-600` } />
        </button>
      </Card>
    );
  }

  return (
    <Card className={ `p-4 shadow-md rounded-lg flex justify-between` }>
      <div className={ `flex space-x-1 items-center` }>
        <Heading>
          { policy.title }
        </Heading>
        <button
          type={ `button` }
          onClick={ () => setIsEditing(true) }
        >
          <PencilSquareIcon className={ `w-6 h-6 text-lateGreen-600` } />
        </button>
      </div>

      { /* Policy State */ }
      <div>
        <ThreeWayToggle
          value={ policy.state }
          onChange={ (e, value) => handleChange({ name: `state`, value }) }
          options={ stateOptions }
          fullDetail
        />
      </div>
    </Card>
  );
}
